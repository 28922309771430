import React from 'react';
import { ViewCard, ViewItemCard, Title, ViewItemCardDescription } from './styles';

type Props = {
    description: string;
    status: string;
    dataEnvio: string;
    isOpen?: boolean;
}

const Cards: React.FC<Props> = ({ description, status, dataEnvio, isOpen, children }) => {

    return (
        <ViewCard openProtocol={isOpen} role="article">

            <ViewItemCardDescription>
                <span><Title>Descrição: </Title>{description}</span>
            </ViewItemCardDescription>

            <ViewItemCard>
                <span><Title>Data de envio: </Title>{dataEnvio}</span>
            </ViewItemCard>

            <ViewItemCardDescription>
                <span><Title>Status: </Title>
                {
                    status === "NAO_VISUALIZADO" ?
                    "Não visto pela administradora"
                    :
                    "Visto pela administradora"
                }
                </span>
            </ViewItemCardDescription>

            {children}

        </ViewCard>
    )
};

export default Cards;