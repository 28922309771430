import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width:95%;
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
`;
