import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ChakraProvider } from '@chakra-ui/react';

import AppProvider from './hooks';
import { SessionListener } from './listeners/sessionListener';
import { RouteListener } from './listeners/routeListener';
import Routes from './routes';
import store from './store';
import { theme } from './styles/theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AppProvider>
          <ToastContainer />
          <Routes>
            <RouteListener />
          </Routes>
          <SessionListener />
        </AppProvider>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
