import styled from 'styled-components';
import { Table } from '../../components/Table/styles';

export const FormAgendamentoVisita = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    background: #FFFFFF;
    border-radius: 5px;

    @media screen and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    @media screen and (max-width: 768px) {
        margin: 20px auto;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`;

export const FormOption = styled.div`
    width: 18%;
    font-size: 0.85em;
    display: flex;
    flex-direction: column;

    input, select {
        margin-top: 5px;
        border-radius: 5px;
        border: 1px solid #B4B4B4;
        padding: 5px;
    }

    @media screen and (max-width: 1100px) {
        width: 40%;
        margin: 0 15px;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        margin: 0 auto;
    }
`;

export const Container = styled.div`
    min-height: 90vh;
    padding: 0 15px;
    width: 100%;
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;
  
  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;
    }

    tr:last-child {
      border: none;
    }

    td {
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const Load = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;