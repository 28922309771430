import { produce } from 'immer';
import IAction from '../../interfaces/IAction';

export const TypesTempFiles = {
  ADD_FILE: 'tempFiles/ADD_FILE',
  REMOVE_FILES: 'tempFiles/REMOVE_FILES',
  CLEAR_FILES: 'tempFiles/CLEAR_FILES',
};

// Reducer

const initialState = [] as Array<string>;

export default function reducer(state = initialState, action:IAction):Array<string> {
  switch (action.type) {
    case TypesTempFiles.ADD_FILE:
      return produce(state, (draft) => { draft.push(action.payload?.file); });
    case TypesTempFiles.REMOVE_FILES:
      return produce(state, (draft) => {
        draft.splice(action.payload?.indexFile, 1);
      });
    case TypesTempFiles.CLEAR_FILES:
      return initialState;

    default:
      return state;
  }
}

// Action Creators
export const ActionsTempFiles = {
  addFile,
  removeFile,
  clearFiles,
};

function addFile(file:string) {
  return {
    type: TypesTempFiles.ADD_FILE,
    payload: { file },
  } as IAction;
}

function removeFile(indexFile:number) {
  return {
    type: TypesTempFiles.REMOVE_FILES,
    payload: { indexFile },
  } as IAction;
}

function clearFiles() {
  return {
    type: TypesTempFiles.CLEAR_FILES,
  } as IAction;
}
