import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const ChatWindow = styled.div`
    display: flex;
    max-height: 80vh;
    min-height: 80vh;
    overflow-y: hidden;
    width: 100%;

    @media screen and (max-width: 520px) {
        max-height: unset;
        min-height: unset;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
    }
`;

export const CollapsedDiv = styled.div`
    width: 100%;
	background-color: #eee;
	padding: 0 10px;
    height: 60px;
	display: flex;
    justify-content: space-between;
	align-items: center;

    div {
        display: flex;
	    align-items: center;
        gap: 15px;
        color: ${COLORS.PRIMARY};
    }
`;

export const LeftContent = styled.div`
    width: 30%;
    /* height: 100%; */
    min-height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    font-size: 1em;

    @media screen and (max-width: 520px) {
        width: 100%;
        min-height: calc(100% - 60px);
        max-height: calc(100% - 60px);
    }

    &::-webkit-scrollbar {
	    width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
        background: orange !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${COLORS.PRIMARY};
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 6px !important;
        background: #f8f8f8 !important;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: #dcdceb;
    }
`;

export const NewChat = styled.div`
    margin: 10px;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.PRIMARY};
    color: #fff;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
`;

export const ChooseUser = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    height: 300px;
    padding: 0 2px;
`;

export const GoBack = styled.div`
    display: flex;
    gap: 5px;
    margin: 20px 0;
    color: ${COLORS.PRIMARY};
    cursor: pointer;
`;

export const EmptyMessage = styled.div`
    background-color: rgba(0,0,0,.2);
    width: 100%;
    padding: 5px;

  span {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const ContactButton = styled.div`
    padding: 20px 5px;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 12px;
    text-align: left;
    overflow-wrap: break-word;
    border: 2px solid #eee;
    border-left: none;
    border-right: none;
    cursor: pointer;

    @media screen and (max-width: 920px) {
        word-break: break-all;
    }

    @media screen and (max-width: 520px) {
        word-break: normal;
    }

    &:hover {
        background-color: #eee;
        transition-duration: .4s;
    }
`;

export const ContactCircle = styled.div`
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    background-color: #ccc;
    color: rgba(0,0,0,.8);
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RightContent = styled.div`
    width: 70%;
    min-height: 100%;
    max-height: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 520px) {
        width: 100%;
        /* height: 100%; */
        min-height: calc(100% - 60px);
        max-height: calc(100% - 60px);
    }
`;

export const HeaderChatName = styled.div`
    height: 40px;
    padding: 5px;
    font-size: 20px;
    text-align: center;
    display: grid;
    place-items: center;
    background-color: #fff;
    color: ${COLORS.PRIMARY};
    font-weight: 600;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }

    @media screen and (max-width: 520px) {
        font-size: 13px;
    }
`;

export const MiddleContent = styled.div`
    max-height: calc(100% - 145px);
    min-height: calc(100% - 145px);
    width: 100%;

    @media screen and (max-width: 520px) {
        min-height: calc(100% - 140px);
        max-height: calc(100% - 140px);
    }
`;

export const ChatGreetings = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 15px;
	height: 100%;
	width: 100%;
	margin: auto;
    color: rgba(0, 0, 0, 0.7);
	text-align: center;
	padding: 0 10px;
`;

export const ChatHistory = styled.ul`
	padding: 0 15px;
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    position: relative;
    overflow-y: scroll;

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
        background: orange !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${COLORS.PRIMARY};
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        width: 6px !important;
        background: #f8f8f8 !important;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: #dcdceb;
    }

    .out {
        background-color: #01AC77;
	    color: #fff;
        border-bottom-right-radius: 0px;
        float: right;
        text-align: right;
    }

    .in {
        background-color: #fff;
	    color: #000;
        border-bottom-left-radius: 0px;
        float: left;
    }
`;

export const ChatMessage = styled.div`
    box-shadow: 1px 5px 10px #485E713B;
    border-radius: 10px;
    max-width: 70%;
    height: fit-content;
    padding: 10px 20px;
    margin-top: 15px;
`;

export const MessageHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 5px;

    h5 {
        font-weight: 700;
    }
`;

export const MessageBody = styled.div`
    height: fit-content;
    font-weight: 700;
`;

export const MessageAlert = styled.div`
    position: sticky;
    right: 10px;
    bottom: 5px;
    align-self: flex-end;
    margin-top: -35px;
    border: 2px solid #fff;
    min-height: 50px;
    width: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: ${COLORS.PRIMARY};
    cursor: pointer;
`;

export const RedDot = styled.div`
    background-color: #f21515;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: 5px;
    border: 1px solid #fff; 
`;

export const BottomChat = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-around;
    padding: 5px 0;
`;

export const SendMessageContainer = styled.textarea`
    width: 90%;
    max-width: 90%;
    height: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 12px;
    resize: none;

    @media screen and (max-width: 520px) {
        width: 85%;
        max-width: 85%;
    }
`;

export const SendButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    color: ${COLORS.PRIMARY};
    background-color: #eee;

    @media screen and (max-width: 520px) {
        height: 40px;
        width: 40px;
    }
`;