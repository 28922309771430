/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
import React, { FormEvent, useEffect, useState } from 'react';
import {
  Flex,
  Image,
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './styles';
import logo from '../../assets/logo.png';

import { ActionsSession } from '../../store/ducks/session';
import { logar } from './services';
import BoxLogin from './components/BoxLogin';
import { MotionFlex, loginOptions } from './utils';
import WelcomeAnimation from './components/WelcomeAnimation';
import { LoginEndpoints } from './enum';
import useErrorToast from '../../hooks/useErrorToast';

interface ErrorMessages {
  [key: number]: string;
}

const errorMessages: ErrorMessages = {
  1: 'Digite novamente seu Usuário e senha.',
  4: 'Navegador não compatível, favor utilizar o Google Chrome, Mozilla Firefox ou Safari.',
  5: 'Portal em desenvolvimento.',
  6: 'Fora do horário de expediente.',
  7: 'Carga horária diária excedida.',
  8: 'Sua conta foi logada em outro computador ou em outro navegador.',
  9: 'Sua sessão expirou.',
};

// Handlig resized width
function useWindowSize() {
  const [sizeWidth, setSizeWidth] = useState(window.innerWidth);
  const [sizeHeight, setSizeHeight] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setSizeWidth(window.innerWidth);
      setSizeHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { sizeWidth, sizeHeight };
}

export default function Main() {
  const dispatch = useDispatch();
  const history = useHistory();

  const widthSize = useWindowSize();

  const [showFormLogin, setShowFormLogin] = useState<{
    route: string;
    id: null | number;
    selected: boolean;
  }>({ route: LoginEndpoints.Login, id: null, selected: false });
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const location = useLocation();

  const showErrorToast = useErrorToast(errorMessages);

  useEffect(() => {
    showErrorToast();
  }, [showErrorToast, location]);

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();

      const isManager = showFormLogin.id === 1;
      const isEmployee = showFormLogin.id === 4;

      const form = !isEmployee ? {
        tipo: isManager ? 3 : 1,
        documento: username,
        senha: password,
      } : {
        tipo: 0,
        login: username,
        senha: password,
      };
      const response = await logar(form);
      if (response.data.erro) {
        toast.error(`Erro: ${response.data.erro}`);
        return;
      }

      const descVinculo = (isManager || isEmployee)
        ? response.data?.listagem[0]?.condominio
        : response.data?.listagem[0]?.unidade;

      dispatch(
        ActionsSession.create({
          idUsuarioLogado: response.data.idUsuarioLogado,
          hash: response.data.hash,
          tipoUsuario: !isEmployee && response.data.tipo,
          documentoFuncionario: response.data.documento,
          isManager,
          isEmployee,
          todosVinculos: response.data.listagem,
          idAdm: !isEmployee && descVinculo.idAdministradora,
          logoAdm: !isEmployee && descVinculo.logoAdministradora,
          descVinculoAtual: !isEmployee && descVinculo.descricao,
          idUsuarioClassecon: !isEmployee && response.data.idUsuarioClassecon,
        }),
      );

      if (isEmployee) {
        history.push('/portal_funcionario');
      } else {
        history.push('/select_unity');
      }
    } catch (error) {
      toast.error('Houve um erro inesperado.');
    }
  };

  const currentLoginOption = loginOptions.find((option) => option.route === location.pathname) ?? {
    title: '', route: '/login/entrar', icon: '', iconWhite: '',
  };

  return (
    <S.FlexContainer>
      <S.FlexTopLogo>
        <Image src={logo} w="242px" h="56px" />
      </S.FlexTopLogo>

      <WelcomeAnimation currentLoginOption={currentLoginOption} />

      <Flex>
        {currentLoginOption.route === location.pathname ? (
          <BoxLogin
            setShowFormLogin={setShowFormLogin}
            currentLoginOption={currentLoginOption}
            onSubmit={handleSubmit}
            setUsername={setUsername}
            setPassword={setPassword}
            username={username}
            password={password}
            showFormLogin={showFormLogin}
          />
        ) : null}
        {
          (widthSize.sizeWidth > 728 || (currentLoginOption.route === LoginEndpoints.Login || currentLoginOption.route === LoginEndpoints.LoginEntrar))
            ? (
              <MotionFlex
                animate={{
                  x: showFormLogin.selected ? 2000 : 0,
                }}
                m={showFormLogin.selected ? '' : '0 auto'}
              >
                <S.FlexOptions>
                  {loginOptions.map((option, index) => (
                    <S.ButtonOption
                      aria-label="login-option"
                      key={index}
                      style={
                        showFormLogin.id === index ? { backgroundColor: '#3B308B', color: '#fff' } : { backgroundColor: '#fff', color: '#000' }
                      }
                      onClick={() => {
                        history.push(option.route);
                        if (showFormLogin.id === index) {
                          setShowFormLogin({ route: option.route, id: null, selected: false });
                        } else {
                          setShowFormLogin({ route: option.route, id: index, selected: true });
                        }
                      }}
                    >
                      <Image
                        src={showFormLogin.id === index ? option.iconWhite : option.icon}
                        mb="8px"
                        h="28px"
                      />
                      {option.title}
                    </S.ButtonOption>
                  ))}
                </S.FlexOptions>
              </MotionFlex>
            )
            : null
        }
      </Flex>

      <S.TextFooter>
        © 2020 - Ideia Soluções em Sistemas Ltda.
      </S.TextFooter>
    </S.FlexContainer>
  );
}

export { useWindowSize };
