import React from 'react';
import { Flex } from '@chakra-ui/react';

import { IconType } from 'react-icons/lib';
import CardPanel from './CardPanel';

type Props = {
  list: {
    title: string;
    icon?: IconType;
    initials?: string;
    route_path?: string;
    action?: string;
    emManutencao?: boolean;
  }[];
};

const ContentTabPanel: React.FC<Props> = ({ list }) => (
  <div>
    <Flex flexWrap='wrap' justifyContent='space-around' mt='16px'>
      {list.map((c, i) => (
        <CardPanel
          key={i}
          title={c.title}
          icon={c.icon}
          ml={(i + 1) % 2 === 0}
          initials={c.initials}
          route_path={c.route_path}
          action={c.action}
          emManutencao={c.emManutencao}
        />
      ))}
    </Flex>
  </div>
);

export default ContentTabPanel;
