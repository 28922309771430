import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;

div > div {
    display: flex;
    align-items: center;

    h4{
        margin-right: 12px;
    }
    
}

`;
