/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { IoIosCloseCircleOutline } from 'react-icons/io';

import request from '../../services/request';
import { goToExternalLink } from '../../utils/functions';
import Loading from '../Loading/Loading';

import * as S from './styles';

type Props = {
    closeModal : ()=> void
}
const ClubePartiuCondomino: React.FC<Props> = ({ closeModal }) => {

  const [link, setLink] = useState('');
  const [cancelVisible, setCancelVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAceiteTerms, setshowAceiteTerms] = useState(false);
  const [administradora, setAdministradora] = useState('');

  function handleVisibleCancel(status: boolean) {
    setCancelVisible(status);
  }

  // useEffect(() => {
  //   dispatch(ActionsHeader.changeTextCenter('Partiu Clube de Vantagens'));
  // }, [dispatch]);

  useEffect(() => {
    const aceiteTerms = localStorage.getItem('classecon:aceite_terms');

    if (!aceiteTerms) {
      setshowAceiteTerms(true);
    } else {
      verifyClubePartiu();
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('@classecon/SESSION');
    if (user) {
      const userParsed = JSON.parse(user);
      const administradoraUser = userParsed?.todosVinculos[0]?.unidade?.administradora;

      setAdministradora(administradoraUser);
    }
  }, []);

  function verifyClubePartiu() {
    setLoading(true);
    request.get('isClubePartiuAtivo').then((response) => {
      if (response.data.ok) {
        setLink(response.data.link);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  function cancelClube() {
    if (!link) {
      return;
    }

    request.post('cancelarClubePartiu').then((response) => {
      if (response.data.ok) {
        closeModal();
        alert('Clube cancelado com sucesso');
      } else {
        alert('Falha ao cancelar clube, entre em contato com a administradora');
      }
    });

    setCancelVisible(false);
  }

  function handleAccessClube() {
    if (!link) {
      return;
    }

    goToExternalLink(link);
  }

  function handleAceiteTerms() {
    localStorage.setItem('classecon:aceite_terms', 'true');
    setshowAceiteTerms(false);
    verifyClubePartiu();
  }

  return (
    <S.Container>
      <h4>Partiu Clube de Vantagens</h4>

      <S.Wrapper>
        {showAceiteTerms ? (
          <S.TemrmContainer>
            <h1>
              Termos e condições de serviços
              {' '}
              {administradora === 'Predial' ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://predial.partiu.com.br/legal/termos-de-uso"
                >
                  acesse nesse link
                </a>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://aspnewpred.partiu.com.br/legal/termos-de-uso"
                >
                  acesse nesse link
                </a>
              )}
            </h1>

            <p>Eu li e aceito os Termos de condições dos serviços.</p>
            <div>
              <S.ButtonCancel primary type="button" onClick={handleAceiteTerms}>
                SIM
              </S.ButtonCancel>
              <S.ButtonCancel onClick={closeModal} type="button">
                NÃO
              </S.ButtonCancel>
            </div>
          </S.TemrmContainer>
        ) : (
          <>
            {cancelVisible ? (
              <S.CancelContainer>
                <p>
                  Ao realizar o cancelamento você não terá mais acesso ao clube
                  de vantagens e os valores cobrados nos boletos em aberto serão
                  removidos imediatamente. Deseja realmente CANCELAR?
                </p>
                <div>
                  <S.ButtonCancel primary type="button" onClick={cancelClube}>
                    SIM
                  </S.ButtonCancel>
                  <S.ButtonCancel
                    onClick={() => handleVisibleCancel(false)}
                    type="button"
                  >
                    NÃO
                  </S.ButtonCancel>
                </div>
              </S.CancelContainer>
            ) : (
              <>
                {!link && !cancelVisible ? (
                  loading ? (
                    <Loading />
                  ) : (
                    <h4 style={{ textAlign: "center" }}>O clube não está ativo</h4>
                  )
                ) : (
                  <S.ButtonsWrapper>
                    <S.Button
                      disabled={!link}
                      type="button"
                      onClick={handleAccessClube}
                    >
                      <BiLinkExternal />
                      <p>{link ? 'Acessar Clube' : 'O Clube não está ativo'}</p>
                    </S.Button>
                    <S.Button
                      disabled={!link}
                      cancel
                      type="button"
                      onClick={() => handleVisibleCancel(true)}
                    >
                      <IoIosCloseCircleOutline />
                      <p>
                        {link ? 'Cancelar Clube' : 'O Clube não está ativo'}
                      </p>
                    </S.Button>
                  </S.ButtonsWrapper>
                )}
              </>
            )}
          </>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default ClubePartiuCondomino;
