import styled from 'styled-components';
import { COLORS, WIDTHS } from '../../utils/constants';
import background from '../../assets/background.png';

export const Page = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 40px 60px;
  z-index: 0;
  background-color: #eee;
`;

export const Rectangle1 = styled.div`
  position: absolute;
  width: 1000px;
  height: 1400px;
  background: linear-gradient(-135deg, ${COLORS.PRIMARY},  ${COLORS.SECONDARY});
  border-radius: 80px;
  transform: rotate(30deg);
  left: -300px;
  top: -200px;
`;

export const Rectangle2 = styled.div`
  position: absolute;
  width: 1000px;
  height: 1400px;
  background: ${COLORS.PRIMARY};
  border-radius: 80px;
  transform: rotate(30deg);
  left: -350px;
  top: 50px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  justify-content: space-between;
  color: ${COLORS.TEXT_PRIMARY};
`;

export const DivOptions = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: calc((100vw - 600px)/2);

  transition: all 0.2s;
`;

export const DivForm = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 5%;
  z-index: 1;
  transition: all 0.2s;
  max-width: 400px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(attr: any) => (attr['data-selected'] ? '#fff' : COLORS.TEXT_PRIMARY)};

  width: 220px;
  padding: 10px 20px;
  background-color: ${(attr: any) => (attr['data-selected'] ? COLORS.SECONDARY : '#fff')};
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  min-height: 100px;

  svg {
    margin-bottom: 10px;
  }

  @media(max-width: ${WIDTHS.MED}px){
    width: 160px;
    padding: 10px 5px;
  }
`;

export const Input = styled.input`
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px; 
`;

export const FlexTopLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  height: 80px;
  margin-top: 20px;

  @media screen and (max-width: 1280px) {
    margin-top: 0;
    padding: 15px 0;
    background-color: #FFFFFF;
    width: 100%;
    justify-content: space-around;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 160px;
  }
`;

export const ButtonFirstAccess = styled.div`
  height: 40px;
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-width: 1px;
  border-color: #FFFFFF;
  border-radius: 6px;
  margin: auto 0;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #FFFFFF;
    color: ${COLORS.PRIMARY};
    transition-duration: .4s;
  }

  @media screen and (max-width: 1280px) {
    color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};

    &:hover {
      background-color: ${COLORS.PRIMARY};
      border-color: ${COLORS.PRIMARY};
      color: #FFFFFF;
    }
  }
`;

export const FlexOptions = styled.div`
  max-width: 700px;
  align-self: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 20px;

  @media screen and (max-width: 885px) {
    margin-top: 25px;
  }

  @media screen and (max-width: 575px) {
    padding-bottom: 15px;
  }

  @media screen and (max-width: 425px) {
    padding: 0 10px 15px 10px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  min-height:100vh;
  flex-direction: column;
  background: url(${background}) no-repeat right;
  background-size: contain;

  @media screen and (max-width: 900px) {
    background: unset;
    background-color: ${COLORS.PRIMARY};
  }

  @media screen and (max-width: 520px) {
    padding-bottom: 30px;
  }
`;

export const ButtonOption = styled.div`
  display: flex;
  width: 180px;
  height: 90px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px #00000014;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 15px;
  white-space: normal;
  word-wrap: break-word;
  background-color: #FFFFFF;

  &:hover {
    cursor: pointer;
    transform: scale(110%);
    transition-duration: .4s;
  }

  @media screen and (max-width: 425px) {
    width: 240px;
    height: 105px;
    font-size: 15px;
    line-height: 17px;
  }
`;

export const FlexEntrar = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    flex-direction: column;

    Button {
      flex: unset;
      height: 40px;
      margin: 10px 0;
    }
  }
`;

export const FlexStores = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const CloseButton = styled.div`
  height: 30px;
  width: 100%;
  color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -10px;
`;

export const TextFooter = styled.span`
  font-size: 10.5px;
  color: #000000;
  margin: 5px 0 10px 30px;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 728px) {
    position: static;
  }
`;
