import styled, { css, keyframes } from 'styled-components';

type CardProps = {
    loading : boolean;
}

const shimmerAnimation = keyframes`
    0% { 
        background-position-x: 500%;
        background-position-y: 100%;
    }
  
    100% {
        background-position-x: 10000%;
        background-position-y: 100%;
    }
    
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 5px;
  flex-wrap: wrap;

  select {
    margin-right: 15px;
  }
`;

export const Card = styled.div<CardProps>`
padding : 10px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
background : #fff;
border-radius : 12px;
height : 350px;
width :  350px;
display : flex;
flex-direction : column;
align-items : center;
justify-content : center;

table {
    tbody{
        tr {
            margin-top : 20px;
        }
    }
}


${({ loading }) => loading && css`
background-image: linear-gradient(90deg,#f6f7f9 0,#e9ebee 20%,#f6f7f9 40%,#f6f7f9);
background-size: 99% 100%;
background-repeat: no-repeat;
animation: ${shimmerAnimation} 1s linear 1ms infinite backwards;
`}
`;

export const RelatorioContainer = styled.div`
display: flex;
flex-direction: row; 
justify-content: space-around;
margin: 20px 0 ;
`;

export const Relatorio = styled.div`

height : 100%;
width  : 100%;

display : flex;
flex-direction : column;
justify-content : space-around;
padding : 30px 20px;
`;
export const RelatorioRow = styled.div`
display : flex;
justify-content : space-between;
`;
export const RelatorioTitle = styled.p`
font-weight : bold;
margin : 0;
`;
export const RelatorioDesc = styled.span``;

export const ExportSpan = styled.p`
    
`;