import styled from 'styled-components';
import { COLORS } from '../../../../utils/constants';
import { BiTrash } from 'react-icons/bi';

export const Load = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;

export const TitleHeader = styled.h1`
  font-size: 22px;
  color: ${COLORS.PRIMARY};
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const FormModal = styled.form`

  h6 {
    font-size: 16px;
    font-weight: bold;
    color: #555;
    margin: 18px 0;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  textarea {
    width: 100%;
    font-size: 16px;
    color: #555;
    padding: 10px;
    border: 1px solid #555;
    resize: none;
    border-radius: 8px;
  }
`;

export const TextWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;

export const AttachsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;

export const DropDown = styled.div`
    height: 280px;
    width: 420px;
    position: relative;
    border: 2px dashed ${COLORS.PRIMARY};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
    padding: 2px;

    @media screen and (max-width: 768px) {
        width: 80%;
    }

    @media screen and (max-width: 520px) {
        width: 100%;
        height: 200px;
    }

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
  }
`;

export const DropDownText = styled.p`
    font-size: 14px;
    text-align: center;
    padding: 0 5px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 200px;
    width: 200px;
    border-radius: 10px;
    outline: 2px solid ${COLORS.PRIMARY};
    outline-offset: -2px;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      height: 100%;
      border-radius: 10px;
    }

    @media screen and (max-width: 520px) {
      height: 300px;
      width: 90vw;
    }

    @media screen and (max-width: 375px) {
      height: 250px;
    }
`;

export const EditGroup = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 5px 15px;
    align-items: center;

    @media screen and (max-width: 425px) {
        padding: 5px;
    }
`;

export const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;
`;

export const EditText = styled.span`
    color: ${COLORS.PRIMARY};
    font-size: 12px;
`;

export const PdfWrapper = styled.div`
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #E31E07;

  @media screen and (max-width: 520px) {
    height: 90vw;
    width: 90vw;
  }
`;

export const SpanWrapper = styled.div`
  color: #fff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  span:first-child {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const DeleteAttach = styled(BiTrash)`
  cursor: pointer;
  align-self: flex-end;
  margin-top: 5px;
  margin-right: 5px;
  padding: 2px;
  background-color: rgba(0,0,0,.7);
  border-radius: 5px;
`;

export const DeleteAttachImg = styled(BiTrash)`
  cursor: pointer;
  padding: 2px;
  background-color: rgba(0,0,0,.7);
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
`;