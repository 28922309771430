import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  Flex,
  Badge,
} from '@chakra-ui/react';
import * as React from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { IAssemblies } from '../../../interfaces/responses/IAssemblies';
import { COLORS } from '../../../utils/constants';
import {
  dateStringToDateFormat,
  dateStringToTimestamp,
} from '../../../utils/dates';
import { goToExternalLink } from '../../../utils/functions';

/* eslint-disable no-unused-vars */

interface Props {
  assembly: IAssemblies;
  isManager: boolean;
  handleDelete: (id: number) => void;
  handlePublishAssembly: (id: number) => void;
  handleActive: (id: number) => void;
  openModalCreatePauta: () => void;
  handleInitiVotes : any
}
/* eslint-enable */

const ItemList2 = ({
  assembly,
  isManager,
  handlePublishAssembly,
  handleDelete,
  handleActive,
  openModalCreatePauta,
  handleInitiVotes,
}: Props) => {
  const history = useHistory();
  const currentDate = new Date();

  const getColor = () => {
    if (assembly.status === 'CRIADA') return 'gray';
    if (assembly.status === 'CANCELADA'
    || assembly.status === 'REPROVADA-ADM' || assembly.status === 'REPROVADA-SINDICO'
    ) return 'red';
    if (assembly.status === 'ATIVA') {
      return 'green';
    }
    return 'orange';
  };

  return (
    <tr key={assembly.id}>
      <td style={{ paddingLeft: 16 }}>
        <Badge
          alignSelf="center"
          variant="solid"
          fontSize="18"
          colorScheme={getColor()}
        >
          {assembly.status}
        </Badge>
      </td>
      <td className="title-assembly">{assembly.titulo}</td>
      <td>
        {`${dateStringToDateFormat(
          assembly.horarioInicio,
        )} --> ${dateStringToDateFormat(assembly.horarioTermino)}`}
      </td>
      <td>{dateStringToDateFormat(assembly.dataPublicacao)}</td>
      <td>
        {assembly.status === 'PUBLICADA' ? (
          <Tooltip
            label="Aguardando aprovação da administradora"
            aria-label="A tooltip"
          >
            <Flex justifyContent="flex-end">
              <Menu>
                <MenuButton
                  background={COLORS.PRIMARY}
                  _hover={{ background: COLORS.PRIMARY }}
                  color="#FFF"
                  as={Button}
                  _active={{ background: COLORS.PRIMARY }}
                  rightIcon={<AiOutlineDown />}
                >
                  Mais opções
                </MenuButton>
              </Menu>
            </Flex>
          </Tooltip>
        ) : (
          <Flex justifyContent="flex-end">
            <Menu>
              <MenuButton
                background={COLORS.PRIMARY}
                _hover={{ background: COLORS.PRIMARY }}
                color="#FFF"
                as={Button}
                _active={{ background: COLORS.PRIMARY }}
                rightIcon={<AiOutlineDown />}
              >
                Mais opções
              </MenuButton>
              <MenuList>
                {isManager ? (
                  <>
                    {assembly.status === 'CRIADA' && (
                      <>
                        <MenuItem
                          onClick={() => handlePublishAssembly(assembly.id)}
                        >
                          Enviar p/ Administradora
                        </MenuItem>
                        <MenuItem>Editar</MenuItem>
                        <MenuItem onClick={() => handleDelete(assembly.id)}>
                          Excluir assembleia
                        </MenuItem>
                      </>
                    )}
                    {assembly.status === 'APROVADA-ADM' && (
                      <MenuItem onClick={() => handleActive(assembly.id)}>
                        Ativar Assembleia
                      </MenuItem>
                    )}

                    {(assembly.status === 'APROVADA-EDICAO-ADM'
                      || assembly.status === 'ATIVA') && (
                      <MenuItem
                        onClick={() => history.push(`assembly/${assembly.id}/details`)}
                      >
                        Ver detalhes
                      </MenuItem>
                    )}

                    {assembly.status === 'CRIADA' && (
                      <MenuItem onClick={() => openModalCreatePauta()}>
                        Criar Pautas
                      </MenuItem>
                    )}
                  </>
                ) : (
                  <>
                    {assembly.status === 'ATIVA' && (
                      <>
                        {dateStringToTimestamp(assembly.horarioInicio)
                          < currentDate.getTime()
                          && dateStringToTimestamp(assembly.horarioTermino)
                            > currentDate.getTime() && (
                            <MenuItem onClick={handleInitiVotes}>
                              Votar
                            </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => history.push(`assembly/${assembly.id}/chat`)}
                        >
                          Chat da Assembleia
                        </MenuItem>
                      </>
                    )}
                  </>
                )}

                {assembly.linkEdital && (
                  <MenuItem
                    onClick={() => goToExternalLink(assembly.linkEdital as string)}
                    theme="primary"
                  >
                    Ver edital
                  </MenuItem>
                )}
                {!(assembly.status !== 'ATIVA'
                  || dateStringToTimestamp(assembly.horarioTermino)
                    > currentDate.getTime()) && (
                    <MenuItem
                      onClick={() => history.push(`assembly/${assembly.id}/results`)}
                    >
                      Resultados
                    </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        )}
      </td>
    </tr>
  );
};

export default ItemList2;
