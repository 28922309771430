import styled from 'styled-components';
import { Text, Flex } from '@chakra-ui/react';

export const FlexButton = styled.div`
  display: flex;
  width: 237px;
  height: 130px;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 3px 20px #00000014;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.125rem;
  //ml={ml ? 'auto' : 0}
  margin: 12px 8px;

  &:hover {
    cursor: pointer;
    background-color: white;
    transform: scale(110%);
    transition-duration: .5s;
  }

  @media screen and (max-width: 1130px) {
    width: 80%;
    height: 120px;
    max-height: 130px;
  }

  @media screen and (max-width: 768px) {
    width: 45%;
    height: 120px;
    max-height: 130px;
  }

  @media screen and (max-width: 520px) {
    margin: 8px 0;
  }
`;

export const IconButton1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 490px) {
    display: none;
  }
`;

export const IconButton2 = styled.div`
  display: none;

  @media screen and (max-width: 490px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InitialsText = styled(Text)`
    font-size: 12px;
    font-weight: bold;
    color: #009B6E;

    @media screen and (max-width: 490px) {
        font-size: 10px;
    }
`;

export const ButtonText = styled(Text)`
    font-size: 14px;
    margin-top: 0.375rem;
    text-align: center;
    font-family: Montserrat;
    font-weight: bold;
    max-width: 95%;

  @media screen and (max-width: 490px) {
    font-size: 12px;
  }
`;

export const FlexCard = styled(Flex)`
    align-items: center;
    justify-content: center;
    background-color: #E5F5F0;
    border-radius: 50%;
    height: 55px;
    width: 55px;

    @media screen and (max-width: 490px) {
        height: 45px;
        width: 45px;
    }
`;