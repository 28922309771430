import styled from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS } from '../../utils/constants';

export const AsideFlex = styled.aside`
    padding: 0 10px 0 20px;
`;

export const ImagePredial = styled.img`
  width: 417px;
  height: 299px;
  object-fit: contain;

  @media screen and (max-width: 1300px) {
    width: 100%;
    height: 250px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1075px) {
    margin-top: 20px;
  }
`;

export const FlexApp = styled.div`
  display: flex;
  width: 417px;
  height: 151px;
  background-color: #FFFFFF;
  margin-top: 84px;
  border-radius: 0.625rem;
  box-shadow: 0px 3px 20px #00000014;
  position: relative;

  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: static;
    margin-top: 30px;
    padding: 20px;
    padding-bottom: 0;
  }

  @media screen and (max-width: 1075px) {
    margin: 30px auto;
    height: 200px;
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    height: 260px;
    margin-top: 0;
  }

  @media screen and (max-width: 425px) {
    height: fit-content;
    flex-direction: column;
  }
`;

export const FlexText = styled.div`
    padding: 16px;
    padding-right: 140px;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        padding: 0;
    }
`;

export const ImagePhone = styled.img`
  width: 165px;
  height: 216px;
  object-fit: contain;
  position: absolute;
  right: -12px;
  bottom: -4px;

  @media screen and (max-width: 1300px) {
    position: static;
    margin-bottom: -4px;
  }

  @media screen and (max-width: 1075px) {
    height: 180px;
    margin-bottom: -8px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: -32px;
    height: 216px;
  }

  @media screen and (max-width: 425px) {
    margin-bottom: -4px;
  }
`;

export const FlexStores = styled.div`
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    height: 120px;
    justify-content: space-evenly;
  }
`;

export const FlexButton = styled.div`
    display: flex;
    justify-content: center;
    padding: 4px 12px;
    background-color: #484E53;
    border-radius: 4px;
    color: #fff;
    margin: 0 5px;
    cursor: pointer;
    
    @media screen and (max-width: 1300px) {
        padding: 10px 12px;
    }
`;

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 2em;
    text-align: center;
    font-weight: bold;
  }

  h2 {
    font-size: 1em;
    text-align: center;
  }
`;

export const DescBox = styled.div`
  padding: 20px;
  width: 70%;
  height: fit-content;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin: auto;

  background-color: white;
  border: 1px solid black;
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;


export const BoxMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    padding-left: 16px;

    @media screen and (max-width: 1075px) {
        max-width: unset;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;export const TableFlex1 = styled(Table)`
margin: 20px 0;

tr {
  text-align: center;
}

td {
  text-align: center;
}

tr:nth-child(odd) {background-color: #white !important;}

@media screen and (max-width: 768px) {
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
`;

export const TableFlex2 = styled(Table)`
  margin: 15px 0;

  tr {
    border-bottom: 1px solid rgba(77, 77, 77, 0.5);
    height: 40px;
    text-align: center;
  }

  tr:last-child {
    border: none;
  }

  td {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  th {
    padding-left: 10px;
    
    @media screen and (max-width: 520px) {
      padding-right: 15px;
    }
  }
`;export const FormAgendamentoVisita = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    background: #FFFFFF;
    border-radius: 5px;

    @media screen and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    @media screen and (max-width: 768px) {
        margin: 20px auto;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`;

export const FormOption = styled.div`
    width: 18%;
    font-size: 0.85em;
    display: flex;
    flex-direction: column;

    input, select {
        margin-top: 5px;
        border-radius: 5px;
        border: 1px solid #B4B4B4;
        padding: 5px;
    }

    @media screen and (max-width: 1100px) {
        width: 40%;
        margin: 0 15px;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        margin: 0 auto;
    }
`;
export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 15px auto;
  align-self: center;
`;

export const Label = styled.span`
  color: ${COLORS.TEXT_PRIMARY};
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const InputText = styled.input`
    border: 1px solid #666;
    border-color: #666;
    border-radius: 5px;
    background-color: #fff;
    color: #333398;
    height: 35px;
    width: 220px;
    padding: 0 8px;
    font-size: 14px;
    font-weight: bold;
`;

export const TextDescricao = styled.textarea`
    border: 1px solid #666;
    border-color: #666;
    border-radius: 5px;
    background-color: #fff;
    color: #333398;
    padding: 0 8px;
    font-size: 14px;
    font-weight: bold;
    display: block;
    width: 99.6%;
    height: 90px;
`;