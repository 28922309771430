import styled from 'styled-components';

export const Modal = styled.div``;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 12px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    color: #333333;
  }

  p {
    font-size: 18px;
    margin-top: 24px;
    color: #333333;
    max-width: 500px;
  }
`;
