import React, { useState, useEffect } from "react";
import { BiDownload } from "react-icons/bi";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import Loading from "react-loading";
import Select from "../../components/Select/Select";
import request from "../../services/request";
import { COLORS } from "../../utils/constants";
import { goToExternalLink } from "../../utils/functions";
import Header from "./components/Header";
import PhotosModal from "./components/PhotosModal";
import { ButtonDownloadFiles, Content, FlexCol, FlexRow, HoriontalView, ImageContainer, TextArea, Title, Wrapper } from "./styles";
import { dateStringToDateTimeFormat } from "../../utils/dates";
import { IListaUnidade } from "./UnityOcorrences";
import { useWindowSize } from "../Login";

type Props = {
    key1: number;
    key2: number;
    listUnidades: IListaUnidade[];
    idOcorrencia: number;
    handleClose: () => void;
    updateOccurrenceAsRead: (key1: number, key2: number) => void;
    refreshOccurrences: () => void;
}

const optionsFeedback = [
    { value: null, label: 'Escolha uma opção' },
    { value: 'CADASTRADA', label: 'Cadastrada' },
    { value: 'AGUARDANDO NOVO CONTATO', label: 'Aguardando novo contato' },
    { value: 'ATENDIDO', label: 'Atendido' },
    { value: 'EM ATENDIMENTO', label: 'Em atendimento' },
    { value: 'CANCELADA', label: 'Cancelada' },
] as Array<{ value: string | number, label: string }>;

/* ++++++++++++++++++++++++++++++ COMPONENT ++++++++++++++++++++++++++++++ */

const SelectedOcorrence: React.FC<Props> = ({
    key1, key2, listUnidades, idOcorrencia, handleClose, updateOccurrenceAsRead, refreshOccurrences
}) => {

    /* ======================= CONSTANTS and STATES ========================= */

    const size = useWindowSize();
    const [comentary, setComentary] = useState("");
    const [feedback, setFeedback] = useState("");
    const [open, setOpen] = useState(false);
    const [idImage, setIdImage] = useState(0);
    const [currentImage, setCurrentImage] = useState('');
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [buttonText, setButtonText] = useState('Enviar Revisão');
    const currentOccurrence = listUnidades[key1].ocorrencia[key2];

    /* ======================= EFFECTS ========================= */


    /* ======================= FUNCTIONS ========================= */

    function filterImages(value: any) {
        if (value.tipoDocumento === 'image')
            return value;
    }

    const listUnidadesFiltered = listUnidades[key1].ocorrencia[key2].documentos.filter(filterImages);

    const handleModal = () => {
        setOpen(!open);
    }

    const handleID = (id: number) => {
        setIdImage(id);
        setCurrentImage(listUnidadesFiltered[id].linkDocumento);
        setOpen(true);
    }

    const handlePrevious = () => {
        if (idImage - 1 < 0) {
            return null
        } else {
            setIdImage(idImage - 1);
            setCurrentImage(listUnidadesFiltered[idImage - 1].linkDocumento);
        }
    }

    const handleNext = () => {
        if (idImage + 1 >= listUnidadesFiltered.length) {
            return null
        } else {
            setIdImage(idImage + 1);
            setCurrentImage(listUnidadesFiltered[idImage + 1].linkDocumento);
        }
    }

    const capitalizeFirstLetter = (string: string) => {
        let stringLow = string.toLocaleLowerCase();
        return stringLow[0].toUpperCase() + stringLow.slice(1);
    }

    const handleText = (elem: number) => {
        if (elem === 0) {
            return (
                <div style={{ backgroundColor: "rgba(0,0,0,.2)", padding: "5px 0px", borderRadius: 5, marginBottom: 15 }}>
                    <p style={{ textAlign: "center", color: "#000", fontSize: 14 }}>
                        Ocorrência não possui anexos de fotos
                    </p>
                </div>
            )
        }
    }

    useEffect(() => {
        let intervalId: number;

        if (disabledButton) {
            let dots = 0;
            intervalId = setInterval(() => {
                dots = (dots + 1) % 4;
                const dotsText = '.'.repeat(dots);
                setButtonText(`Enviando${dotsText}`);
            }, 500);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [disabledButton]);

    /* ======================= REQUESTS ======================== */
    const handleSubmit = () => {

        if (feedback === null || feedback === '') {
            alert('Por favor, adicione um feedback para a ocorrência.');
        } else {
            setLoadingCreate(true);
            setDisabledButton(true);
            setButtonText('Enviando...');

            const form = {
                situacao: feedback,
                comentario: comentary,
                idOcorrencia: idOcorrencia
            };

            request.post('enviarFeedbackOcorrenciaSindico', form).then((response) => {
                if (response.data.ok) {
                    toast.success('Feedback enviado com sucesso.');
                    updateOccurrenceAsRead(key1, key2);
                    refreshOccurrences();
                    handleClose();
                } else {
                    toast.error('Ocorreu um erro ao enviar seu feedback.');
                }
            }).catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro : A requisição falhou');
                }
            }).finally(() => {
                setLoadingCreate(false);
                setDisabledButton(false);
                setButtonText('Enviar Revisão');
            });
        }
    };           

    /* ======================= RENDERS ========================= */

    return (
        <Content>
            {loadingCreate && <Loading width={50} type="spin" color={COLORS.SECONDARY} />}

            <Header handleClose={handleClose} textCenter="Ocorrência" />

            <Wrapper>
                <FlexRow>
                    <span><Title>Condômino: </Title>{listUnidades[key1].nomeCondomino}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Unidade: </Title>{listUnidades[key1].nomeUnidade}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Data: </Title>{dateStringToDateTimeFormat(listUnidades[key1].ocorrencia[key2].dataCriacao)}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Status: </Title>{capitalizeFirstLetter(listUnidades[key1].ocorrencia[key2].situacao)}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Assunto: </Title>{capitalizeFirstLetter(listUnidades[key1].ocorrencia[key2].assunto)}</span>
                </FlexRow>

                <FlexCol>
                    <Title>Mensagem:</Title>
                    <p>{listUnidades[key1].ocorrencia[key2].descricao}</p>
                </FlexCol>
                <FlexCol>
                    <Title>Respostas:</Title>
                    {currentOccurrence.respostas && currentOccurrence.respostas.length > 0 ? (
                        currentOccurrence.respostas.map((resposta, index) => (
                            <FlexCol key={index} style={{ marginBottom: 10 }}>
                                <p><strong>{resposta.autor}</strong> ({dateStringToDateTimeFormat(resposta.data)}):</p>
                                <p>{resposta.texto}</p>
                            </FlexCol>
                        ))
                    ) : (
                        <p>Não há respostas para esta ocorrência.</p>
                    )}
                </FlexCol>
                <FlexCol>
                    <Title>Anexos:</Title>
                    {
                        listUnidades[key1].ocorrencia[key2].totalAnexos != 0 &&
                        <HoriontalView>
                            {
                                listUnidadesFiltered.map((elem, index) => (
                                    <ImageContainer key={index}>
                                        <img onClick={() => handleID(index)} src={elem.linkDocumento} style={{ height: 85, width: 85 }} />
                                    </ImageContainer>
                                ))
                            }
                        </HoriontalView>
                    }
                </FlexCol>

                {
                    listUnidades[key1].ocorrencia[key2].totalAnexos == 0 ?
                        (
                            <div style={{ backgroundColor: "rgba(0,0,0,.2)", padding: '5px 0px', borderRadius: 5, marginBottom: 5 }}>
                                <p style={{ textAlign: "center", color: "#000", fontSize: 14 }}>
                                    Ocorrência não possui anexos
                                </p>
                            </div>
                        )
                        :
                        handleText(listUnidadesFiltered.length)
                }

                {
                    listUnidades[key1].ocorrencia[key2].totalAnexos != 0 &&
                    listUnidades[key1].ocorrencia[key2].documentos.map((elem, index) => (
                        elem.tipoDocumento !== 'image' &&
                        (
                            <ButtonDownloadFiles key={index} onClick={() => goToExternalLink(elem.linkDocumento)}>
                                <BiDownload size={25} color='white' />
                                <span style={{ color: "#fff", fontSize: 14, marginLeft: 10 }}>{elem.nomeArquivo}</span>
                            </ButtonDownloadFiles>
                        )
                    ))
                }

                <FlexCol>
                    <Title>Feedback</Title>
                    <Select
                        options={optionsFeedback}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        style={{ borderColor: COLORS.PRIMARY, borderWidth: 2, cursor: "pointer" }}
                    />
                </FlexCol>

                <FlexCol>
                    <Title>Comentário</Title>
                    <TextArea onChange={(e) => setComentary(e.target.value)} value={comentary} maxLength={900} />
                    <span style={{ marginRight: 10, marginTop: 3, alignSelf: "flex-end" }}>{comentary.length}/900</span>
                </FlexCol>

                <Button
                    type="submit"
                    onClick={handleSubmit}
                    theme="primary"
                    marginTop={10}
                    style={{
                        fontWeight: 'bold', fontSize: 18, width: size.sizeWidth >= 475 ? '40%' : '80%', height: 50, margin: '0 auto', padding: '5px 10px', marginTop: 10,
                        pointerEvents: disabledButton ? "none" : "auto", opacity: disabledButton ? 0.6 : 1
                    }}
                    disabled={disabledButton}
                >
                    {buttonText}
                </Button>
            </Wrapper >

            <PhotosModal
                open={open}
                handleModal={handleModal}
                idImage={idImage}
                currentImage={currentImage}
                length={listUnidadesFiltered.length}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
            />

        </Content >
    );
}

export default SelectedOcorrence;