import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div<{ openProtocol?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.openProtocol ? 'space-around' : 'space-between'};
    padding: 0 15px;
`;

export const CardsWrapper = styled.div<{ openProtocol?: boolean }>`
    overflow: auto;
    display: flex;
    width: 100%;
    height: ${props => props.openProtocol ? '180px' : 'fit-content'};
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 35px auto;
    margin-bottom: 15px;
`;

export const ButtonCard = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 105px;
    width: 30px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background-color: ${COLORS.SECONDARY};
    color: #fff;
    cursor: pointer;
`;

export const ButtonDownloadFiles = styled.div`
  background-color: ${COLORS.SECONDARY};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 10px 5px;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;

  span {
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
  }

  @media screen and (max-width: 425px) {
    width: 90%;
  }
`;

export const Content = styled.div`
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 2px solid ${COLORS.SECONDARY};
    overflow: auto;
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexRow = styled.div`
    display: flex;
    margin: 5px 0;
`;

export const Title = styled.span`
    font-weight: 700;
`

export const HoriontalView = styled.div`
    display: flex;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 15px;
`;

export const Load = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;