import React from 'react';
import { BreadcrumbsProvider } from './modules/BreadcrumbsContext';
import { ChatFuncionarioProvider } from './modules/ChatFuncionarioContext';
import { ModalClubePartiuProvider } from './modules/ModalClubePartiuContext';
import { TabSelectedProvider } from './modules/TabSelectedContext';
import { TabViewProvider } from './modules/TabViewContext';
import { CameraProvider } from './modules/CameraContext';

const AppProvider: React.FC = ({ children }) => (
  <ChatFuncionarioProvider>
    <BreadcrumbsProvider>
      <CameraProvider>
        <TabSelectedProvider>
          <ModalClubePartiuProvider>
            <TabViewProvider>
              {children}
            </TabViewProvider>
          </ModalClubePartiuProvider>
        </TabSelectedProvider>
      </CameraProvider>
    </BreadcrumbsProvider>
  </ChatFuncionarioProvider>
);

export default AppProvider;
