import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActionsHeader } from '../../../../store/ducks/header';
import { useWindowSize } from '../../../Login';
import { Column } from './styles';

const VisitasCadastradas = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useWindowSize();

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Cadastro de Visitas'));
}, [dispatch]);

  return (
    <Column>

      <Button
        colorScheme="blue"
        onClick={() => history.push('/listar_visitas')}
        w="70%"
        paddingTop={screen.sizeWidth >= 768 ? 10 : 7}
        paddingBottom={screen.sizeWidth >= 768 ? 10 : 7}
        fontSize={screen.sizeWidth >= 768 ? 25 : 18}
      >
        Listar Visitantes
      </Button>

      <Button
        colorScheme="blue"
        onClick={() => history.push('/cadastro_visita')}
        w="70%"
        paddingTop={screen.sizeWidth >= 768 ? 10 : 7}
        paddingBottom={screen.sizeWidth >= 768 ? 10 : 7}
        fontSize={screen.sizeWidth >= 768 ? 25 : 18}
      >
        Cadastrar Visitante
      </Button>

    </Column>
  );
};

export default VisitasCadastradas;
