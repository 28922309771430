import styled from 'styled-components';
import { COLORS } from '../../../../utils/constants';

export const TextButton = styled.span`
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
`;

export const SubmitButton = styled.div`
  display: flex;
  padding: 10px;
  height: 40px;
  width: 250px;
  background-color: ${COLORS.PRIMARY};
  margin: 10px auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const ViewInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 0;
  background: #fff;
  padding: 5px;
  color: #000;
  border-radius: 5px;
`;

export const Input = styled.textarea`
    width: 100%;
    height: 150px;
    font-size: 16px;
    padding: 12px;
    border: 1px solid ${COLORS.PRIMARY};
    resize: none;
    border-radius: 8px;
    color: #000;
    margin-top: 15px;
`;

export const TextLabelInput = styled.span`
  font-size: 16px;
  color: #000;
  font-weight: bold;
`;

export const GoBackButton = styled.div`
  display: flex;
  padding: 10px;
  height: 40px;
  width: 250px;
  background-color: ${COLORS.SECONDARY};
  margin: 10px auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextGoBack = styled.span`
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
`;