import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Select } from '@chakra-ui/react';
import RootState from '../../../interfaces/states/RootState';
import request from '../../../services/request';
import { ActionsSession } from '../../../store/ducks/session';

const SelectUnidades = () => {
  const dispatch = useDispatch();
  const todosVinculos = useSelector((state:RootState) => state.session.todosVinculos);
  const idVinculo = useSelector((state:RootState) => state.session.idVinculo);
  const isManager = useSelector((state:RootState) => state.session.isManager);

  const [unidades, setUnidades] = React.useState<Array<any>>([]);

  const setVinculoAtual = (vinculoId: string) => {
    const vinculo = todosVinculos.find((v:any) => v.id === Number.parseInt(vinculoId, 10));
    if (!vinculo) return;

    request
      .post(
        '/salvaAcesso',
        {
          id: vinculo.condominio ? vinculo.condominio.id : vinculo.id,
          tipo: isManager ? 'SINDICO' : 'CONDOMINO',
        },
        true,
      )
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error(
            'Falha na rede. : Verifique sua conexão e tente novamente.',
          );
        } else {
          toast.error('Erro : A requisição falhou');
        }
      });

    dispatch(ActionsSession.setVinculo({
      idVinculo: vinculoId,
      hashVinculo: vinculo.hashVinculo,
      logoAdm: vinculo.unidade.logoAdministradora,
      descVinculoAtual: vinculo.unidade.descricao,
    }));
  };

  React.useEffect(() => {
    const arrayTemp = todosVinculos.map((i) => ({ value: i.id, label: i.unidade.descricao }));
    setUnidades(arrayTemp);
  }, [todosVinculos]);

  return (
    <Select
      onChange={(e) => setVinculoAtual(e.target.value)}
      value={idVinculo || (unidades.length >= 1 && unidades[0].value)}
      background="#009B6E"
      color="white"
      borderRadius="14px"
      fontSize="16px"
      fontFamily="Montserrat"
      fontWeight="bold"
      size="lg"
      height="47px"
    >
      {unidades.map((v) => (
        <option
          key={v.value}
          value={v.value}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            color: '#111',
          }}
        >
          {v.label}
        </option>
      ))}
    </Select>
  );
};

export default SelectUnidades;
