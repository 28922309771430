import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import request from '../../../services/request';
import { ActionsHeader } from '../../../store/ducks/header';
import { useHistory, useParams } from 'react-router-dom';
import { Label, Container, InputText, TextDescricao } from '../styles';

function EditEnquete() {

  const dispatch = useDispatch();
  const [multiplaEscolha, setMultiplaEscolha] = React.useState(false);
  const [exibirParcial, setExibirParcial] = React.useState(false);
  const [enqueteProprietario, setEnqueteProprietario] = React.useState(false);
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');
  const [statusEnquete, setStatusEnquete] = React.useState<Array<any>>([]);
  const [opcoes, setOpcoes] = React.useState<Array<any>>([]);
  const [statusEnqueteValue, setStatusEnqueteValue] = React.useState<string | number>();  
  const history = useHistory();
  const { idEnquete } = useParams() as any;
  const [disabledButton, setDisabled] = React.useState(false);


  React.useEffect(() => {

        // const status = ['Ativa', 'Cancelada', 'Suspensa', 'Encerrada'];

        const status = [
            {id:0,descricao:'Ativa'},
            {id:1,descricao:'Cancelada'},
            {id:2,descricao:'Suspensa'},
            {id:3,descricao:'Encerrada'},
        ]

        const statusEnqueteTemp = status.map((i: any) => ({
            value: i.id,
            label: `${i.descricao}`,
          }));
        
        request.get(`getEnquetes/${idEnquete}`).then((response) => {
            if (response.data.sucesso) {
                var enquetes = response.data.enquetes;
                setOpcoes(response.data.enquetes[0].opcoes);
                setTitulo(enquetes[0].titulo);
                setDescricao(enquetes[0].descricao);
                setDataInicial(enquetes[0].dataInicial);
                setDataFinal(enquetes[0].dataFinal);
                setMultiplaEscolha((enquetes[0].multiplaEscolha == 1));
                setExibirParcial((enquetes[0].exibirParcial == 1));
            }            
        });

        setStatusEnquete(statusEnqueteTemp);
        dispatch(ActionsHeader.changeTextCenter('Editar Enquete'));

  }, [dispatch, idEnquete]);

  const handleChangeOpcao = (desc: string, idOpcao: string) => {
    var inputOpcao = document.getElementById(idOpcao);
    // console.log(desc);
    if (inputOpcao != null) {
      inputOpcao.attributes[3].value = desc;
      // console.log(inputOpcao);
    }
  };

  const handleSubmit = () => {
    setDisabled(true);
    const routeApi = 'salvarEnquete';
    const opcoes_arr = document.querySelectorAll('.opcoes');
    let opcoes_aux = new Array;
    opcoes_aux.length = opcoes_arr.length;
    for(var i = 0; i < opcoes_arr.length; i++) {
      console.log(opcoes_arr[i].attributes[2].textContent);
      var id = Number(opcoes_arr[i].id)
      opcoes_aux.push([opcoes_arr[i].attributes[2].textContent, id]);
    }
    const form = {
      idEnquete: idEnquete,
      titulo: titulo,
      descricao: descricao,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      situacao: statusEnqueteValue || statusEnquete[0].value,
      opcoes: opcoes_aux,
      multiplaEscolha: (multiplaEscolha) ? 1 : 0,
      exibirParcial: (exibirParcial) ? 1 : 0,
      enqueteProprietario: (enqueteProprietario) ? 1 : 0
    };
    request.post(routeApi, form).then((response) => {
      if (response.data.sucesso) {
        toast.success('Muito bem, Enquete salva com sucesso');
        history.push('/list_enquetes')
      } else if (!response.data.sucesso) {
        toast.error(response.data.mensagem);
        setDisabled(false);
      } else {
        toast.error('Ops, algo de errado aconteceu ao tentar salvar a enquete');
        setDisabled(false);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        setDisabled(false);
      } else {
        toast.error('Erro : A requisição falhou');
        setDisabled(false);
      }
    });
  };

  const handle_enqueteProprietario = () => {
    
    if (!enqueteProprietario && window.confirm("Essa opção fará com que apenas condoôminos cadastrados como PROPRIETARIOS visualizem essa enquete. Deseja confirmar?")) {
      setEnqueteProprietario(!enqueteProprietario);
    } else if (enqueteProprietario) {
      setEnqueteProprietario(!enqueteProprietario);
    }
    return;
  }

  
  // console.log(opcoes);
  return (
    <>  
        <div style={{ width: "95%", margin: '0 auto' }}>
            <div style={{ width: "100%" }}>
                <CheckBox label="Exibir parcial (Permite a visualização dos votos antes da finalização da enquete)" checked={exibirParcial} onChange={() => setExibirParcial(!exibirParcial)} checkboxOrder='1' marginLeft={10} />
            </div>
            <Input type="text" label="Título da Enquete" value={titulo} onChange={(e: any) => setTitulo(e.target.value)} style={{ width: "100%" }} />
            <span className="sc-bsipQr eieICQ">Descrição (Máx 400 caracteres.)</span>
            <TextDescricao value={descricao} onChange={(e: any) => setDescricao(e.target.value)} style={{ width: "100%" }} maxLength={400}></TextDescricao>
            <Label>Opções</Label>
            <div>         
            {opcoes.map((v) => (
              <Container data-readonly={false}  style={{width: '100%'}}>
              <InputText
                style={{width: '100%'}}
                defaultValue={v.descricao}
                id={v.idOpcao}
                onChange={(e: any) => handleChangeOpcao(e.target.value, v.idOpcao)}
                className='opcoes'
              />
              </Container>
            ))}
            </div>
            <CheckBox label="Múltipla escolha (Permite marcar mais de uma opção)" checked={multiplaEscolha} onChange={() => setMultiplaEscolha(!multiplaEscolha)} checkboxOrder='1' marginLeft={10} />
            <Input type="datetime-local" label="Data inicial da votação" value={dataInicial} onChange={(e: any) => setDataInicial(e.target.value)} style={{ width: "100%" }} />
            <Input type="datetime-local" label="Data final da votação" value={dataFinal} onChange={(e: any) => setDataFinal(e.target.value)} style={{ width: "100%" }} />
            <Select
                options={statusEnquete}
                value={statusEnqueteValue}
                label="Status"
                onChange={(e) => setStatusEnqueteValue(e.target.value)}
                style={{ width: "100%" }} />
            <CheckBox label="Enquete para proprietários" checked={enqueteProprietario} onChange={() => handle_enqueteProprietario()} checkboxOrder='1' marginLeft={10} />
            <Button theme="secondary" marginTop={10} onClick={handleSubmit} style={{ width: "100%" }} disabled={disabledButton}>Salvar</Button>
        </div>
    </>
  );
}

export default EditEnquete;
