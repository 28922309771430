import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import SelectedOcorrence from './SelectedOcorrence';
import Cards from './components/Cards';
import { Button, CardsWrapper, Container } from './styles';
import { IoIosArrowForward } from 'react-icons/io';
import { useTabSelected } from '../../hooks/modules/TabViewContext';
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import TextAlternative from './components/TextAlternative';
import Loading from 'react-loading';
import { COLORS } from '../../utils/constants';
import { toast } from 'react-toastify';
import { dateStringToDateTimeFormat } from '../../utils/dates';

/* ======================= TYPES ========================= */

export interface IListaUnidade {
    nomeUnidade: string;
    nomeCondomino: string;
    idCondomino: number;
    totalOcorrencias: number;
    ocorrencia: {
        situacao: string;
        dataCriacao: string;
        dataCriacaoFormatada: string;
        descricao: string;
        nomeCondomino: string;
        idCondomino: number;
        documentos: {
            linkDocumento: string;
            tipoDocumento: string;
            extensaoArquivo: string;
            nomeArquivo: string;
        }[];
        assunto: string;
        idOcorrencia: number;
        ocorrenciaLida: '0' | '1';
        totalAnexos: number;
        totalRespostas: number;
        respostas: {
            texto: string;
            data: string;
            autor: string;
        }[];
        ultimaResposta: {
            texto: string;
            data: string;
            autor: string;
        }
    }[];
}

export interface IData {
    id?: number | any;
    key1?: number;
    key2?: number;
    idOcorrencia?: number;
}

/* ++++++++++++++++++++++++++++++ COMPONENT ++++++++++++++++++++++++++++++ */

const UnityOcorrences: React.FC = () => {

    /* ======================= CONSTANTS and STATES ========================= */

    const { defaultIndex, handleChangeDefaultIndex } = useTabSelected();
    const dispatch = useDispatch();
    const [cardKey1, setCardKey1] = useState(0);
    const [cardKey2, setCardKey2] = useState(0);
    const [idOcorrencia, setIdOcorrencia] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [listUnidades, setListUnidades] = useState<IListaUnidade[]>([]);
    const [openOcorrence, setOpenOcorrence] = useState(false);

    /* ======================= REQUESTS ========================= */

    async function getOccurrence() {
        setIsLoading(true);
        request.get('listarOcorrenciaSindico').then((response) => {
            if (response.data) {
                setListUnidades(response.data.listagem);
            } else {
                toast.error(response.data.erro);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getOccurrence();
    }, []);

    /* ======================= FUNCTIONS ========================= */

    function handleScroll() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const handleCard = (key1: number, key2: number, id: number) => {
        setCardKey1(key1);
        setCardKey2(key2);
        setIdOcorrencia(id);
        setOpenOcorrence(true);
        handleScroll();
    
        // Verifica se a ocorrência já foi lida antes de enviar a requisição
        const selectedOccurrence = listUnidades[key1].ocorrencia[key2];
        if (selectedOccurrence.ocorrenciaLida !== '1') {
            handlePostView(id);
        }
    };

    async function refreshOccurrences() {
        setIsLoading(true);
        try {
            const response = await request.get('listarOcorrenciaSindico');
            if (response.data) {
                setListUnidades(response.data.listagem);
            } else {
                toast.error(response.data.erro);
            }
        } catch (error) {
            toast.error('Erro ao recarregar ocorrências.');
        } finally {
            setIsLoading(false);
        }
    }

    const updateOccurrenceAsRead = (key1: number, key2: number) => {
        const updatedListUnidades = listUnidades.map((unidade, i) => {
            if (i === key1) {
                const updatedOcorrencias = unidade.ocorrencia.map((ocorrencia, j) => {
                    if (j === key2) {
                        return {
                            ...ocorrencia,
                            ocorrenciaLida: '1' as '1',
                        };
                    }
                    return ocorrencia;
                });
    
                return {
                    ...unidade,
                    ocorrencia: updatedOcorrencias,
                };
            }
            return unidade;
        });
    
        setListUnidades(updatedListUnidades);
    };

    const handlePostView = (idOcorrencia: number) => {

        const ocorrenciaLida = 1;

        const form = {
            ocorrenciaLida,
            idOcorrencia,
        };

        request.post('verificarVisualizacaoOcorrencia', form).then((response) => {
            if (response.data.ok) {
                // console.log(response.data.ok);
            } else {
                // console.log(response.data.erro);
            }
        }).catch((error) => {
            if (error?.message === 'Network Error') {
                toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
                toast.error('Erro : A requisição falhou');
            }
        });
    };    

    const countTotalOcorrencies = () => {
        let total = 0;
        listUnidades?.map((list) => {
            total += list?.totalOcorrencias
        })
        return total;
    }

    const handleClose = () => {
        setOpenOcorrence(false);
        updateOccurrenceAsRead(cardKey1, cardKey2);  // Mova a ocorrência para "Visualizadas" ao fechar
    };

    const countNotViewed = () => {
        let total = 0;
        listUnidades?.forEach((list) => {
            list?.ocorrencia.forEach((o) => {
                if (o.ocorrenciaLida === '0') {
                    total += 1;
                }
            });
        });
        return total;
    };
    
    const countViewed = () => {
        let total = 0;
        listUnidades?.forEach((list) => {
            list?.ocorrencia.forEach((o) => {
                if (o.ocorrenciaLida === '1') {
                    total += 1;
                }
            });
        });
        return total;
    };

    /* ======================= EFFECTS ========================= */

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter(countTotalOcorrencies() <= 1 ? `${countTotalOcorrencies()} ocorrência` : `${countTotalOcorrencies()} ocorrências`));
    }, [listUnidades]);

    /* ======================= RENDERS ========================= */

    return (
        <Container openOcorrence={openOcorrence}>

            <Tabs variant="solid-rounded" isFitted mt="12px" style={{ padding: "0 5px", display: "flex", flexDirection: "column", alignItems: "center" }} defaultIndex={defaultIndex} onChange={handleChangeDefaultIndex}>
                <TabList style={{ width: "95%" }}>
                    <Tab
                        h="43px"
                        boxShadow="5px 5px 20px #0000000D"
                        bg="#fff"
                        _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
                        borderRadius="14px 0px 0px 14px"
                        fontSize="16px"
                        fontFamily="Montserrat"
                        fontWeight="bold"
                    >
                        Não Visualizadas ({countNotViewed()})
                    </Tab>
                    <Tab
                        boxShadow="5px 5px 20px #0000000D"
                        bg="#fff"
                        _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
                        borderRadius="0px 14px 14px 0px"
                        fontSize="16px"
                        fontFamily="Montserrat"
                        fontWeight="bold"
                    >
                        Visualizadas ({countViewed()})
                    </Tab>
                </TabList>

                {
                    isLoading && (
                        <div style={{ marginTop: 20 }}>
                            <Loading width={70} type="spin" color={COLORS.SECONDARY} />
                        </div>
                    )
                }

                <TabPanels>
                    <TabPanel p="0">

                        {
                            <CardsWrapper openOcorrence={openOcorrence}>
                                {
                                    listUnidades ? (
                                        listUnidades?.map((list, index) => (
                                            list?.ocorrencia.map((o, i) => (
                                                o?.ocorrenciaLida == '0' && (
                                                    <Cards
                                                        key={i}
                                                        nomeUnidade={list.nomeUnidade}
                                                        dataCriacao={dateStringToDateTimeFormat(o.dataCriacao)}
                                                        ultimaResposta={o.ultimaResposta?.texto}
                                                        assunto={o.assunto}
                                                        isOpen={openOcorrence}
                                                    >
                                                        <Button onClick={() => handleCard(index, i, o.idOcorrencia)}>
                                                            <IoIosArrowForward size={20} />
                                                        </Button>
                                                    </Cards>
                                                )
                                            ))
                                        ))
                                    ) :
                                        <TextAlternative text='Nenhuma ocorrência para visualizar' />
                                }
                            </CardsWrapper>
                        }

                        {
                            openOcorrence &&
                            <SelectedOcorrence key1={cardKey1} key2={cardKey2} listUnidades={listUnidades} idOcorrencia={idOcorrencia} handleClose={handleClose} updateOccurrenceAsRead={updateOccurrenceAsRead} refreshOccurrences={refreshOccurrences} />
                        }

                    </TabPanel>
                    <TabPanel p="0">

                        <CardsWrapper openOcorrence={openOcorrence}>
                            {
                                listUnidades ? (
                                    listUnidades?.map((list, index) => (
                                        list?.ocorrencia.map((o, i) => (
                                            o?.ocorrenciaLida == '1' && (
                                                <Cards
                                                    key={i}
                                                    nomeUnidade={list.nomeUnidade}
                                                    dataCriacao={dateStringToDateTimeFormat(o.dataCriacao)}
                                                    ultimaResposta={o.ultimaResposta?.texto}
                                                    assunto={o.assunto}
                                                    isOpen={openOcorrence}
                                                >
                                                    <Button onClick={() => handleCard(index, i, o.idOcorrencia)}>
                                                        <IoIosArrowForward size={25} />
                                                    </Button>
                                                </Cards>
                                            )
                                        ))
                                    ))
                                ) :
                                    <TextAlternative text='Nenhuma ocorrência visualizada' />
                            }
                        </CardsWrapper>

                        {
                            openOcorrence &&
                            <SelectedOcorrence key1={cardKey1} key2={cardKey2} listUnidades={listUnidades} idOcorrencia={idOcorrencia} handleClose={handleClose} updateOccurrenceAsRead={updateOccurrenceAsRead} refreshOccurrences={refreshOccurrences} />
                        }

                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default UnityOcorrences;