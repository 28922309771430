import React from 'react';
import { IoIosClose } from 'react-icons/io';
import { COLORS } from '../../utils/constants';

import { Container, ContainerModal, TitleModal } from './styles';

interface Props {
  onClose?: ()=>void;
  children?: any;
}

const Modal = ({ children }:any) => (
  <Container>
    {children}
  </Container>
);

const Content = ({ children, onClose }:Props) => (
  <ContainerModal>
    <IoIosClose color={COLORS.TEXT_PRIMARY} size={40} onClickCapture={onClose} />
    {children}
  </ContainerModal>
);

const Title = ({ children }:any) => (
  <TitleModal>
    {children}
  </TitleModal>
);

export default Modal;
export const ModalContent = {
  Content,
  Title,
};
