import styled from 'styled-components';
import { COLORS } from '../../../../utils/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${COLORS.TEXT_PRIMARY};
    font-size: 18px;
    font-weight : bold;
  }

  select {
    background-color:#fff;
    color: ${COLORS.TEXT_PRIMARY};
    border-radius: 5px;
    height : 40px;
    margin-left: 12px;
    padding-left : 12px;
    padding-right : 12px;
    border: 1px solid #666;
    font-size : 16px;
    font-weight : bold;
    max-width: 320px;
  }
`;
