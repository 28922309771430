import styled from 'styled-components';
import { WIDTHS } from '../../utils/constants';

export const GridCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:4px;

  @media(max-width: ${WIDTHS.MED}px){
    grid-template-columns: repeat(2, 1fr)
  }

  @media(max-width: ${WIDTHS.MIN}px){
    grid-template-columns: repeat(1, 1fr)
  }
`;
