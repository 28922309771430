import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionsHeader } from '../../store/ducks/header';
import request from '../../services/request';
import { dateStringToDateTimeFormat } from '../../utils/dates';
import { Container, EditGroup, EditText, EditWrapper, Group, InputWrapper, Load, PhoneWrapper, SubmitButton, TextUpdated, Title } from './styles';
import Input from '../../components/Input/Input';
import { BiEdit } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { COLORS } from '../../utils/constants';
import CheckBox from '../../components/CheckBox/CheckBox';
import { cpfMask, maskCNPJ, maskPhone } from '../../utils/masks';
import Loading from 'react-loading';
import { useWindowSize } from '../Login';

interface Props {
  previousData: any;
  updated: any;
}

const RegisterUpdatePJ = ({ previousData, updated } : Props) => {

  /* ========================= CONSTANTS / STATES ======================= */

  const dispatch = useDispatch();
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [cpfResponsavel, setCpfResponsavel] = useState('');
  const [telefoneEmpresa1, setTelefoneEmpresa1] = useState('');
  const [telefoneEmpresa2, setTelefoneEmpresa2] = useState('');
  const [telefoneResponsavel1, setTelefoneResponsavel1] = useState('');
  const [telefoneResponsavel2, setTelefoneResponsavel2] = useState('');
  const [email, setEmail] = useState('');

  const [isWhatsappEmpresa1, setIsWhatsappEmpresa1] = useState(false);
  const [isWhatsappEmpresa2, setIsWhatsappEmpresa2] = useState(false);
  const [isWhatsappResponsavel1, setIsWhatsappResponsavel1] = useState(false);
  const [isWhatsappResponsavel2, setIsWhatsappResponsavel2] = useState(false);
  const [isEditable1, setIsEditable1] = useState(false);
  const [isEditable2, setIsEditable2] = useState(false);

  /* ========================= EFFECTS =========================== */

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Atualizacão cadastral'));
  }, [dispatch]);

  useEffect(() => {
    if (updated) {
      setRazaoSocial(updated.razaoSocial);
      setNomeFantasia(updated.nomeFantasia);
      setCnpj(updated.cnpj);
      setResponsavel(updated.responsavel);
      setCpfResponsavel(updated.cpfResponsavel);
      setTelefoneEmpresa1(updated.telefoneEmpresa1);
      setTelefoneEmpresa2(updated.telefoneEmpresa2);
      setTelefoneResponsavel1(updated.telefoneResponsavel1);
      setTelefoneResponsavel2(updated.telefoneResponsavel2);
      setEmail(updated.email);

      // Transformação de string recebida da resposta da API para booleano
      if (updated.isWhatsappEmpresa1 === '0') {
        setIsWhatsappEmpresa1(false);
      } else {
        setIsWhatsappEmpresa1(true);
      }

      if (updated.isWhatsappEmpresa2 === '0') {
        setIsWhatsappEmpresa2(false);
      } else {
        setIsWhatsappEmpresa2(true);
      }

      if (updated.isWhatsappResponsavel1 === '0') {
        setIsWhatsappResponsavel1(false);
      } else {
        setIsWhatsappResponsavel1(true);
      }

      if (updated.isWhatsappResponsavel2 === '0') {
        setIsWhatsappResponsavel2(false);
      } else {
        setIsWhatsappResponsavel2(true);
      }

    } else if (previousData) {
      setNomeFantasia(previousData.fantasia);
      setCnpj(previousData.cnpj);
      setResponsavel(previousData.responsavel);
      setCpfResponsavel(previousData.cpfResponsavel);
      setTelefoneEmpresa1(previousData.celular);
      setTelefoneEmpresa2(previousData.telefoneTrabalho);
      setTelefoneResponsavel1(previousData.celular2);
      setTelefoneResponsavel2(previousData.telefoneResidencial);
      setEmail(previousData.email);

      // Transformação de string recebida da resposta da API para booleano
      if (previousData.flagTelefoneResidencialWhats === '0') {
        setIsWhatsappEmpresa1(false);
      } else {
        setIsWhatsappEmpresa1(true);
      }

      if (previousData.flagTelefoneTrabalhoWhats === '0') {
        setIsWhatsappEmpresa2(false);
      } else {
        setIsWhatsappEmpresa2(true);
      }

      if (previousData.flagCelularWhats === '0') {
        setIsWhatsappResponsavel1(false);
      } else {
        setIsWhatsappResponsavel1(true);
      }

      if (previousData.flagCelular2Whats === '0') {
        setIsWhatsappResponsavel2(false);
      } else {
        setIsWhatsappResponsavel2(true);
      }

    }
  }, [previousData, updated]);

  /* ========================= FUNCTIONS =========================== */

  const handleEdit1 = () => {
    setIsEditable1(!isEditable1)
  }
  const handleEdit2 = () => {
    setIsEditable2(!isEditable2)
  }

  /* ========================== REQUESTS ========================= */

  const handleSubmit = () => {
    setLoading(true);

    const form = {
      razaoSocial,
      nomeFantasia,
      cnpj,
      responsavel,
      cpfResponsavel,
      telefoneEmpresa1,
      telefoneEmpresa2,
      telefoneResponsavel1,
      telefoneResponsavel2,
      isWhatsappEmpresa1,
      isWhatsappEmpresa2,
      isWhatsappResponsavel1,
      isWhatsappResponsavel2,
      email,
    };

    if (isEditable1) {
      if (!razaoSocial || razaoSocial.length < 3) {
        setLoading(false);
        toast.warn('Atenção: O campo razão social é obrigatório.');
        return;
      }
      else if (!nomeFantasia || nomeFantasia.length < 3) {
        setLoading(false);
        toast.warn('Atenção: O campo nome fantasia é obrigatório.');
        return;
      }
      else if (!cnpj) {
        setLoading(false);
        toast.warn('Atenção: O CNPJ é obrigatório.');
        return;
      }
      else if (!telefoneEmpresa1 && !telefoneEmpresa2) {
        setLoading(false);
        toast.warn('Atenção: Preencha ao menos um telefone para a empresa.');
      } 
      else if (!email && email.length < 3) {
        setLoading(false);
        toast.warn('Atenção: O campo email é obrigatório.');
      } else {
        try {
          request.post('atualizarCadastro', form).then((response) => {
            if (response.data.ok === 'Sucesso') {
              toast.success(`Muito bem! ${response.data.mensagem}`);
              setIsEditable1(false);
            } else {
              toast.error(`Atenção: ${response.data.erro}`);
            }
          }).catch((error) => {
            if (error?.message === 'Network Error') {
              toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
              toast.error('Atenção : A requisição falhou');
            }
          }).finally(() => {
            setLoading(false);
          });
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      }
    }

    if (isEditable2) {
      if (!responsavel || responsavel.length < 3) {
        setLoading(false);
        toast.warn('Atenção: O campo representante legal é obrigatório.');
        return;
      }
      else if (!cpfResponsavel) {
        setLoading(false);
        toast.warn('Atenção: O campo CPF do representante legal é obrigatório.');
        return;
      } else if (!telefoneResponsavel1 && !telefoneResponsavel2) {
        setLoading(false);
        toast.warn('Atenção: Preencha ao menos um telefone para o representante legal.');
      } else {
        try {
          request.post('atualizarCadastro', form).then((response) => {
            if (response.data.ok === 'Sucesso') {
              toast.success(`Muito bem! ${response.data.mensagem}`);
              setIsEditable1(false);
            } else {
              toast.error(`Atenção: ${response.data.erro}`);
            }
          }).catch((error) => {
            if (error?.message === 'Network Error') {
              toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
              toast.error('Atenção : A requisição falhou');
            }
          }).finally(() => {
            setLoading(false);
          });
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      }
    }
  };

  /* ========================== RENDERS ========================= */

  return (
    <Container>

      {
        updated && (
          <TextUpdated>
            {`Ultima atualização: ${dateStringToDateTimeFormat(updated.dataAtualizacao)}`}
          </TextUpdated>
        )
      }

      <Group>

        <EditGroup>
          <div style={{ width: 30 }} />
          <Title style={{ textAlign: "center" }}>Dados da empresa</Title>
          <EditWrapper onClick={handleEdit1}>
            {
              !isEditable1 ?
                <BiEdit size={30} color={COLORS.PRIMARY} />
                :
                <IoIosClose size={30} color={COLORS.PRIMARY} />
            }
            <EditText>{!isEditable1 ? "editar" : "cancelar"}</EditText>
          </EditWrapper>
        </EditGroup>

        <Input readOnly={!isEditable1 ? true : false} label="Razão social" fontColor={COLORS.PRIMARY} value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} placeholder="Razão social" style={{ width: "100%", textOverflow: "ellipsis" }} />

        <Input readOnly={!isEditable1 ? true : false} label="Nome fantasia" fontColor={COLORS.PRIMARY} value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} placeholder="Nome" style={{ width: "100%", textOverflow: "ellipsis" }} />

        <Input readOnly={!isEditable1 ? true : false} label="CNPJ" fontColor={COLORS.PRIMARY} value={cnpj} onChange={(e) => setCnpj(maskCNPJ(e.target.value))} placeholder="CNPJ" style={{ width: "100%" }} />

        <PhoneWrapper>
          <Input readOnly={!isEditable1 ? true : false} label="Telefone 1" fontColor={COLORS.PRIMARY} value={telefoneEmpresa1} onChange={(e) => setTelefoneEmpresa1(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable1 ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

          <CheckBox label="Marcar como whatsapp" checked={isWhatsappEmpresa1} onChange={() => setIsWhatsappEmpresa1(!isWhatsappEmpresa1)} disabled={!isEditable1 ? true : false} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
        </PhoneWrapper>

        <PhoneWrapper>
          <Input readOnly={!isEditable1 ? true : false} label="Telefone 2" fontColor={COLORS.PRIMARY} value={telefoneEmpresa2} onChange={(e) => setTelefoneEmpresa2(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable1 ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

          <CheckBox label="Marcar como whatsapp" checked={isWhatsappEmpresa2} onChange={() => setIsWhatsappEmpresa2(!isWhatsappEmpresa2)} disabled={!isEditable1 ? true : false} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
        </PhoneWrapper>

        <Input readOnly={!isEditable1 ? true : false} label="Email" fontColor={COLORS.PRIMARY} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="nome@email.com" type="email" style={{ width: "100%", textOverflow: "ellipsis" }} />

        {
          isEditable1 &&
          (
            !loading ?
              <InputWrapper>
                <SubmitButton onClick={handleSubmit}>Confirmar</SubmitButton>
              </InputWrapper>
              :
              <Load>
                <Loading color={COLORS.PRIMARY} height={50} type="spin" />
              </Load>
          )
        }

      </Group>

      <Group>

        <EditGroup>
          <div style={{ width: 30 }} />
          <Title style={{ textAlign: "center" }}>Dados do representante legal</Title>
          <EditWrapper onClick={handleEdit2}>
            {
              !isEditable2 ?
                <BiEdit size={30} color={COLORS.PRIMARY} />
                :
                <IoIosClose size={30} color={COLORS.PRIMARY} />
            }
            <EditText>{!isEditable2 ? "editar" : "cancelar"}</EditText>
          </EditWrapper>
        </EditGroup>

        <Input readOnly={!isEditable2 ? true : false} label="Representante legal" fontColor={COLORS.PRIMARY} value={responsavel} onChange={(e) => setResponsavel(e.target.value)} placeholder="Nome" style={{ width: "100%", textOverflow: "ellipsis" }} />

        <Input readOnly={!isEditable2 ? true : false} label="CPF do representante legal" fontColor={COLORS.PRIMARY} value={cpfResponsavel} onChange={(e) => setCpfResponsavel(cpfMask(e.target.value)
        )} placeholder="000.000.000-00" style={{ width: "100%" }} />

        <PhoneWrapper>
          <Input readOnly={!isEditable2 ? true : false} label="Telefone 1" fontColor={COLORS.PRIMARY} value={telefoneResponsavel1} onChange={(e) => setTelefoneResponsavel1(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable2 ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

          <CheckBox label="Marcar como whatsapp" checked={isWhatsappResponsavel1} onChange={() => setIsWhatsappResponsavel1(!isWhatsappResponsavel1)} disabled={!isEditable2 ? true : false} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
        </PhoneWrapper>

        <PhoneWrapper>
          <Input readOnly={!isEditable2 ? true : false} label="Telefone 2" fontColor={COLORS.PRIMARY} value={telefoneResponsavel2} onChange={(e) => setTelefoneResponsavel2(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable1 ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

          <CheckBox label="Marcar como whatsapp" checked={isWhatsappResponsavel2} onChange={() => setIsWhatsappResponsavel2(!isWhatsappResponsavel2)} disabled={!isEditable2 ? true : false} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
        </PhoneWrapper>

        {
          isEditable2 &&
          (
            !loading ?
              <InputWrapper>
                <SubmitButton onClick={handleSubmit}>Confirmar</SubmitButton>
              </InputWrapper>
              :
              <Load>
                <Loading color={COLORS.PRIMARY} height={50} type="spin" />
              </Load>
          )
        }

      </Group>

    </Container>
  );
}

export default RegisterUpdatePJ;