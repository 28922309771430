import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import { Divisor } from '../../components/Table/styles';
import { IOccurrence } from '../../interfaces/responses/IOccurrence';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { EmptyMessage, FormModal, Container, TableFlex1, TableFlex2, ButtonDetails, TextWrapper, AttachsWrapper, DivAttatch, TitleHeader, Load } from './styles';
import { Input } from '@chakra-ui/react';
import Select from '../../components/Select/Select';
import './style.css';
import { useWindowSize } from '../Login';
import RootState from '../../interfaces/states/RootState';
import { ActionsTempFiles } from '../../store/ducks/tempFiles';
import OcorrenceModal from './Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { COLORS } from '../../utils/constants';
import Loading from 'react-loading';

/* ========================= OBJECTS ======================== */

const optionsAssunto = [
  { value: null, label: '' },
  { value: 'SUGESTAO', label: 'Sugestão' },
  { value: 'RECLAMACAO', label: 'Reclamação' },
  { value: 'DUVIDAS', label: 'Dúvidas' },
  { value: 'ELOGIOS', label: 'Elogios' },
  { value: 'INFORMACAO', label: 'Informação' },
  { value: 'MUDANCA', label: 'Mudança' },
] as Array<{ value: string | number, label: string }>;

/* ========================= COMPONENT ======================== */

const ListOcurrencies = () => {

  /* ========================= CONSTANTS / STATES ======================== */

  const dispatch = useDispatch();
  const size = useWindowSize();
  const tempFiles = useSelector((state: RootState) => state.tempFiles);
  const erro = 'Nenhuma ocorrência cadastrada.';

  const [occurrencies, setOccurrencies] = useState<Array<IOccurrence>>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [date, setDate] = useState('');

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [toggleTable, setToggleTable] = useState(false);

  const [dataOcorrencia, setDataOcorrencia] = React.useState('');
  const [nomeCondomino, setNomeCondomino] = React.useState('');
  const [mensagemCondomino, setMensagemCondomino] = React.useState('');
  const [situacao, setSituacao] = React.useState('');
  const [comentary, setComentary] = React.useState('');

  const [modalOcorrenceVisible, setModalOcorrenceVisible] = React.useState(false);
  const [respostas, setRespostas] = React.useState<any[]>([]);

  /* ========================= REQUESTS ======================== */

  const getList = useCallback(async () => {
    setLoading(true);
    request.get('listarOcorrencias').then((response) => {
      if (response.data.erro) {
        //setErro(response.data.erro);
        return;
      }

      if (response.data.ok === 'Sucesso') {
        setOccurrencies(response.data.listagem);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  /* ========================= EFFECTS ======================== */

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Ocorrências'));
  }, [dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (size.sizeWidth <= 768)
      setToggleTable(true);
    else {
      setToggleTable(false);
    }
  }, [size.sizeWidth])

  /* ========================= FUNCTIONS ======================== */

  const convertFileToBase64 = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => addAttachTemp(reader.result);
  };

  const addAttachTemp = (item: any) => {
    dispatch(ActionsTempFiles.addFile(item));
  };

  const removeAttachTemp = (index: number) => {
    dispatch(ActionsTempFiles.removeFile(index));
  };

  function handleModalVisible() {
    setModalVisible(true);
  }

  const handleAddFile = (file: any) => {
    if (file) {
      const mimeType = file.type;
      const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf", "application/msword"];

      if (!allowedFileTypes.includes(mimeType)) {
        window.alert('Arquivo não suportado. Arquivos devem ser do tipo jpeg, png, pdf e/ou docx');
      } else {
        convertFileToBase64(file);
      }
    }
  };

  const handleOcorrenceModal = (dataOcorrencia: string, nomeCondomino: string, mensagem: string, situacao: string, respostas: any[], assunto: string) => {
    const textosRespostas = respostas.map(resposta => resposta.texto);
    
    setDataOcorrencia(dataOcorrencia);
    setNomeCondomino(nomeCondomino);
    setMensagemCondomino(mensagem);
    setSituacao(situacao);
    setComentary(comentary);
    setAssunto(assunto);
    setRespostas(textosRespostas);
    setModalOcorrenceVisible(true);
  }

  /* ========================= REQUESTS ======================== */

  function handleShowModalCreateOcorrency(e: React.FormEvent) {
    e.preventDefault();
    setLoadingCreate(true);

    const form = {
      assunto,
      mensagem,
      anexos: tempFiles,
      date
    };

    request.post('gravarOcorrencia', form).then((res) => {
      if (res.data.ok) {
        toast.success('Ocorrência cadastrada com sucesso.');
        setModalVisible(false);
        getList();
      } else {
        toast.error('Problemas para cadastrar sua ocorrência.');
        console.log(res.data.erro);

      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoadingCreate(false);
    });
  }

  /* ========================= RENDERS ======================== */

  return (
    <Container modalVisible={modalVisible} modalOcorrenceVisible={modalOcorrenceVisible}>
      <Button
        onClick={handleModalVisible}
        theme="primary"
        marginTop={10}
        style={size.sizeWidth <= 520 ? { margin: '10px auto 20px auto', fontWeight: 'bold', fontSize: 16, width: size.sizeWidth * 70 / 100, height: 40 } : {
          marginLeft: 'auto', fontWeight: 'bold', fontSize: 18, width: 300, height: 40, marginTop: 40,
        }}
      >
        Cadastrar ocorrência
      </Button>


      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
              <th>Data da ocorrência</th>
              <th>Condômino</th>
              <th>Assunto</th>
              <th>Status</th>
              <th>Detalhes</th>
            </tr>
          </thead>
          <Divisor colSpan={4} />
          <tbody>
            {loading && (
              <Load>
                <Loading type='spin' width={50} color={COLORS.PRIMARY} />
              </Load>
            )}
            {(occurrencies.length === 0 && !loading) && (
              <EmptyMessage colSpan={4}>
                <p>{erro}</p>
              </EmptyMessage>
            )}
            {occurrencies.map((o) => (
              <tr key={o.id} data-testid="occorrence-list">
                <td data-testid="ocorrence-date">{o.dataOcorrencia}</td>
                <td>{o.nomeCondomino}</td>
                <td>{o.assunto}</td>
                <td>{o.situacao}</td>
                <td>
                  <ButtonDetails
                    onClick={() => handleOcorrenceModal(o.dataOcorrencia, o.nomeCondomino, o.mensagem, o.situacao, o.resposta, o.assunto)}
                    data-testid="view-occorrence-button"
                  >
                    Visualizar
                  </ButtonDetails>
                </td>
              </tr>
            ))}
          </tbody>
        </TableFlex1>
        :
        <>
          {loading && (
            <Load>
              <Loading type='spin' width={50} color={COLORS.PRIMARY} />
            </Load>
          )}
          {(occurrencies.length === 0 && !loading) && (
            <EmptyMessage colSpan={4}>
              <p>{erro}</p>
            </EmptyMessage>
          )}
          {occurrencies.map((o) => (
            <TableFlex2 key={o.id} data-testid="occorrence-list">
              <tr>
                <th>Data da ocorrência</th>
                <td data-testid="ocorrence-date">{o.dataOcorrencia}</td>
              </tr>
              <tr>
                <th>Condômino</th>
                <td style={{ maxWidth: size.sizeWidth * 50 / 100 }}>{o.nomeCondomino}</td>
              </tr>
              <tr>
                <th>Assunto</th>
                <td style={{ maxWidth: size.sizeWidth * 50 / 100 }}>{o.assunto}</td>
              </tr>
              <tr>
                <th>Mensagem</th>
                <td style={{ maxWidth: size.sizeWidth * 50 / 100 }}>{o.mensagem}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{o.situacao}</td>
              </tr>
              <tr>
                <th>Detalhes</th>
                <td>
                  <ButtonDetails
                    onClick={() => handleOcorrenceModal(o.dataOcorrencia, o.nomeCondomino, o.mensagem, o.situacao, o.resposta, o.assunto)}
                    data-testid="view-occorrence-button"
                  >
                    Visualizar
                  </ButtonDetails>
                </td>
              </tr>
            </TableFlex2>
          ))}
        </>
      }

      <OcorrenceModal
        dataOcorrencia={dataOcorrencia}
        nomeCondomino={nomeCondomino}
        mensagem={mensagemCondomino}
        situacao={situacao}
        respostas={respostas}
        assunto={assunto}
        modalOcorrenceVisible={modalOcorrenceVisible}
        setModalOcorrenceVisible={setModalOcorrenceVisible}
      />

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Example Modal"
        className="contentCustom"
        overlayClassName="overlayCustom"
      >
        <div className="modal-content">

          <button type="button" onClick={() => setModalVisible(false)} className="close">X</button>

          {/* <TitleHeader style={{ textAlign: "center" }}>Nova Ocorrência</TitleHeader> */}

          <FormModal onSubmit={handleShowModalCreateOcorrency}>

            <Select
              options={optionsAssunto}
              value={assunto}
              label="Assunto: *"
              onChange={(e) => setAssunto(e.target.value)}
            />

            {
              assunto === "MUDANCA" ? (
                <>
                  <TextWrapper>
                    <h6>Data da mudança</h6>
                    <Input
                      type="date"
                      value={date}
                      onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setDate(e.target.value)}
                      borderColor="#3a3a3a"
                      _hover={{
                        borderColor: "#3a3a3a"
                      }}
                    />
                  </TextWrapper>

                  <TextWrapper>
                    <h6>Observações:</h6>
                    <textarea maxLength={900} rows={(size.sizeWidth <= 475 && size.sizeHeight >= 640) ? 12 : 6} onChange={(e) => setMensagem(e.target.value)} value={mensagem} />
                    <span style={{ marginRight: 10, alignSelf: "flex-end" }}>{mensagem.length}/900</span>
                  </TextWrapper>
                </>
              ) : (
                <>
                  <TextWrapper>
                    <h6>Mensagem:</h6>
                    <textarea maxLength={900} rows={(size.sizeWidth <= 475 && size.sizeHeight >= 640) ? 12 : 6} onChange={(e) => setMensagem(e.target.value)} value={mensagem} />
                    <span style={{ marginRight: 10, alignSelf: "flex-end" }}>{mensagem.length}/900</span>
                  </TextWrapper>

                  <AttachsWrapper>
                    <h6>Anexos:</h6>
                    {
                      tempFiles.map((a, i) => (
                        <DivAttatch key={a}>
                          <span>{`Anexo - ${i + 1}`}</span>
                          <AiOutlineClose onClickCapture={() => removeAttachTemp(i)} />
                        </DivAttatch>
                      ))
                    }
                    <Input type="file" onChange={(e: any) => handleAddFile(e.target.files[0])} style={{ border: "none", paddingLeft: 0 }} />
                  </AttachsWrapper>
                </>
              )

            }

            <Button
              type="submit"
              theme="primary"
              disabled={loadingCreate}
              marginTop={10}
              style={{
                fontWeight: 'bold', fontSize: 18, width: '100%', height: 50, marginTop: 40,
              }}
              data-testid="submit-occorrence-btn"
            >
              {!loadingCreate
                ? (assunto === "MUDANCA" ? "Cadastrar mudança" : "Cadastrar ocorrência")
                : (<Loading type='spin' height={30} width={30} />)
              }
            </Button>
          </FormModal>
        </div >
      </Modal >
    </Container >
  );
};

export default ListOcurrencies;