import {Flex} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Loading from 'react-loading';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IListReservas } from '../../../../interfaces/responses/IListReservas';
import request from '../../../../services/request';
import { ActionsHeader } from '../../../../store/ducks/header';
import { COLORS } from '../../../../utils/constants';
import { useWindowSize } from '../../../Login';
import TableReservas from '../../components/TableReservas';
import { Load } from '../../styles';
import { CalendarContainer, Container } from './styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useSelector } from 'react-redux';
import RootState from '../../../../interfaces/states/RootState';

const initialDate = new Date();
const initialMonth = initialDate.getMonth() + 1;
const initialYear = initialDate.getFullYear();

const ReservasCadastradas = () => {

    const idCondominioUsuario = useSelector((state: RootState) => state.session.isEmployee);

    const dispatch = useDispatch();
    const size = useWindowSize();
    const calendarRef = React.useRef<any>();
    const [reserves, setReserves] = React.useState<Array<IListReservas>>([]);
    const [unavailable, setUnavailable] = React.useState<Array<IListReservas>>([]);
    const [reservesFilter, setReservesFilter] = React.useState<Array<IListReservas>>(
      [],
    );
    const [year, setYear] = React.useState(initialYear);
    const [month, setMonth] = React.useState(initialMonth);
    const [dateSelected, setDateSelected] = React.useState('');

    const handleAlert = (dateStr: string) => {
  
      if (!unavailable[0]) {
        return;
      }
  
      const filtered = unavailable.find((u) => u.dataReserva === dateStr)
  
      if (filtered) {
        alert(`Atenção: ${filtered.areaLazer} indisponível nesta data.`)
      }
    }

    const filterReservesDay = React.useCallback(
      (dateSelectedParam) => {
        console.log(dateSelectedParam)
        setReservesFilter(
          reserves.filter((o) => o.dataReserva === dateSelectedParam),
        );
        setDateSelected(dateSelectedParam);        
        
        request
            .post('listarReservasRealizadasFuncionario', {"dataReserva" : dateSelectedParam})
            .then((response) => {
                if (response.data.ok = "Sucesso") {
                    setreservasList(response.data.listagem)
                    console.log(response.data.listagem)
                } else {
                    toast.error("Erro");
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            })

      },
      [reserves],
    );

    function getColorStatus(status: string) {
      switch (status) {
        case 'CANCELADO':
          return 'red';
        case 'PRE_RESERVADO':
          return 'blue';
        case 'CONFIRMADO':
          return 'green';
        case 'INDISPONIVEL':
          return 'purple';
        default:
          return 'gray';
      }
    }

    function calendarChangeMonth() {
      const calendarApi = calendarRef?.current?.getApi();
  
      if (calendarApi) {
        setMonth(Number(calendarApi.getDate().toISOString().split('-')[1]));
        setYear(Number(calendarApi.getDate().toISOString().split('-')[0]));
      }
    }

    const reservesMonthList = React.useMemo(() => {
      const reservesMonth = reserves.filter(
        (r) => Number(r.dataReserva.split('-')[1]) === month
          && Number(r.dataReserva.split('-')[0]) === year,
      );
  
      const unavailableMonth = unavailable.filter(
        (r) => Number(r.dataReserva.split('-')[1]) === month
          && Number(r.dataReserva.split('-')[0]) === year,
      );
  
      const combine = [...reservesMonth, ...unavailableMonth]
  
      return combine;
    }, [reserves, month, year]);

    const [loading, setLoading] = useState(false);

    // const [listUnidades, setListUnidades] = useState<Array<IUnidades>>([]);
    const [reservasList, setreservasList] = useState<Array<IListReservas>>([]);

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter('Reservas Cadastradas'));
    }, [dispatch]);

    const getVisits = useCallback(async () => {
        setLoading(true);
        
        request
            .post('listarReservasRealizadasFuncionario')
            .then((response) => {
                if (response.data.ok = "Sucesso") {
                    setreservasList(response.data.listagem)
                    console.log(response.data.listagem)
                } else {
                    toast.error("Erro");
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getVisits();
    }, [getVisits]);

    const handleVisitorType = (visitor: number) => {
        if (visitor === 1) {
            return "Visitante";
        } else if (visitor === 2) {
            return "Prestador de serviço";
        } else {
            return "Entregador";
        }
    }

    return (
        <Flex
            h="100%"
            bg="gray.50"
            p="4"
            direction="column"
        >
        <Container>

                {
                    loading ? (
                        <Load>
                            <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                        </Load>
                    ) :
                        (
                            reservasList &&
                            <TableReservas
                                reservasList={reservasList}
                                handleVisitorType={handleVisitorType}
                            />
                        )
                }
                

            <CalendarContainer>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today',
                }}
                buttonText={{
                today: 'Hoje',
                }}
                ref={calendarRef}
                initialView="dayGridMonth"
                editable
                selectable={true}
                selectMirror
                dayMaxEvents={false}
                eventDisplay="list-item"
                progressiveEventRendering
                dayMaxEventRows={false}
                locale="pt-BR"
                timeZone="America/Sao_Paulo"
                datesSet={calendarChangeMonth}
                dateClick={({ dateStr }) => {
                filterReservesDay(dateStr);
                handleAlert(dateStr);
                }}
            />
            </CalendarContainer>
        </Container>
        </Flex>
    );
}

export default ReservasCadastradas;