import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import request from '../../../services/request';
import { ActionsHeader } from '../../../store/ducks/header';
import { useHistory, useParams } from 'react-router-dom';
import { TableFlex1, Container, DescBox } from '../styles';
function VoteEnquete() {

  const dispatch = useDispatch();
  const [opcoes, setOpcoes] = React.useState<Array<any>>([]);
  const [enquete, setEnquete] = React.useState<Array<any>>([]);
  const [tituloEnquete, setTituloEnquete] = React.useState();
  const [votosMultiplaEscolha, setVotosMultiplaEscolha] = React.useState<Array<any>>([]);
  const [multiplaEscolha, setMultiplaEscolha] = React.useState(false);
  const [votoUnico, setVotoUnico] = React.useState(0);
  const history = useHistory();
  const { idEnquete } = useParams() as any;


  React.useEffect(() => {
        
        request.get(`getEnquetes/${idEnquete}`).then((response) => {
            if (response.data.sucesso) {
                setEnquete([response.data.enquetes[0]]);
                if (response.data.enquetes[0].multiplaEscolha == 1) {
                  setMultiplaEscolha(true);
                }
                setOpcoes(response.data.enquetes[0].opcoes);
            }            
        });

        dispatch(ActionsHeader.changeTextCenter('Votar'));

  }, [dispatch, idEnquete]);

  const adicionaVoto = (idOpcao: number) => {
    console.log(idOpcao);
    var arr_votos = votosMultiplaEscolha;

    if (!arr_votos.includes(idOpcao)) {
      arr_votos.push(idOpcao);
    } else {
      const index = arr_votos.indexOf(idOpcao);
      arr_votos.splice(index, 1);
    }
    setVotosMultiplaEscolha(arr_votos);

  };

  const handleSubmit = () => {
    console.log(idEnquete);
    const routeApi = 'votarEnquete';
    const hasVotos = votoUnico != 0 || votosMultiplaEscolha.length > 0;

    if (!hasVotos) {
      toast.error('Informe seu voto antes de confirmar!');
    } else {
      if (window.confirm('Deseja confirmar?')) {
        const form = {
          idEnquete: idEnquete,
          votos: (multiplaEscolha) ? votosMultiplaEscolha : [votoUnico] ,
        };
        console.log(form);
        request.post(routeApi, form).then((response) => {
          console.log(response);
          if (response.data.sucesso) {
            toast.success('Muito bem, seu voto foi Enviado');
            history.push('/list_enquetes')
          } else if (!response.data.sucesso) {
            toast.error(response.data.mensagem);
          } else {
            toast.error('Ops, algo de errado aconteceu ao tentar enviar o seu voto');
          }
        }).catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro : A requisição falhou');
          }
        });
      }

    }
  }
  
  var count = 1;

  return (
    <>  
        <div style={{ width: "95%", margin: '0 auto' }}>
            <Container>
              {enquete[0] != undefined && (
                <div>
                  <h1>{enquete[0].titulo}</h1>
                  <h2>Descrição da enquete: </h2>
                  <DescBox style={{fontSize: "21px", fontWeight: "bold"}}>{enquete[0].descricao}</DescBox>
                </div>
              )}
              <form>
                <TableFlex1>                
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Descrição opção</th>
                            <th>Votar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opcoes.map((o) => (
                        <>
                            <tr key={o.idOpcao}>
                                <td>{count++}</td>
                                <td className="table-unidade">{`${o.descricao}`}</td>
                                <td>
                                        {multiplaEscolha ? 
                                          <div style={{width: "fit-content", margin: "auto"}}><Input type={"checkbox"} onChange={() => adicionaVoto(o.idOpcao)} /></div>
                                        :
                                          <div style={{width: "fit-content", margin: "auto"}}><Input name="votoUnico" type={"radio"} onChange={() => setVotoUnico(o.idOpcao)} /></div>
                                        }
                                    
                                </td>
                            </tr>
                        </>
                        ))}
                    </tbody>
                </TableFlex1>
              </form>
              <Button theme="primary" onClick={handleSubmit} style={{ width: "100%", margin: "auto" }}> Confirmar voto </Button>
            </Container>
        </div>
    </>
  );
}

export default VoteEnquete;
