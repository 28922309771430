import React, {
  FormEvent, useCallback, useEffect, useState,
} from 'react';
import request from '../../../../../services/request';

import * as S from './styles';

interface IPauta {
  id: string;
  titulo: string;
  descricao: string;
}

type FormCreatePautaProps = {
  assemblySelected: undefined | { id: number; titulo: string };
};

const FormCreatePauta = ({ assemblySelected }: FormCreatePautaProps) => {
  const [pautas, setPautas] = useState<IPauta[]>([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState<{loading : boolean, pauta : IPauta | null}>();
  const [editMode, setEditMode] = useState(false);
  const [pautaSelected, setPautaSelected] = useState<IPauta | null>(null);

  const getPautasAssembly = useCallback(async () => {
    if (assemblySelected) {
      request
        .get(`pautas?idAssembleiaVirtual=${assemblySelected.id}`)
        .then((response) => {
          if (response.data.ok === 'Sucesso') {
            setTitle('');
            setDescription('');

            const { resultados } = response.data.listagem;
            setPautas(resultados?.reverse());
          }
        });
    }
  }, [assemblySelected]);

  useEffect(() => {
    getPautasAssembly();
  }, [getPautasAssembly]);

  function deletePauta(pauta : IPauta) {
    if (pauta && assemblySelected) {
      setLoadingDelete({ loading: true, pauta });
      const routeApi = `pautas/${pauta.id}`;

      request
        .delete(routeApi)
        .then((response) => {
          if (response.data.ok === 'Sucesso') {
            getPautasAssembly();
          }
        })
        .finally(() => {
          setLoadingDelete({ loading: false, pauta: null });
        });
    }
  }

  function editPauta(e: FormEvent) {
    e.preventDefault();

    if (pautaSelected && assemblySelected) {
      setLoadingEdit(true);
      const routeApi = `pautas/${pautaSelected.id}`;

      const form = {
        titulo: title,
        descricao: description,
        idAssembleiaVirtual: assemblySelected.id,
      };

      request
        .post(routeApi, form)
        .then((response) => {
          if (response.data.ok === 'Pauta modificada com sucesso.') {
            getPautasAssembly();
            setEditMode(false);
          }
        })
        .finally(() => {
          setDescription('');
          setTitle('');
          setLoadingEdit(false);
        });
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (assemblySelected) {
      const routeApi = 'pautas';
      const form = {
        titulo: title,
        descricao: description,
        idAssembleiaVirtual: assemblySelected.id,
      };
      setLoadingCreate(true);
      request
        .post(routeApi, form)
        .then((response) => {
          if (response.data.ok === 'Pauta adicionada com sucesso.') {
            getPautasAssembly();
          }
        })
        .finally(() => setLoadingCreate(false));
    }
  };

  if (editMode) {
    return (
      <S.Container>
        <h2>{pautaSelected?.titulo}</h2>
        <h4>Editar Pauta</h4>
        <form onSubmit={editPauta}>
          <div>
            <input
              placeholder="Título"
              value={title}
              onChange={(text) => setTitle(text.target.value)}
              type="text"
            />
            <input
              placeholder="Descrição"
              value={description}
              onChange={(text) => setDescription(text.target.value)}
              type="text"
            />
          </div>

          <span>
            <button
              type="button"
              className="cancel"
              onClick={() => {
                setDescription('');
                setTitle('');
                setEditMode(false);
              }}
            >
              Cancelar
            </button>
          </span>
          <button disabled={loadingEdit} type="submit">
            {loadingEdit ? 'Editando Pauta...' : 'Editar Pauta'}
          </button>
        </form>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <h2>{assemblySelected?.titulo}</h2>
      <h4>Criar Pautas</h4>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            placeholder="Título"
            value={title}
            onChange={(text) => setTitle(text.target.value)}
            type="text"
          />
          <input
            placeholder="Descrição"
            value={description}
            onChange={(text) => setDescription(text.target.value)}
            type="text"
          />
        </div>

        <button disabled={loadingCreate} type="submit">
          {loadingCreate ? 'Criando Pauta...' : 'Criar Pauta'}
        </button>
      </form>

      <h4>Lista de Pautas</h4>
      <ul>
        {pautas.map((p) => (
          <S.PautaItem>
            <div>
              <p>{p.titulo}</p>
              <span>{p.descricao}</span>
              <footer>
                <button
                  className="edit"
                  type="button"
                  onClick={() => {
                    setTitle(p.titulo);
                    setDescription(p.descricao);
                    setPautaSelected(p);
                    setEditMode(true);
                  }}
                >
                  Editar
                </button>
                <button disabled={loadingDelete?.loading} className="delete" type="button" onClick={() => deletePauta(p)}>
                  {(loadingDelete?.loading && loadingDelete.pauta?.id === p.id) ? 'Deletando...' : 'Deletar'}
                </button>
              </footer>
            </div>

          </S.PautaItem>
        ))}
      </ul>
    </S.Container>
  );
};

export default FormCreatePauta;
