import IAction from '../../interfaces/IAction';

export const Types = {
  OPEN: 'drawer/OPEN',
  CLOSE: 'drawer/CLOSE',
  TOGGLE: 'drawer/TOGGLE',
};

// Reducer

const initialState = {
  isOpen: false,
};

export default function reducer(state = initialState, action:IAction) {
  switch (action.type) {
    case Types.OPEN:
      return { ...state, isOpen: true };
    case Types.CLOSE:
      return { ...state, isOpen: false };
    case Types.TOGGLE:
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
}

// Action Creators

export function open() {
  return {
    type: Types.OPEN,
  };
}

export function close() {
  return {
    type: Types.CLOSE,
  };
}

export function toggle() {
  return {
    type: Types.TOGGLE,
  };
}
