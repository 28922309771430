import React from 'react';
import { Title } from '../styles';
import { ViewCard, ViewItemCard, ViewItemCardWithScroll } from './styles';

type Props = {
    nomeUnidade: string;
    dataCriacao: string;
    ultimaResposta?: string;
    assunto?: string;
    isOpen?: boolean;
}

const Cards: React.FC<Props> = ({ nomeUnidade, dataCriacao, ultimaResposta, assunto, isOpen, children }) => {
    return (
        <ViewCard openOcorrence={isOpen} data-testid="card">

            <ViewItemCard>
                <span><Title>Unidade: </Title>{nomeUnidade}</span>
            </ViewItemCard>

            <ViewItemCard>
                <span><Title>Data da ocorrência: </Title>{dataCriacao}</span>
            </ViewItemCard>

            {assunto && (
                <ViewItemCard>
                    <span><Title>Assunto: </Title>{assunto}</span>
                </ViewItemCard>
            )}

            {ultimaResposta && (
                <ViewItemCardWithScroll>
                    <span><Title>Última resposta: </Title><i>"{ultimaResposta}"</i></span>
                </ViewItemCardWithScroll>
            )}

            {children}

        </ViewCard>
    )
};

export default Cards;