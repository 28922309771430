import { Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import {
  FlexButton,
  IconButton1,
  IconButton2,
  InitialsText,
  ButtonText,
  FlexCard,
} from './styles';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useModalClubePartiu } from '../../../hooks/modules/ModalClubePartiuContext';
import request from '../../../services/request';
import { goToExternalLink } from '../../../utils/functions';

type Props = {
  title: string;
  icon?: IconType;
  initials?: string;
  ml?: boolean;
  route_path?: string;
  action?: string;
  emManutencao?: boolean;
};

const CardPanel: React.FC<Props> = ({
  title,
  icon: Icon,
  initials,
  ml = false,
  route_path,
  action,
  emManutencao,
}) => {
  const history = useHistory();

  const { handleOpen } = useModalClubePartiu();

  const createLetterCompliance = () => {
    request
      .get('cartaAdimplencia')
      .then((response) => {
        if (response.data.ok === 'Sucesso') {
          goToExternalLink(response.data.descricao);
        } else {
          toast.error(`Erro ${response.data.erro}`);
        }
      })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error(
            'Falha na rede. : Verifique sua conexão e tente novamente.'
          );
        } else {
          toast.error('Erro : A requisição falhou');
        }
      });
  };

  function handlePressCard() {
    if (emManutencao) {
      alert('Funcionalidade em manutenção');
    } else {
      if (route_path) {
        history.push(route_path);

        return;
      }
      if (action) {
        if (action === 'OPEN_MODAL_CP') {
          handleOpen();
        }
        if (action === 'CREATE_LETTER_COMPLIANCE') {
          createLetterCompliance();
        }
      }
    }
  }

  return (
    <FlexButton
      onClick={handlePressCard}
      style={{ opacity: emManutencao ? 0.5 : 1 }}
    >
      <FlexCard>
        <IconButton1>
          {!!Icon && <Icon size='28px' color='#009B6E' />}
        </IconButton1>

        <IconButton2>
          {!!Icon && <Icon size='22px' color='#009B6E' />}
        </IconButton2>

        {initials && <InitialsText>{initials}</InitialsText>}
      </FlexCard>
      <ButtonText>{title}</ButtonText>
    </FlexButton>
  );
};

export default CardPanel;
