import React from 'react';
import styled from 'styled-components';

type Props = {
    text: string;
}

const TextAlternative: React.FC<Props> = ({ text }) => {
    return (
        <TextWrapper>
            <p style={{ textAlign: "center", color: "#000", fontSize: 14 }}>
                {text}
            </p>
        </TextWrapper>
    )
};

const TextWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.2);
    padding: 5px 0;
    border-radius: 5px;
`;

export default TextAlternative;