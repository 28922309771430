import React from "react";
import Modal from 'react-modal';
import customStyles from "../../utils/customStylesModal";
import { FlexCol, FlexRow, Title } from "../ManagerOcorrence/styles";
import { TitleHeader, Wrapper } from "./styles";

type Props = {
    dataOcorrencia: string;
    nomeCondomino: string;
    mensagem: string;
    situacao: string;
    respostas: Array<{ texto: string, data: string, autor: string }>;
    assunto: string;
    modalOcorrenceVisible: boolean;
    setModalOcorrenceVisible: (e: boolean) => void;
}

const OcorrenceModal: React.FC<Props> = ({ dataOcorrencia, nomeCondomino, mensagem, situacao, respostas, assunto, modalOcorrenceVisible, setModalOcorrenceVisible }) => {

    return (
        <Modal
            isOpen={modalOcorrenceVisible}
            onRequestClose={() => setModalOcorrenceVisible(false)}
            style={customStyles}
            contentLabel="Example Modal"
            className="contentCustom"
            overlayClassName="overlayCustom"
        >
            <div className="modal-content">

                <button type="button" onClick={() => setModalOcorrenceVisible(false)} className="close">X</button>

                <TitleHeader style={{ textAlign: "center" }}>Detalhes da ocorrência</TitleHeader>

                <Wrapper>
                    <FlexRow>
                        <span data-testid="display-nomeCondomino"><Title>Condômino: </Title>{nomeCondomino}</span>
                    </FlexRow>

                    <FlexRow>
                        <span data-testid="display-occorrenceDate"><Title>Data da ocorrência: </Title>{dataOcorrencia}</span>
                    </FlexRow>

                    <FlexRow>
                        <span data-testid="display-occorrenceStatus"><Title>Status: </Title>{situacao}</span>
                    </FlexRow>

                    <FlexRow>
                        <span data-testid="display-occorrenceSubject"><Title>Assunto: </Title>{assunto}</span>
                    </FlexRow>

                    <FlexCol style={{ marginBottom: 10 }}>
                        <Title>Mensagem:</Title>
                        <p>{mensagem}</p>
                    </FlexCol>

                    <FlexCol>
                        <Title>Feedback do síndico:</Title>
                        {respostas.length > 0 ? (
                            respostas.map((texto, index) => (
                                <p key={index}>{texto}</p>
                                ))
                                ) : (
                                    <div style={{ backgroundColor: "rgba(0,0,0,.2)", padding: "5px 0px", borderRadius: 5, marginBottom: 15 }}>
                                        <p style={{ textAlign: "center", color: "#000", fontSize: 14 }}>
                                            Não há feedback do síndico
                                        </p>
                                    </div>
                                )
                        }
                    </FlexCol>

                </Wrapper>
            </div>
        </Modal>
    )
}

export default OcorrenceModal;