import React from 'react';

import { ButtonDefault, ButtonPrimary, ButtonSecondary } from './styles';

interface Props {
    theme?: 'primary' | 'secondary' | 'default'
    onClick?: ()=>void;
    children: any;
    type?: 'button' | 'submit' | 'reset';
    marginHorizontal?: number;
    marginVertical?: number;
    marginTop?: number;
    maxwidth?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
}

const Button = (props:Props) => {
  const {
    theme = 'default', onClick, children, type, marginHorizontal, marginVertical, marginTop, maxwidth, style, disabled
  } = props;
  switch (theme) {
    case 'primary': return (
      <ButtonPrimary
        type={type || 'button'}
        onClick={onClick}
        data-marginhorizontal={marginHorizontal}
        data-marginvertical={marginVertical}
        data-margintop={marginTop}
        data-maxwidth={maxwidth}
        style={style}
        disabled={disabled}
      >
        {children}
      </ButtonPrimary>
    );
    case 'secondary': return (
      <ButtonSecondary
        type={type || 'button'}
        onClick={onClick}
        data-marginhorizontal={marginHorizontal}
        data-marginvertical={marginVertical}
        data-margintop={marginTop}
        style={style}
        disabled={disabled}
      >
        {children}
      </ButtonSecondary>
    );
    default: return (
      <ButtonDefault
        onClick={onClick}
        data-marginhorizontal={marginHorizontal}
        data-marginvertical={marginVertical}
        data-margintop={marginTop}
        style={style}
        disabled={disabled}
      >
        {children}
      </ButtonDefault>
    );
  }
};

export default Button;
