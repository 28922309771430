import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import RootState from '../../interfaces/states/RootState';
import request, { setHeader } from '../../services/request';
import { ActionsSession } from '../../store/ducks/session';
import { Container } from './styles';

function SelectUnity() {
  const dispatch = useDispatch();
  const history = useHistory();

  const todosVinculos = useSelector((state: RootState) => state.session.todosVinculos);
  const isManager = useSelector((state: RootState) => state.session.isManager);
  const { idUsuarioLogado, hash, tipoUsuario } = useSelector((state: RootState) => state.session);

  const [unidades, setUnidades] = useState<Array<any>>([]);
  const [vinculoId, setVinculoId] = useState(todosVinculos[0].id);

  const logout = () => {
    dispatch(ActionsSession.destroy());
  };

  function handleSelectUnity() {
    const vinculo = todosVinculos.find((v: any) => Number(v.id) === Number(vinculoId));

    if (!vinculo) return;

    setHeader({
      hash,
      idUsuarioLogado,
      tipoUsuario,
      hashVinculo: vinculo.hashVinculo,
      idVinculo: vinculo.condominio ? vinculo.condominio.id : vinculo.id,
      agent: 'web',
    });

    request
      .post(
        '/salvaAcesso',
        {
          id: vinculo.condominio ? vinculo.condominio.id : vinculo.id,
          tipo: isManager ? 'SINDICO' : 'CONDOMINO',
        },
        true,
      )
      .then((response) => {
        if (response.data.ok === 'Sucesso') {
          dispatch(
            ActionsSession.setVinculo({
              idVinculo: vinculoId,
              hashVinculo: vinculo.hashVinculo,
              logoAdm:
                vinculo?.unidade?.logoAdministradora
                || vinculo?.condominio?.logoAdministradora,
              descVinculoAtual:
                vinculo?.unidade?.descricao || vinculo?.condominio?.descricao,
            }),
          );
          history.push(isManager ? '/home_manager' : '/home_condomino');
        } else {
          alert('Falha ao selecionar unidade');
        }
      });
  }

  React.useEffect(() => {
    const arrayTemp = todosVinculos.map((i) => ({ value: i.id, label: i.unidade?.descricao || i.condominio?.descricao }));
    setUnidades(arrayTemp);
  }, [todosVinculos]);

  return (
    <Container>
      <h1>Selecione a unidade</h1>
      <select
        onChange={(e) => setVinculoId(e.target.value)}
        value={vinculoId}
      >
        {unidades.map((v) => <option key={v.value} value={v.value}>{v.label}</option>)}
      </select>

      <Button theme="primary" onClick={handleSelectUnity}>Ir para unidade</Button>
      <Button theme="secondary" onClick={logout}>Sair</Button>
    </Container>
  );
}

export default SelectUnity;
