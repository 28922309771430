import IReserve from '../../interfaces/responses/IReserve';
import request from '../../services/request';

export interface DetailsReserve {
  idCondomino: number;
  condomino: string;
  termoReserva: string;
  condominio: string;
}

export const getDetailsReserve = async (id: number) => {
  const response = await request.get(`dadosReserva/${id}`);
  return response.data.listagem as DetailsReserve;
};

export const getListReserves = async () => {
  const response = await request.get('listarReservasRealizadasV2');
  return response.data.listagem as Array<IReserve>;
};

export const getListUnavailable = async () => {
  const response = await request.get('listarIndisponibilidadeReservas');
  return response.data.listagem as Array<IReserve>;
};
