import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
  margin-top: ${(props:any) => (props['data-margintop'] ? `${props['data-margintop']}px` : '8px')};
`;

export const Label = styled.span`
  color: ${(props:any) => (props['data-lablecolor'] ? props['data-lablecolor'] : COLORS.SECONDARY)};
  font-weight: bold;
  margin-left: ${(props:any) => (props['data-marginleft'] ? `${props['data-marginleft']}px` : '0px')};

  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

export const InputStyled = styled.input`
  height: 20px;
  width: 20px;
  padding: 8px 5px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: ${(props:any) => (props['data-lablecolor'] ? props['data-lablecolor'] : COLORS.SECONDARY)};
`;
