import styled from 'styled-components';
import { COLORS } from '../../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 24px 0 0;
`;
export const ButtonCreateAssembleia = styled.button`
  align-self: flex-end;
  margin: 12px 0;
  padding: 10px;
  font-weight: bold;
  background-color: ${COLORS.PRIMARY};
  border: 0;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  display: flex;
  align-items: center;

  > p {
    margin: 0;
    margin-right: 6px;
  }

  margin-top: 40px;
`;
export const Modal = styled.div``;
export const ModalContentVotes = styled.div`
  max-height: calc(100vh - 200px);
`;
export const CardOptionVote = styled.div`
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 10px;
`;
