import request from '../../services/request';

interface params {
  tipo: number;
  documento: string;
  senha: string;
}

interface params2 {
  tipo: number;
  login: string;
  senha: string;
}

export const logar = (payload:params | params2) => request.postNotHeader('logar', payload);
