import React from 'react';
import ReactLoading from 'react-loading';
import { Container } from './styles';
import { COLORS } from '../../utils/constants';

const Loading = () => (
  <Container>
    <ReactLoading type="spin" color={COLORS.SECONDARY} width="8%" />
  </Container>
);

export default Loading;