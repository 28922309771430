import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  BoxProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { MdMessage } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdChatboxes, IoMdExit } from 'react-icons/io';
import { FaCalendarAlt, FaRegCalendarCheck, FaHome, FaClock, FaRegCalendar} from 'react-icons/fa';

import NavItem from './NavItem';
import logo from '../../../assets/logo-borda-branca.png';
import { goToExternalLink } from '../../../utils/functions';
import RootState from '../../../interfaces/states/RootState';
import { ActionsSession } from '../../../store/ducks/session';

interface LinkItemProps {
  name: string;
  icon: IconType;
  hash: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', icon: FaHome, hash: '/' },
  { name: 'Visitas Agendadas', icon: FaCalendarAlt, hash: 'visitas_agendadas' },
  { name: 'Cadastro de Visitas', icon: FaRegCalendarCheck, hash: 'visitas_cadastradas' },
  { name: 'Consumos', icon: FaClock, hash: 'consumos_funcionario' },
  { name: 'Reservas', icon: FaRegCalendar, hash: 'reservas_cadastradas' },
  { name: 'Chat', icon: IoMdChatboxes, hash: 'chat_funcionario' },
  { name: 'Fale com a Administradora', icon: MdMessage, hash: 'fale_admin' },
  { name: 'Sair', icon: IoMdExit, hash: 'sair' },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { descVinculoAtual, todosVinculos, isManager } = useSelector((state : RootState) => state.session);

  const handleClick = () => onClose();

  const handleIdAdm = (todosVinculos: any, descVinculoAtual: string, isManager: boolean) => {
    if (isManager) {
      const { condominio } = todosVinculos.find((item: any) => item?.condominio?.descricao === descVinculoAtual)
      return Number(condominio.idAdministradora);
    } 
    else {
      const { unidade } = todosVinculos.find((item: any) => item.unidade.descricao === descVinculoAtual)
      return unidade.idAdministradora;
    }
    return 0;
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 72 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        {/* <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Logo
        </Text> */}
        <img src={logo} width={200} height={45} alt="Logo" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          hash={link.hash}
          key={link.name}
          icon={link.icon}
          onClick={() => {
            handleClick();
            if (link.hash === 'sair') {
              dispatch(ActionsSession.destroy());
              return;
            }

            if (link.hash === 'fale_admin') {
              if (handleIdAdm(todosVinculos, descVinculoAtual, isManager) !== 34) {            
                return goToExternalLink('https://api.whatsapp.com/send?phone=558003457900&text=Oi');
              }
              return;
            }

            history.push(link.hash);
          }}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

export default SidebarContent;
