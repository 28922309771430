import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../Login';
import { toast } from 'react-toastify';
import request from '../../services/request';
import RootState from '../../interfaces/states/RootState';
import { IEmployee } from '../../interfaces/responses/IEmployee';
import { ActionsHeader } from '../../store/ducks/header';
import { IUnidades } from '../../interfaces/responses/IListUnity';
import { IChatHistory } from '../../interfaces/responses/IChatHistory';
import { IListCondominos } from '../../interfaces/responses/IListCondominos';
import ChatView from './ChatView';
import {
  Box, Button, Select,
  Stack, Text
} from '@chakra-ui/react';
import {
  ChatWindow, ChooseUser, CollapsedDiv,
  ContactButton, ContactCircle, EmptyMessage,
  GoBack, LeftContent, NewChat
} from './styles';
import { AiOutlineMenu } from 'react-icons/ai';
import {
  IoIosArrowBack, IoIosClose, IoIosChatboxes
} from 'react-icons/io';
import { COLORS } from '../../utils/constants';

const ChatFuncionario = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const history = useHistory();
  const { isEmployee } = useSelector((state: RootState) => state.session);

  const [funcionarios, setFuncionarios] = useState<Array<IEmployee>>([]);
  const [funcionarioSelected, setFuncionarioSelecet] = useState<IEmployee | null>();

  const [showUnities, setShowUnities] = useState<Array<IUnidades>>([]);
  const [singleUnity, setSingleUnity] = useState();
  const [singleCondominoId, setSingleCondominoId] = useState();
  const [condominos, setCondominos] = useState<Array<IListCondominos>>([]);

  const [unidades, setUnidades] = useState<Array<IChatHistory>>([]);
  const [unidadeSelected, setUnidadeSelected] = useState<IChatHistory | null>();

  const [isHistoryOpen, setIsHistoryOpen] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {

    if (!isEmployee) {
      request.get('buscarFuncionariosCondominio').then((response) => {
        setFuncionarios(response.data);
      })
    } else {
      request.get('buscarUnidadesCondominio')
        .then((response) => {
          if (response.data.ok === "Sucesso") {
            setShowUnities(response.data.Unidades);
          } else {
            toast.error(response.data.erro);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        })

      request.get('historicoMensagens')
        .then((response) => {
          if (response.data.ok === "Sucesso") {
            setUnidades(response.data.historico);
          } else {
            console.log(response.data.erro);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        })
    }

    dispatch(ActionsHeader.changeTextCenter(!isEmployee ? "Fale com o porteiro" : "Chat"));
  }, [dispatch]);

  useEffect(() => {
    if (isEmployee && singleUnity) {
      request.post('buscarCondominosUnidades', { idUnidade: singleUnity })
        .then((response) => {
          if (response.data.ok === "Sucesso") {
            setCondominos(response.data.lista);
          } else {
            toast.error(response.data.erro);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        })
    }
  }, [singleUnity]);

  useEffect(() => {
    if (size.sizeWidth <= 520) {
      setIsHistoryOpen(false);
    } else {
      setIsHistoryOpen(true);
    }
  }, [size.sizeWidth]);

  const handleSelectedFuncionario = async (funcionario: any) => {
    try {
      setFuncionarioSelecet(funcionario);
    }
    catch (error) {
      alert('Erro: Problema ao selecionar funcionário');
    }
    finally {
      if (size.sizeWidth <= 520) {
        setIsHistoryOpen(false);
      } else {
        return;
      }
    }
  }

  const handleSelectedUnidade = async (unidade: any, flag: 1 | 2) => {
    if (flag === 1) {
      try {
        setUnidadeSelected(unidade);
      }
      catch (error) {
        alert('Erro: Problema ao selecionar conversa');
      }
      finally {
        if (size.sizeWidth <= 520) {
          setIsHistoryOpen(false);
        }
      }
    } else {
      try {
        setUnidadeSelected({
          horario: "",
          id: "",
          idCondominio: "",
          idEnviou: singleCondominoId || "",
          idRecebeu: singleCondominoId || "",
          idSalaUsuario: "",
          idUnidade: "",
          lida: "1",
          mensagem: "",
          nomeChat: "",
          nomeSala: "",
          participantes: "",
          tipo: "",
        });
      }
      catch (error) {
        alert('Erro: Problema ao selecionar conversa');
      }
      finally {
        if (size?.sizeWidth <= 520) {
          setIsHistoryOpen(false);
        } else {
          return;
        }
      }
    }
  }

  const startChat = () => {
    const findUnityHistory = unidades.find((unidade) => unidade.idRecebeu == singleCondominoId);

    if (findUnityHistory) {
      handleSelectedUnidade(findUnityHistory, 1);
    } else {
      setOpenChat(true);
      handleSelectedUnidade(unidades[0], 2);
    }
  }

  const firstLetter = (str: string) => {
    return str.charAt(0).toLocaleUpperCase();
  }

  const handleGoBack = () => {
    if (!isEmployee) {
      request.get('buscarFuncionariosCondominio')
        .then((response) => {
          setFuncionarios(response.data);
        })
        .finally(() => {
          setNewChat(false);
        })
    } else {
      request.get('historicoMensagens')
        .then((response) => {
          if (response.data.ok === "Sucesso") {
            setUnidades(response.data.historico);
          } else {
            console.log(response.data.erro);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        })
        .finally(() => {
          setNewChat(false);
        })
    }
  }

  return (
    <ChatWindow
      style={{
        flexDirection: size.sizeWidth <= 520 ? "column" : "row"
      }}
    >
      {
        size.sizeWidth <= 520 && (
          !isHistoryOpen ? (
            <CollapsedDiv>
              <div>
                <AiOutlineMenu
                  size={30}
                  onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                  color="#009B6E"
                />
                <span onClick={() => setIsHistoryOpen(!isHistoryOpen)}>Lista de contatos</span>
              </div>
              <IoIosClose
                size={50}
                color='#009B6E'
                onClick={() => history.push('portal_funcionario')}
                style={{ justifySelf: "flex-end" }}
              />
            </CollapsedDiv>
          ) : (
            <CollapsedDiv>
              <div>
                <IoIosChatboxes
                  size={30}
                  onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                  color="#009B6E"
                />
                <span onClick={() => setIsHistoryOpen(!isHistoryOpen)}>Voltar ao chat</span>
              </div>
              <IoIosClose
                size={50}
                color='#009B6E'
                onClick={() => history.push('portal_funcionario')}
                style={{ justifySelf: "flex-end" }}
              />
            </CollapsedDiv>
          )
        )
      }

      {
        isHistoryOpen && (
          <LeftContent>
            {
              isEmployee && (
                !newChat ? (
                  <NewChat onClick={() => setNewChat(true)}>Nova conversa</NewChat>
                ) : (
                  <ChooseUser>
                    <Stack
                      marginLeft={2}
                      marginRight={2}
                    >
                      <GoBack onClick={() => handleGoBack()}>
                        <IoIosArrowBack
                          size={25}
                          color={COLORS.PRIMARY}
                        />
                        <span>voltar</span>
                      </GoBack>
                      <Box>
                        <Text mb="3" fontWeight="bold" size="2xs">Unidade:</Text>
                        <Select
                          placeholder="Selecione a unidade"
                          size="md" bg="white"
                          onChange={(e: any) => setSingleUnity(e.target.value)}
                          value={singleUnity}
                        >
                          {
                            showUnities.map((elem, index) => (
                              <option key={index} value={elem.idUnidade}>{elem.nomeUnidade}</option>
                            ))
                          }
                        </Select>
                      </Box>
                      <Box>
                        <Text mb="3" fontWeight="bold" size="2xs">Condômino:</Text>
                        <Select
                          placeholder={!singleUnity ? "Selecione uma unidade" : "Selecione um codômino"}
                          disabled={!singleUnity ? true : false}
                          size="md" bg="white"
                          onChange={(e: any) => setSingleCondominoId(e.target.value)}
                          value={singleCondominoId}
                        >
                          {
                            condominos?.map((elem, index) => (
                              <option key={index} value={elem.id}>{elem.nome}</option>
                            ))
                          }
                        </Select>
                      </Box>
                    </Stack>

                    <Button
                      disabled={!singleCondominoId ? true : false}
                      onClick={() => startChat()}
                      bgColor={COLORS.PRIMARY}
                      color="#fff"
                      _hover={{
                        background: COLORS.PRIMARY
                      }}
                    >
                      Começar conversa
                    </Button>
                  </ChooseUser>
                )
              )
            }

            {
              !isEmployee && (!funcionarios || funcionarios.length === 0) &&
              <EmptyMessage>
                <span>Sem informação de funcionários</span>
              </EmptyMessage>
            }

            {
              (isEmployee && (!unidades || unidades.length === 0) && !newChat) &&
              <EmptyMessage>
                <span>Não há conversas recentes</span>
              </EmptyMessage>
            }

            {
              !isEmployee ?
                funcionarios.map((funcionario) => (
                  <ContactButton
                    key={funcionario.idUsuario}
                    onClick={() => handleSelectedFuncionario(funcionario)}
                  >
                    <ContactCircle>{firstLetter(funcionario.nome)}</ContactCircle>
                    <span>{funcionario.nome}</span>
                  </ContactButton>
                ))
                :
                <>
                  {
                    !newChat && (
                      unidades.map((unidade) => (
                        <ContactButton
                          key={unidade.idRecebeu}
                          onClick={() => handleSelectedUnidade(unidade, 1)}
                        >
                          <ContactCircle>{firstLetter(unidade.nomeChat)}</ContactCircle>
                          <span>{unidade.nomeChat}</span>
                        </ContactButton>
                      ))
                    )
                  }
                </>
            }
          </LeftContent>
        )
      }

      <>
        {
          (size.sizeWidth > 520 && isHistoryOpen) &&
          <ChatView
            id={!isEmployee ? funcionarioSelected?.idUsuario : unidadeSelected?.idRecebeu}
            key={!isEmployee ? funcionarioSelected?.idUsuario : unidadeSelected?.idRecebeu}
            name={!isEmployee ? funcionarioSelected?.nome : unidadeSelected?.nomeChat}
            isHistoryOpen={isHistoryOpen}
            openChat={openChat}
          />
        }
        {
          (size.sizeWidth <= 520 && !isHistoryOpen) &&
          <ChatView
            id={!isEmployee ? funcionarioSelected?.idUsuario : unidadeSelected?.idRecebeu}
            key={!isEmployee ? funcionarioSelected?.idUsuario : unidadeSelected?.idRecebeu}
            name={!isEmployee ? funcionarioSelected?.nome : unidadeSelected?.nomeChat}
            isHistoryOpen={isHistoryOpen}
            openChat={openChat}
          />
        }
      </>
    </ChatWindow >
  );
};

export default ChatFuncionario;