import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { IListDefault } from '../../interfaces/responses/IListDefault';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { goToExternalLink } from '../../utils/functions';
import { useWindowSize } from '../Login';
import { Container, EmptyMessage, EmptyMessageText, TableFlex1, TableFlex2 } from './styles';

const ListDefault = () => {
  const dispatch = useDispatch();
  const params = useParams() as any;
  const size = useWindowSize();
  const erro = "Sem informações"
  const [listDefault, setListDefault] = React.useState<Array<IListDefault>>([]);
  const [toggleTable, setToggleTable] = React.useState(false);

  const getListDefault = React.useCallback((apiLink) => {
    request.get(apiLink).then((response) => {
      if (response.data.ok === 'Sucesso') {
        setListDefault(response.data.listagem);
      }
    });
  }, []);

  React.useEffect(() => {
    if (size.sizeWidth <= 1024)
      setToggleTable(true);
    else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  React.useEffect(() => {
    getListDefault(params.apiRoute);
    switch (params.apiRoute) {
      case ('portalTransparencia'): dispatch(ActionsHeader.changeTextCenter('Portal Transparência')); break;
      case ('listarPlanilhas'): dispatch(ActionsHeader.changeTextCenter('Planilha Orçamentária')); break;
      case ('listarAtas'): dispatch(ActionsHeader.changeTextCenter('Atas')); break;
      case ('listarRegimentosInternos'): dispatch(ActionsHeader.changeTextCenter('Regimentos Interno')); break;
      case ('listarConvencoes'): dispatch(ActionsHeader.changeTextCenter('Convençoes')); break;
      case ('listarEditais'): dispatch(ActionsHeader.changeTextCenter('Editais')); break;
      default: dispatch(ActionsHeader.changeTextCenter('Erro'));
    }
  }, [dispatch, params.apiRoute, getListDefault]);

  return (
    <Container>
      {!toggleTable ?
        (<TableFlex1>
          <thead>
            <tr>
              <th>Condomínio</th>
              <th>Descrição</th>
              <th>Data documento</th>
              <th>Documento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {(listDefault?.length === 0) && (
                <EmptyMessage colSpan={4}>
                  <p>{erro}</p>
                </EmptyMessage>
              )}
            </tr>
            {
              listDefault?.map((i) => (
                <tr key={i.id}>
                  <td>{i.nomeCondominio}</td>
                  <td>
                    <p style={{
                      width: '35ch',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      margin: 0,
                    }}
                    >
                      {i.descricao}
                    </p>
                  </td>
                  <td>{i.dataDocumento}</td>
                  <td>
                    <Button theme="primary" marginVertical={5} onClick={() => goToExternalLink(i.linkDownload)}>
                      Download
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </TableFlex1>)
        :
        <>
          {(listDefault?.length === 0) && (
            <EmptyMessageText>{erro}</EmptyMessageText>
          )}
          {listDefault?.map((i) => (
            <TableFlex2 key={i.id}>
              <thead>
                <tr>
                  <th>Condomínio</th>
                  <td>{i.nomeCondominio}</td>
                </tr>
                <tr>
                  <th>Descrição</th>
                  <td>{i.descricao}</td>
                </tr>
                <tr>
                  <th>Data documento</th>
                  <td>{i.dataDocumento}</td>
                </tr>
                <tr>
                  <th>Documento</th>
                  <td>
                    <Button theme="primary" marginVertical={5} onClick={() => goToExternalLink(i.linkDownload)}>
                      Download
                    </Button>
                  </td>
                </tr>
              </thead>
            </TableFlex2>
          ))
          }
        </>
      }
    </Container>
  );
};
export default ListDefault;
