import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';

// details_surveis
const DetailsSurveis = () => {
  const dispatch = useDispatch();

  const { id } = useParams<any>();

  const [, setItens] = React.useState([]);
  const [, setVotes] = React.useState([]);
  const [, setComments] = React.useState([]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Detalhes da enquetes'));

    request.get(`enquetes/resultados?id=${id}`).then((response) => {
      setVotes(response.data.listagem);
    });
    request.get(`enquetesOpcoes?idEnquete=${id}`).then((response) => {
      setItens(response.data.listagem.resultados);
    });
    request.get(`comentariosEnquetes?idEnquete=${id}`).then((response) => {
      setComments(response.data.listagem.resultados);
    });
  }, [dispatch, id]);

  return (
    <div>
      <h3>{}</h3>
    </div>
  );
};

export default DetailsSurveis;
