import axios from 'axios';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import ToggleButtons from '../../components/ToggleButtons/ToggleButtons';
import { IBankSlip } from '../../interfaces/responses/IBankSlip';
import RootState from '../../interfaces/states/RootState';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { ActionsToggleButtons } from '../../store/ducks/toggleButtons';
import TableBankSlip from './components/TableBankSlip';

const ListBankSlip = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state:RootState) => state.toggleButtons.listBankSlip);
  const [bankSlips1, setBankSlips1] = React.useState<Array<IBankSlip>>([]);
  const [bankSlips2, setBankSlips2] = React.useState<Array<IBankSlip>>([]);
  const [bankSlips3, setBankSlips3] = React.useState<Array<IBankSlip>>([]);
  const [errorRequest, setErrorRequest] = React.useState({
    response1: '',
    response2: '',
    response3: '',
  });
  const [loading, setLoading] = React.useState(false);

  const getBankSlips = React.useCallback(() => {
    setLoading(true);

    const request1 = request.get('listarBoletosV2/1');
    const request2 = request.get('listarBoletosV2/2');
    const request3 = request.get('listarBoletosV2/3');

    axios.all([request1, request2, request3]).then(axios.spread((...responses) => {
      const response1 = responses[0].data;
      const response2 = responses[1].data;
      const response3 = responses[2].data;

      if (response1.erro) {
        setErrorRequest((oldErrorRequest) => ({
          ...oldErrorRequest,
          response1: response1.erro,
        }));
      }
      if (response2.erro) {
        setErrorRequest((oldErrorRequest) => ({
          ...oldErrorRequest,
          response2: response2.erro,
        }));
      }
      if (response3.erro) {
        setErrorRequest((oldErrorRequest) => ({
          ...oldErrorRequest,
          response3: response3.erro,
        }));
      }

      if (response1.listagem)setBankSlips1(response1.listagem);
      if (response2.listagem)setBankSlips2(response2.listagem);
      if (response3.listagem)setBankSlips3(response3.listagem);
    })).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Boletos'));
    getBankSlips();
  }, [dispatch, getBankSlips]);

  const buttons = [
    {
      label: 'A vencer',
      onClick: () => dispatch(ActionsToggleButtons.setSelectedListsBankSlip(0)),
    },
    {
      label: 'Vencidos',
      onClick: () => dispatch(ActionsToggleButtons.setSelectedListsBankSlip(1)),
    },
    {
      label: 'Pagos',
      onClick: () => dispatch(ActionsToggleButtons.setSelectedListsBankSlip(2)),
    },
  ];

  return (
    <div>
      {loading && (
        <Loading />
      )}

      <ToggleButtons selected={selected} buttons={buttons} />
      { selected === 0 && <TableBankSlip error={errorRequest.response1} bankSlips={bankSlips1} />}

      { selected === 1 && <TableBankSlip error={errorRequest.response2} bankSlips={bankSlips2} />}

      { selected === 2 && <TableBankSlip error={errorRequest.response3} bankSlips={bankSlips3} />}

    </div>
  );
};
export default ListBankSlip;
