import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  min-width: 90px;
  /* margin-top: ${(props: any) =>
    props['data-margintop'] ? `${props['data-margintop']}px` : '8px'}; */

  input {
    border: 1px solid #666;
    border-color: ${(props: any) => (props['data-readonly'] ? '#999' : '#666')};
    border-radius: 5px;
    background-color: #fff;
    color: ${(props: any) =>
      props['data-color'] ? props['data-color'] : COLORS.SECONDARY};
    height: 35px;
    width: 220px;
    padding: 0 8px;
    font-size: 14px;
    font-weight: bold;

    &[type='file'] {
      color: #fff;
    }

    &:disabled {
      background: #ccc;
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  input::placeholder {
    color: #aaa;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  ${(attr: any) =>
    attr['data-margintop'] && `margin-top: ${attr['data-margintop']}px;`};
`;

export const Label = styled.span`
  color: ${COLORS.TEXT_PRIMARY};
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;

  display: inline-block;
  justify-content: space-between;
  align-items: center;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  @media screen and (max-width: 475px) {
    margin-left: 5px;
  }
`;

export const Input = styled.input`
  border: 1px solid #666;
  border-radius: 5px;
  background-color: #fff;
  color: ${COLORS.SECONDARY};
  height: 35px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;

  &::placeholder {
    color: #aaa;
  }
`;

const animationResults = keyframes`
 0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
`;
export const Results = styled.div`
  position: absolute;
  box-shadow: 0px 3px 3px 1px rgba(153, 153, 153, 1);
  border-radius: 0px 0px 6px 6px;
  width: 100%;
  max-height: 300px;
  background: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  -webkit-animation: ${animationResults} 0.4s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: ${animationResults} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  button:nth-child(even) {
    background-color: #dddd;
  }

  button {
    border: 0;
    background: transparent;
    width: 100%;
    text-align: left;

    color: ${COLORS.TEXT_PRIMARY};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    padding: 12px;
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  position: absolute;
  right: 12px;
  top: 4.5px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spinAnimation} 2s linear infinite;
`;
