import React, { useEffect } from "react";
import {
    Button, Flex, Stack, Table, Text,
    Tbody, Td, Th, Thead, Tr,
} from "@chakra-ui/react";
import { useWindowSize } from "../../../../Login";
import { IListaUnica } from "../../../../../interfaces/responses/IListaUnica";

interface Props {
    leituraUnica: IListaUnica[];
    handleIdUnidade: (value: number) => string | undefined;
    handleExclude: (id: number) => void;
}

const IndividualLeiturasTable: React.FC<Props> = ({
    leituraUnica,
    handleIdUnidade,
    handleExclude
}) => {

    const screen = useWindowSize();
    const [toggleTable, setToggleTable] = React.useState(false);

    useEffect(() => {
        if (screen.sizeWidth <= 980)
            setToggleTable(true);
        else {
            setToggleTable(false);
        }
    }, [screen.sizeWidth]);

    return (
        <>
            {!toggleTable ? (
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Data e hora</Th>
                            <Th>Valor da leitura</Th>
                            <Th>Unidade</Th>
                            <Th>Ações</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {leituraUnica?.map((elem) => (
                            elem.ativo == 1 && (
                                <Tr key={elem.id}>
                                    <Td>{elem.data}</Td>
                                    <Td>{elem.valorLeitura}</Td>
                                    <Td>{handleIdUnidade(elem.idUnidade as number)}</Td>
                                    <Td>
                                        <Button
                                            colorScheme="red"
                                            onClick={() => handleExclude(elem.id)}
                                        >
                                            Excluir
                                        </Button>
                                    </Td>
                                </Tr>)
                        ))}
                    </Tbody>
                </Table>
            ) : (
                leituraUnica?.map((item) => (
                    <Stack
                        marginBottom={5}
                        borderBottom="1px solid #444"
                        _last={{ border: 'none', marginBottom: 0 }}
                        paddingBottom={5}
                    >
                        <Flex alignItems='center'>
                            <Text fontSize='sm' fontWeight='extrabold'>
                                Data e hora:
                            </Text>
                            <Text fontSize='sm' marginLeft={2}>
                                {item.data}
                            </Text>
                        </Flex>

                        <Flex alignItems='center'>
                            <Text fontSize='sm' fontWeight='extrabold'>
                                Valor da leitura:
                            </Text>
                            <Text fontSize='sm' marginLeft={2}>
                                {item.valorLeitura}
                            </Text>
                        </Flex>

                        <Flex alignItems='center'>
                            <Text fontSize='sm' fontWeight='extrabold'>
                                Unidade:
                            </Text>
                            <Text fontSize='sm' marginLeft={2}>
                                {handleIdUnidade(item.idUnidade as number)}
                            </Text>
                        </Flex>

                        <Flex alignItems='center'>
                            <Text fontSize='sm' fontWeight='extrabold'>
                                Ações:
                            </Text>
                            <Text fontSize='sm' marginLeft={2}>
                                <Button
                                    colorScheme="red"
                                    size='sm'
                                    onClick={() => handleExclude(item.id)}
                                >
                                    Excluir
                                </Button>
                            </Text>
                        </Flex>

                    </Stack>
                ))
            )}
        </>
    )
}

export default IndividualLeiturasTable;