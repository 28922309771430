import * as React from 'react';

import Paginate from '../../components/Paginate/Paginate';
import { dateStringToDateFormat } from '../../utils/dates';
import { goToExternalLink } from '../../utils/functions';
import { useWindowSize } from '../Login';
import { TableFlex1, TableFlex2 } from './styles';

interface InadimplenciasBoletosPropos {
  boletos: Array<{
    id: any;
    nomeUnidade: any;
    nomeSacado: any;
    vencimentoOriginal: any;
    valorDevido: any;
    instrucao1: any;
    linkDownload: any;
    // situacao : string;
    valorPago: string;
    ajuizado_processos: boolean;
    status_cobranca: 'LIBERADO' | 'BLOQUEADO';
    situacao: 'ABERTO' | 'CANCELADO' | 'NEGOCIADO' | 'PAGO' | 'VENCIDO';
  }>;
  totalPages: number;
  currentPage: number;
  getBoletos: (n: number) => void;
}

const InadimplenciasBoletos = ({
  boletos,
  totalPages,
  currentPage,
  getBoletos,
}: InadimplenciasBoletosPropos) => {

  const size = useWindowSize();
  const [toggleTable, setToggleTable] = React.useState(false);

  React.useEffect(() => {
    if (size.sizeWidth <= 1200) {
      setToggleTable(true);
    } else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  return (
    <div>

      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
              <th>Unidade</th>
              <th>Condômino</th>
              <th>Vencimento</th>
              <th>Valor</th>
              <th>Status</th>
              <th>Valor pago</th>
              <th>Descrição</th>
              <th>Boleto</th>
            </tr>
          </thead>
          <tbody>
            {boletos.map((b) => (
              <>
                {
                  b.status_cobranca === 'LIBERADO' && (
                    <tr key={b.id}>
                      <td className="table-unidade">{`${b.nomeUnidade}`}</td>
                      <td>{`${b.nomeSacado}`}</td>
                      <td>{`${dateStringToDateFormat(b.vencimentoOriginal)}`}</td>
                      <td>{`${b.valorDevido}`}</td>
                      <td>{`${b.situacao}`}</td>
                      <td>{`${b.valorPago}`}</td>
                      <td>{`${b.instrucao1}`}</td>
                      <td>
                        {
                          b.situacao === 'ABERTO' && (
                            <span
                              style={{
                                color: '#47c',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                              }}
                              onClickCapture={() => goToExternalLink(b.linkDownload)}
                            >
                              Visualizar
                            </span>
                          )
                        }

                        {
                          (b.situacao === 'VENCIDO' && !b.ajuizado_processos) && (
                            <span
                              style={{
                                color: '#47c',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                              }}
                              onClickCapture={() => goToExternalLink(b.linkDownload)}
                            >
                              Visualizar
                            </span>
                          )
                        }

                      </td>
                    </tr>
                  )
                }
              </>
            ))}
          </tbody>
        </TableFlex1>
        :
        boletos?.map((b) => (
          <TableFlex2 key={b.id}>
            <thead>
              <tr>
                <th>Unidade</th>
                <td className="table-unidade">{`${b.nomeUnidade}`}</td>
              </tr>
              <tr>
                <th>Condômino</th>
                <td>{`${b.nomeSacado}`}</td>
              </tr>
              <tr>
                <th>Vencimento</th>
                <td>{`${dateStringToDateFormat(b.vencimentoOriginal)}`}</td>
              </tr>
              <tr>
                <th>Valor</th>
                <td>{`${b.valorDevido}`}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{`${b.situacao}`}</td>
              </tr>
              <tr>
                <th>Valor pago</th>
                <td>{`${b.valorPago}`}</td>
              </tr>
              <tr>
                <th>Descrição</th>
                <td>{`${b.instrucao1}`}</td>
              </tr>
              <tr>
                <th>Boleto</th>
                <td>
                  {
                    b.situacao === 'ABERTO' && (
                      <span
                        style={{
                          color: '#47c',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                        }}
                        onClickCapture={() => goToExternalLink(b.linkDownload)}
                      >
                        Visualizar
                      </span>
                    )
                  }

                  {
                    (b.situacao === 'VENCIDO' && !b.ajuizado_processos) && (
                      <span
                        style={{
                          color: '#47c',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                        }}
                        onClickCapture={() => goToExternalLink(b.linkDownload)}
                      >
                        Visualizar
                      </span>
                    )
                  }

                </td>
              </tr>
            </thead>
          </TableFlex2>
        ))
      }

      {totalPages > 1 && (
        <Paginate
          current={currentPage}
          totalPages={totalPages}
          onClickButton={(n) => getBoletos(n)}
        />
      )}
    </div>
  )
};

export default InadimplenciasBoletos;
