import CalendarReserves from './Reserves/CalendarReserves';
import CAMCC from './CAMCC/CAMCC';
import ChatAssembly from './Assemblies/Chat/Chat';
import CMOC from './CMOC/CMOC';
import ResultsAssembly from './Assemblies/Results';
import CreateAssembly from './Assemblies/Create/CreateAssembly';
import CreateExpense from './Expenses/Create/CreateExpense';
import DetailsAssembly from './Assemblies/DetailsAssembly/DetailsAssembly';
import DetailsBankSlip from './BankSlip/DetailsBankSlip';
import DetailsSurvey from './Surveis/DetailsSurvey';
import Inadimplencias from './Inadimplencias/Inadimplencias';
import InadimplenciasBoletos from './Inadimplencias/InadimplenciasBoletos';
import ListAssemblies from './Assemblies/List/ListAssemblies';
import ListBankSlip from './BankSlip/ListBankSlip';
import ListBudgets from './Budget/ListBudgets';
import ListDefault from './ListDefault/ListDefault';
import ListExpenses from './Expenses/List/Expenses';
import ListOcurrencies from './Occurrencies/ListOcurrencies';
import ListSurveis from './Surveis/ListSurveis';
import Login from './Login';
import HomeCondomino from './Home/HomeCondomino';
import HomeManager from './Home/HomeManager';
import CondominiumDocuments from './CondominiumDocuments';
import MyUnit from './MyUnit/MyUnit';
import NotFound from './NotFound/NotFound';
import OwnerPortal from './OwnerPortal/OwnerPortal';
import Revenues from './Revenues/Revenues';
import RegisterUpdate from './RegisterUpdate';
import YearAccountability from './Accountability/YearAccountability';
import ListVisits from './Visits/ListVisits';
import EditVisit from './Visits/EditVisit';
import ScheduleVisit from './Visits/ScheduleVisit';
import ChatFuncionario from './ChatFuncionario/ChatFuncionario';
import ResetPassword from './ResetPassword';
import RequestPassword from './RequestPassword';
import SelectUnity from './SelectUnity';
import AutoAcordo from './AutoAcordo';
import PortalFunctionario from './PortalFunctionario';
import ListResidents from './ListResidents/ListResidents';
import UnityOcorrences from './ManagerOcorrence/UnityOcorrences';
import VirtualProtocol from './VirtualProtocol/VirtualProtocol';
import VisitasAgendadas from './PortalFunctionario/pages/VisitasAgendadas';
import VisitasCadastradas from './PortalFunctionario/pages/VisitasCadastradas';
import CadastroVisita from './PortalFunctionario/pages/CadastroVisita';
import ListarVisitas from './PortalFunctionario/pages/ListarVisitas';
import ConsumosFuncionario from './PortalFunctionario/pages/Consumos';
import GravarLeituras from './PortalFunctionario/pages/GravarLeituras';
import ListarConsumos from './PortalFunctionario/pages/ListarConsumos';
import ReservasCadastradas from './PortalFunctionario/pages/Reservas';
import ListEnquetes from './ListEnquetes/ListEnquetes';
import CreateEnquete from './ListEnquetes/Create/CreateEnquete';
import EditEnquete from './ListEnquetes/Edit/EditEnquete';
import VoteEnquete from './ListEnquetes/Vote/VoteEnquete';

export default {
  PortalFunctionario,
  VisitasAgendadas,
  VisitasCadastradas,
  CadastroVisita,
  ListarVisitas,
  ConsumosFuncionario,
  GravarLeituras,
  ListarConsumos,
  ReservasCadastradas,
  SelectUnity,
  AutoAcordo,
  CalendarReserves,
  CAMCC,
  ChatAssembly,
  CMOC,
  CreateAssembly,
  CreateExpense,
  ResultsAssembly,
  DetailsAssembly,
  DetailsBankSlip,
  DetailsSurvey,
  Inadimplencias,
  InadimplenciasBoletos,
  ListAssemblies,
  ListBankSlip,
  ListBudgets,
  ListDefault,
  ListExpenses,
  ListSurveis,
  ListOcurrencies,
  Login,
  HomeCondomino,
  HomeManager,
  MyUnit,
  NotFound,
  OwnerPortal,
  Revenues,
  RegisterUpdate,
  YearAccountability,
  ListVisits,
  EditVisit,
  ScheduleVisit,
  ChatFuncionario,
  ResetPassword,
  RequestPassword,
  CondominiumDocuments,
  ListResidents,
  UnityOcorrences,
  VirtualProtocol,
  ListEnquetes,
  CreateEnquete,
  EditEnquete,
  VoteEnquete
};
