import styled from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS } from '../../utils/constants';

export const Container = styled.div<{modalVisible?: boolean; modalOcorrenceVisible?: boolean }>`
  padding: 0 15px;
  position: relative;
  height: ${props => (props.modalVisible || props.modalOcorrenceVisible) && '20vh'};
  overflow-y: ${props => (props.modalVisible || props.modalOcorrenceVisible) && 'hidden'};
`;

export const YearComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const FormModal = styled.form`

  h6 {
    font-size: 16px;
    font-weight: bold;
    color: #555;
    margin: 18px 0;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  textarea {
    width: 100%;
    font-size: 16px;
    color: #555;
    padding: 10px;
    border: 1px solid #555;
    resize: none;
    border-radius: 8px;
  }
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;
  
  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;
    }

    tr:last-child {
      border: none;
    }

    td {
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const DivAttatch = styled.div`
  display: flex;
  background-color: #aaa;
  margin: 8px 5px;
  padding: 5px;
  text-align: center;
   span {
     flex:1
   }

   svg {
    cursor: pointer;
   }
`;

export const TextWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;

export const AttachsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  background-color: ${COLORS.PRIMARY};
  border-radius: 6px;
`;

export const TitleHeader = styled.h1`
  font-size: 22px;
  color: ${COLORS.PRIMARY};
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 15px;
`;

export const Load = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
`;