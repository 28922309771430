import styled from 'styled-components';
import { COLORS } from '../../utils/constants';
import { Table } from '../../components/Table/styles';

export const Container = styled.div`
  margin-top: 34px;
  width: 100%;
  padding: 0 25px;
`;

export const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
  display: block;
`;

export const AgreementConfigForm = styled.div`
  margin-top: 21px;
  display: block;

  display: flex;
  flex-direction: column;

  div {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
    margin: 16px 0 0 0;
    padding: 20px;
    flex-direction: column;

    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 12px;
    }

    span {
      font-size: 16px;
    }
  }

  label {
    font-weight: bold;
    font-size: 18px;
    margin: 12px 0 21px;
    display: block;
  }

  section {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      > div {
        flex-direction: column;
        box-shadow: none;

        > div {
          width: 100%;
        }

        table {
          box-shadow: none;
        }
        button {
          padding: 16px;
          border-radius: 6px;
          border: 0;
          margin: -16px -16px 0;
          box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
          background: ${COLORS.PRIMARY};

          font-weight: bold;
          color: #fff;
          font-size: 16px;

          @media screen and (max-width: 1024px) {
            padding: 10px;
            font-size: 16px;
          }
        }
      }
      span {
        margin-bottom: 16px;
      }
    }

    select {
      display: block;
      padding: 16px;
      margin: 12px 0 12px;
      min-width: fit-content;
      font-weight: bold;
      font-size: 16px;
      box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 6px;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  input {
    border: 0;
    box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 1);
    padding: 20px 12px;
    border-radius: 6px;

    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin-top: 16px;
  }

  button {
    padding: 10px 20px;
    border-radius: 6px;
    background: ${COLORS.PRIMARY};
    border: 0;
    box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);

    font-weight: bold;
    color: #fff;
    font-size: 18px;
    margin-top: 16px;
  }
`;

export const Load = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const TableFlex1 = styled(Table)`
  margin: 10px 0;
  min-width: fit-content;

  th, td {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      text-align: center;
    }
    th {
      font-size: 14px;
      text-align: center;
    }
  }
`;

export const TableFlex2 = styled(Table)`
  margin: 15px 0;

  tr {
    border-bottom: 1px solid rgba(77, 77, 77, 0.5);
    height: 40px;
    padding: 20px 0;
  }

  tr:last-child {
    border: none;
  }

  th {
    padding-left: 10px;

    @media screen and (max-width: 520px) {
      padding-right: 15px;
    }
  }

  thead {
    color: unset;
    font-weight: unset;
    font-size: 14px;

    th {
      padding-left: 10px;

      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
  }
`;

export const DiscountAlert = styled.span`
  font-size: 12px;
  color: #f00;
  /* margin-bottom: -20px; */
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column !important;
  }

  .half {
    min-width: 45%;
    @media screen and (max-width: 970px) {
      justify-content: center;
      margin: auto;
      width: 100%;
    }
  }
`;
