import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { MotionFlex } from '../../utils';

import * as S from './styles';

interface WelcomeAnimationProps {
  currentLoginOption: {
    title: string;
    route: string;
    icon: string;
    iconWhite: string;
}
}

const WelcomeAnimation = ({ currentLoginOption }: WelcomeAnimationProps) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    fontWeight="bold"
    color="white"
  >
    <MotionFlex
      animate={{
        opacity: (currentLoginOption.route === '/login' || currentLoginOption.route === '/login/entrar') ? 1 : 0,
      }}
      flexDirection="column"
    >
      <S.TextWelcome>
        BEM VINDO!
      </S.TextWelcome>
      <Text fontSize="15px" fontWeight="bold" fontFamily="Lato" textAlign="center">
        Selecione o tipo de usuário abaixo e faça login.
      </Text>
    </MotionFlex>
  </Flex>
);

export default WelcomeAnimation;
