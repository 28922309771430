import React, { useState } from 'react';
import {
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import copy from "copy-to-clipboard";  
import { toast } from 'react-toastify';
import { IoIosArrowDown } from 'react-icons/io';

import ModalPix from './ModalPix';
import request from '../../../services/request';
import Loading from '../../../components/Loading/Loading';
import { goToExternalLink } from '../../../utils/functions';
import { IBankSlip } from '../../../interfaces/responses/IBankSlip';

import { TextEmpty, LinkDetails, Container, TableFlex1, TableFlex2 } from './styles';

const TableBankSlip = ({ bankSlips, error }: { error: string, bankSlips: Array<IBankSlip> }) => {

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState({
    qrcodepix: '' , qrcodepix_base64: null
  });

  const handleOpenModal = ({qrcodepix , qrcodepix_base64}: any) => {  
    if (qrcodepix) {
      setModalOpen(true);
      setDataModal({qrcodepix , qrcodepix_base64})
    } else {
      toast.warning('Aviso, não encontramos conteúdo para gerar o qrCode.')
    }
  }

  const copyToClipboard = (value: string) => {
    if (value) {
      copy(value);
      toast.success('Sucesso, código de barras copiado para a área de transferência.');
    } else {
      toast.warning('Aviso, não encontramos conteúdo para copiar.');
    }
  }
  
  const copyToClipboardPix = (value: string) => {
    if (value) {
      copy(value);
      toast.success(
        'Pronto, código do Pix copiado para a área de transferência.', 
        { style: {zIndex: 20} }
      );
    } else {
      toast.warning(
        'Aviso, não encontramos conteúdo para copiar.', 
        { style: {zIndex: 20} }
      );
    }
  }
  
  const sendEmail = (emails: string, linkDownload: string) => {
    setLoading(true);
  
    const form = { emails, linkDownload };
  
    request.post('enviarBoletoEmail', form)
    .then((response) => {
      if (!response.data.ok) {
        toast.warning(
          'Atenção, não foi possível enviar o boleto no momento, tente novamente mais tarde!',
        );
      } else {
        toast.success('Pronto, o boleto já foi enviado para seu email.');
      }
    })
    .catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede, verifique sua conexão e tente novamente.');
      } else {
        toast.warning('Atenção, a requisição falhou');
      }
    })
    .finally(() => setLoading(false));
  };
  
  const getEmailCondomino = (linkDownload: string) => {
    setLoading(true);
    
    request.get('boletoEmailCondomino').then((response) => {
      if (response.data.ok === 'Sucesso') {
        const { emailCondomino } = response.data.listagem;
        sendEmail(emailCondomino, linkDownload);
      } else {
        toast.warning('Atenção, não foi possível obter o email do condômino');
      }
    }).catch((error: any) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede, verifique sua conexão e tente novamente.');
      } else {
        toast.warning('Atenção, a requisição falhou');
      }
    }).finally(() => setLoading(false));
  };

  return (
    <Container>
      {loading && <Loading /> }

      <ModalPix 
        data={dataModal}
        modalVisible={modalOpen}
        setModalVisible={setModalOpen}
        onClick={() => copyToClipboardPix(dataModal.qrcodepix)}
      />

      <TableFlex1>
        <thead>
          <tr>
            <th>Número do documento</th>
            <th>Vencimento original</th>
            <th>Vencimento atual</th>
            <th>Valor</th>
            <th>Descrição</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {bankSlips.map((b) => (
            <tr key={b.id}>
              <td>{b.numero_documento}</td>
              <td>{b.data_vencimento_original}</td>
              <td>{b.data_vencimento}</td>
              <td>{b.valor}</td>
              <td>{b.descricao}</td>
              <td>
                {((b.situacao === 'ABERTO' && b.status_cobranca === 'LIBERADO')) && (
                  <Menu>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: 'none' }}
                      data-testid="up-navbar-menu"
                      bgColor={"#4a8"}
                      borderRadius='md'
                      px={10}
                    >
                      <HStack textColor="#fff" fontSize={13}>
                        <Text>Opções</Text>
                        <IoIosArrowDown color="#fff" size={18} />
                      </HStack>
                    </MenuButton>
                    <MenuList bg={'white'}>
                      <MenuItem onClick={() => goToExternalLink(b.linkDownload)}>Baixar Boleto</MenuItem>
                      <MenuItem onClick={() => handleOpenModal({qrcodepix: b.qrcodepix, qrcodepix_base64: b.qrcodepix_base64})}>Pagar com o Pix</MenuItem>                     
                      <MenuItem onClick={() => copyToClipboard(b.linha_digitavel)}>Copiar Código de Barras</MenuItem>
                      <MenuItem onClick={() => getEmailCondomino(b.linkDownload)}>Enviar Boleto por E-mail</MenuItem>
                    </MenuList>
                  </Menu>
                )}

                {(b.situacao === 'VENCIDO' && !b.ajuizado_processos) && (
                  <Menu>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: 'none' }}
                      data-testid="up-navbar-menu"
                      bgColor={"#4a8"}
                      borderRadius='md'
                      px={10}
                    >
                      <HStack textColor="#fff" fontSize={13}>
                        <Text>Opções</Text>
                        <IoIosArrowDown color="#fff" size={18} />
                      </HStack>
                    </MenuButton>
                    <MenuList bg={'white'}>
                      <MenuItem onClick={() => goToExternalLink(b.linkDownload)}>Baixar Boleto</MenuItem>
                      <MenuItem onClick={() => handleOpenModal({qrcodepix: b.qrcodepix, qrcodepix_base64: b.qrcodepix_base64})}>Pagar com o Pix</MenuItem>
                      <MenuItem onClick={() => copyToClipboard(b.linha_digitavel)}>Copiar Código de Barras</MenuItem>
                      <MenuItem onClick={() => getEmailCondomino(b.linkDownload)}>Enviar Boleto por E-mail</MenuItem>
                    </MenuList>
                  </Menu>
                )}

                {b.situacao === 'PAGO' && (
                  <LinkDetails href={b.linkDownload} target="_blank" rel="noopener noreferrer">
                    Detalhes do boleto
                  </LinkDetails>
                )}
              </td>
            </tr>
          ))}

          {bankSlips.length === 0 && (
            <tr>
              <td colSpan={5}>
                <TextEmpty>{error}</TextEmpty>
              </td>
            </tr>
          )}
        </tbody>
      </TableFlex1>

      {/* ============================== RESPONSIVO ===========================*/}

      {bankSlips.map((b) => (
        <TableFlex2 key={b.id}>
          <tr>
            <th>Número do documento</th>
            <td>{b.numero_documento}</td>
          </tr>
          <tr>
            <th>Vencimento original</th>
            <td>{b.data_vencimento_original}</td>
          </tr>
          <tr>
            <th>Vencimento atual</th>
            <td>{b.data_vencimento}</td>
          </tr>
          <tr>
            <th>Valor</th>
            <td>{b.valor}</td>
          </tr>
          <tr>
            <th>Descrição</th>
            <td>{b.descricao}</td>
          </tr>
          <tr>
            <th>Opções</th>
            <td>
              {((b.situacao === 'ABERTO' && b.status_cobranca === 'LIBERADO')) && (
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}
                    data-testid="up-navbar-menu"
                    bgColor={"#4a8"}
                    borderRadius='md'
                    px={10}
                  >
                    <HStack textColor="#fff" fontSize={13}>
                      <Text>Opções</Text>
                      <IoIosArrowDown color="#fff" size={18} />
                    </HStack>
                  </MenuButton>
                  <MenuList bg={'white'}>
                    <MenuItem onClick={() => goToExternalLink(b.linkDownload)}>Baixar Boleto</MenuItem>
                    <MenuItem onClick={() => handleOpenModal({qrcodepix: b.qrcodepix, qrcodepix_base64: b.qrcodepix_base64})}>Pagar com o Pix</MenuItem>                    
                    <MenuItem onClick={() => copyToClipboard(b.linha_digitavel)}>Copiar Código de Barras</MenuItem>
                    <MenuItem onClick={() => getEmailCondomino(b.linkDownload)}>Enviar Boleto por E-mail</MenuItem>
                  </MenuList>
                </Menu>
              )}

              {(b.situacao === 'VENCIDO' && !b.ajuizado_processos) && (
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}
                    data-testid="up-navbar-menu"
                    bgColor={"#4a8"}
                    borderRadius='md'
                    px={10}
                  >
                    <HStack textColor="#fff" fontSize={13}>
                      <Text>Opções</Text>
                      <IoIosArrowDown color="#fff" size={18} />
                    </HStack>
                  </MenuButton>
                  <MenuList bg={'white'}>
                    <MenuItem onClick={() => goToExternalLink(b.linkDownload)}>Baixar Boleto</MenuItem>
                    <MenuItem onClick={() => handleOpenModal({qrcodepix: b.qrcodepix, qrcodepix_base64: b.qrcodepix_base64})}>Pagar com o Pix</MenuItem>                    
                    <MenuItem onClick={() => copyToClipboard(b.linha_digitavel)}>Copiar Código de Barras</MenuItem>
                    <MenuItem onClick={() => getEmailCondomino(b.linkDownload)}>Enviar Boleto por E-mail</MenuItem>
                  </MenuList>
                </Menu>
              )}

              {b.situacao === 'PAGO' && (
                <LinkDetails href={b.linkDownload} target="_blank" rel="noopener noreferrer">
                  Detalhes do boleto
                </LinkDetails>
              )}
            </td>
          </tr>
        </TableFlex2>
      ))}
    </Container>
  )
};

export default TableBankSlip;