/* eslint-disable no-unused-expressions */
import React, { createContext, useContext, useState } from 'react';

interface ModalClubePartiuContextData {
    showModalClubePartiu: boolean;
    handleOpen: () => void
    handleClose: () => void
}

const ModalClubePartiuContext = createContext<ModalClubePartiuContextData>(
  {} as ModalClubePartiuContextData,
);

const ModalClubePartiuProvider: React.FC = ({ children }) => {
  const [showModalClubePartiu, setShowModalClubePartiu] = useState(false);

  function handleOpen() {
    setShowModalClubePartiu(true);
  }
  function handleClose() {
    setShowModalClubePartiu(false);
  }

  return (
    <ModalClubePartiuContext.Provider
      value={{
        showModalClubePartiu,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </ModalClubePartiuContext.Provider>
  );
};

function useModalClubePartiu(): ModalClubePartiuContextData {
  const context = useContext(ModalClubePartiuContext);

  return context;
}

export { ModalClubePartiuProvider, useModalClubePartiu };
