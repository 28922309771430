import {
  Box, Button, Flex, Input,
  Select, Stack, Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { BiImageAdd, BiTrash, BiPlusCircle } from 'react-icons/bi';
import { FaCamera, FaFolder } from 'react-icons/fa';
import Loading from 'react-loading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Camera from '../../../../components/Camera';
import { useCamera } from '../../../../hooks/modules/CameraContext';
import { IUnidades } from '../../../../interfaces/responses/IListUnity';
import request from '../../../../services/request';
import { ActionsHeader } from '../../../../store/ducks/header';
import { COLORS } from '../../../../utils/constants';
import { dateStringToDate, dateStringToTime } from '../../../../utils/dates';
import { cpfMask } from '../../../../utils/masks';
import { useWindowSize } from '../../../Login';
import { Load } from '../../styles';
import {
  ButtonConfirm, DropDown, DropdownFlex,
  DropDownText, ImageWrapper
} from './styles';

const tipoVisitante = [
  {
    key: 1,
    type: "Visitante"
  },
  {
    key: 2,
    type: "Prestador de serviço"
  },
  {
    key: 3,
    type: "Entregador"
  }
];

const CadastroVisita = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cameraOpened1, setCameraOpened1] = useState(false);
  const [cameraOpened2, setCameraOpened2] = useState(false);

  const {
    clearFunction,
    clearCanvas,
    getCameraFunction,
    photo,
    hasPhoto,
    videoOpen,
    photoRef,
    snapShotFunction,
    stopCameraFunction,
    videoRef,
    mediaStream
  } = useCamera();

  const [listUnidades, setListUnidades] = useState<Array<IUnidades>>([]);
  const [visitorsType, setVisitorsType] = useState(0);
  const [visitorsName, setVisitorsName] = useState('');
  const [visitorsCpf, setVisitorsCpf] = useState('');
  const [visitorsTime, setVisitorsTime] = useState('');
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [unityId, setUnityId] = useState('');

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Cadastrar Visita'));
  }, [dispatch]);

  useEffect(() => {
    if (file1) {
      setCameraOpened1(false);
    }
    if (file2) {
      setCameraOpened2(false);
    }
  }, [file1, file2])

  const handleCamera = (flag: 1 | 2) => {
    if (flag === 1) {
      if (cameraOpened2) {
        setCameraOpened2(false);
        if (photo) {
          clearFunction(1080, 720);
        }
      }
      setCameraOpened1(!cameraOpened1);
    } else {
      if (cameraOpened1) {
        setCameraOpened1(false);
        if (photo) {
          clearFunction(1080, 720);
        }
      }
      setCameraOpened2(!cameraOpened2);
    }
  }

  // Creating an image by canvas to compress file if file's size is greater than a certain number
  const addAttach = (item: any, index: number, size: number) => {
    const imgElement = document.createElement("img");
    imgElement.src = item;

    imgElement.onload = (event: any) => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = 400;
      const scaleSize = MAX_WIDTH / event.target.width;

      canvas.width = MAX_WIDTH;
      canvas.height = event.target.height * scaleSize;

      const ctx = canvas.getContext("2d");

      ctx?.drawImage(event.target, 0, 0, canvas.width, canvas.height);

      const l = 7 * 1024 * 1024;
      const md = 3 * 1024 * 1024;
      const sm = 1 * 1024 * 1024;

      const compressFactor = size >= l ? 0.7 : size >= md ? 0.8 : 0.87;
      const srcEncoded = ctx?.canvas.toDataURL("image/jpeg", compressFactor);

      if (size >= sm) {
        if (index === 1) {
          setFile1(srcEncoded || "");
        } else {
          setFile2(srcEncoded || "");
        }
      } else {
        if (index === 1) {
          setFile1(item);

        } else {
          setFile2(item);
        }
      }
    }
  };

  const convertFileToBase64 = (file: Blob, index: number, fileSize: number) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => addAttach(reader.result, index, fileSize);
  };

  const handleAddFile = (file: any, index: number) => {
    if (!file) {
      return;
    }
    const mimeType = file.type;
    const fileSize = file.size;

    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (!allowedFileTypes.includes(mimeType)) {
      toast.warn('Arquivo não suportado. Arquivos devem ser do tipo jpeg, jpg ou png.');
      return;
    } else if (fileSize > 15 * 1024 * 1024) {
      toast.warn('Tamanho de arquivo não suportado (Max.: 15Mb)');
      if (index === 1) {
        setFile1("");
      } else {
        setFile2("");
      }
      return;
    } else {
      convertFileToBase64(file, index, fileSize);
    }
  };

  const removeAttachTemp = (index: number) => {
    if (index === 1) {
      setFile1("");
    } else {
      setFile2("");
    }
  }

  const getUnidades = useCallback(async () => {
    setLoading(true);

    request.get('buscarUnidadesCondominio')
      .then((response) => {
        if (response.data.ok === "Sucesso") {
          setListUnidades(response.data.Unidades);
        } else {
          toast.error(response.data.erro);
        }
      })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro: A requisição falhou');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getUnidades();
  }, [getUnidades]);

  const handleSubmit = () => {

    const formCheck = [
      visitorsName,
      visitorsType,
      visitorsCpf,
      visitorsTime,
      unityId,
      file1,
      file2
    ]

    if (formCheck.includes("") || visitorsType === 0) {
      setError(true);
      toast.error("Atenção: Campos obrigatórios não preenchidos");
    } else {
      setError(false);
      submitData();
    }
  }

  const submitData = () => {

    setLoading(true);

    const form = {
      nomeVisitante: visitorsName,
      tipoVisitante: visitorsType,
      cpfVisitante: visitorsCpf,
      horario: visitorsTime,
      unidade: unityId,
      fotoVisitante: file1,
      fotoDocumento: file2
    }

    request.post('cadastrarVisita', form)
      .then((response) => {
        if (response.data.ok === "Sucesso") {
          toast.success("Visita cadastrada com sucesso");
          history.push('/visitas_cadastradas')
        } else {
          toast.error(response.data.erro);
        }
      })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro: A requisição falhou');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {

    const formCheck = [
      visitorsName,
      visitorsType,
      visitorsCpf,
      visitorsTime,
      unityId,
      file1,
      file2
    ]

    if (!formCheck.includes("") && visitorsType !== 0) {
      setError(false);
    }

  }, [
    visitorsName,
    visitorsType,
    visitorsCpf,
    visitorsTime,
    unityId,
    file1,
    file2
  ]);

  return (
    <Flex
      h="100%"
      bg="gray.50"
      p="4"
      direction="column"
    >
      <Stack
        direction={{
          base: 'column', sm: 'column', md: 'column', lg: 'row',
        }}
        spacing="15px"
        alignItems={screen.sizeWidth >= 992 ? "flex-end" : ""}
        w="100%"
      >
        <Box
          w={screen.sizeWidth < 992 ? "50" : "100%"}
        >
          <Text
            mb="3" size="2xs"
            fontWeight="bold"
          >
            Nome do visitante:
          </Text>
          <Input
            placeholder="Nome do visitante"
            size="md" bg="white" flex="1"
            borderColor={(error && !visitorsName) ? "red" : "gray.200"}
            value={visitorsName}
            onChange={(e: any) => setVisitorsName(e.target.value)}
          />
        </Box>
        <Box
          w={screen.sizeWidth < 992 ? "50" : "100%"}
        >
          <Text mb="3" fontWeight="bold" size="2xs">CPF:</Text>
          <Input
            placeholder="CPF"
            size="md" bg="white"
            borderColor={(error && !visitorsCpf) ? "red" : "gray.200"}
            value={visitorsCpf}
            onChange={(e: any) => setVisitorsCpf(cpfMask(e.target.value))}
          />
        </Box>
        <Box
          w={screen.sizeWidth < 992 ? "50" : "100%"}
        >
          <Text mb="3" fontWeight="bold" size="2xs">Horário:</Text>
          <Input
            size="md" bg="white"
            borderColor={(error && !visitorsTime) ? "red" : "gray.200"}
            type="datetime-local"
            min={`${dateStringToDate(new Date().toISOString())}T${dateStringToTime(new Date().toISOString())}`}
            value={visitorsTime}
            onChange={(e: any) => setVisitorsTime(e.target.value)}
          />
        </Box>
      </Stack>

      <DropdownFlex>
        <Flex w="100%" flexDir="column" alignItems="center" mt={5}>
          <Text fontWeight="bold" size="2xs" alignSelf="flex-start">Foto do visitante:</Text>

          <Flex
            alignSelf="flex-start"
            marginTop={5}
            cursor="pointer"
            onClick={() => handleCamera(1)}
          >
            {
              cameraOpened1 ? (
                <>
                  <FaFolder size={20} color={COLORS.PRIMARY} />
                  <Text size="2x" marginLeft={2}>
                    Selecionar arquivo local
                  </Text>
                </>
              )
                : (
                  <>
                    <FaCamera size={20} color={COLORS.PRIMARY} />
                    <Text size="2x" marginLeft={2}>
                      Tirar foto com a câmera
                    </Text>
                  </>
                )
            }
          </Flex>

          <DropDown style={screen.sizeWidth <= 520 ? { height: (screen.sizeWidth - 50) / (4 / 5), width: screen.sizeWidth - 30, borderColor: (error && !file1) ? "red" : COLORS.PRIMARY } : { borderColor: (error && !file1) ? "red" : COLORS.PRIMARY }}>
            {
              (hasPhoto && cameraOpened1 && !file1) && (
                <ButtonConfirm
                  onClick={() => { setFile1(photo); clearCanvas() }}
                  style={hasPhoto ? { zIndex: 9999999 } : { zIndex: 1 }}
                >
                  Confirmar
                </ButtonConfirm>
              )
            }
            {
              cameraOpened1 ? (
                <Camera
                  clearFunction={clearFunction}
                  getCameraFunction={getCameraFunction}
                  clearCanvas={clearCanvas}
                  mediaStream={mediaStream}
                  photo={photo}
                  photoRef={photoRef}
                  snapShotFunction={snapShotFunction}
                  stopCameraFunction={stopCameraFunction}
                  videoRef={videoRef}
                  hasPhoto={hasPhoto}
                  videoOpen={videoOpen}
                  width={screen.sizeWidth <= 520 ? screen.sizeWidth - 60 : 1080}
                  ratio={screen.sizeWidth <= 520 ? (4 / 5) : (16 / 9)}
                />
              ) :
                (
                  !file1 ?
                    <>
                      <BiImageAdd size={screen.sizeWidth <= 520 ? 50 : 70} />
                      <DropDownText><strong>Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui</DropDownText>
                      <input
                        type="file"
                        accept="image/*"
                        name="upload"
                        onChange={(e: any) => handleAddFile(e.target.files[0], 1)}
                      />
                    </>
                    :
                    <ImageWrapper>
                      <img style={{ height: "100%" }} src={file1} />
                      <BiTrash
                        size={25}
                        color={COLORS.PRIMARY}
                        onClickCapture={() => removeAttachTemp(1)}
                        style={{ cursor: "pointer", position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,.7)" }}
                      />
                    </ImageWrapper>
                )
            }
          </DropDown>
        </Flex>
        
        <Flex w="100%" flexDir="column" alignItems="center" mt={5}>

          <Text fontWeight="bold" size="2xs" alignSelf="flex-start">Foto do documento do visitante:</Text>

          <Flex
            alignSelf="flex-start"
            marginTop={5}
            cursor="pointer"
            onClick={() => handleCamera(2)}
          >
            {
              cameraOpened2 ? (
                <>
                  <FaFolder size={20} color={COLORS.PRIMARY} />
                  <Text size="2x" marginLeft={2}>
                    Selecionar arquivo local
                  </Text>
                </>
              )
                : (
                  <>
                    <FaCamera size={20} color={COLORS.PRIMARY} />
                    <Text size="2x" marginLeft={2}>
                      Tirar foto com a câmera
                    </Text>
                  </>
                )
            }
          </Flex>

          <DropDown style={screen.sizeWidth <= 520 ? { height: (screen.sizeWidth - 50) / (4 / 5), width: screen.sizeWidth - 30, borderColor: (error && !file2) ? "red" : COLORS.PRIMARY } : { borderColor: (error && !file1) ? "red" : COLORS.PRIMARY }}>
            {
              (hasPhoto && cameraOpened2 && !file2) && (
                <ButtonConfirm
                  onClick={() => { setFile2(photo); clearCanvas() }}
                  style={hasPhoto ? { zIndex: 9999999 } : { zIndex: 1 }}
                >
                  Confirmar
                </ButtonConfirm>
              )
            }
            {
              cameraOpened2 ? (
                <Camera
                  clearFunction={clearFunction}
                  getCameraFunction={getCameraFunction}
                  photo={photo}
                  clearCanvas={clearCanvas}
                  mediaStream={mediaStream}
                  photoRef={photoRef}
                  snapShotFunction={snapShotFunction}
                  stopCameraFunction={stopCameraFunction}
                  videoRef={videoRef}
                  hasPhoto={hasPhoto}
                  videoOpen={videoOpen}
                  width={screen.sizeWidth <= 520 ? screen.sizeWidth - 60 : 1080}
                  ratio={screen.sizeWidth <= 520 ? (4 / 5) : (16 / 9)}
                />
              ) : (
                !file2 ?
                  <>
                    <BiImageAdd size={screen.sizeWidth <= 520 ? 50 : 70} />
                    <DropDownText>
                      <strong>
                        Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui
                    </DropDownText>
                    <input
                      type="file"
                      accept="image/*"
                      name="upload"
                      onChange={(e: any) => handleAddFile(e.target.files[0], 2)}
                    />
                  </>
                  :
                  <ImageWrapper>
                    <img style={{ height: "100%" }} src={file2} />
                    <BiTrash
                      size={25}
                      color={COLORS.PRIMARY}
                      onClickCapture={() => removeAttachTemp(2)}
                      style={{ cursor: "pointer", position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,.7)" }}
                    />
                  </ImageWrapper>
              )
            }
          </DropDown>
        </Flex>
      </DropdownFlex>

      <Stack
        direction={{
          base: 'column', sm: 'column', md: 'column', lg: 'row',
        }}
        spacing="24px"
        marginTop="4"
      >

        <Box
          w={screen.sizeWidth < 992 ? "50" : "100%"}
        >
          <Text mb="3" fontWeight="bold" size="2xs">Unidade:</Text>
          <Select
            placeholder="Selecione a unidade"
            size="md" bg="white"
            w={['full', 'full', 'full', '80']}
            borderColor={(error && !unityId) ? "red" : "gray.200"}
            value={unityId}
            onChange={(e: any) => setUnityId(e.target.value)}
          >
            {
              listUnidades.map((elem) => (
                <option
                  key={elem.idUnidade}
                  value={elem.idUnidade}
                >
                  {elem.nomeUnidade}
                </option>
              ))
            }
          </Select>
        </Box>
        <Box
          w={screen.sizeWidth < 992 ? "50" : "100%"}
        >
          <Text mb="3" fontWeight="bold" size="2xs">Tipo de visitante:</Text>
          <Select
            placeholder="Selecione o tipo"
            size="md" bg="white"
            borderColor={(error && visitorsType === 0) ? "red" : "gray.200"}
            w={['full', 'full', 'full', '80']}
            value={visitorsType}
            onChange={(e: any) => setVisitorsType(e.target.value)}
          >
            {
              tipoVisitante.map((elem) => (
                <option
                  key={elem.key}
                  value={elem.key}
                >
                  {elem.type}
                </option>
              ))
            }
          </Select>
        </Box>
      </Stack>

      <Flex
        marginTop={screen.sizeWidth > 520 ? 10 : 5}
      >
        <Button
          colorScheme="blue"
          w={screen.sizeWidth > 520 ? "30%" : "50%"}
          onClick={() => handleSubmit()}
        >
          Cadastrar
        </Button>
        <Button
          colorScheme="red"
          variant="outline"
          marginLeft={5}
          w={screen.sizeWidth > 520 ? "30%" : "50%"}
          onClick={() => history.push('/portal_funcionario')}
        >
          Cancelar
        </Button>
      </Flex>

      {
        loading && (
          <Load>
            <Loading type='spin' width={50} color={COLORS.SECONDARY} />
          </Load>
        )
      }
    </Flex>
  );

};

export default CadastroVisita;