import React from 'react';
import * as S from './styles';

interface Props {
  onClickButton: (n:number)=>void
  totalPages: number
  current?: number
}
const Paginate = ({ onClickButton, totalPages, current }:Props) => {
  const listNumber = generateArrayNumber(totalPages);

  function generateArrayNumber(limit = 1) {
    if (limit > 15)limit = 15;
    if (limit < 1) limit = 1;
    const temp = [];
    for (let i = 0; i < limit; i += 1) {
      temp.push(i);
    }
    return temp;
  }

  return (
    <S.Container>
      {
        listNumber.map((n) => (
          <S.Button
            onClick={() => onClickButton(n)}
            key={n}
            selected={current === n}
            type="button"
          >
            {n + 1}
          </S.Button>
        ))
      }
    </S.Container>
  );
};

export default Paginate;
