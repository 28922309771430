import React from "react";
import { BiDownload } from "react-icons/bi";
import { dateStringToDateTimeFormat } from "../../utils/dates";
import { goToExternalLink } from "../../utils/functions";
import Header from "./components/Header/Header";
import { ButtonDownloadFiles, Content, FlexCol, FlexRow, Wrapper, Title } from "./styles";
import { IProtocol, ISpecifcProtocol } from "./VirtualProtocol";

type Props = {
    cardId: number;
    protocol: IProtocol[];
    specifcProtocol: ISpecifcProtocol[];
    handleClose: () => void;
}

/* ++++++++++++++++++++++++++++++ COMPONENT ++++++++++++++++++++++++++++++ */

const SelectedProtocol: React.FC<Props> = ({
    cardId, protocol, specifcProtocol, handleClose
}) => {

/* ======================= RENDERS ========================= */

    return (
        <Content>

            <Header handleClose={handleClose} textCenter="Protocolo" />

            <Wrapper>

                <FlexRow>
                    <span><Title>Descrição: </Title>{protocol[cardId].descricao}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Data de envio: </Title>{dateStringToDateTimeFormat(protocol[cardId].dataEnvio)}</span>
                </FlexRow>

                <FlexRow>
                    <span><Title>Status: </Title>
                        {
                            protocol[cardId].status === "NAO_VISUALIZADO" ?
                                "Não visto pela administradora"
                                :
                                "Visto pela administradora"
                        }
                    </span>
                </FlexRow>

                <FlexCol>
                    <Title>Documentos:</Title>
                    {
                        specifcProtocol.length === 0 ?
                            <div style={{ backgroundColor: "rgba(0,0,0,.2)", padding: "5px 0px", borderRadius: 5, marginBottom: 15 }}>
                                <p style={{ textAlign: "center", color: "#000", fontSize: 14 }}>
                                    Protocolo não possui anexos
                                </p>
                            </div>
                            :
                            specifcProtocol.map((elem, index) => (
                                <ButtonDownloadFiles key={index} onClick={() => goToExternalLink(elem.link)}>
                                    <BiDownload size={25} color='white' />
                                    <span>{elem.nomeArquivo}</span>
                                </ButtonDownloadFiles>
                            ))
                    }
                </FlexCol>
            </Wrapper>

        </Content >
    );
}

export default SelectedProtocol;