/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IVisits } from '../../interfaces/responses/IVisits';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { Divisor } from '../../components/Table/styles';
import { dateStringToDateTimeFormat, dateStringToTimestamp } from '../../utils/dates';
import Button from '../../components/Button/Button';
import { cpfMask } from '../../utils/masks';
import {
  FormAgendamentoVisita, FormOption, Container, TableFlex1, EmptyMessage, TableFlex2, Load
} from './styles';
import { useWindowSize } from '../Login';
import Loading from 'react-loading';
import { toast } from 'react-toastify';
import { COLORS } from '../../utils/constants';

/* =========================== CONSTANTS / STATES =========================== */

const ListVisits = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const erro = 'Nenhuma visita agendada';
  const [loading, setLoading] = React.useState(false);
  const [toggleTable, setToggleTable] = React.useState(false);
  const [visits, setVisits] = React.useState<Array<IVisits>>([]);
  const [dataAgendamento, setDataAgendamento] = React.useState('');
  const [nomeVisitante, setNomeVisitante] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [tipoVisita, setTipoVisita] = React.useState('');

  /* =========================== EFFECTS =========================== */

  React.useEffect(() => {
    setLoading(true);
    request.get('agendamentoVisitas').then((response) => {
      setVisits(response.data);
    }).finally(() => {
      setLoading(false);
    });

    dispatch(ActionsHeader.changeTextCenter('Agendamento de Visitas'));
  }, [dispatch]);

  React.useEffect(() => {
    if (size.sizeWidth <= 768)
      setToggleTable(true);
    else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  /* =========================== FUNCTIONS =========================== */

  const handleTipoVisitante = (tipoVisitante: string) => {
    if (tipoVisitante === "1") {
      return "Visitante/Familiar";
    } else if (tipoVisitante === "2") {
      return "Prestador de serviço";
    } else {
      return "Entregador";
    }
  }
  /* =========================== REQUESTS =========================== */

  function buscarVisita(e: any) {
    e.preventDefault();
    setVisits([]);
    setLoading(true);

    const form = {
      horario: dataAgendamento,
      nomeVisitante,
      cpf,
      tipoVisitante: tipoVisita,
    };

    request.post('agendamentoVisitas', form).then((response) => {
      setVisits(response.data);
    })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro : A requisição falhou');
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container>

      <Link to="/schedule_visit">
        <Button theme="primary" style={{ marginTop: '10px' }}>Agendar Visita</Button>
      </Link>

      <FormAgendamentoVisita
        name="formAgendamentoVisita"
        onSubmit={(e) => buscarVisita(e)}
      >
        <FormOption>
          <label>Data agendamento:</label>
          <input
            type="date"
            name="dataAgendamento"
            onChange={(e) => setDataAgendamento(e.target.value)}
          />
        </FormOption>

        <FormOption>
          <label>Nome do visitante:</label>
          <input
            type="text"
            name="nomeVisitante"
            placeholder="Nome do visitante"
            onChange={(e) => setNomeVisitante(e.target.value)}
          />
        </FormOption>

        <FormOption>
          <label>CPF:</label>
          <input
            type="text"
            name="cpf"
            placeholder="000.000.000-00"
            onChange={(e) => setCpf(cpfMask(e.target.value))}
          />
        </FormOption>

        <FormOption>
          <label>Tipo de visita</label>
          <select
            name="tipoVisitante"
            onChange={(e) => setTipoVisita(e.target.value)}
          >
            <option value="">Todas</option>
            <option value="1">Visitante/Familiar</option>
            <option value="2">Prestador de serviço</option>
            <option value="3">Entregador</option>
          </select>
        </FormOption>

        {
          size.sizeWidth < 1100 ?
            <Button theme="secondary" type="submit" maxwidth={false} style={{ width: "40%", margin: "10px auto", marginBottom: 0 }}>
              Buscar
            </Button>
            :
            <Button theme="secondary" type="submit" >
              Buscar
            </Button>
        }

      </FormAgendamentoVisita>

      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
              <th>Nome do visitante</th>
              <th>CPF</th>
              <th>Tipo de visitante</th>
              <th>Horário da visita</th>
              <th>Opções</th>
            </tr>
          </thead>
          <Divisor colSpan={4} />
          <tbody>
            {loading && (
              <Load>
                <Loading type='spin' width={50} color={COLORS.SECONDARY} />
              </Load>
            )}
            {(visits.length === 0 && !loading) && (
              <EmptyMessage colSpan={4}>
                <p>{erro}</p>
              </EmptyMessage>
            )}
            {visits.map((visit) => (
              <tr key={visit.id}>
                <td>{visit.nomeVisitante}</td>
                <td>{visit.cpf}</td>
                <td>
                  {handleTipoVisitante(visit.tipoVisitante)}
                </td>
                <td>{dateStringToDateTimeFormat(visit.horario)}</td>
                <td>
                  {
                    dateStringToTimestamp(visit.horario) > new Date().getTime()
                    && (
                      <Link to={`edit_visit/${visit.id}`}>
                        <Button theme="primary" type="button">
                          Editar
                        </Button>
                      </Link>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </TableFlex1>
        :
        <>
          {loading && (
            <Load>
              <Loading type='spin' width={50} color={COLORS.SECONDARY} />
            </Load>
          )}
          {(visits.length === 0 && !loading) && (
            <EmptyMessage colSpan={4}>
              <p>{erro}</p>
            </EmptyMessage>
          )}
          {visits.map((visit) => (
            <TableFlex2 key={visit.id}>

              <tr>
                <th>Nome do visitante</th>
                <td>{visit.nomeVisitante}</td>
              </tr>
              <tr>
                <th>CPF</th>
                <td style={{ maxWidth: size.sizeWidth * 50 / 100 }}>{visit.cpf}</td>
              </tr>
              <tr>
                <th>Tipo de visitante</th>
                <td style={{ maxWidth: size.sizeWidth * 50 / 100 }}>
                  {handleTipoVisitante(visit.tipoVisitante)}
                </td>
              </tr>
              <tr>
                <th>Horário da visita</th>
                <td>{dateStringToDateTimeFormat(visit.horario)}</td>
              </tr>
              <tr>
                <th>Opções</th>
                <td>
                  {
                    dateStringToTimestamp(visit.horario) > new Date().getTime()
                    && (
                      <Link to={`edit_visit/${visit.id}`}>
                        <Button theme="primary" type="button">
                          Editar
                        </Button>
                      </Link>
                    )
                  }
                </td>
              </tr>
            </TableFlex2>
          ))}
        </>
      }

    </Container>
  );
};

export default ListVisits;