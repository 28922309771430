import React from 'react';
import { IoIosClose } from 'react-icons/io';
import {
    CenterHeader, Container, LeftHeader, RightHeader, TextCenter,
} from './styles';
import { COLORS } from '../../../utils/constants';

type Props = {
    textCenter: string;
    handleClose: () => void;
}

const Header: React.FC<Props> = ({ textCenter, handleClose }) => {

    return (
        <Container>
            <LeftHeader>
                <IoIosClose size={50} onClick={handleClose} color={COLORS.SECONDARY} />
            </LeftHeader>
            <CenterHeader>
                <TextCenter>{textCenter}</TextCenter>
            </CenterHeader>
            <RightHeader />
        </Container>
    );
};

export default Header;
