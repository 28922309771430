import React, { useState, useEffect } from 'react';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Flex,
  Link,
  Text
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import SidebarContent from '../components/SidebarContent';
import { useBreadcrumbs } from '../../../hooks/modules/BreadcrumbsContext';
import MobileNav from '../../WrapperDashboard/components/MobileNav';
import { FlexIdeia } from '../../WrapperDashboard/styles';

const SidebarWithHeader: React.FC = ({ children }) => {
  const { hash } = useLocation();
  const { changeBreadcrumbsChatFuncionario } = useBreadcrumbs();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentHash, setCurrentHash] = useState('/portal_funcionario');

  useEffect(() => {

    if (currentHash) {
      switch (currentHash) {
        case 'portal_funcionario':
          changeBreadcrumbsChatFuncionario([
            { title: 'Home', to: 'Home', hash: 'portal_funcionario' },
          ]);
          break;
        case 'visitas_agendadas':
          changeBreadcrumbsChatFuncionario([
            { title: 'Visitas Agendadas', to: 'Visitas Agendadas', hash: 'visitas_agendadas' },
          ]);
          break;
        case 'visitas_cadastradas':
          changeBreadcrumbsChatFuncionario([
            { title: 'Cadastro de Visitas', to: 'Cadastro de Visitas', hash: 'visitas_cadastradas' },
          ]);
          break;
        case 'listar_visitas':
          changeBreadcrumbsChatFuncionario([
            { title: 'Listar Visitantes', to: 'Listar Visitantes', hash: 'listar_visitas' },
          ]);
          break;
        case 'cadastro_visita':
          changeBreadcrumbsChatFuncionario([
            { title: 'Cadastrar Visita', to: 'Cadastrar Visita', hash: 'cadastro_visita' },
          ]);
          break;
        case 'leituras':
          changeBreadcrumbsChatFuncionario([
            { title: 'Leituras', to: 'Leituras', hash: 'leituras_funcionario' },
          ]);
          break;
        case 'chat':
          changeBreadcrumbsChatFuncionario([
            { title: 'Chat', to: 'Chat', hash: 'chat' },
          ]);
          break;
        default:
          changeBreadcrumbsChatFuncionario([]);
          break;
      }
      setCurrentHash(hash);
    }
  }, [hash, changeBreadcrumbsChatFuncionario, currentHash]);

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue('gray.100', 'gray.900')}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <MobileNav onOpen={onOpen} />

      <Flex
        ml={{ base: 0, md: '288px' }}
        bg="gray.50"
        direction="column"
        justifyContent="space-between"
        minH="calc(100vh - 90px)"
      >
        {children}

        <FlexIdeia>
          <Box>
            <Text textAlign="center">© 2020 - Ideia Soluções em Sistemas Ltda.</Text>
          </Box>

          <Box>
            <Link as="a" href="https://ideiasti.com.br" cursor="pointer">
              https://ideiasti.com.br
            </Link>
          </Box>

          <Box>
            <Link as="a" href="contato@ideiasti.com.br" cursor="pointer">
              contato@ideiasti.com.br
            </Link>
          </Box>
        </FlexIdeia>
      </Flex>
    </Box>
  );
}

export default SidebarWithHeader;