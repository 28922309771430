import * as React from 'react';

import { dateStringToDateFormat } from '../../../utils/dates';
import { goToExternalLink } from '../../../utils/functions';
import { useWindowSize } from '../../Login';
import { TableFlex1, TableFlex2, Container } from '../styles';
import Button from '../../../components/Button/Button';
import { useSelector } from 'react-redux';
import RootState from '../../../interfaces/states/RootState';

interface ResultadosEnqueteProps {
  resultado: Array<{
    idOpcao: number;
    opcao: string;
    qtdVotos: number;
  }>;
}

const ResultadosEnquete = ({
  resultado
}: ResultadosEnqueteProps) => {

  const size = useWindowSize();
  const isManager = useSelector((state: RootState) => state.session.isManager);

  return (
    <div>

      {/* ======================= RESPONSIVE TABLE ===================== */}

        <Container>
        <div><h1 style={{textAlign: 'center'}}>Resultados da enquete</h1></div>
        <TableFlex1>
          <thead>
            <tr>
              <th>Descrição opção</th>
              <th>Qtde. Votos</th>
            </tr>
          </thead>
          <tbody>
            {resultado.map((r) => (
              <>
                <tr key={r.idOpcao}>
                    <td className="table-unidade">{`${r.opcao}`}</td>
                    <td>{`${r.qtdVotos}`}</td>
                </tr>
              </>
            ))}
          </tbody>
        </TableFlex1>
            {isManager && (
            <Button
                theme="primary"
                style={{ width: size.sizeWidth >= 768 ? '60%' : '90vw', alignSelf: "right", marginTop: "20px" }}
            >
                Enviar Resultados por E-mail
            </Button>
            )}
        </Container>
    </div>
  )
};

export default ResultadosEnquete;
