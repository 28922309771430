import styled from 'styled-components';
import { WIDTHS } from '../../utils/constants';

export const ContentBody = styled.div`

`;

export const ContentBodyGuest = styled.div`
  margin: 0 auto;
  max-width: ${WIDTHS.MAX}px;
`;
