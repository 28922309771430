import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
    width: 100%;
    padding: 0 15px;

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
  }
`;

export const TextUpdated = styled.div`
    text-align: center;
    background-color: ${COLORS.SECONDARY};
    color: #fff;
    font-size: 16px;
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0 10px 20px 10px;
    margin: 15px 0;
    border: 2px solid ${COLORS.PRIMARY};
    border-radius: 6px;
`;

export const EditGroup = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 5px 15px;
    align-items: center;

    @media screen and (max-width: 425px) {
        padding: 5px;
    }
`;

export const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;
`;

export const Title = styled.span`
    color: ${COLORS.PRIMARY};
    font-size: 18px;
    font-weight: 700;

    @media screen and (max-width: 425px) {
        font-size: 16px;
    }
`;

export const EditText = styled.span`
    color: ${COLORS.PRIMARY};
    font-size: 12px;
`;

export const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  width: 250px;
  background: ${COLORS.PRIMARY};
  margin: 10px auto;
  border-radius: 5px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
`;

export const GoBackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 40px;
  width: 250px;
  border: 2px solid ${COLORS.PRIMARY};
  margin: 5px auto;
  border-radius: 5px;
  font-size: 18px;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto;
`;

export const PhoneWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: flex-end;

    @media screen and (max-width: 520px) {
        flex-direction: column;
        align-items: unset;
        gap: 5px;
        height: fit-content;
        margin-bottom: 10px;
    }
`;

export const CheckWhats = styled.div`
    display: flex;
`;

export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: unset;
    }
`;

export const DropDown = styled.div`
    height: 280px;
    width: 420px;
    position: relative;
    border: 2px dashed ${COLORS.PRIMARY};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    padding: 2px;

    @media screen and (max-width: 768px) {
        width: 80%;
    }

    @media screen and (max-width: 520px) {
        width: 100%;
        height: 200px;
    }
`;

export const DropDownText = styled.p`
    font-size: 14px;
    text-align: center;
    padding: 0 5px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

export const DropdownFlex = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const Load = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    z-index: 99999;
`;

export const Instructions = styled.h6`
    text-align: center;
    font-weight: 800;

    @media screen and (max-width: 425px) {
        font-size: 14px;
    }
`;

export const Alert = styled.p`
    text-align: center;
    font-size: 14px;
    color: #7d7d7d;

    @media screen and (max-width: 425px) {
        font-size: 13px;
    }
`;