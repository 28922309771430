import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import {
  FaCashRegister,
  FaFileAlt,
} from 'react-icons/fa';
import {
  AiOutlineSearch, AiOutlineTable, AiOutlineBook, AiOutlineForm, AiFillGift, AiOutlineCheck
} from 'react-icons/ai';

import { IoIosEye, IoIosAttach } from 'react-icons/io';
import {
  MdAttachMoney, MdMoneyOff, MdPoll,
} from 'react-icons/md';

import { ActionsHeader } from '../../store/ducks/header';
import SelectCondominios from './components/SelectCondominios';

import ContentTabPanel from './components/ContentTabPanel';
import { useTabSelected } from '../../hooks/modules/TabSelectedContext';

import ModalClubePartiu from './components/ModalClubePartiu';
import { useModalClubePartiu } from '../../hooks/modules/ModalClubePartiuContext';
import { BoxMenu, Container } from './styles';
import MarketingPanel from './components/MarketingPanel';
import RootState from '../../interfaces/states/RootState';

const cardsFinancer = [
  {
    title: 'Receitas',
    icon: MdAttachMoney,
    route_path: 'revenues',
  },
  {
    title: 'Despesas',
    icon: MdMoneyOff,
    route_path: 'list_expenses',
  },
  {
    title: 'Portal da transparência',
    icon: IoIosEye,
    route_path: 'list_default/portalTransparencia',
  },
  {
    title: 'Prestação de contas',
    icon: AiOutlineSearch,
    route_path: 'year_accountability',
  },
  {
    title: 'Planilha Orçamentária',
    icon: AiOutlineTable,
    route_path: 'list_default/listarPlanilhas',
  },
  {
    title: 'Orçamento',
    icon: FaCashRegister,
    route_path: 'list_budgets',
  },
  {
    title: 'Inadimplências',
    icon: MdPoll,
    route_path: 'inadimplencias',
  },
  {
    title: 'Partiu Clube de Vantagens',
    icon: AiFillGift,
    action: 'OPEN_MODAL_CP',
  }];

const cardsADM = [
  {
    title: 'Protocolo Virtual',
    icon: IoIosAttach,
    route_path: 'virtual_protocol',
  },
  {
    title: 'Assembleia virtual',
    icon: AiOutlineBook,
    route_path: 'list_assemblies',
  },
  {
    title: 'Calendário Mensal de Obrigações Condominiais',
    initials: 'CMOC',
    route_path: 'cmoc',
  },
  {
    title: 'Calendário Anual de Manutenção e Conservação Condominial',
    initials: 'CAMCC',
    route_path: 'camcc',
  },
  {
    title: 'Documentos do Condomínio',
    icon: FaFileAlt,
    route_path: 'condominium_documents',
  },
  // {
  //   title: 'Atas',
  //   icon: FaBook,
  //   route_path: 'list_default/listarAtas',
  // },
  // {
  //   title: 'Regimento interno',
  //   icon: AiOutlineInfoCircle,
  //   route_path: 'list_default/listarRegimentosInternos',
  // },
  // {
  //   title: 'Convenções',
  //   icon: AiOutlineForm,
  //   route_path: 'list_default/listarConvencoes',
  // },
  {
    title: 'Reservas',
    icon: IoIosEye,
    route_path: 'calendar_reserves',
  },
  {
    title: 'Lista de moradores',
    icon: AiOutlineForm,
    route_path: 'list_residents',
  },
  {
    title: 'Ocorrências',
    icon: IoIosEye,
    route_path: 'unity_ocorrence',
  },
  {
    title: 'Enquetes',
    icon: AiOutlineCheck,
    route_path: 'list_enquetes',
  }
];

function HomeManager() {
  const dispatch = useDispatch();
  const { descVinculoAtual, todosVinculos } = useSelector((state : RootState) => state.session);

  const { defaultIndex, handleChangeDefaultIndex } = useTabSelected();
  const { showModalClubePartiu, handleClose } = useModalClubePartiu();

  const handleIdAdm = async (todosVinculos: any, descVinculoAtual: string) => {
    const { condominio } = todosVinculos.find((item: any) => item?.condominio?.descricao === descVinculoAtual)
    const element = document.getElementById('wbot-chat-container');
    
    if(condominio.idAdministradora === '34') {
      element!.style!.display = "none";
      return;
    }

    element!.style!.display = "";
    return;
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Portal do Síndico'));
  }, [dispatch]);

  React.useEffect(() => {
    setTimeout(() => {
      handleIdAdm(todosVinculos, descVinculoAtual)
    }, 1000);
  }, []);

  return (
    <Container>
      <BoxMenu>

        <SelectCondominios />

        <Tabs variant="solid-rounded" isFitted mt="12px" defaultIndex={defaultIndex} onChange={handleChangeDefaultIndex}>
          <TabList>
            <Tab
              h="43px"
              boxShadow="5px 5px 20px #0000000D"
              bg="#fff"
              _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
              borderRadius="14px 0px 0px 14px"
              fontSize="16px"
              fontFamily="Montserrat"
              fontWeight="bold"
            >
              Financeiro
            </Tab>
            <Tab
              boxShadow="5px 5px 20px #0000000D"
              bg="#fff"
              _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
              borderRadius="0px 14px 14px 0px"
              fontSize="16px"
              fontFamily="Montserrat"
              fontWeight="bold"
            >
              Administrativo
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              <ContentTabPanel list={cardsFinancer} />
            </TabPanel>
            <TabPanel p="0">
              <ContentTabPanel list={cardsADM} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </BoxMenu>

      <MarketingPanel />

      <ModalClubePartiu
        visible={showModalClubePartiu}
        handleClose={handleClose}
        vinculo="SINDICO"
      />

    </Container>
  );
}

export default HomeManager;
