import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    blue: {
      600: '#0DAA7E',
      500: '#0DAA7E',
    },
  },
});
