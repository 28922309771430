/* eslint-disable react/no-children-prop */
import {
  FaApple, FaGooglePlay, FaKey, FaTimes, FaUserAlt,
} from 'react-icons/fa';
import React, {
  FormEvent, SetStateAction, useEffect, useState,
} from 'react';
import {
  Button, Input, InputGroup, InputLeftElement, InputRightElement, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import * as S from './styles';
import { goToExternalLink } from '../../../../utils/functions';
import { endPoints } from '../../../../utils/constants';
import { LoginEndpoints } from '../../enum';

interface SetShowFormLoginProps {
  route: string,
  id: number | null,
  selected: boolean
}

const validRoutes = [
  LoginEndpoints.Condomino,
  LoginEndpoints.Sindico,
  LoginEndpoints.Produtos,
  LoginEndpoints.Condominio,
];
const BoxLogin = ({
  setShowFormLogin,
  currentLoginOption,
  onSubmit,
  username,
  password,
  setUsername,
  setPassword,
  showFormLogin,
} :
  {
  setShowFormLogin: (props: SetShowFormLoginProps) => void
  currentLoginOption: any
  onSubmit: (e: FormEvent) => void
  username: string
  password: string
  setUsername: (prop: SetStateAction<string>) => void
  setPassword: (prop: SetStateAction<string>) => void
  showFormLogin: {
    route: string;
    id: null | number;
    selected: boolean;
  }
}) => {
  const history = useHistory();

  const isValidRoute = validRoutes.includes(currentLoginOption.route);

  const submitForm = isValidRoute ? onSubmit : undefined;

  const isInvalidRoute = currentLoginOption.route
  && ![
    LoginEndpoints.Condomino,
    LoginEndpoints.Sindico,
    LoginEndpoints.Produtos,
    LoginEndpoints.Condominio,
  ].includes(currentLoginOption.route);

  const action = isInvalidRoute
    ? endPoints.BASE_URL_WEB + currentLoginOption.route
    : undefined;

  const [showPasswordType, setShowPasswordType] = useState(true);
  const [os, setOs] = useState('');

  useEffect(() => {
    const { userAgent } = navigator;
    if (userAgent.match(/Windows/i)) {
      setOs('Windows');
    } else if (userAgent.match(/Android/i)) {
      setOs('Android');
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setOs('iOS');
    } else if (userAgent.match(/Mac/i)) {
      setOs('MacOS');
    } else if (userAgent.match(/Linux/i)) {
      setOs('Linux');
    } else {
      setOs('Outro');
    }
  }, []);

  return (
    <S.BoxLogin>

      <S.CloseButton>
        <FaTimes
          size={25}
          onClick={() => {
            history.push(LoginEndpoints.Login);
            setShowFormLogin({ route: LoginEndpoints.Login, id: null, selected: false });
          }}
          cursor="pointer"
        />
      </S.CloseButton>

      {currentLoginOption.route
        === LoginEndpoints.Adimplencia ? (
          <form
            method="post"
            action={
            endPoints.BASE_URL_WEB + currentLoginOption.route
          }
            target="_blank"
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                name="codigo"
                placeholder="Digite o código"
                _placeholder={{ color: '#403A3A' }}
                type="password"
                mt="31px"
                mb="16px"
              />

              <Button
                bg="#009B6E"
                borderRadius="4px"
                fontSize="12px"
                h="33px"
                mr="6px"
                color="#fff"
                _hover={{ filter: 'brightness(0.9)' }}
                type="submit"
                mb="24px"
              >
                Buscar
              </Button>
            </div>
            <div>
              <p>
                - Este campo é exclusivo para
                {' '}
                <strong>VALIDAÇÃO</strong>
                {' '}
                de
                Cartas de Adimplência. Verifique aqui a autenticidade desse
                documento. - Atenção! Para a geração da sua Carta de
                Adimplência, entre com seu login e senha na aba
                {' '}
                <strong>Portal do Condômino.</strong>
              </p>
            </div>
          </form>
        ) : (
          <form
            method={
            currentLoginOption.route ? 'post' : undefined
          }
            onSubmit={submitForm}
            action={action}
            target={currentLoginOption.route === LoginEndpoints.Adimplencia ? '_blank' : undefined}
          >
            <Text
              fontSize="20px"
              color="#4d4d4d"
              fontWeight="bold"
              fontFamily="Montserrat"
            >
              BEM VINDO AO {currentLoginOption.title.toUpperCase()}
            </Text>

            <Text fontSize="13px" mt="11px" mb="33px" fontFamily="Lato">
              Digite seus dados a baixo.
            </Text>

            <InputGroup borderColor="#C5C5C5">
              <InputLeftElement
                pointerEvents="none"
                children={<FaUserAlt color="#009B6E" />}
              />
              <Input
                value={username}
                onChange={(value: { target: { value: React.SetStateAction<string>; }; }) => setUsername(value.target.value)}
                placeholder="Usuário"
                name="login"
                data-testid="login"
                fontFamily="Montserrat"
                fontWeight="bold"
                _placeholder={{ color: '#403A3A' }}
              />
            </InputGroup>

            <InputGroup mt="12px" borderColor="#C5C5C5">
              <InputLeftElement
                pointerEvents="none"
                children={<FaKey color="#009B6E" />}
              />
              <Input
                value={password}
                onChange={(value: { target: { value: SetStateAction<string>; }; }) => setPassword(value.target.value)}
                placeholder="Senha"
                name="senha"
                data-testid="senha"
                _placeholder={{ color: '#403A3A' }}
                type={showPasswordType ? 'password' : 'text'}
                fontFamily="Montserrat"
                fontWeight="bold"
              />
              <InputRightElement>
                {showPasswordType ? (
                  <IoMdEye
                    size="24"
                    color="#009B6E"
                    onClick={() => setShowPasswordType(!showPasswordType)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <IoMdEyeOff
                    size="24"
                    color="#009B6E"
                    onClick={() => setShowPasswordType(!showPasswordType)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </InputRightElement>
            </InputGroup>

            <S.FlexEntrar>
              <Button
                bg="#009B6E"
                borderRadius="4px"
                fontSize="12px"
                h="33px"
                mr="6px"
                flex="1.7"
                color="#fff"
                _hover={{ filter: 'brightness(0.9)' }}
                type="submit"
                fontFamily="Montserrat"
                fontWeight="bold"
                data-testid="enter-button"
              >
                Entrar
              </Button>

              {
              (showFormLogin?.id === 1 || showFormLogin?.id === 2) && (
                <>
                  <Button
                    borderRadius="4px"
                    fontSize="11px"
                    color="#3B308B"
                    bg="#D9D7E5"
                    h="33px"
                    mr="6px"
                    flex="1"
                    _hover={{ filter: 'brightness(0.9)' }}
                    onClick={() => history.push('/request_password')}
                  >
                    Primeiro acesso
                  </Button>
                  <Button
                    borderRadius="4px"
                    fontSize="11px"
                    color="#3B308B"
                    bg="#D9D7E5"
                    h="33px"
                    flex="1"
                    _hover={{ filter: 'brightness(0.9)' }}
                    onClick={() => history.push('/request_password')}
                  >
                    Recuperar senha
                  </Button>
                </>
              )
            }
            </S.FlexEntrar>

            <Text
              color="#616161"
              mt="25px"
              mb="15px"
              fontSize="13px"
              fontFamily="Lato"
            >
              Condômino, baixe o aplicativo Classecon!
            </Text>

            <S.FlexStores>
              {os !== 'MacOS' && os !== 'iOS' && (
              <Button
                bg="#0F143D"
                color="#fff"
                fontSize="10px"
                leftIcon={<FaGooglePlay size="18" />}
                _hover={{ filter: 'brightness(0.9)' }}
                onClick={() => goToExternalLink(
                  'https://play.google.com/store/apps/details?id=br.com.ideiatecnologia.classecon',
                )}
              >
                Google Play
              </Button>
              )}
              <Button
                bg="#0F143D"
                color="#fff"
                fontSize="10px"
                leftIcon={<FaApple size="21" />}
                _hover={{ filter: 'brightness(0.9)' }}
                onClick={() => goToExternalLink(
                  'https://apps.apple.com/us/app/classecon/id1190138751',
                )}
              >
                App Store
              </Button>
            </S.FlexStores>
          </form>
        )}
    </S.BoxLogin>
  );
};

export default BoxLogin;
