import React from 'react';

import { Container, Box } from './styles';

type LegendProps = {
    label : string;
    color : string;
}

const Legend: React.FC<LegendProps> = ({ label, color }) => (
  <Container>
    <Box color={color} />
    <p>{label}</p>
  </Container>
);

export default Legend;
