/* eslint array-callback-return:off */
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import Loading from '../../components/Loading/Loading';
import * as S from './styles';

const MyUnit = () => {
  const dispatch = useDispatch();
  const [units, setUnits] = React.useState([]);
  const [condominos, setCondominos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Minha unidade'));
    async function getAllData() {
      setLoading(true);
      const request1 = request.get('listarUnidade/1');
      const request2 = request.get('listarUnidade/2');
      axios.all([request1, request2]).then(axios.spread((...responses) => {
        const response1 = responses[0].data;
        const response2 = responses[1].data;
        if (response1.ok)setUnits(response1.listagem);
        if (response2.ok)setCondominos(response2.listagem);
      })).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro : A requisição falhou');
        }
      }).finally(() => {
        setLoading(false);
      });
    }
    getAllData();
  }, [dispatch]);

  return (
    <S.Container>
      <h3>Dados da unidade</h3>
      {
        units.map((u:any) => (
          <S.Card key={u.nomeUnidade}>
            <div>
              <strong>Administradora: </strong>
              <span data-testid="admin">{u.nomeAdministradora}</span>
            </div>
            <div>
              <strong>Condominio: </strong>
              <span>{u.nomeCondominio}</span>
            </div>
            <div>
              <strong>Unidade: </strong>
              <span>{u.nomeUnidade}</span>
            </div>
            <div>
              <strong>Boleto em nome do: </strong>
              <span>{u.boletoNoNomeDo}</span>
            </div>
            <div>
              <strong>Imobiliária: </strong>
              <span>{u.imobiliaria}</span>
            </div>
          </S.Card>
        ))
      }
      <h3>Dados do condômino</h3>
      {
        condominos.map((c:any) => (
          <S.Card key={c.codigo}>
            <div>
              <strong>Condômino: </strong>
              <span role="paragraph">{c.nomeCondomino}</span>
            </div>
            <div>
              <strong>Telefone: </strong>
              <span>{c.telefone}</span>
            </div>
            <div>
              <strong>Celular: </strong>
              <span>{c.celular}</span>
            </div>
            <div>
              <strong>Email: </strong>
              <span>{c.email}</span>
            </div>
            <div>
              <strong>CPF/CNPJ: </strong>
              <span>{c.documento}</span>
            </div>
            <div>
              <strong>Data Cadastro: </strong>
              <span>{c.dataCadastro}</span>
            </div>
            <div>
              <strong>Enviar Cobrança: </strong>
              <span>{c.enviaCobranca}</span>
            </div>
          </S.Card>
        ))
      }
      {loading && <Loading />}
    </S.Container>
  );
};

export default MyUnit;
