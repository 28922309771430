import styled from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS } from '../../utils/constants';

export const FiltersBudget = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 10px;

  select {
    margin-right: 10px;
  }

  @media screen and (max-width: 820px) {
    font-size: 12px;
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 15px;

    select {
      margin: 0;
    }
  }
`;

export const Attatch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aaa;
  padding: 5px 0;
  margin-top: 10px;
  text-align: center;

  svg {
    margin-left: 10px;
  }
`;

export const ContentModal = styled.div`
  max-width: 400px;

  h1 {
    margin-left: 30px;
    color : #333333;
  }
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;

      @media screen and (max-width: 820px) {
        font-size: 12px;
      }
    }

    tr:last-child {
      border: none;
    }

    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 10px;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 15px auto;
  align-self: center;
`;

export const TitleHeader = styled.h1`
  font-size: 22px;
  color: ${COLORS.PRIMARY};
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const FormModal = styled.form`
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  button {
    margin: 10px auto;
    width: 100%;
  }

  h6 {
    font-size: 16px;
    font-weight: bold;
    color: #555;
    margin: 18px 0;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }
`;