import { produce } from 'immer';
import IAction from '../../interfaces/IAction';
import IToggleButtons from '../../interfaces/states/IToggleButtons';

export const TypesToggleButtons = {
  SET_SELECTED_HOME_MANAGER: 'toggleButtons/SET_SELECTED_HOME_MANAGER',
  SET_SELECTED_HOME_CONDOMINO: 'toggleButtons/SET_SELECTED_HOME_CONDOMINO',
  SET_SELECTED_LIST_BANK_SLIP: 'toggleButtons/SET_SELECTED_LIST_BANK_SLIP',
  SET_SELECTED_LEITURA_ID: 'toggleButtons/SET_SELECTED_LEITURA_ID',
  SET_SELECTED_TAXA_ID: 'toggleButtons/SET_SELECTED_TAXA_ID',
};

// Reducer

const initialState = {
  homeCondomino: 0,
  homeManager: 0,
  listBankSlip: 0,
  leituraId: 0,
  taxaId: 0,
} as IToggleButtons;

export default function reducer(state = initialState, action:IAction) {
  switch (action.type) {
    case TypesToggleButtons.SET_SELECTED_HOME_MANAGER:
      return produce(state, (draft) => { draft.homeManager = action.payload.selected; }) as IToggleButtons;
    case TypesToggleButtons.SET_SELECTED_HOME_CONDOMINO:
      return produce(state, (draft) => { draft.homeCondomino = action.payload.selected; }) as IToggleButtons;
    case TypesToggleButtons.SET_SELECTED_LIST_BANK_SLIP:
      return produce(state, (draft) => { draft.listBankSlip = action.payload.selected; }) as IToggleButtons;
      case TypesToggleButtons.SET_SELECTED_LEITURA_ID:
      return produce(state, (draft) => { draft.leituraId = action.payload.selected; }) as IToggleButtons;
      case TypesToggleButtons.SET_SELECTED_TAXA_ID:
      return produce(state, (draft) => { draft.taxaId = action.payload.selected; }) as IToggleButtons;
    default:
      return state;
  }
}

// Action Creators

export const ActionsToggleButtons = {
  setSelectedHomeManager,
  setSelectedHomeCondomino,
  setSelectedListsBankSlip,
  setSelectedLeituraId,
  setSelectedTaxaId,
};

function setSelectedHomeManager(selected:number) {
  return {
    type: TypesToggleButtons.SET_SELECTED_HOME_MANAGER,
    payload: { selected },
  } as IAction;
}

function setSelectedHomeCondomino(selected:number) {
  return {
    type: TypesToggleButtons.SET_SELECTED_HOME_CONDOMINO,
    payload: { selected },
  } as IAction;
}

function setSelectedListsBankSlip(selected:number) {
  return {
    type: TypesToggleButtons.SET_SELECTED_LIST_BANK_SLIP,
    payload: { selected },
  } as IAction;
}

function setSelectedLeituraId(selected:number) {
  return {
    type: TypesToggleButtons.SET_SELECTED_LEITURA_ID,
    payload: { selected },
  } as IAction;
}

function setSelectedTaxaId(selected:number) {
  return {
    type: TypesToggleButtons.SET_SELECTED_TAXA_ID,
    payload: { selected },
  } as IAction;
}