import React from "react";
import { BiBulb } from 'react-icons/bi';
import { Container, Greetings, Info, List, ListOption } from "./styles";
import { COLORS } from "../../../../utils/constants";

type Props = {
    nomeFuncionario: string;
};

const HomeFuncionario: React.FC<Props> = ({ nomeFuncionario }) => {

    return (
        <Container>
            <Greetings>{`Bem-vindo(a) ${nomeFuncionario}`}</Greetings>
            <BiBulb size={200} color={COLORS.PRIMARY} />
            <Info>Navegando pelo menu lateral você pode realizar:</Info>
            <List>
                <ListOption>gerenciamento de cadastro de visitas</ListOption>
                <ListOption>gerenciamento de agendamento de visitas</ListOption>
                <ListOption>leitura de contas de energia e gás</ListOption>
                <ListOption>chat com condôminos, sindico e administradora</ListOption>
            </List>
        </Container>
    );
}

export default HomeFuncionario;