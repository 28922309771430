import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsHeader } from '../../store/ducks/header';
import Select from '../../components/Select/Select';
import { EmptyMessage, Container, TableFlex1, EmptyMessageText } from './styles';
import RootState from '../../interfaces/states/RootState';
import { IResponseListarEnquetes, IResponseEnquete } from '../../interfaces/responses/IEnquetes';
import request from '../../services/request';
import { useHistory, Link } from 'react-router-dom';
import { useWindowSize } from '../Login';
import Button from '../../components/Button/Button';
import { toast } from 'react-toastify';
import { AiOutlineEdit } from 'react-icons/ai';
import { dateStringToDateTimeFormat } from '../../utils/dates';
import ResultadosEnquete from './components/ResultadosEnquete';

function ListEnquetes() {  

  const dispatch = useDispatch();
  const history = useHistory();
  const size = useWindowSize();
  const isManager = useSelector((state: RootState) => state.session.isManager);
  const [listEnquetes, setListEnquetes] = React.useState<Array<IResponseEnquete>>();
  const [statusEnquete, setStatusEnquete] = React.useState<Array<any>>([]);
  const [statusEnqueteValue, setStatusEnqueteValue] = React.useState<string | number>();
  const [resultadoEnquete, setResultadoEnquete] = React.useState<Array<any>>([]);
  const [votosUsuario, setVotosUsuario] = React.useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [exibeVotos, setExibeVotos] = React.useState<number>();
  const erro = 'Nenhuma enquete encontrada';
  const situacaoView = ['Ativa', 'Cancelada', 'Suspensa', 'Encerrada' ]

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Nova Enquete'));

        // const status = ['Ativa', 'Cancelada', 'Suspensa', 'Encerrada'];

        const status = [
            {id:0,descricao:'Ativa'},
            {id:1,descricao:'Cancelada'},
            {id:2,descricao:'Suspensa'},
            {id:3,descricao:'Encerrada'},
        ]

        const statusEnqueteTemp = status.map((i: any) => ({
            value: i.id,
            label: `${i.descricao}`,
          }));

        setStatusEnquete(statusEnqueteTemp);

  }, [dispatch]);
  
  const listToArray = (responseEnquetes: IResponseListarEnquetes) => {
    if (responseEnquetes.sucesso == true) {
      const arrayEnquetes = Object.values(responseEnquetes.enquetes).map((r) => r);
      setListEnquetes(arrayEnquetes);
    }
  };
  
  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Enquetes'));
    request.get('/getEnquetes').then((response) => {
      listToArray(response.data);
    });
    request.get('/getVotosUsuario').then((response) => {
        const result = response.data;
        setVotosUsuario(result.votos);
    });
  }, [dispatch]);

  function handleChange(event: any) {
    console.log(event.target.value);
    if (!window.confirm("Deseja realmente alterar a situação da enquete?")) {
      return;
    } else {

      let form = event.target.parentNode.parentNode;
      let situacao = event.target.value;
      let enquete = form.id.replaceAll('alterar_situacao_', '');

      request.get('/alterarSituacaoEnquete/'+enquete+'/'+situacao).then((response) => {
        var result = response.data;
        if (result.sucesso) {
          toast.success('Muito bem, situação da enquete alterada com sucesso!');    
          if (situacao == 3) {
            exibirResultado(enquete);
          }
        } else if (!result.sucesso) {
          toast.error(result.mensagem);
        } else {
          toast.error('Ops, algo de errado aconteceu ao tentar salvar a enquete');
        }
      }).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro : A requisição falhou');
        }
      });
    }
  }

  /* const handleIdAdm = async (todosVinculos: any, descVinculoAtual: string) => {
    const { condominio } = todosVinculos.find((item: any) => item?.condominio?.descricao === descVinculoAtual)
    const element = document.getElementById('wbot-chat-container');
    
    if(condominio.idAdministradora === '34') {
      element!.style!.display = "none";
      return;
    }

    element!.style!.display = "";
    return;
  }; */

  const exibirResultado = async (idEnquete: number) => {

    // var divResultado = document.getElementById('resultado-'+idEnquete);

    setExibeVotos(idEnquete);

    request.get('/exibirVotos/'+idEnquete).then((response) => {
      var result = response.data;
      if (result.sucesso) {
        setResultadoEnquete(result.votos);
      } else if (!result.sucesso) {
        setResultadoEnquete([]);
        setErrorMessage(result.mensagem);
      } else {
        toast.error('Ops, algo de errado aconteceu ao tentar exibir a enquete');
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  }

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Enquetes'));
  }, [dispatch]);

  /* React.useEffect(() => {
    setTimeout(() => {
      handleIdAdm(todosVinculos, descVinculoAtual)
    }, 1000);
  }, []); */

  var count = 1;

  return (
    <Container>
        {isManager && (
          <Button
            onClick={() => history.push('enquete/create')}
            theme="primary"
            style={{ width: size.sizeWidth >= 768 ? '60%' : '90vw', alignSelf: "right", marginTop: "20px" }}
          >
            Cadastrar enquete
          </Button>
        )}

        <TableFlex1>
          <thead>
            <tr>
              <th>ID</th>
              <th>Título</th>
              <th>Data Inicial</th>
              <th>Data Final</th>
              <th>Situação</th>
              <th>Exibir Votos</th>
              <th>{isManager ? <>Editar</> : <>Votar</>}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {(listEnquetes?.length === 0) && (
                <EmptyMessage colSpan={6}>
                  <p>{erro}</p>
                </EmptyMessage>
              )}
            </tr>
            {listEnquetes?.map((e) => (
              <>
                <tr key={e.id}>
                  <td>{e.id}</td>
                  <td>{e.titulo} <br></br> <span style={{fontWeight: 'bold'}}>{e.enqueteProprietario == 1 ? 'ENQUETE PARA PROPRIETÁRIOS' : ''}</span></td>
                  <td style={{width: '10em'}}>{dateStringToDateTimeFormat(e.dataInicial)}</td>
                  <td style={{width: '10em'}}>{dateStringToDateTimeFormat(e.dataFinal)}</td>                          
                    {isManager ? <td style={{width: '1em'}}><form id={'alterar_situacao_'+e.id} data-originalstatus={e.situacao} encType='multipart/form-data'>
                      <Select
                        options={statusEnquete}
                        value={statusEnqueteValue}
                        onChange={handleChange}
                        margintop={0}
                        defaultValue={e.situacao}
                        style={{ width: "60%", margin: "auto" }} />
                    </form></td> : 
                      <td style={{width: '1em'}}>{situacaoView[e.situacao]}</td>
                    }
                  
                  <td style={{width: '1em'}}>
                    {(e.exibirParcial == 1) ? <Button
                      theme="primary"
                      onClick={() => exibirResultado(e.id)}
                      style={{ alignSelf: "center", margin: "auto" }}
                    >
                      Exibir Votos
                    </Button>
                    :
                    (
                      e.situacao == 3 ? ( <Button
                        theme="primary"
                        onClick={() => exibirResultado(e.id)}
                        style={{ alignSelf: "center", margin: "auto" }}
                      >
                        Exibir Votos
                      </Button>)
                      :
                      (e.situacao == 4 ? 
                        <p>Votação não iniciada...</p>  
                      :
                        <p>Em andamento...</p>
                      )
                    )
                    }
                  </td>
                  {isManager ?
                    <td style={{width: '7em'}}>
                      <Link to={`/enquete/edit/${e.id}`}><AiOutlineEdit style={{color: 'green', margin: 'auto', fontSize: '16px'}} /></Link>
                    </td>
                    :        
                    <td style={{width: '7em'}}>
                      {e.situacao != 0 ? ( e.situacao == 3 ? <p style={{color: 'red'}}>Votação Encerrada</p> : <p style={{color: 'red'}}></p> ) : (votosUsuario == undefined || !votosUsuario.includes(e.id) ? <Link to={`/enquete/vote/${e.id}`}><Button theme="secondary">Votar</Button></Link> : <p>Voto Enviado</p> )}
                    </td>
                  }
                </tr>
                { exibeVotos == e.id && (
                <tr>
                  <td colSpan={8}>
                    <div style={{textAlign: 'center'}}>          
                      {resultadoEnquete.length == 0 ? (
                        <EmptyMessageText>{errorMessage}</EmptyMessageText>
                      ) : (
                        <ResultadosEnquete
                          resultado={resultadoEnquete}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                )} 
              </>
            ))}
          </tbody>
        </TableFlex1>

    </Container>
  );
}

export default ListEnquetes;
