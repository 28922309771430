import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.MODAL_BACKGROUND};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-width: 300px;
  max-width: 100%;
  min-height: 100px;
  background-color: #ddd;
  padding: 10px 0;

  > svg {
    cursor: pointer;
  }

  h3 {
    text-align: center;
    color: ${COLORS.TEXT_PRIMARY};
  }
`;
export const TitleModal = styled.span`
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
`;
