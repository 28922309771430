/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { toast } from 'react-toastify';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import Loading from '../../components/Loading/Loading';

import {
  Container,
  ContainerTable,
  CalendarContainer,
  LegendContainer,
  Ballet,
  ButtonDownload,
  InsideFlex,
  Wrapper,
  TableFlex1,
  TableFlex2,
  EmptyMessage,
  EmptyMessageText,
  TdWrapper,
} from './styles';
import { BiDownload } from 'react-icons/bi';
import { goToExternalLink } from '../../utils/functions';
import { COLORS } from '../../utils/constants';
import { useWindowSize } from '../Login';

const initialDate = new Date();
const initialMonth = initialDate.getMonth() + 1;
const initialYear = initialDate.getFullYear();

const CMOC: React.FC = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const calendarRef = React.useRef<any>();
  const erro = 'Não há registros';

  const [obligations, setObligations] = React.useState<any[]>([]);
  const [obligationsFilter, setObligationsFilter] = React.useState<any[]>([]);
  const [year, setYear] = React.useState(initialYear);
  const [month, setMonth] = React.useState(initialMonth);
  const [loading, setLoading] = React.useState(false);
  const [toggleTable, setToggleTable] = React.useState(false);
  const [calendarHeight, setCalendarHeight] = React.useState(350);
  const [dateSelected, setDateSelected] = React.useState('');

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('CMOC'));
  }, [dispatch]);

  React.useEffect(() => {
    if (size.sizeWidth <= 768) {
      setCalendarHeight(220);
    } else if (size.sizeWidth <= 1024) {
      setCalendarHeight(300);
    } else {
      setCalendarHeight(350);
    }
  }, [size.sizeWidth]);

  React.useEffect(() => {
    if (size.sizeWidth <= 1024) {
      setToggleTable(true);
    } else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  function getColorObligation(obligation: any): string {
    let color = 'red';
    if (obligation.aberto) {
      color = 'orange';
    } else if (obligation.pago) {
      color = 'green';
    }

    return color;
  }
  const eventsCalendar = React.useMemo(() => {
    const eventsCalendarTemp = obligations
      .filter((o) => {
        const [ano, mes, dia] = o.dataValidade.split('-');
        return parseInt(mes) === month;
      })
      .map((o) => {
        const color = getColorObligation(o);
        return {
          id: o.id,
          title: '',
          start: o.dataValidade,
          display: 'background',
          backgroundColor: color,
        };
      });

    return eventsCalendarTemp;
  }, [obligations, month]);

  const getObligations = React.useCallback(() => {
    setLoading(true);
    request
      .get(`calendarioMensal/${month}/${year}`)
      .then((response) => {
        if (response.data.ok === 'Sucesso') {
          const { listagem } = response.data;
          setObligations(listagem);
        } else {
          alert('Erro : Dados retornados inválidos');
        }
      })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error(
            'Falha na rede. : Verifique sua conexão e tente novamente.'
          );
        } else {
          toast.error('Erro : A requisição falhou');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [month, year]);

  function calendarChangeMonth() {
    const calendarApi = calendarRef?.current?.getApi();

    if (calendarApi) {
      setMonth(Number(calendarApi.getDate().toISOString().split('-')[1]));
      setYear(Number(calendarApi.getDate().toISOString().split('-')[0]));
    }
  }

  React.useEffect(() => {
    getObligations();
  }, [getObligations]);

  const filterObligationsDay = React.useCallback(
    (dateSelectedParam) => {
      setObligationsFilter(
        obligations.filter((o) => o.dataValidade === dateSelectedParam)
      );
      setDateSelected(dateSelectedParam);
    },
    [obligations]
  );
  return (
    <Container>
      <h1>CMOC - Calendario Mensal de Obrigações Condominiais</h1>
      <LegendContainer dateSelected={dateSelected != '' ? true : false}>
        <Wrapper>
          <InsideFlex>
            <span>PAGO</span>
            <Ballet color='green' />
          </InsideFlex>
          <InsideFlex>
            <span>ABERTO</span>
            <Ballet color='orange' />
          </InsideFlex>
          <InsideFlex>
            <span>VENCIDO</span>
            <Ballet color='red' />
          </InsideFlex>
        </Wrapper>

        {dateSelected && (
          <button type='button' onClick={() => setDateSelected('')}>
            Mudar lista para Mês
          </button>
        )}
      </LegendContainer>

      <CalendarContainer>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: size.sizeWidth > 375 ? 'title' : '',
            right: size.sizeWidth <= 375 ? 'title' : 'today',
          }}
          buttonText={{
            today: 'Hoje',
          }}
          initialView='dayGridMonth'
          editable
          selectable
          selectMirror
          dayMaxEvents
          dateClick={({ dateStr }) => filterObligationsDay(dateStr)}
          initialDate={initialDate}
          initialEvents={eventsCalendar}
          events={eventsCalendar}
          locale='pt-BR'
          timeZone='America/Sao_Paulo'
          datesSet={calendarChangeMonth}
          contentHeight={calendarHeight}
        />
      </CalendarContainer>

      <ContainerTable>
        {loading && <Loading />}

        {/* ======================= RESPONSIVE TABLE ===================== */}

        {!toggleTable ? (
          <TableFlex1>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>Validade</th>
                <th>Pago</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {dateSelected ? (
                <tr>
                  {obligationsFilter?.length === 0 && (
                    <EmptyMessage colSpan={7}>
                      <p>{erro}</p>
                    </EmptyMessage>
                  )}
                </tr>
              ) : (
                <tr>
                  {obligations?.length === 0 && (
                    <EmptyMessage colSpan={7}>
                      <p>{erro}</p>
                    </EmptyMessage>
                  )}
                </tr>
              )}
              {dateSelected
                ? obligationsFilter.map((o) => (
                    <tr key={o.id}>
                      <td>
                        <TdWrapper>
                          <Ballet color={getColorObligation(o)} />
                          {o.id}
                        </TdWrapper>
                      </td>
                      <td>{o.nome}</td>
                      <td>{o.dataValidade}</td>
                      <td>{o.pago ? 'SIM' : 'NÃO'}</td>
                      <td>
                        {o.chave != '' && o.isComprovante === '1' && o.pago && (
                          <ButtonDownload
                            onClick={() => goToExternalLink(`${o.chave}`)}
                          >
                            <BiDownload size={30} color={COLORS.ICONS_HEADER} />
                            <span>Baixar Comprovante</span>
                          </ButtonDownload>
                        )}
                        {o.chave != '' && o.isComprovante === '0' && (
                          <ButtonDownload
                            onClick={() => goToExternalLink(`${o.chaveS3}`)}
                          >
                            <BiDownload size={30} color={COLORS.ICONS_HEADER} />
                            <span>Baixar Despesa</span>
                          </ButtonDownload>
                        )}
                      </td>
                    </tr>
                  ))
                : obligations.map((o) => (
                    <tr key={o.id}>
                      <td>
                        <TdWrapper>
                          <Ballet color={getColorObligation(o)} />
                          {o.id}
                        </TdWrapper>
                      </td>
                      <td>{o.nome}</td>
                      <td>{o.dataValidade}</td>
                      <td>{o.pago ? 'SIM' : 'NÃO'}</td>
                      <td>
                        {o.chave != '' && o.isComprovante === '1' && o.pago && (
                          <ButtonDownload
                            onClick={() => goToExternalLink(`${o.chave}`)}
                          >
                            <BiDownload size={30} color={COLORS.ICONS_HEADER} />
                            <p>Baixar Comprovante</p>
                          </ButtonDownload>
                        )}

                        {o.chave != '' && o.isComprovante === '0' && (
                          <ButtonDownload
                            onClick={() => goToExternalLink(`${o.chave}`)}
                          >
                            <BiDownload size={30} color={COLORS.ICONS_HEADER} />
                            <p>Baixar Despesa</p>
                          </ButtonDownload>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </TableFlex1>
        ) : (
          <>
            {dateSelected
              ? obligationsFilter?.length === 0 && (
                  <EmptyMessageText>{erro}</EmptyMessageText>
                )
              : obligations?.length === 0 && (
                  <EmptyMessageText>{erro}</EmptyMessageText>
                )}
            {dateSelected
              ? obligationsFilter?.map((o) => (
                  <TableFlex2 key={o.id}>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <td>
                          <TdWrapper>
                            <Ballet color={getColorObligation(o)} />
                            {o.id}
                          </TdWrapper>
                        </td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <td>{o.nome}</td>
                      </tr>
                      <tr>
                        <th>Validade</th>
                        <td>{o.dataValidade}</td>
                      </tr>
                      <tr>
                        <th>Pago</th>
                        <td>{o.pago ? 'SIM' : 'NÃO'}</td>
                      </tr>
                      <tr>
                        <th>Detalhes</th>
                        <td>
                          {o.chave != '' && o.isComprovante === '1' && o.pago && (
                            <ButtonDownload
                              onClick={() => goToExternalLink(`${o.chave}`)}
                            >
                              <BiDownload
                                size={30}
                                color={COLORS.ICONS_HEADER}
                              />
                              <span>Baixar Comprovante</span>
                            </ButtonDownload>
                          )}

                          {o.chave != '' && o.isComprovante === '0' && (
                            <ButtonDownload
                              onClick={() => goToExternalLink(`${o.chave}`)}
                            >
                              <BiDownload
                                size={30}
                                color={COLORS.ICONS_HEADER}
                              />
                              <span>Baixar Despesa</span>
                            </ButtonDownload>
                          )}
                        </td>
                      </tr>
                    </thead>
                  </TableFlex2>
                ))
              : obligations?.map((o) => (
                  <TableFlex2 key={o.id}>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <td>
                          <TdWrapper>
                            <Ballet color={getColorObligation(o)} />
                            {o.id}
                          </TdWrapper>
                        </td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <td>{o.nome}</td>
                      </tr>
                      <tr>
                        <th>Validade</th>
                        <td>{o.dataValidade}</td>
                      </tr>
                      <tr>
                        <th>Pago</th>
                        <td>{o.pago ? 'SIM' : 'NÃO'}</td>
                      </tr>
                      <tr>
                        <th>Detalhes</th>
                        <td>
                          {o.chave != '' && o.isComprovante === '1' && o.pago && (
                            <ButtonDownload
                              onClick={() => goToExternalLink(`${o.chave}`)}
                            >
                              <BiDownload
                                size={30}
                                color={COLORS.ICONS_HEADER}
                              />
                              <span>Baixar Comprovante</span>
                            </ButtonDownload>
                          )}

                          {o.chave != '' && o.isComprovante === '0' && (
                            <ButtonDownload
                              onClick={() => goToExternalLink(`${o.chave}`)}
                            >
                              <BiDownload
                                size={30}
                                color={COLORS.ICONS_HEADER}
                              />
                              <span>Baixar Despesa</span>
                            </ButtonDownload>
                          )}
                        </td>
                      </tr>
                    </thead>
                  </TableFlex2>
                ))}
          </>
        )}
      </ContainerTable>
    </Container>
  );
};

export default CMOC;
