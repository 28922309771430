import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../interfaces/states/RootState";
import request, { setHeader } from "../../services/request";
import { ActionsHeader } from "../../store/ducks/header";
import { ActionsSession } from "../../store/ducks/session";
import HomeFuncionario from "./components/HomeFuncionario";

const PortalFuncionario = () => {

  const dispatch = useDispatch();

  const { idUsuarioLogado, hash, descVinculoAtual } = useSelector((state: RootState) => state.session);
  const todosVinculos = useSelector((state: RootState) => state.session.todosVinculos);

  const [infoConta, setInfoConta] = useState("");
  const [infoCondominio, setInfoCondominio] = useState("");

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Portal do Funcionário'));
  }, [dispatch]);

  useEffect(() => {
    const { funcionario, condominio } = todosVinculos[0];
    setInfoConta(funcionario.nome);
    setInfoCondominio(condominio.descricao);
  }, []);

  const handleSaveEmployee = useCallback(async () => {

    if (descVinculoAtual) return;

    const { nome } = todosVinculos[0].funcionario;

    setHeader({
      hash,
      idUsuarioLogado
    });

    request
      .post(
        '/salvarLogAcesso',
        {
          id: idUsuarioLogado,
          tipo: 'FUNCIONARIO',
        },
        true,
      )
      .then((response) => {
        if (response.data.ok === 'Sucesso') {
          dispatch(
            ActionsSession.setVinculo({
              descVinculoAtual: nome,
            }),
          );
        } else {
          console.log("Erro ao salvar acesso");
        }
      });
  }, []);

  useEffect(() => {
    handleSaveEmployee();
  }, []);

  return (
      <HomeFuncionario nomeFuncionario={infoConta} />
  )
}

export default PortalFuncionario;