/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { IMessages } from '../../interfaces/responses/IMessages';
import RootState from '../../interfaces/states/RootState';
import request from '../../services/request';
import { IoIosChatboxes, IoIosArrowDown } from 'react-icons/io';
import { toast } from 'react-toastify';
import {
  BottomChat, ChatGreetings, ChatHistory,
  ChatMessage, HeaderChatName, MessageAlert,
  MessageBody, MessageHeader, MiddleContent,
  RedDot, RightContent, SendButton,
  SendMessageContainer
} from './styles';

const ChatView = (props: any) => {
  const scroolRef = useRef<any>();
  const { isEmployee, idUsuarioLogado } = useSelector((state: RootState) => state.session);

  const [conversas, setConversas] = useState<Array<IMessages>>([]);
  const [novaMensagem, setNovaMensagem] = useState('');
  const [messageAlert, setMessageAlert] = useState(false);

  function scroolToEnd() {
    scroolRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  const buscarMensagensWithFuncionario = useCallback(
    async () => {
      await request.post('procurarConversaChatFuncionario', { idFuncionario: props.id }).then((response) => {
        setConversas(response.data);
      });
    }, [props.id],
  );

  const searchMessagesWithUser = useCallback(
    async () => {
      await request.post('procurarConversaChat', { idRecebe: props.id })
        .then((response) => {
          if (response.data) {
            setConversas(response.data.conversas);
          } else {
            setConversas([]);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        })
    }, [props.id],
  );

  useEffect(() => {
    if (!props.id) {
      return;
    }

    // busca as mensagens de uma conversa assim que é chamado o componente
    if (!isEmployee) {
      buscarMensagensWithFuncionario().then(() => rolarBarra());
    } else {
      searchMessagesWithUser().then(() => rolarBarra());
    }
  }, [props.id, buscarMensagensWithFuncionario, searchMessagesWithUser]);

  useEffect(() => {
    if (!conversas) {
      return;
    }

    if (!isEmployee) {
      const interval = setInterval(() => {
        request.post('procurarConversaChatFuncionario', { idFuncionario: props.id })
          .then((response) => {
            if (!response.data) {
              return;
            }

            // atualiza a cada 10s buscando novas mensagens
            const responseLength = response.data.length;
            const conversasLength = conversas.length;

            if (responseLength === conversasLength) {
              return;
            }

            if (response.data[responseLength - 1].mensagem !== conversas[conversasLength - 1].mensagem) {
              buscarMensagensWithFuncionario();
              setMessageAlert(true);
            } else {
              return;
            }
          })
          .catch((error) => {
            if (error?.message === 'Network Error') {
              toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
            } else {
              // toast.error('Erro: A requisição falhou');
              console.log('Erro: A requisição falhou');
            }
          })
      }, 10000);

      return () => {
        clearInterval(interval);
      }
    } else {
      const interval = setInterval(() => {
        request.post('procurarConversaChat', { idRecebe: props.id })
          .then((response) => {
            if (!response.data.conversas) {
              return;
            }

            // atualiza a cada 10s buscando novas mensagens
            const responseLength = response.data.conversas.length;
            const conversasLength = conversas.length;

            if (responseLength === conversasLength) {
              return;
            }

            if (response.data.conversas[responseLength - 1].mensagem !== conversas[conversasLength - 1].mensagem) {
              searchMessagesWithUser();
              setMessageAlert(true);
            } else {
              return;
            }
          })
          .catch((error) => {
            if (error?.message === 'Network Error') {
              toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
            } else {
              console.log(error);
            }
          })
      }, 10000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [conversas, buscarMensagensWithFuncionario, searchMessagesWithUser])

  const checkCurrentScroll = () => {
    const chatList = document.querySelector('.chat-list');
    const onScroll = function () {

      if (!chatList) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = chatList;
      const scroll = scrollHeight - scrollTop - clientHeight;
      // We are not at the bottom of the scroll content if scroll > 0
      // We are at the bottom if scroll == 0

      if (scroll == 0) {
        setMessageAlert(false);
      } else {
        return;
      }
    }
    chatList?.addEventListener('scroll', onScroll);
    return () => chatList?.removeEventListener('scroll', onScroll);
  }

  useEffect(() => {
    checkCurrentScroll();
  }, [])

  // rola a barra da conversa caso tenha muitas conversas
  const rolarBarra = () => {
    const chatList = document.querySelector('#chat-list');
    if (chatList !== null) {
      chatList.scrollTop = chatList.scrollHeight - chatList.clientHeight + 1000;
    }
  };

  const enviarMensagemForEmployee = () => {
    if (novaMensagem !== '' && props.id !== 0) {
      request.post('enviarMensagemChatFuncionario',
        { idFuncionario: props.id, mensagem: novaMensagem })
        .then(() => {
          setNovaMensagem('');
          buscarMensagensWithFuncionario().then(() => scroolToEnd()).finally(() => setMessageAlert(false));
        });
    }
  };

  const enviarMensagemForUser = () => {
    const form = {
      idRecebe: props.id,
      mensagem: novaMensagem,
      tipoMensagem: "user_funcionario"
    }

    if (novaMensagem !== '' && props.id !== 0) {
      request.post('enviarMensagemChat', form)
        .then(() => {
          setNovaMensagem('');
          searchMessagesWithUser().then(() => scroolToEnd()).finally(() => setMessageAlert(false));
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
          } else {
            toast.error('Erro: A requisição falhou');
          }
        });
    }
  };

  const employeeType = (index: any) => {
    if (index != idUsuarioLogado) {
      return "out";
    } else {
      return "in";
    }
  }

  const dealWithName = (type: string, name: string) => {
    if (type === 'out') {
      return "Você"
    } else {
      return name;
    }
  }

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode !== 13) {
      return;
    } else {
      if (!isEmployee) {
        enviarMensagemForEmployee();
      } else {
        enviarMensagemForUser();
      }
    }
  };

  const handleViewNewMessages = () => {
    scroolToEnd();
    setMessageAlert(false);
  }

  return (
    <RightContent>
      <HeaderChatName
        style={!props.name ? { fontSize: 20 } : {}}
      >
        {props.name ? props.name : "Chat"}
      </HeaderChatName>

      <MiddleContent>
        {
          (!props.id && !props.openChat) ? (
            <ChatGreetings>
              <IoIosChatboxes size={100} color='#009B6E' />
              <span>Bem vindo ao chat do funcionário</span>
            </ChatGreetings>
          ) : (
            <ChatHistory id="chat-list" className='chat-list' ref={scroolRef}>
              {
                conversas?.map((conversa) => (
                  <li key={conversa.id} ref={scroolRef}>
                    <ChatMessage className={!isEmployee ? conversa.tipo : employeeType(conversa.idRecebeu)}>
                      <MessageHeader>
                        <h5>{
                          !isEmployee ?
                            dealWithName(conversa.tipo, conversa.nomeEnviou)
                            :
                            dealWithName(employeeType(conversa.idRecebeu), conversa.nomeEnviou)
                        }</h5>
                        <span>
                          {conversa.horario}
                        </span>
                      </MessageHeader>
                      <MessageBody>
                        {conversa.mensagem}
                      </MessageBody>
                    </ChatMessage>
                  </li>
                ))
              }

              {
                messageAlert && (
                  <MessageAlert onClick={() => handleViewNewMessages()}>
                    <RedDot />
                    <IoIosArrowDown size={30} color='#fff' />
                  </MessageAlert>
                )
              }
            </ChatHistory>
          )
        }

      </MiddleContent>

      <BottomChat>
        <SendMessageContainer
          disabled={(!props.id && !props.openChat) ? true : false}
          value={novaMensagem}
          placeholder="Digite sua mensagem..."
          maxLength={500}
          rows={6}
          onChange={(e) => setNovaMensagem(e.target.value)}
          onKeyDown={(e) => onKeyDownHandler(e)}
        />

        <SendButton
          aria-label="enviar mensagem"
          type="button"
          disabled={(!props.id && !props.openChat) ? true : false}
          onClick={!isEmployee ? enviarMensagemForEmployee : enviarMensagemForUser}
        >
          <MdSend size={25} />
        </SendButton>
      </BottomChat>

    </RightContent>
  );
};

export default ChatView;