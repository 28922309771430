import styled from "styled-components";
import { Table } from "../../../../components/Table/styles";

export const TableFlex1 = styled(Table)`
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;
    }

    tr:last-child {
      border: none;
    }

    td {
      padding-right: 15px;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`;