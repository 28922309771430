import React from 'react';
import Modal from 'react-modal';

import { Button } from '@chakra-ui/react';
import * as S from './styles';
import customStyles from '../../../../../utils/customStylesModal';
import imgPermisstionGeolocation from '../../../../../assets/permissrion-location-v2.png';

  type Props = {
    modalVisible : any
    setModalVisible : any
    handleInitiVotes : any
    assemblySelected : any
  }

const ModalNoPermissionGeolocation: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  handleInitiVotes,
  assemblySelected,
}) => (
  <S.Modal>
    <Modal
      isOpen={modalVisible}
      // onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalVisible(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-content">
        <button
          type="button"
          onClick={() => setModalVisible(false)}
          className="close"
        >
          X
        </button>
        <S.ModalContent>
          <h1>Permissão de Localização</h1>

          <img src={imgPermisstionGeolocation} alt="Permissão de Localização" />

          <p>
            No canto superior direito você deve clicar no icone da localização e
            marcar sempre permitir, após isso você pode continuar clicando no
            botão abaixo.
          </p>

          <Button
            colorScheme="teal"
            marginTop="24px"
            onClick={() => handleInitiVotes(assemblySelected)}
            size="lg"
            w="lg"
          >
            CONTINUAR
          </Button>
        </S.ModalContent>
      </div>
    </Modal>
  </S.Modal>
);

export default ModalNoPermissionGeolocation;
