export const goToExternalLink = (link:string) => { window.open(link); };

export const createArrayNumber = (start:number, end:number) => {
  const array = [];
  if (start <= end) {
    for (let i = start; i <= end; i += 1) array.push(i);
  } else {
    for (let i = start; i >= end; i -= 1)array.push(i);
  }
  return array;
};

// eslint-disable-next-line
export const convertFileToBase64 = (file:any, onload:(prop:any)=>void) => {
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onload(btoa(reader.result as string));
  } else {
    onload(undefined);
  }
};

export const numberToCurrency = (n:number) => n.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
