import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { AiOutlinePlus } from 'react-icons/ai';

import { IAssemblies } from '../../../interfaces/responses/IAssemblies';
import { ActionsHeader } from '../../../store/ducks/header';
import { getAssemblies } from '../servicces';
import RootState from '../../../interfaces/states/RootState';
import request from '../../../services/request';
import { Table } from '../../../components/Table/styles';
import ItemList from './ItemList';
import * as S from './styles';
import FormCreateAssembly from './components/FormCreateAssembly';
import FormCreatePauta from './components/FormCreatePauta';
import customStyles from '../../../utils/customStylesModal';
import ModalVote from './components/ModalVote';
import Loading from '../../../components/Loading/Loading';
import ModalNoPermissionGeolocation from './components/ModalNoPermissionGeolocation';
import ModalPermissionGeolocation from './components/ModalPermissionGeolocation';

const Assemblies = () => {
  const dispatch = useDispatch();

  const isManager = useSelector((state:RootState) => state.session.isManager);
  const [assemblies, setAssemblies] = React.useState<Array<IAssemblies>>([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalVisibleVote, setModalVisibleVote] = React.useState(false);
  const [assemblySelected, setAssemblySelected] = React.useState<IAssemblies | undefined>(undefined);
  const [loadingGetAssemblies, setLoadingGetAssemblies] = React.useState(false);
  const [showModalNoPermissionGeolocation, setShowModalNoPermissionGeolocation] = React.useState(false);
  const [showModalPermissionGeolocation, setShowModalPermissionGeolocation] = React.useState(false);

  function changeAssemblySelected(assembly : undefined) {
    setAssemblySelected(assembly);
  }

  function handleInitiVotes(assembly : any) {
    if (assembly) {
      setAssemblySelected(assembly);
    }

    setShowModalNoPermissionGeolocation(false);

    navigator.permissions.query({ name: 'geolocation' })
      .then((permissionObj) => {
        if (permissionObj.state === 'prompt') {
          setShowModalPermissionGeolocation(true);
        }
      });

    navigator.geolocation.getCurrentPosition(() => {
      setShowModalPermissionGeolocation(false);
      setModalVisibleVote(true);
    }, (err) => {
      setShowModalPermissionGeolocation(false);
      if (err.PERMISSION_DENIED === 1) {
        setShowModalNoPermissionGeolocation(true);
      }
    });
  }

  function openModalCreatePauta(assembly : IAssemblies) {
    setAssemblySelected(assembly);
    setModalVisible(true);
  }

  function openModalCreateAssembly() {
    setAssemblySelected(undefined);
    setModalVisible(true);
  }

  function getAssembliesLocal() {
    setLoadingGetAssemblies(true);
    getAssemblies().then((response) => {
      if (response.data.ok === 'Sucesso') {
        const resultados = response.data.listagem.resultados as Array<IAssemblies>;
        setAssemblies(resultados);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoadingGetAssemblies(false);
    });
  }

  const handleActive = (id:number) => {
    request.post(`assembleiasvirtuais/${id}/mudarStatus`, {
      status: 'ATIVA',
    }).then((response) => {
      if (response.data.ok) {
        getAssembliesLocal();
        toast.success('Sucesso, Assembleia ATIVADA com sucesso');
      } else {
        toast.error('Erro', response.data.erro);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  };

  const handleDelete = (id:number) => {
    request.delete(`assembleiasvirtuais/${id}`).then((response) => {
      if (response.data.ok) {
        getAssembliesLocal();
        toast.success('Assembleia Excluída com Sucesso');
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  };

  const handlePublishAssembly = (id:number) => {
    request.post(`assembleiasvirtuais/${id}/mudarStatus`, {
      status: 'PUBLICADA',
    }).then((response) => {
      if (response.data.ok) {
        getAssemblies();
        toast.success('Sucesso, Assembleia enviada com sucesso');

        getAssembliesLocal();
      } else {
        toast.error(`Erro ${response.data.erro}`);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Assembleia Virtual'));
    getAssembliesLocal();
  }, [dispatch]);

  return (
    <S.Container>
      {loadingGetAssemblies && <Loading />}
      {isManager && (
        <S.ButtonCreateAssembleia onClick={openModalCreateAssembly}>
          <p>Criar assembleia</p>
          <AiOutlinePlus size={21} />
        </S.ButtonCreateAssembleia>
      )}

      <Table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Título</th>
            <th>Datas da votação</th>
            <th>Criada em </th>
            <th align="right">Opções</th>
          </tr>
        </thead>
        <tbody>
          {assemblies.map((a) => (
            <ItemList
              key={a.id}
              isManager={isManager}
              assembly={a}
              handlePublishAssembly={handlePublishAssembly}
              handleDelete={handleDelete}
              handleActive={handleActive}
              handleInitiVotes={() => {
                handleInitiVotes(a);
              }}
              openModalCreatePauta={() => {
                openModalCreatePauta(a);
              }}
            />
          ))}
        </tbody>
      </Table>

      <S.Modal>
        <Modal
          isOpen={modalVisible}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setModalVisible(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-content">
            <button
              type="button"
              onClick={() => setModalVisible(false)}
              className="close"
            >
              X
            </button>
            {!assemblySelected ? (
              <FormCreateAssembly
                onClose={() => {
                  getAssembliesLocal();
                }}
                changeAssemblySelected={changeAssemblySelected}
              />
            ) : (
              <FormCreatePauta assemblySelected={assemblySelected} />
            )}
          </div>
        </Modal>
      </S.Modal>

      {modalVisibleVote && assemblySelected && (
      <ModalVote
        modalVisibleVote={modalVisibleVote}
        setModalVisibleVote={setModalVisibleVote}
        assemblySelected={assemblySelected}
      />
      )}
      {showModalNoPermissionGeolocation && (
      <ModalNoPermissionGeolocation
        assemblySelected={assemblySelected}
        modalVisible={showModalNoPermissionGeolocation}
        setModalVisible={setShowModalNoPermissionGeolocation}
        handleInitiVotes={handleInitiVotes}
      />
      )}
      {showModalPermissionGeolocation && (
      <ModalPermissionGeolocation
        modalVisible={showModalPermissionGeolocation}
        setModalVisible={setShowModalPermissionGeolocation}
      />
      )}
    </S.Container>
  );
};

export default Assemblies;
