import styled from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS, WIDTHS } from '../../utils/constants';

export const GridCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:4px;

  @media(max-width: ${WIDTHS.MED}px){
    grid-template-columns: repeat(2, 1fr)
  }

  @media(max-width: ${WIDTHS.MIN}px){
    grid-template-columns: repeat(1, 1fr)
  }
`;

export const CardRevenues = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #fff;

  > div:first-of-type{
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      svg {
        margin-left: 5px;
      }
    }
  } 
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  span {
      color: ${COLORS.TEXT_PRIMARY};
    }
  [data-bold] {
    font-weight: bold;
    margin-right: 4px;
  }
`;

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;
    }

    tr:last-child {
      border: none;
    }

    td {
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`;