import React, { useEffect, useState } from "react";
import { ButtonWrapper, DropDown, DropdownFlex, DropDownText, GoBackButton, ImageWrapper, Instructions, Load, SubmitButton } from "../styles";
import { BiImageAdd, BiTrash } from 'react-icons/bi';
import { ActionsTempFiles } from "../../../store/ducks/tempFiles";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../interfaces/states/RootState";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import request from "../../../services/request";
import { COLORS } from "../../../utils/constants";
import Loading from "react-loading";
import { useWindowSize } from "../../Login";

/* =============================== TYPES =============================== */
type Props = {
  name: string;
  registration: string;
  ufRG: string;
  userCPF: string;
  phone1: string;
  phone2: string;
  isWhats1: boolean;
  isWhats2: boolean;
  genre: string;
  status: string;
  occupation: string;
  birthday: string;
  emailAdress: string;
  handlePrevious: () => void;
}

/* =============================== COMPONENT =============================== */

const SecondStep: React.FC<Props> = ({ name, birthday, emailAdress, genre, isWhats1, isWhats2, phone1, phone2, occupation, registration, status, ufRG, userCPF, handlePrevious }) => {

  /* =============================== CONSTANTS / STATES =============================== */

  const dispatch = useDispatch();
  const history = useHistory();
  const screen = useWindowSize();
  const tempFiles = useSelector((state: RootState) => state.tempFiles);
  const [loading, setLoading] = useState(false);

  /* =============================== EFFECTS =============================== */

  useEffect(() => {
    // Limpa os arquivos do store global
    dispatch(ActionsTempFiles.clearFiles());
  }, [dispatch])

  /* =============================== FUNCTIONS =============================== */

  const convertFileToBase64 = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => addAttachTemp(reader.result);
  };

  const addAttachTemp = (item: any) => {
    dispatch(ActionsTempFiles.addFile(item));
  };

  const removeAttachTemp = (index: number) => {
    dispatch(ActionsTempFiles.removeFile(index));
  };

  const handleAddFile = (file: any) => {
    if (file) {
      const mimeType = file.type;
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (!allowedFileTypes.includes(mimeType)) {
        toast.warn('Arquivo não suportado. Arquivos devem ser do tipo jpeg, jpg ou png.');
        return;
      } else {
        convertFileToBase64(file);
      }
    }
  };

  /* =============================== REQUESTS =============================== */

  const handleSubmit = () => {

    if (!birthday || birthday.length < 10) {
      toast.warn('Atenção: A data de nascimento é obrigatória');
      return;
    }

    if (tempFiles[1] !== null && tempFiles[1] !== undefined) {
      setLoading(true);
      
      const form = {
        nome: name,
        cpf: userCPF,
        rg: registration,
        dataNascimento: birthday,
        sexo: genre,
        ufRG,
        estadoCivil: status,
        telefone: phone1,
        telefone2: phone2,
        isWhatsapp1: isWhats1,
        isWhatsapp2: isWhats2,
        email: emailAdress,
        profissao: occupation,
        anexos: tempFiles,
      };

      request.post('atualizarCadastro', form).then((response) => {
        if (response.data.ok === 'Sucesso') {
          toast.success(`Muito bem! ${response.data.mensagem}`);
          history.goBack();
        } else {
          toast.error(`Erro ${response.data.erro}`);
        }
      }).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro: A requisição falhou');
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      toast.warn('Atenção: Adicione uma imagem em cada quadrado.');
        return;
    }
  };

  /* =============================== RENDERS =============================== */

  return (
    <>
      <Instructions>Adicione 2 anexos: frente e verso do documento de identidade</Instructions>

      <DropdownFlex>

        <DropDown>
          {!tempFiles[0] ?
            <>
              <BiImageAdd size={ screen.sizeWidth <= 520 ? 50 : 70 } />
              <DropDownText><strong>Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui</DropDownText>
              <input type="file" accept="image/*" name="upload" onChange={(e: any) => handleAddFile(e.target.files[0])} />
            </>
            :
            <ImageWrapper>
              <img style={{ height: "100%" }} src={tempFiles[0]} />
              <BiTrash size={25} color={COLORS.PRIMARY} onClickCapture={() => removeAttachTemp(0)} style={{ cursor: "pointer", position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,.7)" }} />
            </ImageWrapper>
          }
        </DropDown>

        <DropDown>
          {!tempFiles[1] ?
            <>
              <BiImageAdd size={ screen.sizeWidth <= 520 ? 50 : 70 } />
              <input type="file" accept="image/*" name="upload" onChange={(e: any) => handleAddFile(e.target.files[0])} />
              <DropDownText><strong>Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui</DropDownText>
            </>
            :
            <ImageWrapper>
              <img style={{ height: "100%" }} src={tempFiles[1]} />
              <BiTrash size={25} color={COLORS.PRIMARY} onClickCapture={() => removeAttachTemp(1)} style={{ cursor: "pointer", position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,.7)" }} />
            </ImageWrapper>
          }
        </DropDown>

      </DropdownFlex>

      <ButtonWrapper>
        {
          !loading ?
            <>
              <SubmitButton onClick={handleSubmit}>Enviar</SubmitButton>
              <GoBackButton onClick={handlePrevious}>Voltar</GoBackButton>
            </>
            :
            <Load>
              <Loading color={COLORS.PRIMARY} height={50} type="spin" />
            </Load>
        }
      </ButtonWrapper>
    </>
  )
}

export default SecondStep;