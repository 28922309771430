import React from 'react';
import Button from '../../../components/Button/Button';
import { IExpenses } from '../../../interfaces/responses/IExpenses';
import { dateStringToDateFormat } from '../../../utils/dates';
import { goToExternalLink } from '../../../utils/functions';
import { TableFlex2 } from '../List/styles';

interface Props {
    expense: IExpenses;
    vencimento: string;
    descricao: string;
    valor: string;
    documento: string;
    opcoes: string;
}

const RowExpenses2 = ({ expense, vencimento, descricao, valor, documento, opcoes }: Props) => (
    <TableFlex2>
        <thead>
            <tr>
                <th>{vencimento}</th>
                <td>{dateStringToDateFormat(expense.dataVencimento).split(' ')[0]}</td>
            </tr>

            <tr>
                <th>{descricao}</th>
                <td>
                    <p style={{
                        maxWidth: '16ch',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        margin: 0,
                    }}
                    >
                        {expense.descricao}
                    </p>
                </td>
            </tr>

            <tr>
                <th>{valor}</th>
                <td>{expense.valorDocumento}</td>
            </tr>

            <tr>
                <th>{documento}</th>
                <td>
                    {expense.documentoLink
                        && (
                            <Button
                                onClick={() => goToExternalLink(expense.documentoLink || '')}
                                theme="primary"
                                marginHorizontal={5}
                            >
                                Download
                            </Button>
                        )}
                </td>
            </tr>

            <tr>
                <th>{opcoes}</th>
                <td>
                    {expense.documentoLink
                        && (
                            <Button
                                onClick={() => goToExternalLink(expense.documentoLink || '')}
                                theme="primary"
                                marginHorizontal={5}
                            >
                                Ver Mais
                            </Button>
                        )}
                </td>
            </tr>
        </thead>
    </TableFlex2>
);

export default RowExpenses2;
