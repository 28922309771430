import styled from 'styled-components';
import { HEIGHTS, WIDTHS, COLORS } from '../../../../utils/constants';

export const CenterHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media(max-width: ${WIDTHS.MIN}px){
    flex: 5;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: ${HEIGHTS.HEADER}px;
`;

export const LeftHeader = styled.div`
  width: 50px;
`;

export const RightHeader = styled.div`
  display: flex;
  svg {
    margin: 0 10px;
    cursor: pointer;
  }
`;

export const TextCenter = styled.label`
  color: ${COLORS.SECONDARY};
  font-size: 30px;
  text-align: center;
`;