import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { dateStringToDate, dateStringToTime } from '../../utils/dates';
import Button from '../../components/Button/Button';
import { cpfMask } from '../../utils/masks';
import {
  FormAgendamentoVisita, FormOption
} from './styles';

const ScheduleVisit = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Agendar Visita'));
  }, [dispatch]);

  function scheduleVisit(e: any) {
    e.preventDefault();

    const form = new FormData(e.target);

    const sendForm = {
      horario: form.get('dataAgendamento'),
      nomeVisitante: form.get('nomeVisitante'),
      cpf: form.get('cpf'),
      tipoVisitante: form.get('tipoVisitante'),
    };
    request.post('agendarNovaVisita', sendForm).then((response) => {
      if (response.data.ok) {
        toast.success(response.data.ok);
        history.goBack();
      } else {
        toast.error(response.data.erro);
      }
    });
  }

  return (
      <FormAgendamentoVisita
        name="formAgendamentoVisita"
        onSubmit={(e) => scheduleVisit(e)}
      >
        <FormOption>
          <label>Data agendamento:</label>
          <input
            type="datetime-local"
            name="dataAgendamento"
            min={`${dateStringToDate(new Date().toISOString())}T${dateStringToTime(new Date().toISOString())}`}
          />
        </FormOption>

        <FormOption>
          <label>Nome do visitante:</label>
          <input
            type="text"
            name="nomeVisitante"
            placeholder="Nome do visitante"
          />
        </FormOption>

        <FormOption>
          <label>CPF:</label>
          <input
            type="text"
            name="cpf"
            placeholder="000.000.000-00"
            onChange={(e) => (
              (e.target.value = cpfMask(e.target.value))
            )}
          />
        </FormOption>

        <FormOption>
          <label>Tipo de visita</label>
          <select name="tipoVisitante">
            <option value="">Selecione o tipo de visita</option>
            <option value="1">Visitante/Familiar</option>
            <option value="2">Prestador de serviço</option>
            <option value="3">Entregador</option>
          </select>
        </FormOption>

        <Button theme="primary" type="submit" style={{ marginTop: 10 }}>
          Agendar
        </Button>
      </FormAgendamentoVisita>
  );
};

export default ScheduleVisit;