/* eslint no-unused-vars:0 */
import React from 'react';

import { Container, Span } from './styles';

interface Props {
  label?: string;
  options: Array<{value:string | number, label:string}>
  onChange?: (e:React.ChangeEvent<HTMLSelectElement>) => void
  value?: any;
  style?: any;
  disabled?: boolean;
  margintop?: number;
  padding?: boolean;
  defaultValue?: number;
}

const Select = ({
  label, options, onChange, value, style, disabled, margintop = 18, padding, defaultValue
}:Props) => (
  <Container data-margintop={margintop} data-padding={padding}>
    {label && <Span>{label}</Span>}
    <select id="email" disabled={disabled} onChange={onChange} value={value} style={style} defaultValue={defaultValue}>
      {options.map((v) => <option key={v.value} value={v.value}>{v.label}</option>)}
    </select>
  </Container>
);

export default Select;
