import * as React from 'react';
import { IMessageAssembly } from '../../../interfaces/IMessageAssembly';
import { dateStringToDateFormat } from '../../../utils/dates';

import {
  DivHeaderMessage, DivMessage, TextMessage, TextTimeMessage, TextNameMessage,
} from './styles';

interface Props {
  message:IMessageAssembly;
  isSelfMessage?: boolean;
}

const Message = ({ message, isSelfMessage = false }:Props) => (
  <DivMessage data-isselfmessage={isSelfMessage}>
    <DivHeaderMessage>
      <TextNameMessage data-isselfmessage={isSelfMessage}>{message.nomeUnidade}</TextNameMessage>
      <TextTimeMessage data-isselfmessage={isSelfMessage}>{dateStringToDateFormat(message.horario)}</TextTimeMessage>
    </DivHeaderMessage>
    <TextMessage data-isselfmessage={isSelfMessage}>{message.mensagem}</TextMessage>
  </DivMessage>
);

export default Message;
