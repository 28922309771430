import React, { useState } from "react";
import Modal from 'react-modal';
import Loading from "../../../../components/Loading/Loading";
import customStyles from "../../../../utils/customStylesModal";
import { toast } from "react-toastify";
import request from "../../../../services/request";
import { ButtonWrapper, GoBackButton, Input, SubmitButton, TextButton, TextGoBack, TextLabelInput, ViewInput } from "./styles";
import { COLORS } from "../../../../utils/constants";
import { getListReserves } from "../../services";
import IReserve from "../../../../interfaces/responses/IReserve";

interface Props {
    reserveId: number;
    modalVisible: boolean;
    setModalVisible: (e: boolean) => void;
    setReserves: React.Dispatch<React.SetStateAction<Array<IReserve>>>;
}

const ModalManager: React.FC<Props> = ({ reserveId, modalVisible, setModalVisible, setReserves }) => {
    
    const [loading, setLoading] = React.useState(false);
    const [feedback, setFeedback] = useState('');
    const [toggleFeedback, setToggleFeedback] = useState(false);

    async function updateReservesList() {
        const responseReserves = await getListReserves();
        setReserves(responseReserves);
    }

    function resetModalState() {
        setFeedback('');
        setToggleFeedback(false);
        setModalVisible(false);
    }

    function handleConfirm(e: React.FormEvent) {
        e.preventDefault();

        const form = {
            idReserva: reserveId
        };

        setLoading(true);
        request.post('confirmarReserva', form).then((res) => {
            if (res.data.ok) {
                toast.success(res.data.ok);
                updateReservesList();
                resetModalState();
            } else {
                toast.error(res.data.erro);
            }
        }).catch((error) => {
            if (error?.message === 'Network Error') {
                toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
                toast.error('Erro : A requisição falhou');
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleCancel(e: React.FormEvent) {
        e.preventDefault();

        // eslint-disable-next-line
        if (feedback == '') {
            toast.error("Adicione o motivo do cancelamento da reserva");
            return;
        }

        const form = {
            idReserva: reserveId,
            motivoCancelamento: feedback
        };

        setLoading(true);
        request.post('cancelarReserva', form).then((res) => {
            if (res.data.ok) {
                toast.success(res.data.ok);
                updateReservesList();
                resetModalState();
            } else {
                toast.error(res.data.erro);
            }
        }).catch((error) => {
            if (error?.message === 'Network Error') {
                toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
                toast.error('Erro : A requisição falhou');
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            isOpen={modalVisible}
            onRequestClose={() => setModalVisible(false)}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="modal-content">
                {loading && <Loading />}
                <button type="button" onClick={() => setModalVisible(false)} className="close">X</button>
                <div>
                    <ButtonWrapper>
                        {!toggleFeedback &&
                            <SubmitButton onClick={handleConfirm} style={toggleFeedback ? { backgroundColor: `${COLORS.PRIMARY}` } : {}}>
                                <TextButton>Confirmar reserva</TextButton>
                            </SubmitButton>
                        }
                        {
                            !toggleFeedback &&
                            <SubmitButton onClick={() => setToggleFeedback(!toggleFeedback)} style={{ backgroundColor: "#c55" }}>
                                <TextButton>Cancelar reserva</TextButton>
                            </SubmitButton>
                        }
                    </ButtonWrapper>

                    {
                        toggleFeedback &&
                        <>
                            <ViewInput>
                                <TextLabelInput>Para cancelar, por favor insira um motivo de cancelamento:</TextLabelInput>
                                <Input onChange={(e) => setFeedback(e.target.value)} value={feedback} maxLength={900} />
                                <span style={{ marginRight: 10, alignSelf: "flex-end" }}>{feedback.length}/900</span>
                            </ViewInput>

                            <SubmitButton onClick={handleCancel} style={{ backgroundColor: "#c55" }}>
                                <TextButton>Enviar cancelamento</TextButton>
                            </SubmitButton>

                            <GoBackButton onClick={() => setToggleFeedback(false)}>
                                <TextGoBack>Voltar</TextGoBack>
                            </GoBackButton>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ModalManager;