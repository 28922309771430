import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import request from '../../../services/request';
import { ActionsHeader } from '../../../store/ducks/header';
import { useHistory } from 'react-router-dom';
import { TextDescricao } from '../styles';

function CreateEnquete() {

  const dispatch = useDispatch();
  const [multiplaEscolha, setMultiplaEscolha] = React.useState(false);
  const [exibirParcial, setExibirParcial] = React.useState(false);
  const [enqueteProprietario, setEnqueteProprietario] = React.useState(false);
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');
  const [statusEnquete, setStatusEnquete] = React.useState<Array<any>>([]);
  const [statusEnqueteValue, setStatusEnqueteValue] = React.useState<string | number>();
  const history = useHistory();
  const [inputArr, setInputArr] = React.useState<Array<any>>([]);
  const [disabledButton, setDisabled] = React.useState(false);

  const inputs = [
    {
      type: "text",
      id: 1,
      value: ""
    }
  ]

  const addInput = () => {
    setInputArr(s => {
      const lastId = s[s.length - 1].id;
      return [
        ...s,
        {
          type: "text",
          id: lastId + 1,
          value: ""
        }
      ];
    });
  };

/*   const removeInput = (index:number) => {
    var newArr = inputArr;
    const remove = newArr.splice(index, 1);
    console.log(remove);
    setInputArr(remove);
  }; */

  const opcaoChange = (e:any) => {

    const index = e.id;
    const newArr = inputArr;
    newArr[index].value = e.value;
    setInputArr(newArr);

  }

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Nova Enquete'));

        // const status = ['Ativa', 'Cancelada', 'Suspensa', 'Encerrada'];

        const status = [
            {id:0,descricao:'Ativa'},
            {id:1,descricao:'Cancelada'},
            {id:2,descricao:'Suspensa'},
            {id:3,descricao:'Encerrada'},
        ]

        const statusEnqueteTemp = status.map((i: any) => ({
            value: i.id,
            label: `${i.descricao}`,
          }));

        setStatusEnquete(statusEnqueteTemp);
        setInputArr(inputs);
        console.log(statusEnquete);

  }, [dispatch]);

  const handleSubmit = () => {
    setDisabled(true);
    const routeApi = 'salvarEnquete';
    let opcoes_aux = new Array;
    for(var i = 0; i < inputArr.length; i++) {
      opcoes_aux.push([inputArr[i].value]);
    }
    const form = {
      titulo: titulo,
      descricao: descricao,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      opcoes: opcoes_aux,
      situacao: statusEnqueteValue || statusEnquete[0].value,
      multiplaEscolha: (multiplaEscolha) ? 1 : 0,
      exibirParcial: (exibirParcial) ? 1 : 0,
      enqueteProprietario: (enqueteProprietario) ? 1 : 0
    };
    
    request.post(routeApi, form).then((response) => {
      if (response.data.sucesso) {
        toast.success('Muito bem, Enquete salva com sucesso');
        history.push('/list_enquetes')
      } else if (!response.data.sucesso) {
        toast.error(response.data.mensagem);
        setDisabled(false);
      } else {
        toast.error('Ops, algo de errado aconteceu ao tentar salvar a enquete');
        setDisabled(false);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        setDisabled(false);
      } else {
        toast.error('Erro : A requisição falhou');
        setDisabled(false);
      }
    });
  };

  const handle_enqueteProprietario = () => {
    
    if (!enqueteProprietario && window.confirm("Essa opção fará com que apenas condoôminos cadastrados como PROPRIETARIOS visualizem essa enquete. Deseja confirmar?")) {
      setEnqueteProprietario(!enqueteProprietario);
    } else if (enqueteProprietario) {
      setEnqueteProprietario(!enqueteProprietario);
    }
    return;
  }

  return (
    <div style={{ width: "95%", margin: '0 auto' }}>
      <div style={{ width: "100%" }}>
        <CheckBox label="Exibir parcial (Permite a visualização dos votos antes da finalização da enquete)" checked={exibirParcial} onChange={() => setExibirParcial(!exibirParcial)} checkboxOrder='1' marginLeft={10} />
      </div>
      <Input type="text" label="Título da Enquete" value={titulo} onChange={(e: any) => setTitulo(e.target.value)} style={{ width: "100%" }} />
      <span className="sc-bsipQr eieICQ">Descrição (Máx 400 caracteres.)</span>
      <TextDescricao value={descricao} onChange={(e: any) => setDescricao(e.target.value)} style={{ width: "100%" }} maxLength={400}></TextDescricao>
      {/* <Input type="text" label="Opção 1" value={opcao1} onChange={(e: any) => setOpcao1(e.target.value)} style={{ width: "100%" }} />
      <Input type="text" label="Opção 2" value={opcao2} onChange={(e: any) => setOpcao2(e.target.value)} style={{ width: "100%" }} /> */}
      {inputArr.map((item, i) => (
        <>
          <Input id={''+i} type={item.type} label={"Opção "+(i+1)} defaultValue={item.value} style={{ width: "100%", display: "inline" }} className='opcoes' onChange={(e: any) => opcaoChange(e.target)} />
          {/* <Button theme="primary" marginTop={10} onClick={() => removeInput(i)} style={{ width: "5%", display: "inline" }}>X</Button> */}
        </>
      ))}
      <Button theme="primary" marginTop={10} onClick={addInput} style={{ width: "100%" }}>+ Adicionar opção</Button>
      <CheckBox label="Múltipla escolha (Permite o voto em múltiplas opções)" checked={multiplaEscolha} onChange={() => setMultiplaEscolha(!multiplaEscolha)} checkboxOrder='1' marginLeft={10} />
      <Input type="datetime-local" label="Data inicial da votação" value={dataInicial} onChange={(e: any) => setDataInicial(e.target.value)} style={{ width: "100%" }} />
      <Input type="datetime-local" label="Data final da votação" value={dataFinal} onChange={(e: any) => setDataFinal(e.target.value)} style={{ width: "100%" }} />
      <Select
        options={statusEnquete}
        value={statusEnqueteValue}
        label="Status"
        onChange={(e) => setStatusEnqueteValue(e.target.value)}
        style={{ width: "100%" }} />
      <CheckBox label="Enquete para proprietários" checked={enqueteProprietario} onChange={() => handle_enqueteProprietario()} checkboxOrder='1' marginLeft={10} />
      <Button theme="secondary" marginTop={10} onClick={handleSubmit} style={{ width: "100%" }} disabled={disabledButton}>Salvar</Button>
    </div>
  );
}

export default CreateEnquete;
