import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FullCalendar, {
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Select from '../../../components/Select/Select';
import { ActionsHeader } from '../../../store/ducks/header';
import { COLORS, MONTH_NAMES } from '../../../utils/constants';
import { getExpenses } from '../services';
import { createArrayNumber } from '../../../utils/functions';
import { IExpenses } from '../../../interfaces/responses/IExpenses';
import Loading from '../../../components/Loading/Loading';
import Button from '../../../components/Button/Button';
import { Divisor, Table } from '../../../components/Table/styles';
import RowExpenses from '../components/RowExpense';
import RootState from '../../../interfaces/states/RootState';

import {
  Filters, DivNewExpense, CalendarContainer, Container, ContainerTable, EmptyMessage, TableFlex1, TableFlex2, EmptyMessageText, Wrapper,
} from './styles';
import { useWindowSize } from '../../Login';
import RowExpenses2 from '../components/RowExpenses2';

const initialDate = new Date();
const initialMonth = (initialDate.getMonth() + 1);
const initialYear = initialDate.getFullYear();

function Expenses() {
  const history = useHistory();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const erro = 'Não há registros';

  const calendarRef = React.useRef<any>();

  const isManager = useSelector((state: RootState) => state.session.isManager);
  const [currentYear, setCurrentYear] = React.useState<number>(initialYear);
  const [currentMonth, setCurrentMonth] = React.useState<number>(initialMonth);
  const [currentSituation, setCurrentSituation] = React.useState<number>(1);
  const [expenses0, setExpenses0] = React.useState<Array<IExpenses>>([]);
  const [expenses1, setExpenses1] = React.useState<Array<IExpenses>>([]);
  const [expenses2, setExpenses2] = React.useState<Array<IExpenses>>([]);
  const [expensesFilter, setExpensesFilter] = React.useState<Array<IExpenses>>([]);
  const [loading, setLoading] = React.useState(false);
  const [dateSelected, setDateSelected] = React.useState('');
  const [toggleTable, setToggleTable] = React.useState(false);
  const [calendarHeight, setCalendarHeight] = React.useState(350);

  const arrayYears = createArrayNumber(initialDate.getFullYear(), 2014);
  const arraySituation = [{ value: 0, label: 'Atrasados' }, { value: 1, label: 'Pagos' }, { value: 2, label: 'Em aberto' }];

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Despesas'));
    setLoading(true);
    getExpenses(currentYear, currentMonth).then((response) => {
      setExpenses0(response.response0);
      setExpenses1(response.response1);
      setExpenses2(response.response2);
    }).finally(() => setLoading(false));
  }, [dispatch, currentYear, currentMonth]);

  React.useEffect(() => {
    if (size.sizeWidth <= 768) {
      setCalendarHeight(220);
    } else if (size.sizeWidth <= 1024) {
      setCalendarHeight(300);
    } else {
      setCalendarHeight(350);
    }
  }, [size.sizeWidth]);

  React.useEffect(() => {
    if (size.sizeWidth <= 1024) {
      setToggleTable(true);
    } else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  const expensesEventsCalendar = React.useMemo(
    () => {
      let eventsCalendarTemp: any[] = [];

      if (currentSituation === 0) {
        eventsCalendarTemp = expenses0.filter((o) => new Date(o.dataVencimento).getMonth() + 1 === currentMonth).map((o) => ({
          id: o.id,
          title: '',
          start: o.dataVencimento,
          display: 'background',
          backgroundColor: 'red',
        }));
      }

      if (currentSituation === 1) {
        eventsCalendarTemp = expenses1.filter((o) => new Date(o.dataVencimento).getMonth() + 1 === currentMonth).map((o) => ({
          id: o.id,
          title: '',
          start: o.dataVencimento,
          display: 'background',
          backgroundColor: 'blue',
        }));
      }

      if (currentSituation === 2) {
        eventsCalendarTemp = expenses2.filter((o) => new Date(o.dataVencimento).getMonth() + 1 === currentMonth).map((o) => ({
          id: o.id,
          title: '',
          start: o.dataVencimento,
          display: 'background',
          backgroundColor: 'orange',
        }));
      }

      return eventsCalendarTemp;
    }, [expenses0, expenses1, expenses2, currentMonth, currentSituation],
  );

  const filterExpensivesDay = React.useCallback(
    (dateSelectedParam) => {
      if (currentSituation === 0) {
        setExpensesFilter(
          expenses0.filter((o) => o.dataVencimento === dateSelectedParam),
        );
      }

      if (currentSituation === 1) {
        setExpensesFilter(
          expenses1.filter((o) => o.dataVencimento === dateSelectedParam),
        );
      }

      if (currentSituation === 2) {
        setExpensesFilter(
          expenses2.filter((o) => o.dataVencimento === dateSelectedParam),
        );
      }

      setDateSelected(dateSelectedParam);
    },
    [expenses0, expenses1, expenses2, currentSituation],
  );

  function calendarChangeMonth() {
    const calendarApi = calendarRef?.current?.getApi();

    if (calendarApi) {
      setCurrentMonth(Number(calendarApi.getDate().toISOString().split('-')[1]));
      setCurrentYear(Number(calendarApi.getDate().toISOString().split('-')[0]));
    }
  }

  function gotoDateCalendar(date: string): void {
    try {
      const calendarApi = calendarRef?.current?.getApi();

      if (calendarApi) {
        calendarApi.gotoDate(new Date(date));
      }
    } catch (error) {
      console.log(error);
    }
  }

  function today(): void {
    setDateSelected('');

    try {
      const calendarApi = calendarRef?.current?.getApi();

      if (calendarApi) {
        calendarApi.today();
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Container>
      <Filters>
        <Select
          label="Ano"
          options={arrayYears.map((n) => ({ value: n, label: String(n) }))}
          onChange={(e) => {
            setCurrentYear(Number.parseInt(e.target.value, 10));

            gotoDateCalendar(`${Number.parseInt(e.target.value, 10)}-${currentMonth}-01`);

            setDateSelected('');
          }}
          value={currentYear}
        />

        <Select
          label="Mês"
          options={MONTH_NAMES.map((m, i) => ({ value: i + 1, label: m }))}
          onChange={(e) => {
            setCurrentMonth(Number.parseInt(e.target.value, 10));

            gotoDateCalendar(`${currentYear}-${Number.parseInt(e.target.value, 10)}-01`);

            setDateSelected('');
          }}
          value={currentMonth}
        />

        <Select
          label="Situação"
          options={arraySituation}
          onChange={(e) => {
            setCurrentSituation(Number.parseInt(e.target.value, 10));
            filterExpensivesDay(dateSelected);
          }}
          value={currentSituation}
        />
      </Filters>

      {isManager && (
        <Button
          onClick={() => history.push('expense/create')}
          theme="primary"
          style={{ width: size.sizeWidth >= 768 ? '60%' : '90vw', alignSelf: "center" }}
        >
          Cadastrar despesas
        </Button>
      )}

      {dateSelected && (
        <Button
          theme="primary"
          onClick={() => setDateSelected('')}
          style={{ width: size.sizeWidth >= 768 ? '60%' : '100%', alignSelf: "center", backgroundColor: 'transparent', borderWidth: 2, borderColor: COLORS.PRIMARY, borderStyle: 'solid', color: COLORS.PRIMARY }}
        >
          Mudar lista para Mês
        </Button>
      )}

      <DivNewExpense />

      <Wrapper>

        <CalendarContainer>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next',
              center: size.sizeWidth > 375 ? 'title' : '',
              right: size.sizeWidth <= 375 ? 'title' : 'todayCustom',
            }}
            customButtons={{
              todayCustom: {
                text: 'Hoje',
                click: today,
              },
            }}
            initialView="dayGridMonth"
            editable
            selectable
            selectMirror
            dayMaxEvents
            initialDate={new Date(`${currentYear}-${currentMonth}-01`)}
            initialEvents={expensesEventsCalendar as any}
            events={expensesEventsCalendar as any}
            locale="pt-BR"
            timeZone="America/Sao_Paulo"
            datesSet={calendarChangeMonth}
            dateClick={({ dateStr }) => filterExpensivesDay(dateStr)}
            contentHeight={calendarHeight}
          />
        </CalendarContainer>

        <ContainerTable>

          {!toggleTable ?
            <TableFlex1>
              <thead>
                <tr>
                  {/* <th>Número do documento</th> */}
                  <th>Vencimento</th>
                  {/* <th>Data do pagamento</th> */}
                  <th>Descrição</th>
                  <th>Valor</th>
                  {/* <th>Valor pago</th> */}
                  <th>Documento</th>
                  <th>Opções</th>
                  {/* <th>Comprovante</th> */}
                </tr>
              </thead>
              <Divisor colSpan={8} />
              <tbody>
                {currentSituation === 0 && expenses0.length === 0 && !dateSelected && (
                  <EmptyMessage colSpan={5}>
                    Não existe registros para esse mês
                  </EmptyMessage>
                )}

                {currentSituation === 1 && expenses1.length === 0 && !dateSelected && (
                  <EmptyMessage colSpan={5}>
                    Não existe registros para esse mês
                  </EmptyMessage>
                )}

                {currentSituation === 2 && expenses2.length === 0 && !dateSelected && (
                  <EmptyMessage colSpan={5}>
                    Não existe registros para esse mês
                  </EmptyMessage>
                )}

                {dateSelected && expensesFilter.length === 0 && (
                  <EmptyMessage colSpan={5}>
                    Não existe registros para esse dia
                  </EmptyMessage>
                )}

                {currentSituation === 0 && !dateSelected
                  && expenses0.map((e) => <RowExpenses key={e.id} expense={e} />)}

                {currentSituation === 1 && !dateSelected
                  && expenses1.map((e) => <RowExpenses key={e.id} expense={e} />)}

                {currentSituation === 2 && !dateSelected
                  && expenses2.map((e) => <RowExpenses key={e.id} expense={e} />)}

                {currentSituation === 0 && dateSelected
                  && expensesFilter.map((e) => <RowExpenses key={e.id} expense={e} />)}

                {currentSituation === 1 && dateSelected
                  && expensesFilter.map((e) => <RowExpenses key={e.id} expense={e} />)}

                {currentSituation === 2 && dateSelected
                  && expensesFilter.map((e) => <RowExpenses key={e.id} expense={e} />)}
              </tbody>
            </TableFlex1>
            :
            <>
              {currentSituation === 0 && expenses0.length === 0 && !dateSelected && (
                <EmptyMessageText>
                  Não existe registros para esse mês
                </EmptyMessageText>
              )}

              {currentSituation === 1 && expenses1.length === 0 && !dateSelected && (
                <EmptyMessageText>
                  Não existe registros para esse mês
                </EmptyMessageText>
              )}

              {currentSituation === 2 && expenses2.length === 0 && !dateSelected && (
                <EmptyMessageText>
                  Não existe registros para esse mês
                </EmptyMessageText>
              )}

              {dateSelected && expensesFilter.length === 0 && (
                <EmptyMessageText>
                  Não existe registros para esse dia
                </EmptyMessageText>
              )}

              {currentSituation === 0 && !dateSelected
                && expenses0.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}

              {currentSituation === 1 && !dateSelected
                && expenses1.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}

              {currentSituation === 2 && !dateSelected
                && expenses2.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}

              {currentSituation === 0 && dateSelected
                && expensesFilter.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}

              {currentSituation === 1 && dateSelected
                && expensesFilter.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}

              {currentSituation === 2 && dateSelected
                && expensesFilter.map((e) => <RowExpenses2 key={e.id} expense={e} vencimento='Vencimento' descricao='Descrição' valor='Valor' documento='Documento' opcoes='Opções' />)}
            </>
          }

        </ContainerTable>
      </Wrapper>

      {loading && <Loading />}
    </Container>
  );
}

export default Expenses;
