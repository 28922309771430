import styled from 'styled-components';
import { COLORS } from '../../../../utils/constants';

export const BoxLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  background-color: #FFFFFF;
  height: fit-content;
  padding: 30px;
  box-shadow: 0px 3px 20px #00000014;
  border-radius: 8px;
  transform: translate(43%, -10%);

  @media screen and (max-width: 728px) {
    width: 90%;
    transform: translate(0, -10%);
    margin: 0 auto;
  }
`;

export const CloseButton = styled.div`
  height: 30px;
  width: 100%;
  color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -10px;
`;

export const FlexEntrar = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    flex-direction: column;

    Button {
      flex: unset;
      height: 40px;
      margin: 10px 0;
    }
  }
`;

export const FlexStores = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;
