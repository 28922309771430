import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import { DataPrevious, DataPreviousPF, DataUpdated } from '../../interfaces/responses/IRegistrationUpdate';
import request from '../../services/request';
import RegisterUpdatePF from './RegisterUpdatePF';
import RegisterUpdatePJ from './RegisterUpdatePJ';

function RegisterUpdate() {
  const dispatch = useDispatch();

  const [tipoPessoa, setTipoPessoa] = React.useState('FISICA');
  const [previousData, setPreviousData] = React.useState<DataPrevious>();
  const [updated, setUpdated] = React.useState<DataUpdated>();
  const [previousDataPF, setPreviousDataPF] = React.useState<DataPreviousPF>();
  const [updatedPF, setUpdatedPF] = React.useState<DataPreviousPF>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    request.get('verificaAtualizacaoCadastral').then((response) => {
      if (response.data.ok === 'Sucesso') {
        setTipoPessoa(response.data.resultado.tipoPessoa);
        if (response.data.resultado.tipoPessoa === "FISICA") {
          setPreviousDataPF(response.data.resultado.dadosAnteriores);
          if (response.data.resultado.atualizacao) {
            setUpdatedPF(response.data.resultado.atualizacao);
          }
        } else {
          setPreviousData(response.data.resultado.dadosAnteriores);
          if (response.data.resultado.atualizacao) {
            setUpdated(response.data.resultado.atualizacao);
          }
        }
      } else {
        toast.error(`Erro ${response.data.erro}`);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  if (loading) {
    return (
      <div style={{
        marginTop: 120,
      }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      {
        (previousData || previousDataPF) && (
          tipoPessoa === 'FISICA' ?
            (
              <RegisterUpdatePF
                previousData={previousDataPF}
                updated={updatedPF}
              />
            )
            :
            <RegisterUpdatePJ previousData={previousData} updated={updated} />
        )
      }
    </>
  )
}

export default RegisterUpdate;