import styled from 'styled-components';

export const DivToggleButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 10px 15px;

  button {
    flex: 1;

    &:hover{
      cursor: pointer;
    }
  }
`;
