import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../interfaces/states/RootState';
import { useHistory } from 'react-router-dom';
import { useCamera } from '../hooks/modules/CameraContext';
import { ActionsToggleButtons } from '../store/ducks/toggleButtons';

export const RouteListener = () => {
  const { textCenter } = useSelector((state: RootState) => state.header);
  const { leituraId } = useSelector((state: RootState) => state.toggleButtons);
  const history = useHistory();
  const dispatch = useDispatch();
  const { stopCameraFunction } = useCamera();

  const getLog = () => {
    const paths = ["/cadastro_visita", "/gravar_leituras"]
    if (paths.includes(history.location.pathname)) {
      return;
    }

    if (history.location.pathname !== "/gravar_leituras" && leituraId !== 0) {
      dispatch(ActionsToggleButtons.setSelectedLeituraId(0));
      dispatch(ActionsToggleButtons.setSelectedTaxaId(0));
    }

    stopCameraFunction();
  }

  useEffect(() => {
    getLog();
  }, [textCenter]);

  return null;
};
