import styled from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
    display: flex;
    margin-top: 15px;
    text-align: center;
    flex-direction: column;
    padding: 0 15px;
`;

export const CalendarContainer = styled.div`
  flex: 1;
  background: #fff;
  width: 100%;
  align-self: center;

  .fc {
    box-shadow: 0px 0px 6px 0px rgba(212,212,212,0.73);
    padding: 15px;
    .fc-button-group {
      /* margin: 12px 0 0 12px; */
      > button {
        background: ${COLORS.PRIMARY};
        border: 0;
        box-shadow: 0px 0px 6px 0px rgba(212,212,212,0.73);
      }
      .fc-prev-button.fc-button.fc-button-primary {
        margin-right: 12px;
      }
      .fc-next-button.fc-button.fc-button-primary {
      }

      .fc-dayGridMonth-button{
        margin-right: 12px;
      }
    }

    .fc-today-button {
      /* margin-right: 12px; */
      background: ${COLORS.SECONDARY};
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    @media screen and (max-width: 580px) {
      font-size: 13px;
    }

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
    @media screen and (max-width: 375px) {
      font-size: 11px;
      padding: 10px;
    }
  }
`;
export const LegendContainer = styled.div<{ dateSelected?: boolean }>`
  display: flex;
  margin: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: ${props => props.dateSelected ? 'column' : 'row'};

  span {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
    }

  button {
      margin: 0 auto;
      padding: 10px;
      border: 0 ;
      border-radius: 6px;
      background: ${COLORS.PRIMARY};
      color: #fff;
      font-weight: bold;
      margin-top: 20px;
      cursor: pointer;
  }
`;

export const InsideFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 80px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Ballet = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: 12px;
`;

export const ContainerTable = styled.div`
  flex: 1;
  margin: 15px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid ${COLORS.PRIMARY};
  }
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;
`;

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;

      @media screen and (max-width: 520px) {
        font-size: 13px;
      }
    }

    tr:last-child {
      border: none;
    }

    td {
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;

      @media screen and (max-width: 520px) {
        font-size: 13px;
      }
    }

    th {
      padding-left: 10px;

      @media screen and (max-width: 520px) {
        font-size: 13px;
      }
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonDownload = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: ${COLORS.PRIMARY};
  width: 95%;
  padding: 5px;
  border-radius: 5px;
  margin: 10px auto;
  color: #fff;
  font-weight: 700;
  cursor: pointer;

  span {
    margin-left: 20px;
  }
`;

export const TdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;