import { Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { BiBookBookmark } from 'react-icons/bi';
import { FaCalendarDay, FaCalendarWeek } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ActionsHeader } from '../../store/ducks/header';
import Card from './components/Card';
import { FlexContainer } from './styles';

const docsCondominium = [
  {
    title: 'Convenções',
    icon: BiBookBookmark,
    route_path: 'list_default/listarConvencoes',
  },
  {
    title: 'Regimento interno',
    icon: FaCalendarWeek,
    route_path: 'list_default/listarRegimentosInternos',
  },
  {
    title: 'Editais',
    icon: FaCalendarDay,
    route_path: 'list_default/listarEditais',
  },
  {
    title: 'Atas',
    icon: AiOutlineWarning,
    route_path: 'list_default/listarAtas',
  },
];

export default function CondominiumDocuments() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Documentos do Condomínio'));
  }, [dispatch]);

  return (
    <FlexContainer>
      {docsCondominium.map((c, index) => (
        <Card key={index} title={c.title} icon={c.icon} route_path={c.route_path} />
      ))}
    </FlexContainer>
  );
}
