import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 30px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Greetings = styled.h1`
  font-size: 30px;
  text-align: center;
  font-weight: 700;
`;

export const Info = styled.span`
  margin-top: 20px;
  font-weight: 600;
`;

export const List = styled.ul`
  padding-left: 10px;
`;

export const ListOption = styled.li`
  font-size: 16px;
`;