/* eslint-disable no-unused-expressions */
import React, {
    createContext, useContext, useRef, useState
} from 'react';

interface CameraContextData {
    videoRef: React.LegacyRef<HTMLVideoElement>;
    photoRef: React.LegacyRef<HTMLCanvasElement>;
    photo: any;
    hasPhoto: boolean;
    videoOpen: boolean;
    mediaStream: MediaStreamTrack[] | null;
    clearFunction: (width: number, height: number) => void;
    clearCanvas: () => void;
    snapShotFunction: (photoWidth: number, ratio: number) => void;
    getCameraFunction: (width: number, height: number) => void;
    stopCameraFunction: () => void;
}

const CameraContext = createContext<CameraContextData>({} as CameraContextData);

const CameraProvider = function ({ children }: { children: any }) {

    const videoRef = useRef<any>(null);
    const photoRef = useRef<any>(null);

    const [hasPhoto, setHasPhoto] = useState(false);
    const [mediaStream, setMediaStream] = useState<MediaStreamTrack[] | null>([]);
    const [videoOpen, setVideoOpen] = useState(true);
    const [photo, setPhoto] = useState();

    const getVideo = (width: number, height: number) => {
        clearCanvas();

        navigator.mediaDevices
            .getUserMedia({
                video: { width, height, facingMode: { ideal: "environment"} }
            })
            .then((stream) => {
                let media = stream.getTracks();
                setMediaStream(media);

                let videoStream = stream.getTracks().find((track) => track.kind === 'video')
                if (videoStream) {
                    videoStream.enabled = true;
                }

                let video = videoRef.current;
                video.srcObject = stream;
                video.play();

                setVideoOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const stopVideo = () => {
        if (mediaStream) {
            mediaStream.forEach(track => {
                track.stop();
            });
            setMediaStream(null);
            setVideoOpen(false);
        }
    }

    const takePhoto = (photoWidth: number, ratio: number) => {
        const width = photoWidth === 1080 ? photoWidth * (40 / 100) : photoWidth;
        const height = photoWidth === 1080 ? (photoWidth * (40 / 100)) / ratio : photoWidth / ratio;

        let video = videoRef.current;
        let photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);

        const srcEncoded = ctx?.canvas.toDataURL("image/jpeg", 1);
        setPhoto(srcEncoded);
        setHasPhoto(true);
    }

    const clearAll = (width: number, height: number) => {
        let photo = photoRef.current;
        let ctx = photo.getContext('2d');

        ctx.clearRect(0, 0, photo.width, photo.height);

        setHasPhoto(false);
        setVideoOpen(true);
        getVideo(width, height);
    }

    const clearCanvas = () => {
        let photo = photoRef.current;
        let ctx = photo.getContext('2d');

        ctx.clearRect(0, 0, photo.width, photo.height);

        setHasPhoto(false);
    }

    return (
        <CameraContext.Provider value={{
            videoRef,
            photoRef,
            photo,
            hasPhoto,
            videoOpen,
            mediaStream,
            clearFunction: clearAll,
            clearCanvas,
            snapShotFunction: takePhoto,
            getCameraFunction: getVideo,
            stopCameraFunction: stopVideo,
        }}
        >
            {children}
        </CameraContext.Provider>
    );
};

function useCamera(): CameraContextData {
    const context = useContext(CameraContext);

    return context;
}

export { CameraProvider, useCamera };  