import styled from 'styled-components';
import { WIDTHS } from '../../utils/constants';

export const Table = styled.table`
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  transition : all 0.2s ease-in-out;

  @media(max-width: ${WIDTHS.MED}px){
    font-size: 12px;
  }

  thead {
    color: #111;
    font-weight : bold;
    font-size : 16px;
    th {
      padding: 15px;
    }
  }

  tbody {
    color: #555;
    tr{
      &:hover{
        background-color: #eee;
      }
    }

    tr:nth-child(even) {background-color: #dddd;}

    td {
      padding: 10px;
      border-bottom: 1px solid #eee;
      color : #333333;
    }

    .table-unidade{
        font-weight : bold;
        color : #111;
    }

    .title-assembly{
    max-width : 222px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    }
  }

  
`;

export const Divisor = styled.td`
  border-bottom: 1px solid #ddd;
  margin: 5px 0;
`;
