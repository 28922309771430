/* eslint-disable no-shadow */
export enum LoginEndpoints {
  Login = '/login',
  LoginEntrar = '/login/entrar',
  Administradora = '/login/entrar/administradora',
  Sindico = '/login/entrar/sindico',
  Condomino = '/login/entrar/condomino',
  Imobiliaria = '/login/entrar/imobiliaria',
  Condominio = '/login/entrar/condominio',
  Servicos = '/login/entrar/servico',
  Produtos = '/login/entrar/produtos',
  Advocacia = '/login/entrar/cobranca',
  Parceira = '/login/entrar/parceira',
  Adimplencia = '/login/carta_adimplencia/carta'
}
