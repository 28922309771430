import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { CardClean } from '../../../components/Card/Card';
import request from '../../../services/request';

import { Container } from './styles';
import { ActionsHeader } from '../../../store/ducks/header';
import customStyles from '../../../utils/customStylesModal';

function Results() {
  const dispatch = useDispatch();

  const [result, setResult] = useState<{tituloAssembleiaVirtual : string, vinculosAtivos : string, vinculosInaptos : string }>();
  const [pautas, setPautas] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const { id } = useParams() as any;

  const getResultAssembly = useCallback(
    () => {
      request.get(`assembleiasvirtuais/${id}/resultados`).then((response) => {
        setResult(response.data.resultado);
        setPautas(response?.data?.resultado?.pautas);
      });
    },
    [id],
  );

  const getComments = (idPauta : string) => {
    request.get(`exibirComentariosAssembleia/${idPauta}`).then((response) => {
      const { resultado } = response.data;
      if (response.data.ok === 'Sucesso') {
        if (resultado.length === 0) {
          alert('Nenhum comentário foi encontrado');
          return;
        }

        setComentarios(response.data.resultado);

        setModalVisible(true);
      }
    });
  };

  useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Resultados da assembleia'));

    getResultAssembly();
  }, [getResultAssembly, dispatch]);

  return (
    <Container>
      <h1>Dados gerais</h1>

      <div>
        <div>
          <h4>Assembleia: </h4>
          <p>{result?.tituloAssembleiaVirtual}</p>
        </div>
        <div>
          <h4>Aptos a Votar: </h4>
          <p>{result?.vinculosAtivos}</p>
        </div>
        <div>
          <h4>Inaptos a Votar: </h4>
          <p>{result?.vinculosInaptos}</p>
        </div>
      </div>

      <h1>Estatísticas</h1>
      {
          pautas?.map((p:any) => (
            <CardClean.Card key={p.pautaId}>
              <CardClean.Row>
                <CardClean.RowTitle>Título: </CardClean.RowTitle>
                <CardClean.RowText>{p.pautaTitulo}</CardClean.RowText>
              </CardClean.Row>
              <CardClean.Row>
                <CardClean.RowTitle>Descrição: </CardClean.RowTitle>
                <CardClean.RowText>{p.pautaDescricao}</CardClean.RowText>
              </CardClean.Row>
              <CardClean.Row>
                <CardClean.RowTitle>Votos de aprovação: </CardClean.RowTitle>
                <CardClean.RowText>{p.votosPositivos}</CardClean.RowText>
              </CardClean.Row>
              <CardClean.Row>
                <CardClean.RowTitle>Votos de reprovação: </CardClean.RowTitle>
                <CardClean.RowText>{p.votosNegativos}</CardClean.RowText>
              </CardClean.Row>
              <CardClean.Row>
                <CardClean.RowTitle>Abstenção: </CardClean.RowTitle>
                <CardClean.RowText>{p.abstencao}</CardClean.RowText>
              </CardClean.Row>
              <CardClean.Row>
                <CardClean.RowTitle>Total de Votos Computados: </CardClean.RowTitle>
                <CardClean.RowText>{p.votosPositivos + p.votosNegativos}</CardClean.RowText>
              </CardClean.Row>

              <button type="button" onClick={() => getComments(p.pautaId)}>Ver comentarios</button>
            </CardClean.Card>

          ))
        }

      <Modal
        isOpen={modalVisible}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalVisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-content">
          <button type="button" onClick={() => setModalVisible(false)} className="close">X</button>

          <h3>Cometários</h3>
          {comentarios.length === 0 && (
          <h4>Nenhum comentário</h4>
          )}

          {
         comentarios.map((c : any) => (
           <div style={{ marginBottom: 12 }} key={c.id}>
             <h4>{c.nomeUnidade}</h4>
             <p>{c.comentario}</p>
           </div>
         ))
        }
        </div>
      </Modal>
    </Container>
  );
}

export default Results;
