import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import Modal from 'react-modal';

import * as S from './styles';
import customStyles from '../../../../../utils/customStylesModal';
import request from '../../../../../services/request';
import Loading from '../../../../../components/Loading/Loading';

  type Props = {
    modalVisibleVote : any
    setModalVisibleVote : any
    assemblySelected : any
  }

const ModalVote: React.FC<Props> = ({
  modalVisibleVote, setModalVisibleVote, assemblySelected,
}) => {
  const [pautas, setPautas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [votesState, setVotesState] = useState<{[key: number]: string;}>({});
  const [commentPaute, setCommentPaute] = useState<any>({});

  const getPautasAssembly = useCallback(async () => {
    try {
      setLoading(true);
      if (assemblySelected) {
        const response = await request
          .get(`pautas?idAssembleiaVirtual=${assemblySelected.id}`);

        if (response.data.ok === 'Sucesso') {
          const responseVotes = await request
            .get(`votos/assembleia?id=${assemblySelected.id}`);

          if (responseVotes.data.ok === 'Sucesso') {
            const { listagem: listagemVotes } = responseVotes.data;

            const { resultados: resultadosPautas } = response.data.listagem;

            const pautasWithVotes = resultadosPautas.map((p: any) => {
              const findPautaVote = listagemVotes.find(
                (v: any) => p.id === v.pautaAssembleiaVirtual.id,
              );

              if (findPautaVote) {
                let textVote = '';
                if (findPautaVote.voto === 1)textVote = 'Você já Aprovou essa pauta';
                if (findPautaVote.voto === 0)textVote = 'Você já Reprovou essa pauta';
                if (findPautaVote.voto === 2)textVote = 'Você optou por abster-se dessa pauta';

                return {
                  voted: true,
                  comentario: findPautaVote.comentario,
                  idVoto: findPautaVote.id,
                  textVote,
                  ...p,
                };
              }

              return p;
            });

            setPautas(pautasWithVotes);
          } else {
            alert('Erro: Dados retornados inválidos');
          }
        } else {
          alert('Erro: Problema ao pegar pautas');
        }
      }
    } catch (error) {
      alert('Erro: Problema ao pegar pautas');
    } finally {
      setLoading(false);
    }
  }, [assemblySelected]);

  useEffect(() => {
    getPautasAssembly();
  }, [getPautasAssembly]);

  function handleSelectVote(index : any, vote : any) {
    const votesStateTemp : any = { ...votesState };

    votesStateTemp[index] = vote;
    setVotesState(votesStateTemp);
  }

  async function handleVote(index : number) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      try {
        const { userAgent } = navigator;

        const infoSystem = {
          userAgent,
          location: {
            latitude,
            longitude,
          },
        };

        const form = {
          idPauta: pautas[index].id,
          voto: votesState[index],
          infoSystem: JSON.stringify(infoSystem),
        };

        const response = await request.post(`votarAssembleia/${assemblySelected.id}`, form);

        if (response.data.ok) {
          await getPautasAssembly();
          alert(`Muito bem! ${response.data.ok}`);
        } else {
          alert(`Erro: ${response.data.erro}`);
        }
      } catch (error) {
        console.log(error);
        alert('Erro: Falha na requisição');
      }
    }, () => {
      setModalVisibleVote(false);
      alert('Não foi possivel pegar sua localização. Tente novamente!');
    });
  }

  function onChangeComment(idPauta : any, comment : any) {
    const commentsPautaTemp = { ...commentPaute };

    commentsPautaTemp[idPauta] = comment;

    setCommentPaute(commentsPautaTemp);
  }

  const handleNewComment = async (idVote : any, idPauta : any) => {
    try {
      setLoading(true);
      const form = {
        idVoto: idVote,
        comentario: commentPaute[idPauta],
      };
      const response = await request.post(`comentarVotoAssembleia/${assemblySelected.id}`, form);

      if (response.data.ok) {
        getPautasAssembly();
        onChangeComment(idVote, '');
        alert(`Muito bem!${response.data.ok}`);
      } else {
        alert(`Erro${response.data.erro}`);
      }
    } catch (error) {
      alert('Erro: falha ao adicionar ');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <S.Modal>
      <Modal
        isOpen={modalVisibleVote}
      // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalVisibleVote(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-content">
          <button
            type="button"
            onClick={() => setModalVisibleVote(false)}
            className="close"
          >
            X
          </button>
          <S.ModalContentVotes>
            <Text fontSize="2xl" marginBottom="24px" maxWidth="320px">
              Sessão de votos para assembleia:
              {' '}
              <strong>
                {' '}
                {assemblySelected.titulo}
              </strong>
            </Text>
            {pautas.map((p :any, index) => (
              <S.CardOptionVote key={p.id}>
                <Text fontSize="lg">
                  <strong>Pauta:</strong>
                  {' '}
                  {p.titulo}
                </Text>
                <Text fontSize="md" marginBottom="12px">
                  <strong>Descrição:</strong>
                  {' '}
                  {p.descricao}
                </Text>

                <hr />

                {p.comentario && (
                <S.CardOptionVoteComment>
                  <strong>Seu comentário:</strong>
                  {' '}
                  {p.comentario}
                </S.CardOptionVoteComment>
                )}

                {p.voted && (
                <S.WrapperVoted>
                  <p>{p.textVote}</p>
                </S.WrapperVoted>
                )}

                {!p.comentario && p.voted && (
                <Flex justifyContent="center">

                  <S.CardOptionVoteComment>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold" color="teal">
                              Fazer um comentário
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel maxWidth="400px">
                          <Textarea
                            placeholder="Escreva seu comentário"
                            value={commentPaute[p.id]}
                            onChange={(value) => {
                              onChangeComment(p.id, value.target.value);
                            }}
                          />

                          <Button
                            colorScheme="teal"
                            marginTop="12px"
                            width="100%"
                            padding="21px"
                            fontWeight="bold"
                            fontSize="16px"
                            onClick={() => handleNewComment(p.idVoto, p.id)}
                          >
                            Enviar Comentário
                          </Button>

                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>

                  </S.CardOptionVoteComment>
                </Flex>
                )}

                {!p.voted && (
                <>

                  <RadioGroup
                    onChange={(value) => {
                      handleSelectVote(index, value);
                    }}
                    value={votesState[index]}
                  >
                    <Stack direction="column">
                      <Box
                        padding="10px"
                        bg="gray.50"
                        borderWidth="1px"
                        borderColor="gray.200"
                        width="400px"
                      >
                        <Radio
                          borderColor="gray.600"
                          colorScheme="whatsapp"
                          value="1"
                        >
                          Aprovo
                        </Radio>
                      </Box>
                      <Box
                        padding="10px"
                        bg="gray.50"
                        borderWidth="1px"
                        borderColor="gray.200"
                        width="400px"
                      >
                        <Radio
                          borderColor="gray.600"
                          colorScheme="whatsapp"
                          value="0"
                        >
                          Não aprovo
                        </Radio>
                      </Box>
                      <Box
                        padding="10px"
                        bg="gray.50"
                        borderWidth="1px"
                        borderColor="gray.200"
                        width="400px"
                      >
                        <Radio
                          borderColor="gray.600"
                          colorScheme="whatsapp"
                          value="2"
                        >
                          Me Abstenho
                        </Radio>
                      </Box>
                    </Stack>
                  </RadioGroup>

                  <Button
                    colorScheme="teal"
                    size="xs"
                    marginTop="12px"
                    width="100%"
                    padding="21px"
                    fontWeight="bold"
                    fontSize="16px"
                    disabled={!votesState[index]}
                    onClick={() => handleVote(index)}
                  >
                    Enviar Voto
                  </Button>

                </>
                )}
              </S.CardOptionVote>
            ))}
          </S.ModalContentVotes>
        </div>
      </Modal>
    </S.Modal>
  );
};

export default ModalVote;
