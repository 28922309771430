import React, { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RootState from '../../interfaces/states/RootState';
import request from '../../services/request';

import { Container } from './styles';

const ResetPassword = () => {

  const { isEmployee } = useSelector((state: RootState) => state.session);

  const [currentPassword, setcurrentPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  
  async function handleSubmit(e : FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const form = {
      senhaAtual: currentPassword,
      senhaAlteracao: newPassword,
    };

    const formEmployee = {
      senhaAtual: currentPassword,
      senhaAlteracao: newPassword,
      tipoUsuario: "FUNCIONARIO"
    }

    try {
      setLoading(true);

      await request.post('alterarSenhaUser', !isEmployee ? form : formEmployee);

      toast.success('Senha alterada');
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <div className="login-box">

        <h2>Alterar Senha</h2>
        <form onSubmit={handleSubmit}>
          <label>Senha Atual</label>
          <input value={currentPassword} onChange={(text) => setcurrentPassword(text.target.value)} type="password" />
          <label>Nova Senha </label>
          <input value={newPassword} onChange={(text) => setnewPassword(text.target.value)} type="password" />
          <button type="submit">{loading ? 'Alterando...' : 'Alterar'}</button>
        </form>
      </div>
    </Container>
  );
};

export default ResetPassword;
