import { Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { useHistory } from 'react-router-dom';

type Props = {
    title : string;
    icon?: IconType;
    route_path?: string
}

const Card : React.FC<Props> = ({
  title, icon: Icon, route_path,
}) => {
  const history = useHistory();

  return (
    <Flex
      w="237px"
      h="130px"
    // m="2"
      bg="white"
      borderRadius="14px"
      boxShadow="0px 3px 20px #00000014"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="0.125rem"
      mr="8px"
      _hover={{ cursor: 'pointer', backgroundColor: 'white.100' }}
      onClick={() => route_path && history.push(route_path)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        bg="#E5F5F0"
        borderRadius="50%"
        h="55px"
        w="55px"
      >
        {!!Icon && (
        <Icon size="28px" color="#009B6E" />
        )}

      </Flex>
      <Text
        fontSize="15px"
        marginTop="0.375rem"
        textAlign="center"
        fontFamily="Montserrat"
        fontWeight="bold"
        maxWidth="95%"
      >
        {title}
      </Text>
    </Flex>
  );
};

export default Card;
