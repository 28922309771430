import React from "react";
import Modal from "react-modal";

import Button from "../../../../components/Button/Button";

import { TitleHeader, Wrapper, customStyles } from "./styles";

type Props = {
  data: any;
  onClick?: any;
  modalVisible: boolean;
  setModalVisible: (e: boolean) => void;
};

const ModalPix: React.FC<Props> = ({
  data,
  onClick,
  modalVisible,
  setModalVisible,
}) => {
  return (
    <Modal
      isOpen={modalVisible}
      style={customStyles}
      className="contentCustom"
      overlayClassName="overlayCustom"
      onRequestClose={() => setModalVisible(false)}
    >
      <div className="modal-content">
        <button type="button" className="close" onClick={() => setModalVisible(false)}>
          X
        </button>

        <TitleHeader>Pagar com Pix</TitleHeader>

        <Wrapper>
          <img src={data?.qrcodepix_base64} style={{ width: 300 }} />

          <Button
            theme="primary"
            onClick={onClick}
            marginVertical={20}
            style={{ minWidth: 300, fontSize: 13 }}
          >
            Copiar Código do Pix
          </Button>
        </Wrapper>
      </div>
    </Modal>
  );
};

export default ModalPix;
