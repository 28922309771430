import React, { ReactText } from 'react';
import {
  Flex,
  Icon,
  FlexProps,
} from '@chakra-ui/react';

import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';

interface NavItemProps extends FlexProps {
  icon: IconType;
  hash: string;
  children: ReactText;
}
const NavItem = ({
  icon, children, hash, ...rest
}: NavItemProps) => {

  const { pathname } = useLocation();

  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      style={
        pathname === `/${hash}` || (hash === '/' && pathname.includes('portal_funcionario')) || (hash === 'visitas_cadastradas' && pathname.includes('cadastro_visita')) || (hash === 'visitas_cadastradas' && pathname.includes('listar_visitas')) || (hash === 'consumos_funcionario' && pathname.includes('gravar_leituras')) || (hash === 'consumos_funcionario' && pathname.includes('listar_consumos'))
          ? { backgroundColor: '#4B9688', color: 'white' }
          : {}
      }
      _hover={{
        bg: hash === 'sair' ? '#f21515' : '#4B9688',
        color: 'white',
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="31"
          color={hash === 'sair' ? '#f21515'
            : pathname === `/${hash}` || (hash === '/' && pathname.includes('portal_funcionario')) || (hash === 'visitas_cadastradas' && pathname.includes('cadastro_visita')) || (hash === 'visitas_cadastradas' && pathname.includes('listar_visitas')) || (hash === 'consumos_funcionario' && pathname.includes('gravar_leituras')) || (hash === 'consumos_funcionario' && pathname.includes('listar_consumos'))
              ? '#ffffff'
              : '#616161'}
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

export default NavItem;
