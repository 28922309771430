import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { CardClean } from '../../components/Card/Card';
import { GridCard } from '../../components/GridCard/styles';
import Loading from '../../components/Loading/Loading';
import { ISurvey } from '../../interfaces/responses/ISurveis';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import Legend from './Legend';
import { ListEmpty, ButtonAdd, Container } from './styles';

const ListSurveis = () => {
  const histoty = useHistory();
  const dispatch = useDispatch();
  const [surveys, setSurveys] = React.useState<Array<ISurvey>>([]);
  const [loading, setLoading] = React.useState(false);

  const getList = React.useCallback(async () => {
    setLoading(true);
    request.get('enquetes').then((response) => {
      if (response.data.ok === 'Sucesso') {
        setSurveys(response.data.listagem.resultados);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Enquetes'));
  }, [dispatch]);

  React.useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Container>
      <div>
        <Legend label="Enquente aberta" color="#FEA500" />
        <Legend label="Enquente Finalizada" color="#008001" />

        <ButtonAdd>Criar enquete</ButtonAdd>
      </div>
      {
       loading && (
       <Loading />
       )
      }
      {surveys.length === 0 && !loading && (
      <ListEmpty>
        <p>Nenhuma enquente encontrada.</p>

      </ListEmpty>
      )}
      <GridCard>
        {
          surveys.map((s) => (
            <CardClean.Card key={s.id}>
              <CardClean.Row>
                <CardClean.RowTitle>Nome:</CardClean.RowTitle>
                <CardClean.RowText>{s.titulo}</CardClean.RowText>
              </CardClean.Row>
              <Button
                onClick={() => histoty.push(`details_survey/${s.id}`)}
                marginTop={20}
                theme="primary"
              >
                Ver enquete
              </Button>
            </CardClean.Card>
          ))
        }
      </GridCard>
    </Container>
  );
};

export default ListSurveis;
