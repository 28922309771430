import React, { forwardRef, InputHTMLAttributes } from 'react';

import { Container, Label } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
  label?:string;
  marginTop?:number;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  readonly?: boolean
  placeholder?: string;
  maxLength?:number;
  fontColor?:string;
  style?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
}
const Input = forwardRef<HTMLInputElement, Props>(({
  label,
  marginTop = 8,
  value,
  onChange,
  readOnly,
  placeholder,
  maxLength,
  fontColor,
  style,
  styleContainer,
  ...rest
}, ref) => (
  <Container data-margintop={marginTop} data-color={fontColor} data-readonly={readOnly} style={styleContainer}>
    {label && <Label>{label}</Label>}
    <input
      ref={ref}
      style={style}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      maxLength={maxLength}
      {...rest}
    />
  </Container>
));

export default Input;
