import styled from 'styled-components';

export const Modal = styled.div``;

export const ModalContentVotes = styled.div`
  max-height: calc(100vh - 200px);
`;

export const CardOptionVote = styled.div`
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 10px;
  min-width: 400px;
`;

export const CardOptionVoteComment = styled.div`
  margin-top: 12px;
  max-width: 350px;
`;

export const WrapperVoted = styled.div`
  background: red;
  text-align: center;
  margin-top: 12px;

  p {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }
`;
