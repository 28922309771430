import styled from 'styled-components';
import { COLORS } from '../../../../../utils/constants';

export const Container = styled.div`
  height: 502px;
  width: 465px;

  form {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      input {
        width: 48%;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #999;
        padding-left: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
    }

    button {
      border: 0;
      background: ${COLORS.PRIMARY};
      height: 40px;
      padding: 0 10px;
      border-radius: 6px;

      color: #fff;
      font-weight: bold;
      font-size: 12px;
      box-shadow: -2px 3px 4px 0px rgba(143, 143, 143, 1);

      &:disabled {
        background: #999;
        cursor: progress;
      }
    }

    .cancel {
        margin-right : 12px;
        background: #fff;
        border : 1px solid ${COLORS.PRIMARY};
        font-weight : bold;
        color : ${COLORS.PRIMARY};
    }
  }

  ul {
    padding-left: 0;
    padding-bottom: 40px;
  }
`;

export const PautaItem = styled.li`
  list-style: none;
  position: relative;

  > div {
    background: #fff;
    box-shadow: 0px 0px 5px 2px rgba(186, 186, 186, 1);
    border-radius: 6px;
    padding: 20px 10px;

    margin-bottom: 12px;
    p {
      margin: 0;
      font-weight: bold;
      color: #333333;
      font-size: 16px;
    }

    span {
      font-weight: 500;
      color: #666;
      font-size: 12px;
    }

    footer {
      display: flex;
      justify-content: flex-end;

      button {
        padding: 6px 12px;
        border : 0;
        border-radius : 6px;
        box-shadow: -2px 3px 4px 0px rgba(143, 143, 143, 1);
        font-weight : bold;
        color : #fff;
      }
      .edit {
        margin-right: 12px;
        background : #15598C;
      }
      .delete {
        background : #EA1E2B;
      }
    }
  }
`;
