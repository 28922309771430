import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

export const FlexIdeia = styled(Flex)`
    padding: 18px 16px 10px 46px;
    margin-top: 20px;
    font-family: Montserrat;
    font-size: 16px;
    background-color: #F4F4F4;
    border-top: 2px solid #009688;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: space-between;
        gap: 7px;
        color: #000;
    }

    @media screen and (max-width: 768px) {
        background-color: #009688;
        color: #fff;
        gap: 10px;
        font-weight: 600;
    }
`;