import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/CheckBox/CheckBox';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import request from '../../../services/request';
import { ActionsHeader } from '../../../store/ducks/header';
import { convertFileToBase64 } from '../../../utils/functions';
import { useHistory } from 'react-router-dom';
import { removeButtonStyle } from './styles';

function CreateExpense() {
  const dispatch = useDispatch();
  const [isScheduling, setIsScheduling] = React.useState(false);
  const [document, setDocument] = React.useState('');
  const [desctiption, setDescription] = React.useState('');
  const [obs, setObs] = React.useState('');
  const [emissionDate, setEmissionDate] = React.useState('');
  const [dueDate, setDueDate] = React.useState('');
  const [paymentDate, setPaymentDate] = React.useState('');
  const [valueTypeExpence, setValueTypeExpence] = React.useState<string | number>();
  const [valuePaymentMethod, setValuePaymentMethod] = React.useState<string | number>();
  const [valueProvider, setValueProvider] = React.useState<string | number>();
  const [amount, setAmount] = React.useState('');
  const [amountPaid, setAmountPaid] = React.useState('');
  const [imageDocument, setImageDocument] = React.useState<string | undefined>();
  const [imageReceipt, setImageReceipt] = React.useState<string | undefined>();
  const fileInputRefDocument = React.useRef<HTMLInputElement>(null);
  const fileInputRefReceipt = React.useRef<HTMLInputElement>(null);
  const [disabledButton, setDisabled] = React.useState(false);
  const history = useHistory();
  const [buttonText, setButtonText] = React.useState('Salvar');

  const [typeExpense, setTypeExpense] = React.useState<Array<any>>([]);
  const [paymentMethod, setPaymentMethod] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Nova despesa'));

    request.get('arrayDespesaFornecedorBancario').then((response) => {
      if (response.data.ok === 'Sucesso') {
        const { despesas, dadosBancarios } = response.data.listagem;
        const typeExpTemp = despesas.map((i: any) => ({
          value: i.id,
          label: `${i.codigo} - ${i.descricao}`,
        }));
        const paymentMethTemp = dadosBancarios.map((i: any) => ({
          value: i.id,
          label: i.descricaoCompleta,
        }));

        setTypeExpense(typeExpTemp);
        setPaymentMethod(paymentMethTemp);
      }
    });
  }, [dispatch]);

  React.useEffect(() => {
    let intervalId: number; 

    if (disabledButton) {
        let dots = 0;
        intervalId = setInterval(() => {
            dots = (dots + 1) % 4; 
            const dotsText = '.'.repeat(dots);
            setButtonText(`Salvando${dotsText}`);
        }, 500); 
    }

    return () => {
        clearInterval(intervalId); 
    };
  }, [disabledButton]);

  const formatCurrency = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const formattedValue = (Number(numericValue) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedValue;
  };

  const handleSubmit = () => {
    setDisabled(true);
    setButtonText('Salvando...');
    const routeApi = isScheduling ? 'gerarAgendamentoDespesas' : 'gerarDespesas';
    const form = {
      numeroDocumento: document,
      descricao: desctiption,
      observacoes: obs,
      dataEmissao: emissionDate,
      dataVencimento: dueDate,
      dataPagamento: paymentDate,
      despesas: valueTypeExpence || typeExpense[0].value,
      dadosBancarios: valuePaymentMethod || paymentMethod[0].value,
      fornecedores: valueProvider,
      valor: amount.replace(/\D/g, ''),
      valorPago: amountPaid.replace(/\D/g, ''),
      fotoDocumento: imageDocument,
      fotoComprovante: imageReceipt,
    };
    request.post(routeApi, form).then((response) => {
      if (response.data.ok) {
        toast.success('Muito bem, Despesa salva com sucesso');
        history.push('/list_expenses');
      } else if (response.data.erro) {
        toast.error(response.data.erro);
        setDisabled(false);
        setButtonText('Salvar');
      } else {
        toast.error('Ops, algo de errado aconteceu ao tentar salvar a despesa');
        setDisabled(false);
        setButtonText('Salvar');
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
        setDisabled(false);
        setButtonText('Salvar');
      }
    });
  };

  const handleFileRemoveDocument = () => {
    setImageDocument(undefined);
    if (fileInputRefDocument.current) {
      fileInputRefDocument.current.value = ''; 
    }
  };

  const handleFileRemoveReceipt = () => {
    setImageReceipt(undefined);
    if (fileInputRefReceipt.current) {
      fileInputRefReceipt.current.value = ''; 
    }
  };

  return (
    <div style={{ width: "95%", margin: '0 auto' }}>
      <div style={{ width: "100%" }}>
        <CheckBox label="Apenas agendar uma despesa?" checked={isScheduling} onChange={() => setIsScheduling(!isScheduling)} checkboxOrder='1' marginLeft={10} />
      </div>
      <Input label="Número do documento" value={document} onChange={(e: any) => setDocument(e.target.value)} style={{ width: "100%" }} />
      <Input label="Descrição" value={desctiption} onChange={(e: any) => setDescription(e.target.value)} style={{ width: "100%" }} />
      <Input label="Observação" value={obs} onChange={(e: any) => setObs(e.target.value)} style={{ width: "100%" }} />
      <Input type="date" label="Data emissão" value={emissionDate} onChange={(e: any) => setEmissionDate(e.target.value)} style={{ width: "100%" }} />
      <Input type="date" label="Data vencimento" value={dueDate} onChange={(e: any) => setDueDate(e.target.value)} style={{ width: "100%" }} />
      <Input type="date" label="Data pagamento" value={paymentDate} onChange={(e: any) => setPaymentDate(e.target.value)} style={{ width: "100%" }} />
      <Select
        options={typeExpense}
        value={valueTypeExpence}
        label="Despesa"
        onChange={(e) => setValueTypeExpence(e.target.value)}
        style={{ width: "100%" }} />
      <Select
        options={paymentMethod}
        value={valuePaymentMethod}
        label="Pagamento"
        onChange={(e) => setValuePaymentMethod(e.target.value)}
        style={{ width: "100%" }} />
      <Select options={[]} value={valueProvider} label="Fornecedor" onChange={(e) => setValueProvider(e.target.value)} style={{ width: "100%" }} />
      <Input label="Valor R$" value={amount} onChange={(e: any) => setAmount(formatCurrency(e.target.value))} style={{ width: "100%" }} />
      <Input label="Valor pago R$" value={amountPaid} onChange={(e: any) => setAmountPaid(formatCurrency(e.target.value))} style={{ width: "100%" }} />
      <div style={{ position: 'relative', width: '100%' }}>
        <Input
          type="file"
          label="Foto do documento"
          accept=".png, .jpg, .jpeg"
          ref={fileInputRefDocument} 
          onChange={(e: any) => convertFileToBase64(e.target.files[0], setImageDocument)}
          style={{ width: "100%", color: "black" }}
        />
        {imageDocument && (
          <Button
            theme="secondary"
            onClick={handleFileRemoveDocument}
            style={removeButtonStyle}
          >
            Remover
          </Button>
        )}
      </div>
      <div style={{ position: 'relative', width: '100%' }}>
        <Input
          type="file"
          label="Foto do comprovante"
          accept=".png, .jpg, .jpeg"
          ref={fileInputRefReceipt} 
          onChange={(e: any) => convertFileToBase64(e.target.files[0], setImageReceipt)}
          style={{ width: "100%", color: "black" }}
        />
        {imageReceipt && (
          <Button
            theme="secondary"
            onClick={handleFileRemoveReceipt}
            style={removeButtonStyle}
          >
            Remover
          </Button>
        )}
      </div>
      <Button theme="secondary" marginTop={10} onClick={handleSubmit} style={{ width: "100%", pointerEvents: disabledButton ? "none" : "auto", opacity: disabledButton ? 0.6 : 1 }} disabled={disabledButton}>{buttonText}</Button>
    </div>
  );
}

export default CreateExpense;
