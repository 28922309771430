import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { toast } from 'react-toastify';

import Button from '../../components/Button/Button';
import IReserve from '../../interfaces/responses/IReserve';
import { ActionsHeader } from '../../store/ducks/header';
import { getListReserves, getListUnavailable } from './services';
//import Input from '../../components/Input/Input';
import request from '../../services/request';
import SelectAreas, { Options } from './components/SelectAreas';
import customStyles from '../../utils/customStylesModal';
import { Divisor, Table } from '../../components/Table/styles';

import {
  Header,
  FormAddReseve,
  Container,
  MessageEmpty,
  ContainerTable,
  CalendarContainer,
  LegendContainer,
  Ballet,
  FormReportContainer,
  Wrapper,
} from './styled';
import { goToExternalLink } from '../../utils/functions';
import Loading from '../../components/Loading/Loading';
import RootState from '../../interfaces/states/RootState';
import ModalSuccess from './components/ModalSuccess';
import ModalManager from './components/ModalManager';
import { Input } from '@chakra-ui/react';

const initialDate = new Date();
const initialMonth = initialDate.getMonth() + 1;
const initialYear = initialDate.getFullYear();

const CalendarReserves = () => {
  const dispatch = useDispatch();
  const calendarRef = React.useRef<any>();

  const isManager = useSelector((state: RootState) => state.session.isManager);

  const [reserves, setReserves] = React.useState<Array<IReserve>>([]);
  const [unavailable, setUnavailable] = React.useState<Array<IReserve>>([]);
  const [reservesFilter, setReservesFilter] = React.useState<Array<IReserve>>(
    [],
  );
  const [year, setYear] = React.useState(initialYear);
  const [month, setMonth] = React.useState(initialMonth);
  const [modalvisible, setModalvisible] = React.useState(false);
  const [dateReserve, setDateReserve] = React.useState('');
  const [initalHour, setInitalHour] = React.useState('');
  const [finalHour, setFinalHour] = React.useState('');
  const [initiDateReport, setInitiDateReport] = React.useState('');
  const [finalDateReport, setfinalDateReport] = React.useState('');
  const [emailReport, setEmailReport] = React.useState('');
  const [area, setArea] = React.useState('');
  const [areasCumuns, setAreasCumuns] = React.useState<Options>([]);
  const [dateSelected, setDateSelected] = React.useState('');
  const [loadingGetReserves, setLoadingGetReserves] = React.useState(false);
  const [loadingCreateReserve, setLoadingCreateReserve] = React.useState(false);
  const [showModalCreateReserverSuccess, setShowModalCreateReserverSuccess] = React.useState(false);
  const [saveId, setSaveId] = React.useState(0);
  const [modalVisible2, setModalVisible2] = React.useState(false);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Reservas'));
  }, [dispatch]);

  React.useEffect(() => {
    getListReserves().then((response) => {
      setReserves(response);
    });
    getListUnavailable().then((response) => {
      setUnavailable(response);
    });
  }, []);

  React.useEffect(() => {
    function getAreasCumuns() {
      request
        .get('listarAreaComum')
        .then((response) => {
          if (response.data.ok === 'Sucesso') {
            const { listagem } = response.data;

            const listAreaTemp = listagem.map((i: any) => ({
              value: i.id,
              label: i.nome,
              descricao: i.descricao,
            }));

            setAreasCumuns(listAreaTemp);
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error(
              'Falha na rede. : Verifique sua conexão e tente novamente.',
            );
          } else {
            toast.error('Erro : A requisição falhou');
          }
        });
    }

    getAreasCumuns();
  }, []);

  async function handleCreateReserve() {
    try {
      setLoadingCreateReserve(true);
      const form = {
        dataReserva: format(
          parseISO(dateReserve || dateSelected),
          'dd/LL/uuuu',
        ),
        horaInicial: initalHour,
        horaFinal: finalHour,
      };

      const response = await request.post(`reservarAreaComum/${area}`, form);
      if (response.data.ok) {
        setModalvisible(false);
        setShowModalCreateReserverSuccess(true);
        getListReserves().then((responseReserves) => {
          setReserves(responseReserves);
        });
      } else {
        alert(response.data.erro);
      }
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        toast.error(
          'Falha na rede. : Verifique sua conexão e tente novamente.',
        );
      } else {
        toast.error('Erro : A requisição falhou');
      }
    } finally {
      setLoadingCreateReserve(false);
    }
  }

  function getColorStatus(status: string) {
    switch (status) {
      case 'CANCELADO':
        return 'red';
      case 'PRE_RESERVADO':
        return 'blue';
      case 'CONFIRMADO':
        return 'green';
      case 'INDISPONIVEL':
        return 'purple';
      default:
        return 'gray';
    }
  }

  function getReserveStatus(status: string): string {
    if (status === 'PRE_RESERVADO') {
      return 'PRE RESERVADO';
    }

    return status;
  }
  function handleModalOpen() {
    if (!area) {
      alert('Você deve escolher uma área');

      return;
    }
    setModalvisible(true);
  }

  const reservesMonthList = React.useMemo(() => {
    const reservesMonth = reserves.filter(
      (r) => Number(r.dataReserva.split('-')[1]) === month
        && Number(r.dataReserva.split('-')[0]) === year,
    );

    const unavailableMonth = unavailable.filter(
      (r) => Number(r.dataReserva.split('-')[1]) === month
        && Number(r.dataReserva.split('-')[0]) === year,
    );

    const combine = [...reservesMonth, ...unavailableMonth]

    return combine;
  }, [reserves, month, year]);

  const reservesMonthListFilter = React.useMemo(() => {
    const reservesMonth = reservesFilter.filter(
      (r) => Number(r.dataReserva.split('-')[1]) === month
        && Number(r.dataReserva.split('-')[0]) === year,
    );

    const unavailableMonth = unavailable.filter(
      (r) => Number(r.dataReserva.split('-')[1]) === month
        && Number(r.dataReserva.split('-')[0]) === year,
    );

    const combine = [...reservesMonth, ...unavailableMonth]

    return combine;
  }, [reservesFilter, month, year]);

  const eventsCalendar = React.useMemo(() => {
    const eventsCalendarTempReserves = reserves
      .filter(
        (r) => new Date(r.dataReserva).getMonth() + 1 === month
          && new Date(r.dataReserva).getFullYear() === year,
      )
      .map((r) => ({
        id: r.id as any,
        title: r.nomeAreaLazer,
        start: r.dataReserva as any,
        display: 'list-item',
        editable: false,
        backgroundColor: getColorStatus(r.status),
      }));

    const eventsCalendarTempUnavailable = unavailable
      .filter(
        (r) => new Date(r.dataReserva).getMonth() + 1 === month
          && new Date(r.dataReserva).getFullYear() === year,
      )
      .map((r) => ({
        id: r.id as any,
        title: r.nomeAreaLazer,
        start: r.dataReserva as any,
        display: 'list-item',
        editable: false,
        backgroundColor: getColorStatus(r.status),
        motivo: r.motivo
      }));

    const eventsCalendarTemp = [...eventsCalendarTempReserves, ...eventsCalendarTempUnavailable];

    return eventsCalendarTemp;
  }, [reserves, month, year]);

  const titleModalFormReserve = React.useMemo(() => {
    let titleTemp = 'Faça sua reserva';

    if (area) {
      titleTemp = `Área ${area}`;

      const reserveFind = areasCumuns.find((r) => r.value === Number(area));

      if (reserveFind) {
        titleTemp = reserveFind.label;
      }
    }

    return titleTemp;
  }, [areasCumuns, area]);

  const descAreaComunSelected = React.useMemo(() => {
    let titleTemp = 'Selecione uma área.';

    if (area) {
      const reserveFind = areasCumuns.find((r) => r.value === Number(area));

      if (reserveFind) {
        titleTemp = reserveFind.descricao;
      }
    }

    return titleTemp;
  }, [areasCumuns, area]);

  function calendarChangeMonth() {
    const calendarApi = calendarRef?.current?.getApi();

    if (calendarApi) {
      setMonth(Number(calendarApi.getDate().toISOString().split('-')[1]));
      setYear(Number(calendarApi.getDate().toISOString().split('-')[0]));
    }
  }

  const filterReservesDay = React.useCallback(
    (dateSelectedParam) => {
      setReservesFilter(
        reserves.filter((o) => o.dataReserva === dateSelectedParam),
      );
      setDateSelected(dateSelectedParam);
    },
    [reserves],
  );

  async function sendReportReservesViaEmail() {
    if (!initiDateReport || !finalDateReport || !emailReport) {
      alert(
        'Atenção, Todos os campos precisam ser preenchidos para enviar o relatório',
      );
      return;
    }

    try {
      setLoadingGetReserves(true);
      await request.post('listarReservasRealizadasPrint', {
        dataInicio: initiDateReport,
        dataFim: finalDateReport,
        email: emailReport,
      });
      toast.success('Relatório enviado com sucesso');

      setfinalDateReport('');
      setInitiDateReport('');
      setEmailReport('');
    } catch (error) {
      toast.warn('Atenção, Houve um erro em enviar seu email!');
    } finally {
      setLoadingGetReserves(false);
    }
  }

  const handleModal = (id: number) => {
    setSaveId(id)
    setModalVisible2(true);
  }

  const handleAlert = (dateStr: string) => {

    if (!unavailable[0]) {
      return;
    }

    const filtered = unavailable.find((u) => u.dataReserva === dateStr)

    if (filtered) {
      alert(`Atenção: ${filtered.nomeAreaLazer} indisponível nesta data.
      \nMotivo: ${filtered.motivo}`)
    }
  }

  return (
    <Wrapper>
      {loadingGetReserves && <Loading />}

      {isManager && (
        <>
          <h1 style={{ fontSize: 24, fontWeight: 'bold', marginTop: 24 }}>
            Relatório Reservas via Email
          </h1>
          <FormReportContainer>
            <Input
              marginTop={18}
              value={initiDateReport}
              label="Data Inicio"
              onChange={(e: any) => setInitiDateReport(e.target.value)}
              maxLength={7}
              type="date"
            />
            <Input
              marginTop={18}
              value={finalDateReport}
              label="Data Fim"
              onChange={(e: any) => setfinalDateReport(e.target.value)}
              maxLength={7}
              type="date"
            />
            <Input
              marginTop={18}
              value={emailReport}
              label="Email"
              name="email"
              onChange={(e: any) => setEmailReport(e.target.value)}
            />

            <Button
              theme="primary"
              style={{
                height: 38,
                fontWeight: 'bold',
                fontSize: 16,
                width: 102,
              }}
              onClick={sendReportReservesViaEmail}
            >
              Enviar
            </Button>
          </FormReportContainer>
          <hr />
        </>
      )}

      <h1 style={{ fontSize: 24, fontWeight: 'bold', marginTop: 24 }}>
        Calendário de Reservas
      </h1>

      <LegendContainer>
        <div>
          <p>CONFIRMADO</p>
          <Ballet color="green" />
        </div>
        <div>
          <p>PRE RESERVADO</p>
          <Ballet color="blue" />
        </div>
        <div>
          <p>CANCELADO</p>
          <Ballet color="red" />
        </div>
      </LegendContainer>

      <Container>
        {!isManager && (
          <Header>
            <h4>Faça sua reserva</h4>

            <div>
              <SelectAreas
                value={area}
                options={[
                  { value: '', label: 'Selecione a Área', descricao: '' },
                  ...areasCumuns,
                ]}
                label="Área comum"
                onChange={(e) => setArea(e.target.value)}
              />

              <p>
                <h4>Regimento</h4>
                {descAreaComunSelected}
              </p>

              <Button
                onClick={handleModalOpen}
                theme="primary"
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                  fontWeight: 'bold',
                  fontSize: 16,
                  marginTop: 'auto',
                }}
              >
                Fazer reserva
              </Button>
            </div>
          </Header>
        )}

        <CalendarContainer>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today',
            }}
            buttonText={{
              today: 'Hoje',
            }}
            ref={calendarRef}
            initialView="dayGridMonth"
            editable
            selectable={true}
            selectMirror
            dayMaxEvents={false}
            eventDisplay="list-item"
            progressiveEventRendering
            initialEvents={eventsCalendar}
            events={eventsCalendar}
            dayMaxEventRows={false}
            locale="pt-BR"
            timeZone="America/Sao_Paulo"
            datesSet={calendarChangeMonth}
            dateClick={({ dateStr }) => {
              filterReservesDay(dateStr);
              handleAlert(dateStr);
            }}
          />
        </CalendarContainer>
      </Container>

      {/* LIS RESERVES */}
      <hr style={{ marginTop: 24 }} />
      <h1 style={{ fontSize: 24, fontWeight: 'bold', marginTop: 24 }}>
        Lista de Reservas
      </h1>
      <ContainerTable>
        {dateSelected && (
          <Button
            style={{ marginBottom: 6, fontWeight: 'bold', fontSize: 12 }}
            theme="primary"
            onClick={() => setDateSelected('')}
          >
            Mudar lista para Mês
          </Button>
        )}
        <Table>
          <thead>
            <tr>
              <th>Unidade</th>
              <th>Área de lazer</th>
              <th>Data</th>
              <th>De</th>
              <th>Até</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <Divisor colSpan={7} />
          <tbody>
            {reservesMonthListFilter.length === 0 && dateSelected ? (
              <MessageEmpty colSpan={7}>
                Não existe registros para esse dia
              </MessageEmpty>
            ) : (
              dateSelected
              && reservesMonthListFilter.map((r) => (
                <tr key={r.id}>
                  <td>{r.unidade}</td>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderBottomColor: 'transparent',
                    }}
                  >
                    <Ballet color={getColorStatus(r.status)} />
                    {r.nomeAreaLazer}
                  </td>
                  <td>{r.dataReserva}</td>
                  <td>{r.horaInicio}</td>
                  <td>{r.horaTermino}</td>
                  <td
                    style={{
                      fontWeight: 'bold',
                      color: getColorStatus(r.status),
                    }}
                  >
                    {getReserveStatus(r.status)}
                  </td>
                  <td>
                    {r.urlTermo && (
                      <Button
                        theme="primary"
                        style={{ fontWeight: 'bold', fontSize: 12, margin: "0 auto", marginBottom: 5 }}
                        onClick={() => goToExternalLink(r.urlTermo)}
                      >
                        Ver Termo
                      </Button>
                    )}
                    {isManager && (
                      <>
                        <Button
                          theme="primary"
                          style={{ fontWeight: 'bold', fontSize: 12 }}
                          onClick={() => handleModal(r.id)}
                        >
                          Confirmar/Cancelar
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
            {reservesMonthList.length === 0 && !dateSelected ? (
              <MessageEmpty colSpan={7}>
                Não existe registros para esse mês
              </MessageEmpty>
            ) : (
              !dateSelected
              && reservesMonthList.map((r) => (
                <tr key={r.id}>
                  <td>{r.unidade}</td>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderBottomColor: 'transparent',
                    }}
                  >
                    <Ballet color={getColorStatus(r.status)} />
                    {r.nomeAreaLazer}
                  </td>
                  <td>{r.dataReserva}</td>
                  <td>{r.horaInicio}</td>
                  <td>{r.horaTermino}</td>
                  <td
                    style={{
                      fontWeight: 'bold',
                      color: getColorStatus(r.status),
                    }}
                  >
                    {getReserveStatus(r.status)}
                  </td>
                  <td>
                    {r.urlTermo && (
                      <Button
                        theme="primary"
                        style={{ fontWeight: 'bold', fontSize: 12, margin: "0 auto", marginBottom: 5 }}
                        onClick={() => goToExternalLink(r.urlTermo)}
                      >
                        Ver Termo
                      </Button>
                    )}
                    {isManager && (
                      <>
                        <Button
                          theme="primary"
                          style={{ fontWeight: 'bold', fontSize: 12 }}
                          onClick={() => handleModal(r.id)}
                        >
                          Confirmar/Cancelar
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </ContainerTable>

      <ModalManager reserveId={saveId} modalVisible={modalVisible2} setModalVisible={setModalVisible2} setReserves={setReserves} />

      <Modal
        isOpen={modalvisible}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-content">
          <button
            type="button"
            onClick={() => setModalvisible(false)}
            className="close"
          >
            X
          </button>
          <FormAddReseve>
            <h1>{titleModalFormReserve}</h1>

            <Input
              marginTop={18}
              value={dateReserve || dateSelected}
              label="Data da reserva"
              onChange={(e: any) => setDateReserve(e.target.value)}
              maxLength={7}
              type="date"
              min={new Date().toLocaleDateString('en-ca')}
            />

            <Input
              marginTop={18}
              value={initalHour}
              label="Horário de início"
              maxLength={7}
              onChange={(e: any) => setInitalHour(e.target.value)}
              type="time"
            />

            <Input
              marginTop={18}
              value={finalHour}
              label="Horário de término"
              maxLength={7}
              onChange={(e: any) => setFinalHour(e.target.value)}
              type="time"
            />

            <button type="button" onClick={handleCreateReserve} disabled={loadingCreateReserve}>
              {loadingCreateReserve ? (
                <div className="loader" />
              ) : (

                'Salvar'
              )}
            </button>
          </FormAddReseve>
        </div>
      </Modal>

      {showModalCreateReserverSuccess && (
        <ModalSuccess
          handleClose={() => setShowModalCreateReserverSuccess(false)}
        />
      )}
    </Wrapper>
  );
};

export default CalendarReserves;
