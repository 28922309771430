import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
display: flex;
flex-direction: column;
margin-top: 4rem;

> div {
    display: flex;
    align-items: center;
}
`;

export const YearComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`;

export const ListEmpty = styled.div`
  display: flex;

  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(212,212,212,0.73);
  margin-top: 12px;
  border-radius: 12px;
  padding: 12px;

  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const ButtonAdd = styled.button`
border : 0;
background: ${COLORS.PRIMARY};
padding: 12px;
box-shadow: 0px 0px 6px 0px rgba(212,212,212,0.73);
border-radius: 6px;
margin-left: auto;

color : #fff;
font-weight: bold;
font-size: 18px;
`;
