import styled from "styled-components";
import { COLORS } from "../../../../../utils/constants";

export const DropDown = styled.div`
    width: calc(1080px * (41/100));
    height: calc(1080px * (41/100) / (16 / 9));
    position: relative;
    border: 2px dashed ${COLORS.PRIMARY};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 5px 0;

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
  }
`;

export const DropDownText = styled.p`
    font-size: 14px;
    text-align: center;
    padding: 0 5px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const ButtonConfirm = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
    background-color: ${COLORS.PRIMARY};
    padding: 5px;
    border-radius: 6px;
    border: 2px solid #fff;
    color: #fff;
    box-shadow: 3px 3px 8px #222;

    @media screen and (max-width: 520px) {
        bottom: 15px;
        right: 25px;
    }
`;