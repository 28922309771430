/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import request from '../../services/request';
import { dateStringToDateFormat } from '../../utils/dates';

import {
  Container,
  AgreementConfigForm,
  ModalContent,
  TableFlex1,
  Load,
  TableFlex2,
  DiscountAlert,
  SectionTitle,
  Row,
} from './styles';
import customStyles from '../../utils/customStylesModal';
import { ActionsHeader } from '../../store/ducks/header';
import Loading from 'react-loading';
import { COLORS } from '../../utils/constants';
import { useWindowSize } from '../Login';
import Input from '../../components/Input/Input';
import { maskBRL } from '../../utils/masks';

const discounts = [
  { value: 1, label: 'à vista c/ desconto de 50%' },
  { value: 2, label: '1 + 1 c/ desconto de 40%' },
  { value: 3, label: '1 + 2 c/ desconto de 30%' },
  { value: 4, label: '1 + 3 c/ desconto de 20%' },
];

const AutoAcordo: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const size = useWindowSize();

  const [debitos, setDebitos] = React.useState([]);
  const [codeSms, setCodeSms] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [totais, setTotais] = React.useState({} as any);
  const [numParcelas, setNumParcelas] = React.useState(1);
  const [verificado, setVerificado] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [valorEntrada, setValorEntrada] = React.useState(0);
  const [valorEntradaInput, setValorEntradaInput] = React.useState('0');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [toggleTable1, setToggleTable1] = React.useState(false);
  const [toggleTable2, setToggleTable2] = React.useState(false);
  const [dadosContado, setDadosContato] = React.useState({} as any);
  const [tokenSolicitado, setTokenSolicitado] = React.useState(false);
  const [valorAcordadoJuros, setValorAcordadoJuros] = React.useState(0);
  const [valorParcelasRestantes, setValorParcelasRestantes] = React.useState(0);
  const [valorEntradaFoiAlterado, setValorEntradaFoiAlterado] = React.useState(false);

  const formatCash = (value: any) => `${'R$ ' + String(value).replace(".", ",")}`;
  const formatCashToNumber = (value: any) => Number(value.replace(".", "").replace(".", "").replace(",", "."));
  const getValorEntrada = (numeroParcelas: number) => Number((getValorAcordado(numeroParcelas) / numeroParcelas).toFixed(2));
  const getValorAcordado = (numeroParcelas: number) => totais.totalDevido - getDesconto(numeroParcelas, totais.totalDespCob);

  const formatCashRegex = (value: any) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100)

    return 'R$ ' + result
  };
    
  const getDesconto = (numeroParcelas: number, totalDespCob: number) => {
    if (totalDespCob) {
      switch (numeroParcelas) {
        case 1:
          return totalDespCob * 0.5;
        case 2:
          return totalDespCob * 0.4;
        case 3:
          return totalDespCob * 0.3;
        case 4:
          return totalDespCob * 0.2;
        default:
          return 0;
      }
    }
    return 0;
  };

  const alterarValorEntradaInput = (e: any) => {
    var value = e.target.value ? e.target.value : '0';
    var valorFormated = formatCashToNumber(formatCashRegex(value).replace('R$ ',''))

    setValorEntradaFoiAlterado(true);
    setValorEntrada(valorFormated);
    setValorEntradaInput(formatCashRegex(value));
    getValorParcelasRestantes(valorFormated, numParcelas);

    e.target.value = value;
    return e;
  };

  const alterarParcelas = (numeroParcelas: number) => {
    setNumParcelas(numeroParcelas);
    var novoValorEntrada = valorEntrada;

    if (!valorEntradaFoiAlterado || numeroParcelas === 1) {
      novoValorEntrada = getValorEntrada(numeroParcelas);

      setValorEntradaInput(formatCashRegex(String(novoValorEntrada)));
      setValorEntrada(novoValorEntrada);
      setValorEntradaFoiAlterado(false);
    }

    getValorParcelasRestantes(novoValorEntrada, numeroParcelas);
  };

  const getValorParcelasRestantes = (valorEntrada2: number, numeroParcelas: number) => {
    const parcelasRestantes = numeroParcelas - 1;
    const totalDesconto = totais.totalDevido - getDesconto(numeroParcelas, totais.totalDespCob);
    const valorFinanciado = totalDesconto - valorEntrada2;
    const taxaMensal = 0.01;
    const valorParcelas = Number((valorFinanciado * taxaMensal) / (1 - (1 + taxaMensal) ** -parcelasRestantes));
    const valorAcordadoComJuros = Number(valorEntrada2 + (valorParcelas * parcelasRestantes));

    setValorParcelasRestantes(valorParcelas);
    setValorAcordadoJuros(valorAcordadoComJuros);
  };

  const valirdarEntradaEParcelas = (valorEntrada: number, numeroParcelas: number) => {
    if (!(numeroParcelas >= 1 && numeroParcelas <= 4)) {
      alert("Atenção! Número de parcelas inválidos");
      return false;
    }

    if (numeroParcelas === 1) return true;

    if (valorParcelasRestantes <= 100) {
      alert("Atenção! O valor mínimo para parcelas é de R$ 100,00");
      return false;
    }

    const valorMinimoEntrada = Number((getValorAcordado(numeroParcelas) / numeroParcelas).toFixed(2));

    const valorEntradaInteiro = valorEntrada * 100
    const valorMinimoEntradaInteiro = valorMinimoEntrada * 100

    if (valorEntradaInteiro < valorMinimoEntradaInteiro) {
      alert(`Atenção! O valor mínimo da entrada é de R$ ${valorMinimoEntrada.toFixed(2)}`);
      return false;
    }
    return true;
  };

  const handleSolicitarToken = () => {
    try {
      if (valirdarEntradaEParcelas(valorEntrada, numParcelas)) {
        request
          .post('enviarSmsAutoAcordo')
          .then((response) => {
            if (response.data.sucesso) {
              setModalVisible(true);
              setDadosContato({
                telefone: response.data.foneCodigo,
                email: response.data.emailCodigo,
              });
              setTokenSolicitado(true);
              if (tokenSolicitado) setModalVisible(true);
              else toast.success(`Atenção! ${response.data.erro} (P01).`);
            } else {
              setModalVisible(false);
              if (response.data.erro && response.data.erro !== '') {
                toast.error(`Atenção! ${response.data.erro} (P02).`);
              } else {
                toast.error("Atenção! Falha ao tentar fazer a operação (P03).");
              }
            }
          })
          .catch((error) => {
            if (error?.message === 'Network Error') {
              toast.error('Falha na rede. Verifique sua conexão e tente novamente (P04).');
            } else {
              toast.error("Atenção! Falha ao tentar fazer a operação (P05).");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (err) {
      toast.error("Atenção! Falha ao tentar fazer a operação (P06).");
    }
  };

  const validarToken = () => {
    const form = { token: codeSms };
    
    try {
      setLoading(true);
      request
      .post('validarTokenAutoAcordo', form)
      .then((response) => {
        if (response.data.ok && response.data.ok === 'Sucesso') {
          setVerificado(true);
        } else {
          setTokenSolicitado(false);
          if(response.data.erro && response.data.erro !== '') {
            toast.error(`Atenção! ${response.data.erro} (P07).`);
          }
          else {
            toast.error("Atenção! Falha ao tentar fazer a operação (P08).");
          }
        }
      })
      .catch((error) => {
        setTokenSolicitado(false);
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. Verifique sua conexão e tente novamente (P09).');
        } else {
          toast.error('Atenção! Falha ao tentar fazer a operação (P10).');
        }
      })
      .finally(() => {
        setLoading(false);
      });
    } catch (err) {
      toast.error("Atenção! Falha ao tentar fazer a operação (P11).");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    try {

      if (!verificado) {
        setModalVisible(true);
        return;
      }

      setModalVisible(false);
      setLoading(true);

      const form = { numParcelas, valorEntrada };

      request
        .post('validarAutoAcordo', form)
        .then((response) => {
          if (response.data.sucesso) {
            // Alert.alert('Pronto', 'O acordo foi criado', [
            //   { text: 'Visualizar Termo', onPress: () => Linking.openURL(response.data.linkTermo) },
            //   { text: 'Voltar', onPress: () => navigation.goBack() },
            // ]);
            toast.success('Parabéns! O seu acordo foi realizado com sucesso');
            history.goBack();
          } else {
            if (response.data.mensagem && response.data.mensagem !== '') {
              toast.error(`Atenção! ${response.data.mensagem} (P13)`);
            } else {
              toast.error("Atenção! Falha ao tentar fazer a operação (P13).");
            }
          }
        })
        .catch((error) => {
          if (error?.message === 'Network Error') {
            toast.error('Falha na rede. Verifique sua conexão e tente novamente (P014).');
          } else {
            toast.error("Atenção! Falha ao tentar fazer a operação (P15).");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      toast.error("Atenção! Falha ao tentar fazer a operação (P16).");
      setLoading(false);
    }
  };

  const handleMaskInput = (e: any) => {
    let value = e.currentTarget.value;
    let valueFormatted = maskBRL(value)
    e.currentTarget.value = valueFormatted;
    return e;
  }

  React.useEffect(() => {
    if (size.sizeWidth <= 1440) setToggleTable2(true);
    else setToggleTable2(false);
  }, [size.sizeWidth]);

  React.useEffect(() => {
    if (size.sizeWidth <= 1024) setToggleTable1(true);
    else setToggleTable1(false);
  }, [size.sizeWidth]);
  
  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Auto Acordo'));
  }, [dispatch]);

  React.useEffect(() => {
    try {
      request
      .get('dadosAutoAcordo')
      .then((response) => {
        if (response.data.ok && response.data.ok === "Sucesso") {
          setDebitos(response.data.debitos);
          setTotais(response.data);
          let valorEntradaInicial = response.data.totalDevido - getDesconto(1, response.data.totalDespCob);
          setValorEntrada(valorEntradaInicial);
          setValorEntradaInput(formatCash(String(valorEntradaInicial)));
        } else {
          if (response.data.erro && response.data.erro !== '') {
            setMessageError(`Atenção : ${response.data.erro}`);
            toast.error(`Atenção! ${response.data.erro} (P17).`);
          } else {
            toast.error("Atenção! Falha ao tentar fazer a operação (P18).");
          }
        }
      })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. Verifique sua conexão e tente novamente (P19).');
        } else {
          toast.error("Atenção! Falha ao tentar fazer a operação (P20).");
        }
      })
      .finally(() => {
        setLoading(false);
      });
    } catch (e) {
      toast.error("Atenção! Falha ao tentar fazer a operação (P21).");
    }
  }, [history]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : messageError ? (
        <h1>{messageError}</h1>
      ) : (
        <>
          <SectionTitle>Configurar acordo</SectionTitle>

          {/* ======================= RESPONSIVE TABLE 1 ===================== */}

          {!toggleTable1 ? (
            <TableFlex1>
              <thead>
                <tr>
                  <th>Valor Cobrado:</th>
                  <th>Multa:</th>
                  <th>Juros:</th>
                  <th>Correção Monetária:</th>
                  <th>Despesa c/ Cobrança:</th>
                  <th>Valor Devido:</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <Load>
                    <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                  </Load>
                )}
                <tr>
                  <td>{maskBRL(totais.totalValor)}</td>
                  <td>{maskBRL(totais.totalMulta)}</td>
                  <td>{maskBRL(totais.totalJuros)}</td>
                  <td>{maskBRL(totais.totalCorMon)}</td>
                  <td>{maskBRL(totais.totalDespCob)}</td>
                  <td>{maskBRL(totais.totalDevido)}</td>
                </tr>
              </tbody>
            </TableFlex1>
          ) : (
            <>
              {loading && (
                <Load>
                  <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                </Load>
              )}

              <TableFlex2>
                <thead>
                  <tr>
                    <th>Valor Cobrado</th>
                    <td>{maskBRL(totais.totalValor)}</td>
                  </tr>
                  <tr>
                    <th>Multa</th>
                    <td>{maskBRL(totais.totalMulta)}</td>
                  </tr>
                  <tr>
                    <th>Juros</th>
                    <td>{maskBRL(totais.totalJuros)}</td>
                  </tr>
                  <tr>
                    <th>Correção Monetária</th>
                    <td>{maskBRL(totais.totalCorMon)}</td>
                  </tr>
                  <tr>
                    <th>Despesa c/ Cobrança</th>
                    <td>{maskBRL(totais.totalDespCob)}</td>
                  </tr>
                  <tr>
                    <th>Valor Devido</th>
                    <td>{maskBRL(totais.totalDevido)}</td>
                  </tr>
                </thead>
              </TableFlex2>
            </>
          )}

          <AgreementConfigForm>
            <section>
              <Row>
                <section className='half'>
                  <SectionTitle>Escolha a quantidade de parcelas:</SectionTitle>

                  <DiscountAlert>
                    Desconto referente a despesa com cobrança *
                  </DiscountAlert>
                  <select
                    id='numparcela'
                    onChange={(e: any) => alterarParcelas(Number(e.target.value))}
                    value={numParcelas}
                  >
                    {discounts.map((v) => (
                      <option key={v.value} value={v.value}>
                        {v.label}
                      </option>
                    ))}
                  </select>
                </section>

                <section className='half'>
                  {numParcelas === 1 ? null : (
                    <>
                      <SectionTitle style={{ marginBottom: 0, marginTop: 20 }}>
                        Insira o valor de entrada:
                      </SectionTitle>

                      <Input
                        value={valorEntradaInput}
                        style={{ border: 'none', minWidth: 300, width: '100%' }}
                        onChange={(e) => alterarValorEntradaInput(e)}
                      />
                    </>
                  )}
                </section>

              </Row>

              <div>
                {/* ======================= RESPONSIVE TABLE 2 ===================== */}

                {!toggleTable1 ? (
                  <TableFlex1>
                    <thead>
                      <tr>
                        {numParcelas > 1 && 
                          <>
                            <th>Parcelas Restantes: </th>
                            <th>Juros de <br/> Parcelamento: </th>
                          </>
                        }
                        <th>Desconto em Despesa <br/> c/ Cobrança: </th>
                        <th>Primeiro Vencimento: </th>
                        <th>Valor Acordado: </th>
                        <th>Valor Total <br/> do Acordado: </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {numParcelas > 1 && (
                          <>
                            <td>{`${numParcelas - 1} x ${maskBRL(valorParcelasRestantes.toFixed(2))}`}</td>
                            <td>1%</td>
                          </>
                        )}
                        <td>{maskBRL(getDesconto(numParcelas, totais.totalDespCob).toFixed(2))}</td>
                        <td>
                          {totais.proximoDiaUtil &&
                            dateStringToDateFormat(totais.proximoDiaUtil).split(
                              ' '
                            )[0]}
                        </td>
                        <td>{maskBRL(getValorAcordado(numParcelas).toFixed(2))}</td>
                        <td>
                          {numParcelas > 1 
                            ? maskBRL(valorAcordadoJuros.toFixed(2))
                            : maskBRL(getValorAcordado(numParcelas).toFixed(2))
                          }
                        </td>
                      </tr>
                    </tbody>
                  </TableFlex1>
                ) : (
                  <TableFlex2>
                    <thead>
                      <tr>
                        <th>Valor da entrada</th>
                        <td>{formatCash(valorEntrada)}</td>
                      </tr>
                      {numParcelas > 1 && 
                        <>
                          <tr>
                            <th>Parcelas Restantes</th>
                              <td>
                                {`${numParcelas - 1} x ${maskBRL(valorParcelasRestantes.toFixed(2))}`}
                              </td>
                          </tr>
                          <tr>
                            <th>Juros de Parcelamento</th>
                            <td>1%</td>
                          </tr>
                        </>
                      }
                      <tr>
                        <th>Desconto em Despesa c/ Cobrança</th>
                        <td>{maskBRL(getDesconto(numParcelas, totais.totalDespCob).toFixed(2))}</td>
                      </tr>
                      <tr>
                        <th>Primeiro Vencimento</th>
                        <td>
                          {totais.proximoDiaUtil &&
                            dateStringToDateFormat(totais.proximoDiaUtil).split(
                              ' '
                            )[0]}
                        </td>
                      </tr>
                      <tr>
                        <th>Valor Acordado</th>
                        <td>{maskBRL(getValorAcordado(numParcelas).toFixed(2))}</td>
                      </tr>
                      <tr>
                        <th>Valor Total do Acordado</th>
                        <td>
                          {numParcelas > 1 
                            ? maskBRL(valorAcordadoJuros.toFixed(2))
                            : maskBRL(getValorAcordado(numParcelas).toFixed(2))
                          }
                        </td>
                      </tr>
                    </thead>
                  </TableFlex2>
                )}

                <div>
                  <button type='button' onClick={handleSolicitarToken}>
                    Avançar
                  </button>
                </div>
              </div>
            </section>
          </AgreementConfigForm>

          {/* ======================= RESPONSIVE TABLE 3 ===================== */}

          <SectionTitle style={{ marginTop: 25 }}>Débitos</SectionTitle>
          {!toggleTable1 ? (
            <TableFlex1>
              <thead>
                <tr>
                  <th>Número:</th>
                  <th>Descrição:</th>
                  <th>Vencimento:</th>
                  <th>Valor Cobrado:</th>
                  <th>Multa:</th>
                  <th>Juros:</th>
                  <th>Correção Monetária:</th>
                  <th>Despesa c/ Cobrança:</th>
                  <th>Valor Devido:</th>
                  <th>Dias Vencidos:</th>
                  <th>Garantida: </th>
                </tr>
              </thead>
              {/* <Divisor colSpan={4} /> */}
              <tbody>
                {loading && (
                  <Load>
                    <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                  </Load>
                )}
                {debitos.map((d: any, index) => (
                  <tr key={index}>
                    <td className='table-unidade'>{d.numDocumento}</td>
                    <td>{d.descricao}</td>
                    <td>{dateStringToDateFormat(d.dataVencimento)}</td>
                    <td>{maskBRL(d.valorCobrado)}</td>
                    <td>{maskBRL(d.valorMulta)}</td>
                    <td>{maskBRL(d.valorJuros)}</td>
                    <td>{maskBRL(d.correcaoMonetaria)}</td>
                    <td>{maskBRL(d.despesaCobranca)}</td>
                    <td>{maskBRL(d.valorDevido)}</td>
                    <td>{d.diasVencidos}</td>
                    <td>{d.possuiGarantia ? 'Sim' : 'Não'}</td>
                  </tr>
                ))}
              </tbody>
            </TableFlex1>
          ) : (
            <>
              {loading && (
                <Load>
                  <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                </Load>
              )}
              {debitos.map((d: any, index) => (
                <TableFlex2 key={index}>
                  <thead>
                    <tr>
                      <th>Número</th>
                      <td>{d.numDocumento}</td>
                    </tr>
                    <tr>
                      <th>Descrição</th>
                      <td>{d.descricao}</td>
                    </tr>
                    <tr>
                      <th>Vencimento</th>
                      <td>{dateStringToDateFormat(d.dataVencimento)}</td>
                    </tr>
                    <tr>
                      <th>Valor Cobrado</th>
                      <td>{maskBRL(d.valorCobrado)}</td>
                    </tr>
                    <tr>
                      <th>Multa</th>
                      <td>{maskBRL(d.valorMulta)}</td>
                    </tr>
                    <tr>
                      <th>Juros</th>
                      <td>{maskBRL(d.valorJuros)}</td>
                    </tr>
                    <tr>
                      <th>Correção Monetária</th>
                      <td>{maskBRL(d.correcaoMonetaria)}</td>
                    </tr>
                    <tr>
                      <th>Despesa c/ Cobrança</th>
                      <td>{maskBRL(d.despesaCobranca)}</td>
                    </tr>
                    <tr>
                      <th>Valor Devido</th>
                      <td>{formatCash(d.valorDevido)}</td>
                    </tr>
                    <tr>
                      <th>Dias Vencidos</th>
                      <td>{d.diasVencidos}</td>
                    </tr>
                    <tr>
                      <th>Garantida</th>
                      <td>{d.possuiGarantia ? 'Sim' : 'Não'}</td>
                    </tr>
                  </thead>
                </TableFlex2>
              ))}
            </>
          )}

          <Modal
            isOpen={modalVisible}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => setModalVisible(false)}
            style={customStyles}
            contentLabel='Example Modal'
          >
            <div className='modal-content'>
              <button
                type='button'
                onClick={() => setModalVisible(false)}
                className='close'
              >
                X
              </button>

              <ModalContent>
                <p>
                  Enviamos um token para seu email/telefone, favor ferificar e
                  digitar o código no campo acima.{'\n'} Telefone:{' '}
                  {dadosContado.telefone} {'\n'} Email: {dadosContado.email}
                </p>
                {verificado ? (
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      margin: 5,
                    }}
                  >
                    Token válidado com sucesso
                  </p>
                ) : (
                  <input
                    maxLength={4}
                    value={codeSms}
                    placeholder='0000'
                    onChange={(e: any) => setCodeSms(e.target.value)}
                  />
                )}

                <button
                  type='button'
                  onClick={verificado ? handleSubmit : validarToken}
                >
                  {verificado ? 'Realizar Acordo' : 'Avançar'}
                </button>
              </ModalContent>
            </div>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default AutoAcordo;
