import { useDispatch } from 'react-redux';
import * as React from 'react';
import { toast } from 'react-toastify';
import { IoMdSend } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { ActionsHeader } from '../../../store/ducks/header';

import {
  ContainerMessages, DivSendMessage, WrapperChat,
} from './styles';
import Message from './Message';
import request from '../../../services/request';
import { IMessageAssembly } from '../../../interfaces/IMessageAssembly';
import { COLORS } from '../../../utils/constants';
import { getAssemblies } from '../servicces';

const ChatAssembly = () => {
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = React.useState('');
  const [messages, setMessages] = React.useState<Array<IMessageAssembly>>([]);
  const [assembly, setAssembly] = React.useState<any>();

  const { id } = useParams() as any;

  React.useEffect(() => {
    getAssemblies().then((response) => {
      if (response.data.ok === 'Sucesso') {
        const { resultados } = response.data.listagem;

        const findAssembly = resultados.find((a : any) => a.id === Number(id));

        setAssembly(findAssembly);
      }
    });
  }, [id]);

  const getMessages = React.useCallback(() => {
    request.get(`mensagensAssembleiasVirtuais?idAssembleiaVirtual=${id}`)
      .then((response) => {
        if (response.data.ok === 'Sucesso') {
          const msgs = response.data.listagem.resultados.map((m:any) => ({
            id: m.id,
            mensagem: m.mensagem,
            horario: m.horario,
            idVinculo: m.vinculo.id,
            nomeVinculo: m.vinculo.descricao,
            nomeUnidade: m.vinculo.unidade.nome,
          }));

          setMessages(msgs);
        }
      }).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro : A requisição falhou');
        }
      });
  }, [id]);

  const handleSendMessage = () => {
    const form = { idAssembleiaVirtual: assembly.id, mensagem: textMessage };
    request.post('mensagensAssembleiasVirtuais', form).then((response) => {
      if (response.data.ok) {
        setTextMessage('');
        getMessages();
      }
    });
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter(`Chat da Assembleia ${assembly?.titulo || ''}`));
    const refresh = setInterval(() => {
      getMessages();
    }, 5000);
    getMessages();

    return () => clearInterval(refresh);
  }, [dispatch, getMessages, assembly]);

  return (
    <WrapperChat>
      <ContainerMessages>
        <div>
          {messages.map((m:any) => <Message key={m.id} message={m} isSelfMessage={m.idVinculo === 138542} />)}
        </div>
      </ContainerMessages>
      <DivSendMessage>
        <input placeholder="Digite sua mensagem" value={textMessage} onChange={(e) => setTextMessage(e.target.value)} />
        <button type="button" onClick={handleSendMessage}>
          <IoMdSend size={30} color={COLORS.PRIMARY} />
        </button>
      </DivSendMessage>
    </WrapperChat>
  );
};

export default ChatAssembly;
