import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const customStyles = {
  content: {
    height: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
};

export const TitleHeader = styled.h1`
  font-size: 22px;
  color: ${COLORS.PRIMARY};
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`;
