import styled from "styled-components";
import { Table } from "../../../../components/Table/styles";

export const Container = styled.div`
  min-height: 100%;
  padding: 15px;
`;

export const ButtonGoBack = styled.div`
`;

export const TableFlex1 = styled(Table)`
  margin: 20px 0;

  td {
    cursor: pointer;
  }
`;