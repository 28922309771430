import styled from 'styled-components';

export const Container = styled.div`
  height : 502px;
  width : 465px;
`;
export const DivAttatch = styled.div`
  display: flex;
  background-color: #aaa;
  margin: 8px 5px;
  padding: 5px;
  text-align: center;
   span {
     flex:1
   }

   svg {
    cursor: pointer;
   }
`;
