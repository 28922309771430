import React from "react";
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from "react-icons/io";
import { COLORS } from "../../../utils/constants";
import { Header, Swipper, ViewAttachs, ViewCardModal } from "./styles";
import Modal from 'react-modal';
import customStyles from "../../../utils/customStylesModal";

export interface Props {
    open: boolean;
    handleModal: () => void;
    handleNext: () => void;
    handlePrevious: () => void;
    idImage: number;
    length: number;
    currentImage: string;
}

const PhotosModal: React.FC<Props> = ({ open, handleModal, currentImage, idImage, length, handlePrevious, handleNext }) => {
    return (
        <Modal
            isOpen={open}
            onRequestClose={handleModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                <ViewCardModal>

                    <Header>
                        <div style={{ width: 40 }} />
                        <span>
                            Anexos de Ocorrência
                        </span>
                        <IoIosClose style={{ cursor: "pointer" }} size={40} onClick={handleModal} color={COLORS.PRIMARY} />
                    </Header>

                    <img src={currentImage} style={{ height: 500, width: 500, marginTop: 10, objectFit: "contain" }} />

                    <Swipper>
                        <ViewAttachs style={{ width: 260 }}>
                            <div onClick={handlePrevious} style={{ paddingLeft: 10, paddingRight: 10, cursor: "pointer" }}>
                                <IoIosArrowBack name="arrow-back-ios" size={40} color="#fff" />
                            </div>

                            <p style={{ color: "#fff" }}><strong>Anexo {idImage + 1} de {length}</strong></p>

                            <div onClick={handleNext} style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 15, cursor: "pointer" }}>
                                <IoIosArrowForward name="arrow-back-ios" size={40} color="#fff" />
                            </div>
                        </ViewAttachs>
                    </Swipper>

                </ViewCardModal>
        </Modal>
    )
}

export default PhotosModal;