import React, { useMemo } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContentBody } from './components/ContentBody/styles';
import Pages from './pages';
import RootState from './interfaces/states/RootState';
import WrapperDashboard from './pages/WrapperDashboard';
import SidebarWithHeader from './pages/PortalFunctionario/WrapperFuncionario';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isLogged = useSelector((state: RootState) => state.session.isLogged);

  return (
    <Route
      {...rest}
      render={(props) => (isLogged ? (<Component {...props} />) : (
        <Redirect
          exact
          to="/login"
        />
      ))}
    />
  );
};

export default function Routes({ children }: any) {
  const isLogged = useSelector((state: RootState) => state.session.isLogged);
  const isManager = useSelector((state: RootState) => state.session.isManager);
  const isEmployee = useSelector((state: RootState) => state.session.isEmployee);
  const idVinculo = useSelector((state: RootState) => state.session.idVinculo);
  const todosVinculos = useSelector((state: RootState) => state.session.todosVinculos);
  const idUsuarioLogado = useSelector((state: RootState) => state.session.idUsuarioLogado);

  const initialPathName = useMemo(
    () => {
      if (!isEmployee) {
        if (!idVinculo && todosVinculos?.length > 0) {
          return '/select_unity';
        }
      }

      if (isEmployee) {
        return '/portal_funcionario'
      } else if (isManager) {
        return '/home_manager'
      } else {
        return '/home_condomino';
      }
    }, [idVinculo, todosVinculos, isManager, isEmployee, idUsuarioLogado],
  );

  const CurrentContentBody = ({ children }: any) => (
    (isLogged && isEmployee)
      ?
      <SidebarWithHeader>{children}</SidebarWithHeader>
      :
      (isLogged && idVinculo && !isEmployee)
        ?
        <WrapperDashboard>{children}</WrapperDashboard>
        :
        <ContentBody>{children}</ContentBody>);

  return (
    <Router>
      <CurrentContentBody>
        {/* {isLogged && idVinculo && (
        <Header />
      )}
      {
        isLogged && (
          <>
            <Drawer idVinculo={idVinculo} />
          </>
        )
    } */}

        <Switch>
          <PrivateRoute path="/portal_funcionario" component={() => <Pages.PortalFunctionario />} />
          <PrivateRoute path="/visitas_agendadas" component={() => <Pages.VisitasAgendadas />} />
          <PrivateRoute path="/visitas_cadastradas" component={() => <Pages.VisitasCadastradas />} />
          <PrivateRoute path="/cadastro_visita" component={() => <Pages.CadastroVisita />} />
          <PrivateRoute path="/listar_visitas" component={() => <Pages.ListarVisitas />} />
          <PrivateRoute path="/consumos_funcionario" component={() => <Pages.ConsumosFuncionario />} />
          <PrivateRoute path="/gravar_leituras" component={() => <Pages.GravarLeituras />} />
          <PrivateRoute path="/listar_consumos" component={() => <Pages.ListarConsumos />} />
          <PrivateRoute path="/reservas_cadastradas" component={() => <Pages.ReservasCadastradas />} />

          <PrivateRoute path="/select_unity" component={() => <Pages.SelectUnity />} />
          <PrivateRoute path="/assembly/create" component={() => <Pages.CreateAssembly />} />
          <PrivateRoute path="/assembly/:id/details" component={() => <Pages.DetailsAssembly />} />
          <PrivateRoute path="/assembly/:id/results" component={() => <Pages.ResultsAssembly />} />
          <PrivateRoute path="/assembly/:id/chat" component={() => <Pages.ChatAssembly />} />
          <PrivateRoute path="/assembly/edit/:id" component={() => <Pages.CreateAssembly />} />
          <PrivateRoute exact path="/bank_slip" component={() => <Pages.ListBankSlip />} />
          <PrivateRoute path="/bank_slip/details" component={() => <Pages.DetailsBankSlip />} />
          <PrivateRoute path="/calendar_reserves" component={() => <Pages.CalendarReserves />} />
          <PrivateRoute path="/camcc" component={() => <Pages.CAMCC />} />
          <PrivateRoute path="/cmoc" component={() => <Pages.CMOC />} />
          {isManager && <PrivateRoute path="/expense/create" component={() => <Pages.CreateExpense />} />}
          <PrivateRoute exact path="/reset_password" component={() => <Pages.ResetPassword />} />
          <PrivateRoute exact path="/inadimplencias" component={() => <Pages.Inadimplencias />} />
          <PrivateRoute path="/list_assemblies" component={() => <Pages.ListAssemblies />} />
          <PrivateRoute path="/list_budgets" component={() => <Pages.ListBudgets />} />
          <PrivateRoute path="/list_default/:apiRoute/" component={() => <Pages.ListDefault />} />
          <PrivateRoute path="/list_expenses" component={() => <Pages.ListExpenses />} />
          <PrivateRoute path="/list_occurrencies" component={() => <Pages.ListOcurrencies />} />
          <PrivateRoute path="/details_survey/:id" component={() => <Pages.DetailsSurvey />} />
          {/* <PrivateRoute path="/list_surveis" component={() => <Pages.ListSurveis />} /> */}
          <PrivateRoute path="/home_condomino" component={() => <Pages.HomeCondomino />} />
          <PrivateRoute path="/home_manager" component={() => <Pages.HomeManager />} />
          <PrivateRoute path="/my_unit" component={() => <Pages.MyUnit />} />
          <PrivateRoute path="/owner_portal" component={() => <Pages.OwnerPortal />} />
          <PrivateRoute path="/revenues" component={() => <Pages.Revenues />} />
          <PrivateRoute path="/register_update" component={() => <Pages.RegisterUpdate />} />
          <PrivateRoute path="/year_accountability" component={() => <Pages.YearAccountability />} />
          <PrivateRoute path="/list_visit_scheduling" component={() => <Pages.ListVisits />} />
          <PrivateRoute path="/edit_visit/:idVisit" component={() => <Pages.EditVisit />} />
          <PrivateRoute path="/schedule_visit/" component={() => <Pages.ScheduleVisit />} />
          <PrivateRoute path="/chat_funcionario" component={() => <Pages.ChatFuncionario />} />
          <PrivateRoute path="/condominium_documents" component={() => <Pages.CondominiumDocuments />} />
          <PrivateRoute path="/auto_acordo" component={() => <Pages.AutoAcordo />} />
          <PrivateRoute path="/list_residents" component={() => <Pages.ListResidents />} />
          <PrivateRoute path="/unity_ocorrence" component={() => <Pages.UnityOcorrences />} />
          <PrivateRoute path="/virtual_protocol" component={() => <Pages.VirtualProtocol />} />
          <PrivateRoute path="/list_enquetes" component={() => <Pages.ListEnquetes />} />
          {isManager && <PrivateRoute path="/enquete/create" component={() => <Pages.CreateEnquete />} />}
          {isManager && <PrivateRoute path="/enquete/edit/:idEnquete" component={() => <Pages.EditEnquete />} />}
          <PrivateRoute path="/enquete/vote/:idEnquete" component={() => <Pages.VoteEnquete />} />

          <Route path="/request_password" component={() => <Pages.RequestPassword />} />
          <Route
            path="/login"
          >
            {!isLogged
              ? (<Pages.Login />)
              : (
                <Redirect
                  exact
                  to={{
                    pathname: initialPathName,
                  }}
                />
              )}
          </Route>
          <Route exact path="/" component={() => <Redirect to="login" />} />

        </Switch>

      </CurrentContentBody>
      {children}
    </Router>
  );
}
