import styled from "styled-components";
import { Table } from "../../../../components/Table/styles";

export const Column = styled.div`
   width: 100%;
   height: 80vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 20px;
`;