import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import request from '../../services/request';

import logo from '../../assets/logo.png';
import { COLORS } from '../../utils/constants';
import {
    Box, Button, Image,
    Stack, Text
} from '@chakra-ui/react';
import {
    Container, Form, Header
} from './styles';
import { useHistory } from 'react-router-dom';

const arrayOpt = [
    {
        value: 0,
        label: 'Selecione uma opção'
    },
    {
        value: 1,
        label: "Condômino"
    },
    {
        value: 3,
        label: "Síndico"
    }
]

const RequestPassword = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [accountType, setAccountType] = useState(0);
    const [username, setUsername] = useState<string>('');

    const handleSubmit = () => {

        if (!accountType || (accountType === 0)) {
            toast.warning('Atenção: Informe corretamente o campo de "tipo de conta"');
            return;
        }

        if (!username || username === '') {
            toast.warning('Atenção: Informe corretamente o campo de CPF/CNPJ');
            return;
        }

        setLoading(true);

        const form = {
            documento: username,
            tipo: accountType?.valueOf(),
        };

        request.post('resetarSennha', form).then((response) => {
            if (response.data.ok) {
                toast.success("Sucesso: " + response.data.ok);
            } else {
                toast.error("Erro: " + response.data.erro);
            }
        }).catch((error) => {
            if (error?.message === 'Network Error') {
                toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
            } else {
                toast.error('Erro : A requisição falhou');
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Container>
            <Header>
                <Image src={logo} w="242px" h="56px" />
            </Header>

            <Form>
                <Box>
                    <Text
                        fontSize="2xl"
                        textAlign="center"
                        fontWeight={700}
                    >
                        Solicitação de Senha de Acesso
                    </Text>
                </Box>

                <Stack
                    w="100%"
                >
                    <Select
                        options={arrayOpt}
                        label="Selecione o tipo de conta"
                        value={accountType}
                        onChange={(e: any) => setAccountType(e.target.value)}
                        padding
                        style={{ width: "100%" }}
                    />
                    <Input
                        label="Informe CPF/CNPJ"
                        fontColor={COLORS.PRIMARY}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="CPF/CNPJ"
                        style={{ width: "100%" }}
                    />
                </Stack>

                <Stack
                    w="100%"
                    alignItems="center"
                >
                    <Button
                        bg="#009B6E"
                        borderRadius="4px"
                        fontSize="12px"
                        h="40px" w="70%"
                        color="#fff"
                        _hover={{ filter: 'brightness(0.9)' }}
                        type="submit"
                        fontFamily="Montserrat"
                        fontWeight="bold"
                        onClick={() => handleSubmit()}
                    >
                        {loading ? 'Solicitando...' : 'Solicitar senha'}
                    </Button>
                    <Button
                        borderRadius="4px"
                        fontSize="11px"
                        color="#3B308B"
                        bg="#D9D7E5"
                        h="40px" w="70%"
                        _hover={{ filter: 'brightness(0.9)' }}
                        onClick={() => history.push('/login')}
                    >
                        Voltar
                    </Button>
                </Stack>
            </Form>
        </Container>
    );
};

export default RequestPassword;
