import styled, { css } from 'styled-components';
import { COLORS } from '../../utils/constants';

type ContainerProps = {
selected : boolean;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 16px 10px 10px;
`;

export const Button = styled.button<ContainerProps>`
    margin-left: 15px;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 5px;

    font-weight : bold;
    font-size : 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    ${({ selected }) => selected && css`
        background: ${COLORS.PRIMARY};
        color: #fff;
    `}
`;
