import axios from 'axios';
import qs from 'qs';
import { endPoints } from '../utils/constants';
import IHeader from '../interfaces/IHeader';
import IHeaderEmployee from '../interfaces/IHeaderEmployee';

const baseURL = endPoints.BASE_URL_API;

export const header = {} as any;

export const setHeader = (newHeader: (IHeader|any)) => {
  header.hash = newHeader.hash;
  header.hashvinculo = newHeader.hashVinculo;
  header.idusuariologado = newHeader.idUsuarioLogado;
  header.idvinculo = newHeader.idVinculo;
  header.tipousuario = newHeader.tipoUsuario;
  header.agent = newHeader.agent;
};

const api = axios.create({
  baseURL,
});

export default {
  get: async (url: string, config?: any) => api.get(url, {
    headers: header,
    ...config, // Inclui quaisquer outras configurações como `params`
  }),

  post: async (url:string, data?:any, json?:boolean) => api.post(url, json ? data : qs.stringify(data), {
    headers: header,
  }),

  postNotHeader: async (url:string, data?:any) => api.post(url, qs.stringify(data)),

  delete: async (url:string) => api.delete(url, {
    headers: header,
  }),

};
