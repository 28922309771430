import React, { useEffect } from "react";
import { BiImageAdd, BiTrash } from "react-icons/bi";
import Camera from "../../../../../components/Camera";
import { useCamera } from "../../../../../hooks/modules/CameraContext";
import { COLORS } from "../../../../../utils/constants";
import { useWindowSize } from "../../../../Login";
import { ButtonConfirm, DropDown, DropDownText, ImageWrapper } from "./styles";

interface IForm {
    idUnidade: number | string;
    anexo: string;
    valorLeitura: number;
    idLeituraGeral: number | string;
}

type Props = {
    cameraOpened: boolean;
    file: any;
    handleAddFile: (file: any) => void;
    setFile: (e: any) => void;
    disabled: boolean;
    formObject: IForm;
}

const PhotoManage: React.FC<Props> = ({
    cameraOpened,
    file,
    setFile,
    handleAddFile,
    disabled,
    formObject
}) => {

    const screen = useWindowSize();

    const {
        clearFunction,
        getCameraFunction,
        photo,
        hasPhoto,
        videoOpen,
        mediaStream,
        photoRef,
        snapShotFunction,
        stopCameraFunction,
        videoRef,
        clearCanvas
    } = useCamera();

    return (
        <DropDown style={screen.sizeWidth <= 520 ? { height: (screen.sizeWidth-50)/(4/5), width: screen.sizeWidth-50 } : {} }>
            {
                (hasPhoto && cameraOpened && !file) && (
                    <ButtonConfirm
                        onClick={() =>
                            setFile({
                                ...formObject,
                                anexo: photo,
                            })
                        }
                        style={hasPhoto ? { zIndex: 9999999 } : { zIndex: 1 }}
                    >
                        Confirmar
                    </ButtonConfirm>
                )
            }

            {
                (cameraOpened && !file) ? (
                    <Camera
                        clearFunction={clearFunction}
                        getCameraFunction={getCameraFunction}
                        clearCanvas={clearCanvas}
                        photo={photo}
                        photoRef={photoRef}
                        mediaStream={mediaStream}
                        snapShotFunction={snapShotFunction}
                        stopCameraFunction={stopCameraFunction}
                        videoRef={videoRef}
                        hasPhoto={hasPhoto}
                        videoOpen={videoOpen}
                        width={screen.sizeWidth <= 520 ? screen.sizeWidth-60 : 1080}
                        ratio={screen.sizeWidth <= 520 ? (4 / 5) : (16 / 9)}
                    />
                ) :
                    (
                        !file ?
                            <>
                                <BiImageAdd size={screen.sizeWidth <= 520 ? 50 : 70} />
                                <DropDownText><strong>Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui</DropDownText>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="upload"
                                    disabled={disabled}
                                    onChange={(e: any) => handleAddFile(e)}
                                />
                            </>
                            :
                            <ImageWrapper>
                                <img style={{ height: "100%" }} src={file} />
                                {
                                    disabled === false && (
                                        <BiTrash
                                            size={25}
                                            color={COLORS.PRIMARY}
                                            onClick={() => setFile({
                                                ...formObject,
                                                anexo: "",
                                            })}
                                            style={{ cursor: "pointer", position: "absolute", top: 5, right: screen.sizeWidth <= 520 ? 15 : 5, backgroundColor: "rgba(0,0,0,.7)" }}
                                        />
                                    )
                                }
                            </ImageWrapper>
                    )
            }
        </DropDown>
    )
}

export default PhotoManage;