import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
display : flex;
height : calc(100vh - 60px);

.login-box {
  width: 600px;
  padding: 40px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  margin : auto;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #333;
  text-align: center;
}




  form{
    display : flex;
    flex-direction : column;

    label {
    font-weight : 900;
    font-size  : 18px;
    margin-bottom  : 6px;
    color : ${COLORS.PRIMARY};
    }

    input{
    height : 40px;
    border-radius : 6px;
    margin-bottom  : 12px;
    border : 1px solid #999;

    padding-left : 12px;
    font-weight  : bold;
    font-size : 16px;
    background : #fff;
    }

    button{
    border-radius : 6px;
    height : 40px;
    border : 0;
    background : ${COLORS.PRIMARY};

    color : #fff;
    font-weight : bold;
    font-size  : 16px;
    width : 80%;
    align-self : center;
    }
  } 
`;
