import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  BoxProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { ImKey } from 'react-icons/im';
import {
  MdHome, MdMessage, MdOutlineMessage, MdExitToApp,
} from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavItem from './NavItem';
import logo from '../../../assets/logo-classecon.png';
import { ActionsSession } from '../../../store/ducks/session';
import RootState from '../../../interfaces/states/RootState';
import { goToExternalLink } from '../../../utils/functions';

interface LinkItemProps {
    name: string;
    icon: IconType;
    hash :string;
  }
const LinkItems: Array<LinkItemProps> = [
  { name: 'Início', icon: MdHome, hash: '/' },
  { name: 'Fale com a Administradora', icon: MdMessage, hash: 'fale_admin' },
  { name: 'Fale com o Porteiro', icon: MdOutlineMessage, hash: 'chat_funcionario' },
  { name: 'Trocar de Senha', icon: ImKey, hash: 'reset_password' },
  { name: 'Sair', icon: MdExitToApp, hash: 'sair' },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { logoAdm, descVinculoAtual, todosVinculos, isManager } = useSelector((state : RootState) => state.session);

  const handleClick = () => onClose();

  const handleIdAdm = (todosVinculos: any, descVinculoAtual: string, isManager: boolean) => {
    if (isManager) {
      const { condominio } = todosVinculos.find((item: any) => item?.condominio?.descricao === descVinculoAtual)
      return Number(condominio.idAdministradora);
    } 
    else {
      const { unidade } = todosVinculos.find((item: any) => item.unidade.descricao === descVinculoAtual)
      return unidade.idAdministradora;
    }
    return 0;
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: '288px' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex justifyContent="space-between">

        <Flex flexDir="column" justifyContent="space-between" alignItems="center" w={288}>

          <Box marginTop="6">
            <a href='/'>
            <img src={logo} width={211} height={52} alt="Logo" />
            </a>
          </Box>

          <Flex
            w="228px"
            h="65px"
            marginTop="6"
            alignItems="center"
            justifyContent="center"
          >
            <img src={logoAdm} alt="Logo" style={{ objectFit: 'contain', width: 175, height: 44 }} />
          </Flex>

        </Flex>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box marginTop="35px">

        {LinkItems.map((link) => (
          <NavItem
            hash={link.hash}
            key={link.name}
            icon={link.icon}
            onClick={() => {
              handleClick();
              if (link.hash === 'sair') {
                dispatch(ActionsSession.destroy());

                return;
              }
              if (link.hash === 'fale_admin') { 
                if (handleIdAdm(todosVinculos, descVinculoAtual, isManager) !== 34) {
                  return goToExternalLink('https://api.whatsapp.com/send?phone=558003457900&text=Oi');
                }
                return;
              }

              history.push(link.hash);
            }}
          >
            {link.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

export default SidebarContent;