import React, { useEffect, useState } from 'react';
import Loading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RootState from '../../../../interfaces/states/RootState';
import request from '../../../../services/request';
import { ActionsTempFiles } from '../../../../store/ducks/tempFiles';
import { AttachsWrapper, DeleteAttach, DeleteAttachImg, DropDown, DropDownText, EditGroup, EditText, EditWrapper, FormModal, ImageWrapper, Load, PdfWrapper, SpanWrapper, TextWrapper, TitleHeader } from './styles';
import { COLORS } from '../../../../utils/constants';
import Button from '../../../../components/Button/Button';
import { useWindowSize } from '../../../Login';
import { BiImageAdd } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';

type Props = {
  modalVisible: boolean;
  setModalVisible: (e: boolean) => void;
  getAllProtocols: () => void;
}

const NewProtocol: React.FC<Props> = ({ modalVisible, setModalVisible, getAllProtocols }) => {

  /* ========================= CONSTANTS/STATES =========================== */

  const dispatch = useDispatch();
  const size = useWindowSize();
  const tempFiles = useSelector((state: RootState) => state.tempFiles);

  const [description, setDescription] = useState("");
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  /* ========================= EFFECTS =========================== */

  useEffect(() => {
    // Limpa os arquivos do store global
    dispatch(ActionsTempFiles.clearFiles());
  }, [dispatch]);

  useEffect(() => {
    if (tempFiles.length !== 0) {
      handleScrollDown();
    }
  }, [loadingFile]);

  /* ========================= ATTACHS =========================== */

  const convertFileToBase64 = (file: Blob) => {
    // setLoadingFile(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => addAttachTemp(reader.result);
  };

  const addAttachTemp = (item: any) => {
    dispatch(ActionsTempFiles.addFile(item));
    setLoadingFile(false);
  };

  const removeAttachTemp = (index: number) => {
    dispatch(ActionsTempFiles.removeFile(index));
  };

  /* ========================== FUNCTIONS ========================= */

  function handleScrollDown() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  const filterAttach = (elem: string | string[]) => {
    if (elem.includes("data:application/pdf;base64,")) {
      return 2;
    } else {
      return 1;
    }
  }

  const handleAddFile = (file: any) => {
    setLoadingFile(true);
    if (file) {
      // Dealing with adding multiple files
      [...Array(file.length)].map((f, i) => {
        let mimeType = file[i].type;
        let allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"];

        if (!allowedFileTypes.includes(mimeType)) {
          setLoadingFile(false);
          window.alert('Arquivo não suportado. Arquivos devem ser do tipo jpeg, png e/ou pdf');
        } else {
          convertFileToBase64(file[i]);
        }
      })
    } else {
      setLoadingFile(false);
    }
  };

  /* ========================== REQUESTS ========================= */

  function handleCreateProtocol(e: React.FormEvent) {
    e.preventDefault();

    if (tempFiles[0] && tempFiles[0] !== undefined && description) {

      const form = {
        descricao: description,
        anexos: tempFiles,
      };

      setLoadingCreate(true);
      request.post('atualizarProtocoloVirtual', form).then((res) => {
        if (res.data) {
          toast.success('Protocolo cadastrado com sucesso.');
        } else {
          toast.error('Problemas para cadastrar seu protocolo.');
        }
      }).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro: A requisição falhou');
        }
      }).finally(() => {
        getAllProtocols();
        setLoadingCreate(false);
        setModalVisible(false);
      });
    } else if (!description) {
      toast.warning('Atenção: Preencha o campo de nome/descrição.');
    } else {
      toast.warning('Atenção: O protocolo deve conter ao menos um anexo.');
    }
  }

  /* ========================= RENDERS =========================== */

  return (
    <>

      <EditGroup>
        <div style={{ width: 30 }} />
        <TitleHeader style={{ textAlign: "center" }}>Novo Protocolo</TitleHeader>
        <EditWrapper onClick={() => setModalVisible(false)}>
          <IoIosClose size={30} color={COLORS.PRIMARY} />
          <EditText>cancelar</EditText>
        </EditWrapper>
      </EditGroup>

      <FormModal onSubmit={handleCreateProtocol}>

        <TextWrapper>
          <h6>Nome/Descrição:</h6>
          <textarea maxLength={80} rows={(size.sizeWidth <= 475 && size.sizeHeight >= 640) ? 4 : 3} onChange={(e) => setDescription(e.target.value)} value={description} />
          <span style={{ marginRight: 10, alignSelf: "flex-end" }}>{description.length}/80</span>
        </TextWrapper>

        <h6>Documentos:</h6>
        <DropDown>
          <BiImageAdd size={size.sizeWidth <= 520 ? 50 : 70} />
          <DropDownText><strong>Selecione um arquivo</strong> ou <strong> arraste e solte-o</strong> aqui</DropDownText>
          <input type="file" accept="image/*, application/pdf" name="upload" onChange={(e: any) => handleAddFile(e.target.files)
          } multiple />
        </DropDown>

        <AttachsWrapper>
          {
            tempFiles.map((file, index) => (
              <div key={index}>
                {
                  (filterAttach(file) === 1) ?
                    <ImageWrapper>
                      <img src={file} />
                      <DeleteAttachImg size={25} color="#fff" onClickCapture={() => removeAttachTemp(index)} />
                    </ImageWrapper>
                    :
                    <PdfWrapper>
                      <DeleteAttach size={25} color="#fff" onClickCapture={() => removeAttachTemp(index)} />
                      <SpanWrapper>
                        <span>pdf</span>
                        <span>{`Anexo ${index + 1}`}</span>
                      </SpanWrapper>
                      <div style={{ height: 40 }} />
                    </PdfWrapper>
                }
              </div>
            ))
          }
          {
            loadingFile &&
            <ImageWrapper>
              <Loading type='spin' width={40} color={COLORS.SECONDARY} />
            </ImageWrapper>
          }
        </AttachsWrapper>

        {
          loadingCreate && (
            <Load>
              <Loading type='spin' width={60} color={COLORS.SECONDARY} />
            </Load>
          )
        }

        <Button
          type="submit"
          theme="primary"
          marginTop={10}
          style={{
            fontWeight: 'bold', fontSize: 18, width: '100%', height: 50, marginTop: 40,
          }}
        >
          Cadastrar protocolo
        </Button>
      </FormModal>
    </>
  );
};

export default NewProtocol;