import * as React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading/Loading';
import { IAnnualObligations } from '../../interfaces/responses/IAnnualObligations';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { dateStringToDateFormat } from '../../utils/dates';
import { useWindowSize } from '../Login';
import { YearComponent, Container, TableFlex1, TableFlex2, EmptyMessage, EmptyMessageText } from './styles';

const CAMCC = () => {
  const dispatch = useDispatch();
  const dateCurrent = new Date();
  const size = useWindowSize();
  const erro = 'Não existe registros para esse ano';
  
  const [annualObligations, setAnnualObligations] = React.useState<Array<IAnnualObligations>>([]);
  const [currentYear, setCurrentYear] = React.useState(dateCurrent.getFullYear());
  const [loading, setLoading] = React.useState(false);
  const [toggleTable, setToggleTable] = React.useState(false);

  const getList = React.useCallback(async () => {
    setLoading(true);
    request.get(`calendarioAnual/${currentYear}`).then((response) => {
      if (response.data.ok === 'Sucesso') {
        const { listagem } = response.data;
        setAnnualObligations(listagem);
      } else {
        toast.error(`Erro: ${response.data.erro}`);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [currentYear]);

  React.useEffect(() => {
    if (size.sizeWidth <= 768)
      setToggleTable(true);
    else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('CAMCC'));
  }, [dispatch]);

  React.useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Container>
      {loading && <Loading />}

      <h1>CAMCC - Calendario Anual de Manutenção e Conservação Condominiais</h1>
      <YearComponent>
        <IoIosArrowBack size={30} onClick={() => setCurrentYear(currentYear - 1)} />
        <span>{currentYear}</span>
        <IoIosArrowForward size={30} onClick={() => setCurrentYear(currentYear + 1)} />
      </YearComponent>

      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
            <th>Nome</th>
            <th>Validadde</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {(annualObligations?.length === 0) && (
                <EmptyMessage colSpan={2}>
                  <p>{erro}</p>
                </EmptyMessage>
              )}
            </tr>
            {annualObligations?.map((o) => (
              <tr key={o.id}>
                <td>{o.nome}</td>
                <td>{dateStringToDateFormat(o.dataValidade).split(' ')[0]}</td>
              </tr>
            ))}
          </tbody>
        </TableFlex1>
        :
        <>
          {(annualObligations?.length === 0) && (
            <EmptyMessageText>{erro}</EmptyMessageText>
          )}
          {annualObligations?.map((o) => (
            <TableFlex2 key={o.id}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <td>{o.nome}</td>
                </tr>
                <tr>
                  <th>Validadde</th>
                  <td>{dateStringToDateFormat(o.dataValidade).split(' ')[0]}</td>
                </tr>
              </thead>
            </TableFlex2>
          ))}
        </>
      }
    </Container>
  );
};

export default CAMCC;
