import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';

const DetailsBankSlip = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  //   const [bankSlip, setBankSlip] = React.useState();
  //   const [menssage, setMenssage] = React.useState('');
  const linkDownload = new URLSearchParams(search).get('linkDownload') as string;
  const linkDados = new URLSearchParams(search).get('linkDados') as string;

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Detalhes do boleto'));
    request.get(linkDados).then(() => {
    //   if (response.data.situacao) {
    //     setBankSlip(response.data);
    //   } else{
    //       setMenssage(response.data.mensagem);
    //   }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>
      <h2>{linkDownload}</h2>
    </div>
  );
};
export default DetailsBankSlip;
