import * as React from 'react';
import { useDispatch } from 'react-redux';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import Button from '../../components/Button/Button';
import { BiDownload } from 'react-icons/bi';
// eslint-disable-next-line
import Residents from './Residents';
import Loading from 'react-loading';
import { COLORS } from "../../utils/constants";
import { ExportSpan } from './styles';
import { goToExternalLink } from '../../utils/functions';
import { toast } from 'react-toastify';

const ListResidents = () => {
  const dispatch = useDispatch();
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [linkDownload, setLinkDownload] = React.useState('');

  const getList = React.useCallback(async () => {
    setLoadingSearch(true);
    request.get('listarInformacoesCondominos').then((response) => {
      if (response.data.erro) {
        toast.error(response.data.erro);
        return;
      }
      if (response.data.ok === 'Sucesso') {
        setLinkDownload(response.data.LinkDowload)
      }
    })
      .catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Erro : A requisição falhou');
        }
      })
      .finally(() => {
        setLoadingSearch(false);
      });
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Lista de Moradores'));
  }, [dispatch]);

  return (
    <div style={{ position: "relative", minHeight: "70vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

      {loadingSearch &&
        <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
          <Loading type='spin' color={COLORS.SECONDARY} width={90} />
        </div>
      }

      <ExportSpan>Listagem de condôminos em PDF</ExportSpan>

      <Button
        theme="primary"
        disabled={loadingSearch ? true : false}
        onClick={() => goToExternalLink(linkDownload)}
        style={loadingSearch ? {
          height: 40,
          width: 190,
          fontSize: 16,
          fontWeight: 'bold',
          marginTop: 12,
          marginLeft: 10,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "rgba(68,170,136,.5)"
        } : {
          height: 40,
          width: 190,
          fontSize: 16,
          fontWeight: 'bold',
          marginTop: 12,
          marginLeft: 10,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <BiDownload size={30} color='white' />
        Exportar arquivo
      </Button>

      {/* <Residents
        myarray={myarray}
        totalPages={totalPages}
        currentPage={currentPage}
        getBoletos={getList}
      /> */}
    </div>
  );
};

export default ListResidents;