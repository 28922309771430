import React, { useEffect } from "react";
import { IListReservas } from "../../../../interfaces/responses/IListReservas";
import { useWindowSize } from "../../../Login";
import { EmptyMessage, TableFlex1 } from "./styles";

interface Props {
    reservasList: IListReservas[];
    handleVisitorType: (value: number) => string;
}

const TableReservas: React.FC<Props> = ({ reservasList }) => {

    const size = useWindowSize();
    const erro = 'Nenhum resultado encontrado';

    return (
        <>
            {/* ======================= RESPONSIVE TABLE ===================== */}

            {
                <TableFlex1>
                    <thead>
                        <tr>
                            <th>Data da Reserva</th>
                            <th>Horário</th>
                            <th>Condômino</th>
                            <th>Área Comum</th>
                            <th>Termo de Reserva</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {(reservasList.length === 0) && (
                                <EmptyMessage colSpan={5}>
                                    <p>{erro}</p>
                                </EmptyMessage>
                            )}
                        </tr>
                        {reservasList &&
                            reservasList.map((v) => (
                                <tr key={v.id}>
                                    <td>{v.dataReserva}</td>
                                    <td>{v.horaInicio} às {v.horaTermino}</td>
                                    <td>{v.condomino}</td>
                                    <td>{v.areaLazer}</td>
                                    <td>{v.urlTermo}</td>
                                    <td>{v.status}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </TableFlex1>
            }
        </>
    )
}

export default TableReservas;