import React from 'react';
import {
  IconButton,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  FiMenu,
} from 'react-icons/fi';

import styled from 'styled-components';
import { COLORS } from '../../../utils/constants';

import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import RootState from '../../../interfaces/states/RootState';

import iconUser from '../../../assets/icon-user.png';
import { ActionsSession } from '../../../store/ducks/session';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const dispatch = useDispatch();

  const { descVinculoAtual, isEmployee } = useSelector((state: RootState) => state.session);
  const { textCenter } = useSelector((state: RootState) => state.header);
  const history = useHistory();

  const initialsPathNames = ['/home_manager', '/home_condomino', '/portal_funcionario'];

  const logout = () => {
    dispatch(ActionsSession.destroy());
  };

  return (
    <FlexHeader
      //paddingLeft={initialsPathNames.includes(history.location.pathname) ? '252px' : '0'}
      boxShadow="xs"
      {...rest}
    >

      {!isEmployee ? (
        initialsPathNames.includes(history.location.pathname) && (
          <>
            <Empty />
            <IconButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onOpen}
              variant="unstyled"
              aria-label="open menu"
              icon={<FiMenu color="#fff" size={30} />}
            />
          </>)
      ) : (
        <>
          <Empty />
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
            variant="unstyled"
            aria-label="open menu"
            icon={<FiMenu color="#fff" size={30} />}
          />
        </>
      )
      }

      {(!initialsPathNames.includes(history.location.pathname) && !isEmployee) && (
        <ButtonWrapper>
          <BiArrowBack size={31} onClick={() => history.goBack()} color="#fff" style={{ cursor: 'pointer' }} />
        </ButtonWrapper>
      )}

      <CenterText>
        {textCenter}
      </CenterText>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
              data-testid="up-navbar-menu"
            >
              <HStack alignItems="center">
                <img src={iconUser} alt="user avatar" width="31px" />
                <TextVinculo>
                  {descVinculoAtual}
                </TextVinculo>
                <IoIosArrowDown color="#fff" />
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => history.push('reset_password')}>Trocar Senha</MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout} data-testid="up-navbar-logout">Sair</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </FlexHeader>
  );
};

const FlexHeader = styled(Flex)`
    //margin-left:288px;
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
    height: 88px;
    align-items: center;
    background: transparent linear-gradient(90deg, #009B6E 0%, #3B308B 100%) 0% 0% no-repeat padding-box;

    @media screen and (max-width: 1024px) {
      padding-right: 25px;
    }

    @media screen and (max-width: 768px) {
      padding-right: 15px;
      padding-left: 15px;
    }
`;

const Empty = styled.div`
  width: 288px;

  @media screen and (max-width: 768px) {
      display: none;
  }
`;

const TextVinculo = styled(Text)`
    font-size: 16px;
    color: white;
    font-weight: bold;
    max-width: 223px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
`;

const CenterText = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  color: white;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }

  @media screen and (max-width: 520px) {
    font-size: 22px;
  }

  @media screen and (max-width: 425px) {
    font-size: 19px;
  }

  @media screen and (max-width: 375px) {
    text-align: center;
    padding: 0 20px ;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 288px;
  padding-left: 45px;

  @media screen and (max-width: 1024px) {
      padding-left: 25px;
  }

  @media screen and (max-width: 768px) {
      margin-left: 0;
      padding-left: 0;
  }
`;

export default MobileNav;