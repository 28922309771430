import { parseISO, format } from 'date-fns';

export const timestampToDateStringISO = (timestamp:number) => {
  const date = new Date(timestamp);
  const day = (`00${date.getDate()}`).slice(-2);
  const month = (`00${date.getMonth() + 1}`).slice(-2);
  const year = date.getFullYear();
  const string = `${year}-${month}-${day}`;
  return string;
};

export const timestampToTimeString = (timestamp:number) => {
  const date = new Date(timestamp);
  const day = (`00${date.getHours()}`).slice(-2);
  const month = (`00${date.getMinutes()}`).slice(-2);
  const string = `${day}:${month}`;
  return string;
};

export const dateStringToTimestamp = (dateString:string) => parseISO(dateString).getTime();

export const dateStringToDate = (dateString:string) => format(parseISO(dateString), 'uuuu-LL-dd');

export const dateStringToTime = (dateString:string) => format(parseISO(dateString), 'HH:mm');

export const dateStringToDateFormat = (dateString:string) => format(parseISO(dateString), 'dd/LL/uuuu');

export const dateStringToYearMonth = (dateString:string) => format(parseISO(dateString), 'LL/uuuu');

export const dateStringToDateTimeFormat = (dateString:string) => format(parseISO(dateString), 'dd/LL/uuuu HH:mm');

export const timestampToDateTimeFormat = (timestamp:number) => format(timestamp, 'dd/LL/uuuu HH:mm');

export const timestampToDateTimeISO = (timestamp:number) => format(timestamp, 'uuuu-LL-dd HH:mm');

export const timestampToDateString = (timestamp:number) => format(timestamp, 'dd/LL/uuuu');
