import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ErrorMessages {
  [key: number]: string;
}

const useErrorToast = (errorMessages: ErrorMessages) => {
  const location = useLocation();

  const showErrorToast = () => {
    const [, error] = location.search.split('=');

    const errorMessage = errorMessages[Number(error)];

    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          fontWeight: 'bold',
          padding: 20,
          borderRadius: 12,
        },
      });
    }
  };

  return showErrorToast;
};

export default useErrorToast;
