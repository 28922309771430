import styled from 'styled-components';
import { COLORS, WIDTHS } from '../../../utils/constants';

export const ContainerMessages = styled.div`
  width: 100%;
  max-width: ${WIDTHS.MAX}px;
  max-height: calc(100vh - 170px);
  padding: 4px 0;

  display: flex;
  max-width: ${WIDTHS.MAX};
  flex-direction: column-reverse;
  overflow: hidden;

  > div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 34px;
    box-sizing: content-box;

    margin-top: 20px;
    padding-bottom: 5px;
  }
`;

export const DivHeaderMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DivMessage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 5px #aaa;
  min-width: 250px;
  max-width: 450px;
  margin-top: 20px;

  align-self: ${(props: any) => (props['data-isselfmessage'] ? 'flex-end' : 'flex-start')};
  background-color: ${(props: any) => (props['data-isselfmessage'] ? COLORS.PRIMARY : '#fff')};
`;

export const TextNameAssembly = styled.h2`
  color: ${COLORS.TEXT_PRIMARY};
  font-size: 26px;
  text-align: center;
`;

export const TextMessage = styled.span`
  color: ${(props: any) => (props['data-isselfmessage'] ? '#fff' : COLORS.TEXT_PRIMARY)};
  font-size: 14px;
`;

export const TextNameMessage = styled.span`
  color: ${(props: any) => (props['data-isselfmessage'] ? '#fff' : COLORS.TEXT_PRIMARY)};
  font-size: 12px;
`;

export const TextTimeMessage = styled.span`
  color: ${COLORS.SECONDARY};
  font-size: 12px;
`;

export const WrapperChat = styled.div`
  height: 400px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DivSendMessage = styled.div`
  /* position: absolute;
  bottom: 10px; */
  width: 100%;
  max-width: ${WIDTHS.MAX}px;

  display: flex;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
  margin-top: auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  input {
    display: flex;
    flex: 1;
    padding: 5px 20px;
    border: none;
    font-size: 16px;
    &:focus {
      outline: none;
    }
  }
`;
