import React, { ReactNode } from 'react';
import {
  ColWrapper,
  Container, ContainerClean, DivIcon, RowTextStyled, RowTitleStyled, RowWrapper, Title,
} from './styles';

interface CardProps {
  icon?: JSX.Element;
  onClick?: () => void;
  title?: string;
  children?: ReactNode;
}

const Card = ({ children }:any) => (
  <ContainerClean>
    {children}
  </ContainerClean>
);

const Row = ({ children }:any) => (
  <RowWrapper>
    {children}
  </RowWrapper>
);

const Col = ({ children }:any) => (
  <ColWrapper>
    {children}
  </ColWrapper>
);

const RowTitle = ({ children }:any) => (
  <RowTitleStyled>
    {children}
  </RowTitleStyled>
);

const RowText = ({ children }:any) => (
  <RowTextStyled>
    {children}
  </RowTextStyled>
);

const CardHome = ({
  title, icon, children, onClick,
}:CardProps) => (
  <Container onClick={onClick}>
    {
      children || (
        <>
          <DivIcon>
            {icon}
          </DivIcon>
          <Title>{title}</Title>
        </>
      )
    }

  </Container>
);

export default CardHome;

export const CardClean = {
  Card,
  Col,
  Row,
  RowTitle,
  RowText,
};
