import React, { useEffect } from "react";
import { IListVisits } from "../../../../interfaces/responses/IListVisits";
import { useWindowSize } from "../../../Login";
import {
    EmptyMessage, EmptyMessageText,
    TableFlex1, TableFlex2
} from "./styles";

interface Props {
    visitsList: IListVisits[];
    handleVisitorType: (value: number) => string;
}

const TableVisits: React.FC<Props> = ({ visitsList, handleVisitorType }) => {

    const size = useWindowSize();
    const erro = 'Nenhum resultado encontrado';
    const [toggleTable, setToggleTable] = React.useState(false);

    useEffect(() => {
        if (size.sizeWidth <= 980)
            setToggleTable(true);
        else {
            setToggleTable(false);
        }
    }, [size.sizeWidth]);

    return (
        <>
            {/* ======================= RESPONSIVE TABLE ===================== */}

            {!toggleTable ?
                <TableFlex1>
                    <thead>
                        <tr>
                            <th>Nome do visitante</th>
                            <th>CPF</th>
                            <th>Tipo de visitante</th>
                            <th>Horário da visita</th>
                            <th>Unidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {(visitsList.length === 0) && (
                                <EmptyMessage colSpan={5}>
                                    <p>{erro}</p>
                                </EmptyMessage>
                            )}
                        </tr>
                        {visitsList &&
                            visitsList.map((v) => (
                                <tr key={v.id}>
                                    <td>{v.nomeVisitante}</td>
                                    <td>{v.cpf}</td>
                                    <td>{handleVisitorType(v.tipoVisitante)}</td>
                                    <td>{v.horario}</td>
                                    <td>{v.unidade.descricaoCompleta}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </TableFlex1>
                :
                <>
                    {(visitsList.length === 0) && (
                        <EmptyMessageText>{erro}</EmptyMessageText>
                    )}
                    {visitsList.map((v) => (
                        <TableFlex2 key={v.id}>
                            <thead>
                                <tr>
                                    <th>Nome do visitante</th>
                                    <td>{v.nomeVisitante}</td>
                                </tr>
                                <tr>
                                    <th>CPF</th>
                                    <td>{v.cpf}</td>
                                </tr>
                                <tr>
                                    <th>Tipo de visitante</th>
                                    <td>{handleVisitorType(v.tipoVisitante)}</td>
                                </tr>
                                <tr>
                                    <th>Horário da visita</th>
                                    <td>{v.horario}</td>
                                </tr>
                                <tr>
                                    <th>Unidade</th>
                                    <td>{v.unidade.descricaoCompleta}</td>
                                </tr>
                            </thead>
                        </TableFlex2>
                    ))}
                </>
            }
        </>
    )
}

export default TableVisits;