import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsSession } from '../store/ducks/session';
import { varLocalStorage } from '../utils/constants';
import { setHeader } from '../services/request';
import RootState from '../interfaces/states/RootState';
import ISession from '../interfaces/states/ISession';

export const SessionListener = () => {
  const dispatch = useDispatch();
  const session: any = useSelector((state: RootState) => state.session);

  useEffect(() => {
    async function verifySession() {
      const sessionStore = JSON.parse(
        localStorage.getItem(varLocalStorage.session) || '{}',
      ) as ISession;
      if (sessionStore && sessionStore.isLogged) {
        dispatch(ActionsSession.replace(sessionStore));
      }
    }
    verifySession();
  }, [dispatch]);

  useEffect(() => {
    async function saveSession() {
      // if (session.stayConnect) {
      localStorage.setItem(varLocalStorage.session, JSON.stringify(session));
      // } else {
      //   localStorage.removeItem(varLocalStorage.session);
      // }
    }
    saveSession();
    setHeader(session);
  }, [session]);

  return null;
};