import { combineReducers } from 'redux';
import drawer from './drawer';
import header from './header';
import session from './session';
import tempFiles from './tempFiles';
import toggleButtons from './toggleButtons';

export default combineReducers({
  drawer,
  header,
  session,
  tempFiles,
  toggleButtons
});
