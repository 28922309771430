import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import produce from 'immer';
import { toast } from 'react-toastify';
import { ActionsHeader } from '../../../store/ducks/header';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { convertFileToBase64 } from '../../../utils/functions';
import RootState from '../../../interfaces/states/RootState';
import { ActionsTempFiles } from '../../../store/ducks/tempFiles';

import { DivAttatch } from './styles';
import request from '../../../services/request';

const CreateAssembly = () => {
  const dispatch = useDispatch();
  const tempFiles = useSelector((state:RootState) => state.tempFiles);

  const [idAssembly, setIdAssembly] = React.useState();
  const [title, setTitle] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [attatchs, setAttatchs] = React.useState([]);
  const [status] = React.useState('CRIADA');
  const [initialDate, setInitialDate] = React.useState('');
  const [finalyDate, setFinalyDate] = React.useState('');

  const [typeSubmit, setTypeSubmit] = React.useState('new');

  const addAttachTemp = (item:string) => {
    dispatch(ActionsTempFiles.addFile(item));
  };

  const removeAttach = (idAttach:number, index:number) => {
    const handleDeleteAttachInServer = async () => {
      request.post(`removeAnexosAssembleiaVirtual/${idAttach}`).then((response) => {
        if (response.data.ok) {
          setAttatchs(produce(attatchs, (draft) => {
            draft.splice(index, 1);
          }));
        }
      });
    };
    handleDeleteAttachInServer();
  };

  const removeAttachTemp = (index:number) => {
    dispatch(ActionsTempFiles.removeFile(index));
  };

  const getAttachments = (assemblyId:number) => {
    request.get(`getAnexosByAssembleiaVirtual/${assemblyId}`).then((response) => {
      const arrayTemp = response.data.listagem.map((a:any) => ({ id: a.id, link: a.link }));
      setAttatchs(arrayTemp);
    });
  };

  const handleSubmit = () => {
    const apiRoute = typeSubmit === 'edit'
      ? `assembleiasvirtuais/${idAssembly}`
      : 'assembleiasvirtuais';

    const form = {
      titulo: title,
      descricao: desc,
      horarioInicio: initialDate,
      horarioTermino: finalyDate,
      status,
      'anexos[]': tempFiles,
    };

    request.post(apiRoute, form).then((response) => {
      if (response.data.ok) {
        const { elemento } = response.data;
        toast.success('Assembleia criada com sucesso');

        setTypeSubmit('edit');
        setIdAssembly(elemento.id);

        request.post(`addAnexosAssembleiaVirtual/${elemento.id}`, form)
          .then((response2) => {
            if (response2.data.ok) {
              getAttachments(elemento.id);
              dispatch(dispatch(ActionsTempFiles.clearFiles()));
            }
          });
      } else {
        toast.error('Erro ao criar assembleia');
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Nova assembleia virtual'));
    dispatch(ActionsTempFiles.clearFiles());
  }, [dispatch]);

  return (
    <div>
      <Form.Container>
        <Input value={title} onChange={(e:any) => setTitle(e.target.value)} label="Título da assembleia" />
        <Input
          value={initialDate}
          type="datetime-local"
          label="Horário de início"
          onChange={(e:any) => setInitialDate(e.target.value)}
        />
        <Input
          value={finalyDate}
          type="datetime-local"
          label="Horário de término"
          onChange={(e:any) => setFinalyDate(e.target.value)}
        />
        <Input value={desc} onChange={(e:any) => setDesc(e.target.value)} label="Descrição" />

        {
          attatchs.map((a:any, i) => (
            <DivAttatch key={a}>
              <span>{`Anexo - ${i + 1}`}</span>
              <AiOutlineClose onClickCapture={() => removeAttach(a.id, i)} />
            </DivAttatch>
          ))
        }

        {
          tempFiles.map((a, i) => (
            <DivAttatch key={a}>
              <span>{`Anexo - ${i + 1}`}</span>
              <AiOutlineClose onClickCapture={() => removeAttachTemp(i)} />
            </DivAttatch>
          ))
        }
        <Input type="file" label="Adicionar anexo" onChange={(e:any) => convertFileToBase64(e.target.files[0], addAttachTemp)} />
        <Button onClick={handleSubmit} theme="secondary" marginTop={20}>Salvar</Button>
      </Form.Container>

    </div>
  );
};

export default CreateAssembly;
