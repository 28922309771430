import styled from 'styled-components';

export const TextWelcome = styled.div`
  font-size: 48px;
  font-weight: bold;
  font-family: "Montserrat";
  margin-top: 5px;

  @media screen and (max-width: 425px) {
    font-size: 40px;
    text-align: center;
  }
`;
