import { Table } from "@chakra-ui/react";
import styled from "styled-components";

export const TableFlex2 = styled(Table)`
    margin: 15px 0;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      padding: 5px 0;
    }

    tr:last-child {
      border: none;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }

    thead {
      
      th {
        padding-left: 10px;
        
        @media screen and (max-width: 520px) {
          padding-right: 15px;
        }
      }
    }
`;