import React from 'react';
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';
import {
  FlexIdeia
} from './styles'

import SidebarContent from './components/SidebarContent';
import MobileNav from './components/MobileNav';

const WrapperDashboard: React.FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />

      <Flex
        ml={{ base: 0, md: '288px' }}
        bg="gray.50"
        paddingTop="23px"
        direction="column"
        justifyContent="space-between"
      >
        {children}

        <FlexIdeia>
          <Box>
            <Text textAlign="center">© 2020 - Ideia Soluções em Sistemas Ltda.</Text>
          </Box>

          <Box>
            <Link as="a" href="https://ideiasti.com.br" cursor="pointer">
              https://ideiasti.com.br
            </Link>
          </Box>

          <Box>
            <Link as="a" href="contato@ideiasti.com.br" cursor="pointer">
              contato@ideiasti.com.br
            </Link>
          </Box>

        </FlexIdeia>
      </Flex>
    </Box>
  );
};

export default WrapperDashboard;