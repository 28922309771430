/* eslint array-callback-return:off */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { YearComponent, CardMonth, ContainerModal } from './styles';
import { GridCard } from '../../components/GridCard/styles';
import { COLORS, MONTH_NAMES } from '../../utils/constants';
import { IAccountability, IAccountabilityByYearMonth } from '../../interfaces/responses/IAccountability';
import Modal from '../../components/Modal/Modal';
import { CardClean } from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import { goToExternalLink } from '../../utils/functions';

const YearAccountability = () => {
  const dateCurrent = new Date();
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = React.useState(dateCurrent.getFullYear());
  const [accountabilities, setAccountabilities] = React.useState<IAccountabilityByYearMonth>({});
  const [accountabilitiesByYearMonth, setAccountabilitiesByYearMonth] = React.useState<Array<IAccountability>>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalVisible, setModalVisible] = React.useState(false);

  const getAccountabilitiesByYearMonth = (year:number, month:number) => accountabilities[`${year}-${(`00${month}`).slice(-2)}`];

  const formatDatas = (arrayAccountability:Array<IAccountability>) => {
    const ac = {} as IAccountabilityByYearMonth;
    arrayAccountability.map((a) => {
      const [year, month] = a.dataDocumento.split('-');
      if (!ac[`${year}-${month}`]) ac[`${year}-${month}`] = [];
      ac[`${year}-${month}`].push(a);
    });
    return ac;
  };

  const getList = React.useCallback(async () => {
    setIsLoading(true);
    request.get(`listarPrestacao/${currentYear}`).then((response) => {
      if (response.data.ok === 'Sucesso') {
        const arrayAccountability = response.data.listagem as Array<IAccountability>;
        setAccountabilities(formatDatas(arrayAccountability));
      } else {
        toast.error(`Erro: ${response.data.erro}`);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [currentYear]);

  const getFormatTextItens = (year:number, month:number) => {
    const itens = getAccountabilitiesByYearMonth(year, month);
    if (!itens || itens.length === 0) return 'Nenhum item';
    if (itens.length === 1) return '1 item';
    return `${itens.length} itens`;
  };

  const showAccountabilityMonth = (year:number, month:number) => {
    const byYearMonth = getAccountabilitiesByYearMonth(year, month);
    if (byYearMonth?.length >= 1) {
      setAccountabilitiesByYearMonth(byYearMonth);
      setModalVisible(true);
      return;
    }
    toast.warning('Nenhuma prestação de conta foi encontrada para o mês selecionado');
  };

  React.useEffect(() => {
    getList();
  }, [getList]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Prestação de contas'));
  }, [dispatch]);

  return (
    <div>
      <YearComponent>
        <IoIosArrowBack size={30} onClick={() => setCurrentYear(currentYear - 1)} />
        <span>{currentYear}</span>
        <IoIosArrowForward size={30} onClick={() => setCurrentYear(currentYear + 1)} />
      </YearComponent>

      <GridCard>
        {
          MONTH_NAMES.map((m, i) => (
            <CardMonth key={m} onClickCapture={() => showAccountabilityMonth(currentYear, i + 1)}>
              <span>{m}</span>
              <span>
                {getFormatTextItens(currentYear, i + 1)}
              </span>
            </CardMonth>
          ))
        }
      </GridCard>
      {isLoading && <Loading /> }
      {modalVisible && (
      <Modal>
        <ContainerModal>
          <IoIosClose color={COLORS.TEXT_PRIMARY} size={40} onClickCapture={() => setModalVisible(false)} />
          {
            accountabilitiesByYearMonth.map((a) => (
              <CardClean.Card key={a.id}>
                <h3>{a.descricao}</h3>
                <Button onClick={() => goToExternalLink(a.linkDownload)} theme="primary">Download</Button>
              </CardClean.Card>
            ))
          }
        </ContainerModal>
      </Modal>
      )}
    </div>
  );
};

export default YearAccountability;
