/* eslint-disable no-unused-expressions */
import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import RootState from '../../interfaces/states/RootState';

import request from '../../services/request';

interface ChatFuncionarioContextData {
  newMessages: number;
}

const ChatFuncionarioContext = createContext<ChatFuncionarioContextData>(
  {} as ChatFuncionarioContextData,
);

const ChatFuncionarioProvider: React.FC = ({ children }) => {
  const { isLogged } = useSelector((state: RootState) => state.session);

  const [newMessages, setNewMessages] = React.useState(0);

  React.useEffect(() => {
  
    // let interval: any = null;

    if (isLogged) {
      // request.get('buscarFuncionariosCondominio').then((response) => {
      //   if (response?.data?.lenght > 0) {
      //         response?.data?.forEach((f: any) => {
      //           request
      //             .post('procurarConversaChatFuncionarioNaoLida', {
      //               idFuncionario: f.idUsuario,
      //             })
      //             .then((responseMessages) => {
      //               setNewMessages(responseMessages?.data?.quantidade || 0);
      //             });
      //         });
      //   }
      // });

      // atualiza a cada 10s buscando novas mensagens
      // interval = setInterval(() => {
      //   request.get('buscarFuncionariosCondominio').then((response) => {
      //     if (response?.data?.lenght > 0) {
      //           response?.data?.forEach((f: any) => {
      //             request
      //               .post('procurarConversaChatFuncionarioNaoLida', {
      //                 idFuncionario: f.idUsuario,
      //               })
      //               .then((responseMessages) => {
      //                 setNewMessages(responseMessages?.data?.quantidade || 0);
      //               });
      //           });
      //     }
      //   });
      // }, 10000);
    }

    // return () => {
    //   if (interval) {
    //     clearInterval(interval);
    //   }
    // };
  }, [isLogged]);

  return (
    <ChatFuncionarioContext.Provider
      value={{
        newMessages,
      }}
    >
      {children}
    </ChatFuncionarioContext.Provider>
  );
};

function useChatFuncionario(): ChatFuncionarioContextData {
  const context = useContext(ChatFuncionarioContext);

  return context;
}

export { ChatFuncionarioProvider, useChatFuncionario };
