import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const YearComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${COLORS.PRIMARY};
  margin: 10px 0;

  span {
    font-size: 24px;
    margin: 0 50px;
  }

  svg {
    cursor: pointer;
  }
`;

export const CardMonth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  min-height: 100px;
  color: ${COLORS.TEXT_DISABLED};
  font-size:14px;
  cursor: pointer;

  span:first-of-type {
    color: ${COLORS.TEXT_PRIMARY};
    font-weight: bold;
    font-size:22px;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-width: 300px;
  max-width: 100%;
  min-height: 100px;
  background-color: #ddd;
  padding: 10px 0;

  > svg {
    cursor: pointer;
  }

  h3 {
    text-align: center;
    color: ${COLORS.TEXT_PRIMARY};
  }
`;
