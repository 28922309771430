import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const Top = styled.div`
   display: flex;
   justify-content: center;
   gap: 10px;
   width: 100%;
   align-items: center;
`;

export const SeeFilter = styled.span`
  font-size: 18px;
  color: ${COLORS.PRIMARY};
`;

export const TopHideFilter = styled.div`
   display: flex;
   justify-content: center;
   gap: 10px;
   align-items: center;
   align-self: flex-end;
   cursor: pointer;
`;

export const ArrowButton = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 30px;
`;