import React from 'react';
import Button from '../Button/Button';

import { DivToggleButton } from './styles';

interface Props {
  selected: number;
  buttons: Array<{
    label: string;
    onClick: ()=>void;
  }>;
}

const ToggleButtons = ({ selected, buttons }: Props) => (
  <DivToggleButton>
    {buttons.map((b, i) => (
      <Button
        style={{ fontWeight: 'bold', fontSize: 16 }}
        key={b.label}
        theme={selected === i ? 'secondary' : 'default'}
        onClick={b.onClick}
      >
        {b.label}
      </Button>
    ))}
  </DivToggleButton>
);

export default ToggleButtons;
