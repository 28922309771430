import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import { Container, BoxMenu } from './styles';

import {
  FaBook,
  FaFileAlt,
  FaEye,
  FaSearch,
  FaEdit,
  FaBarcode,
  FaHandshake,
  FaUserEdit,
  FaIdCardAlt,
  FaWalking,
} from 'react-icons/fa';
import { AiFillGift, AiOutlineCheck } from 'react-icons/ai';
import { MdAutorenew } from 'react-icons/md';
import { IoIosWarning } from 'react-icons/io';

import { BiSpreadsheet } from 'react-icons/bi';

import { ActionsHeader } from '../../store/ducks/header';
import SelectUnidades from './components/SelectUnidades';

import ContentTabPanel from './components/ContentTabPanel';
import { useTabSelected } from '../../hooks/modules/TabSelectedContext';
import ModalClubePartiu from './components/ModalClubePartiu';
import { useModalClubePartiu } from '../../hooks/modules/ModalClubePartiuContext';
import MarketingPanel from './components/MarketingPanel';
import RootState from '../../interfaces/states/RootState';

const cardsCondominium = [
  {
    title: 'Documentos do Condomínio',
    icon: FaFileAlt,
    route_path: 'condominium_documents',
  },
  {
    title: 'Portal da Transparência, Contratos e Diversos',
    icon: FaEye,
    route_path: 'list_default/portalTransparencia',
  },
  {
    title: 'Prestação de contas',
    icon: FaSearch,
    route_path: 'year_accountability',
  },
  {
    title: 'Assembleia virtual',
    icon: FaBook,
    route_path: 'list_assemblies',
  },
  {
    title: 'Calendário Mensal de Obrigações Condominiais',
    initials: 'CMOC',
    route_path: 'cmoc',
  },
  {
    title: 'Calendário Anual de Manutenção e Conservação Condominial',
    initials: 'CAMCC',
    route_path: 'camcc',
  },
  {
    title: 'Receitas',
    icon: FaEdit,
    route_path: 'revenues',
  },
  //   {
  //     title: 'Despesas',
  //     icon: MdMoneyOff,
  //     route_path: 'list_expenses',
  //   },
  {
    title: 'Planilha orçamentária',
    icon: BiSpreadsheet,
    route_path: 'list_default/listarPlanilhas',
  },
  {
    title: 'Enquetes',
    icon: AiOutlineCheck,
    route_path: 'list_enquetes',
  }
];

const cardsUnity = [
  {
    title: 'Meus Dados / Conta',
    icon: FaIdCardAlt,
    route_path: 'my_unit',
  },
  {
    title: 'Atualização cadastral',
    icon: FaUserEdit,
    route_path: 'register_update',
  },
  {
    title: 'Boletos | 2ª Via',
    icon: FaBarcode,
    route_path: 'bank_slip',
  },
  {
    title: 'Faça sua reserva',
    icon: FaHandshake,
    route_path: 'calendar_reserves',
  },
  {
    title: 'Registre Ocorrências',
    icon: IoIosWarning,
    route_path: 'list_occurrencies',
  },
  {
    title: 'Faça seu Auto Acordo de Débito',
    icon: MdAutorenew,
    route_path: 'auto_acordo',
  },
  {
    title: 'Carta de adimplência',
    icon: FaFileAlt,
    action: 'CREATE_LETTER_COMPLIANCE',
  },
  {
    title: 'Cadastre / Agende suas Visitas',
    icon: FaWalking,
    route_path: 'list_visit_scheduling',
  },
  {
    title: 'Partiu Clube de Vantagens',
    icon: AiFillGift,
    action: 'OPEN_MODAL_CP',
  },
];
function HomeCondomino() {
  const dispatch = useDispatch();
  const { descVinculoAtual, todosVinculos, isManager } = useSelector((state : RootState) => state.session);

  const { defaultIndex, handleChangeDefaultIndex } = useTabSelected();
  const { showModalClubePartiu, handleClose } = useModalClubePartiu();

  const handleIdAdm = async (todosVinculos: any, descVinculoAtual: string) => {
    const { unidade } = todosVinculos.find((item: any) => item.unidade.descricao === descVinculoAtual);
    const element = document.getElementById('wbot-chat-container');

    if(unidade.idAdministradora === 34) {
      element!.style!.display = "none";
      return;
    }

    element!.style!.display = "";
    return;
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Portal do Condômino'));
  }, [dispatch]);

  React.useEffect(() => {
    setTimeout(() => {
      handleIdAdm(todosVinculos, descVinculoAtual)
    }, 1000);
  }, []);

  return (
    <Container>
      <BoxMenu>
        <SelectUnidades />

        <Tabs
          variant='solid-rounded'
          isFitted
          mt='12px'
          defaultIndex={defaultIndex}
          onChange={handleChangeDefaultIndex}
        >
          <TabList>
            <Tab
              h='43px'
              boxShadow='5px 5px 20px #0000000D'
              bg='#fff'
              _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
              borderRadius='14px 0px 0px 14px'
              fontSize='16px'
              fontFamily='Montserrat'
              fontWeight='bold'
            >
              Unidade
            </Tab>
            <Tab
              fontSize='16px'
              boxShadow='5px 5px 20px #0000000D'
              bg='#fff'
              _selected={{ backgroundColor: '#3B308B', color: '#fff' }}
              borderRadius='0px 14px 14px 0px'
              fontFamily='Montserrat'
              fontWeight='bold'
            >
              Condomínio
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p='0'>
              <ContentTabPanel list={cardsUnity} />
            </TabPanel>
            <TabPanel p='0'>
              <ContentTabPanel list={cardsCondominium} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </BoxMenu>

      <MarketingPanel />

      <ModalClubePartiu
        visible={showModalClubePartiu}
        handleClose={handleClose}
        vinculo='CONDOMINO'
      />
    </Container>
  );
}

export default HomeCondomino;
