import styled from 'styled-components';

export const FiltersBudget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const Container = styled.div`

h3{
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin : 24px 0 12px;
}
`;

export const Card = styled.div`
background: #FFFFFF;
border-radius: 13px;
opacity: 1;
padding: 16px;

strong {
    margin-right: 8px;
    font-family: Montserrat;
    font-weight: bold;
}

span {
    font-family: Montserrat;
    font-weight: 500;
}
`;
