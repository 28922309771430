import styled from "styled-components";
import { COLORS } from "../../utils/constants";

export const CaptureVideo = styled.div`
    position: relative;
    background-color: #000;

    .video {
        width: 100%;
        height: 100%;
        background-color: #000;
    }
`;

export const ButtonTakePhoto = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
    background-color: ${COLORS.PRIMARY};
    padding: 5px;
    border-radius: 6px;
    color: #fff;
    box-shadow: 3px 3px 8px #222;
`;

export const ButtonStopVideo = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
`;

export const ButtonDeletePhoto = styled.div`
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 999999;
`;

export const PictureTaken = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;