import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Button = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background-color: ${COLORS.SECONDARY};
    color: #fff;
    cursor: pointer;
    height: 100%;

    @media screen and (max-width: 575px) {
        height: 100%;
    }
`;

export const Container = styled.div<{ openOcorrence?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.openOcorrence ? 'space-around' : 'space-between'};
`;

/* 
${props => props.isOpen && `
    display: inline;
    padding-top: 10px;
    padding-right: 30px;
  `}
 */

export const CardsWrapper = styled.div<{ openOcorrence?: boolean }>`
    overflow: auto;
    display: flex;
    width: 100%;
    height: ${props => props.openOcorrence ? '180px' : 'fit-content'};
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px;
    margin: 35px auto;
    margin-bottom: 15px;
`;

export const HoriontalView = styled.div`
    display: flex;
`;

export const ImageContainer = styled.div`
    border: solid 2px ${COLORS.PRIMARY};
    border-radius: 4px;
    margin-right: 12px;
    cursor: pointer;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //height: 350px;
    padding: 0 15px;
`;

export const Content = styled.div`
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 2px solid ${COLORS.SECONDARY};
    overflow: auto;
`;

export const ButtonDownloadFiles = styled.div`
  background-color: ${COLORS.SECONDARY};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  border-radius: 6px;
  align-self: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const TextArea = styled.textarea`
    resize: none;
    height: 160px;
    border: 2px solid ${COLORS.PRIMARY};
    border-radius: 8px;
    padding: 10px;
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexRow = styled.div`
    display: flex;
    margin: 5px 0;
`;

export const Title = styled.span`
    font-weight: 700;
`