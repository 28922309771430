import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineDownload } from 'react-icons/ai';
import Button from '../../components/Button/Button';
import { IResponseListarReceitas, IResponseReceita } from '../../interfaces/responses/Receitas';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { goToExternalLink } from '../../utils/functions';
import { useWindowSize } from '../Login';
import { EmptyMessage, TableFlex1, TableFlex2, EmptyMessageText, Container } from './styles';

function Revenues() {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const erro = 'Nenhuma receita cadastrada';

  const [listRevennues, setListRevenues] = React.useState<Array<IResponseReceita>>();
  const [toggleTable, setToggleTable] = React.useState(false);

  const listToArray = (responseRevenues: IResponseListarReceitas) => {
    const arrayRevenues = Object.values(responseRevenues.listagem).map((r) => r);
    setListRevenues(arrayRevenues);
  };

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Receitas'));
    request.get('/listarReceitas').then((response) => {
      listToArray(response.data);
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (size.sizeWidth <= 920)
      setToggleTable(true);
    else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  return (
    <Container>

      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
              <th>Data</th>
              <th>Receitas</th>
              <th>Despesas</th>
              <th>Líquido</th>
              <th>Folha de Rosto</th>
              <th>Comprovante</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {(listRevennues?.length === 0) && (
                <EmptyMessage colSpan={6}>
                  <p>{erro}</p>
                </EmptyMessage>
              )}
            </tr>
            {listRevennues?.map((r) => (
              <tr key={r.id}>
                <td>{`${r.dataRepasse}/${r.competencia}`}</td>
                <td>{r.receita}</td>
                <td>{r.despesa}</td>
                <td>{r.valorRepassado}</td>
                <td>
                  <Button theme="primary" onClick={() => goToExternalLink(r.download)}>
                    Download
                    <AiOutlineDownload size={20} />
                  </Button>
                </td>
                <td>
                  <Button theme="primary" onClick={() => goToExternalLink(r.comprovante)}>
                    Download
                    <AiOutlineDownload size={20} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableFlex1>
        :
        <>
          {(listRevennues?.length === 0) && (
            <EmptyMessageText>{erro}</EmptyMessageText>
          )}
          {listRevennues?.map((r) => (
            <TableFlex2 key={r.id}>
              <thead>
                <tr>
                  <th>Data</th>
                  <td>{`${r.dataRepasse}/${r.competencia}`}</td>
                </tr>
                <tr>
                  <th>Receitas</th>
                  <td>{r.receita}</td>
                </tr>
                <tr>
                  <th>Despesas</th>
                  <td>{r.despesa}</td>
                </tr>
                <tr>
                  <th>Líquido</th>
                  <td>{r.valorRepassado}</td>
                </tr>
                <tr>
                  <th>Folha de Rosto</th>
                  <td>
                    <Button theme="primary" onClick={() => goToExternalLink(r.download)}>
                      Download
                      <AiOutlineDownload size={20} />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Comprovante</th>
                  <td>
                    <Button theme="primary" onClick={() => goToExternalLink(r.comprovante)}>
                      Download
                      <AiOutlineDownload size={20} />
                    </Button>
                  </td>
                </tr>
              </thead>
            </TableFlex2>
          ))}
        </>
      }
    </Container>
  );
}

export default Revenues;
