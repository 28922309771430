import styled from 'styled-components';
import { HEIGHTS, WIDTHS, COLORS } from '../../../utils/constants';

export const ViewCard = styled.div<{ openOcorrence?: boolean }>`
  position: relative;
  width: 48%;
  min-height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: flex-start;
  padding: 5px 20px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
  height: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 48%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    min-height: 115px;
    height: auto;
  }

`;

export const ViewItemCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-around;
  padding-right: 20px;

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${HEIGHTS.HEADER}px;
`;

export const LeftHeader = styled.div`
  display: flex;
  flex: 1;
  svg {
    margin: 0 10px;
    cursor: pointer;
  }
`;

export const CenterHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media(max-width: ${WIDTHS.MIN}px){
    flex: 5;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  flex: 1;
`;

export const TextCenter = styled.label`
  color: ${COLORS.ICONS_HEADER};
  font-size: 30px;
  text-align: center;
`;


export const Modal = styled.div`
  display: flex;
`;

export const ViewCardModal = styled.div`
    display: flex;
    position: relative;
    //height: 450px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
`;

export const Header = styled.div`
    display: flex;
    width: 600px;
    height: 45px;
    border-bottom-width: 1px;
    border-bottom-color: ${COLORS.PRIMARY};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Swipper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding-top: 10px;
  width: 100%;
`;

export const ViewAttachs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const ViewItemCardWithScroll = styled(ViewItemCard)`
  max-height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  margin-bottom: 10px;
  display: block;
`;