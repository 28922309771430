import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { ButtonCard, CardsWrapper, Container, Load } from './styles';
import Button from '../../components/Button/Button';
import { IoIosArrowForward } from 'react-icons/io';
import TextAlternative from './components/TextAlternative';
import Loading from 'react-loading';
import { COLORS } from '../../utils/constants';
import { toast } from 'react-toastify';
import Cards from './components/Cards/Cards';
import SelectedProtocol from './SelectedProtocol';
import { useWindowSize } from '../Login';
import NewProtocol from './components/NewProtocol/NewProtocol';
import { dateStringToDateTimeFormat } from '../../utils/dates';

/* ======================= TYPES ========================= */

export interface IProtocol {
    id: number;
    descricao: string;
    dataEnvio: string;
    status: 'NAO_VISUALIZADO' | 'VISUALIZADO';
}

export interface ISpecifcProtocol {
    nomeArquivo: string;
    tipoDocumento: string;
    extensaoArquivo: string;
    link: string;
}

/* ++++++++++++++++++++++++++++++ COMPONENT ++++++++++++++++++++++++++++++ */

const VirtualProtocol: React.FC = () => {

    /* ======================= CONSTANTS and STATES ========================= */

    const dispatch = useDispatch();
    const size = useWindowSize();

    const [isLoading, setIsLoading] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [modalNewProtocol, setModalNewProtocol] = React.useState(false);
    const [openProtocol, setOpenProtocol] = useState(false);
    const [cardId, setCardId] = useState<null | number>(null);
    const [protocol, setProtocol] = useState<IProtocol[]>([]);
    const [specifcProtocol, setSpecifcProtocol] = useState<ISpecifcProtocol[]>();

    /* ======================= REQUESTS ========================= */

    const getAllProtocols = useCallback(async () => {
        setIsLoading(true);
        request.get('listarTodosProtocoloVirtuais').then((response) => {
            if (response.data.ok === 'Sucesso') {
                const { listarProtocolos } = response.data;
                setProtocol(listarProtocolos);
            } else {
                toast.error(response.data.erro);
            }
        }).finally(() => {
            setIsLoading(false);
        });
      }, []);

    const getSpecificProtocol = (id: number) => {
        request.get(`listarProtocoloVirtualUnico/${id}`).then((response) => {
            if (response.data.ok === 'Sucesso') {
                const { listarProtocoloUnico } = response.data;
                setSpecifcProtocol(listarProtocoloUnico);
            } else {
                toast.error(response.data.erro);
            }
        }).catch((error) => {
            if (error?.message === 'Network Error') {
                toast.warn('Falha na rede: Verifique sua conexão e tente novamente');
            } else {
                toast.error('Erro: A requisição falhou');
            }
        }).finally(() => {
            setIsLoading(false);
            setLoadingRequest(false);
        });
    }

    useEffect(() => {
        getAllProtocols();
    }, [getAllProtocols]);

    /* ======================= FUNCTIONS ========================= */

    function handleScroll() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const handleOpenCard = (id: number, index: number) => {
        setIsLoading(true);
        setLoadingRequest(true);
        getSpecificProtocol(id);
        if (!loadingRequest) {
            setCardId(index);
        }
        setOpenProtocol(true);
        handleScroll();
    }

    const handleClose = () => {
        setOpenProtocol(false);
    }

    function handleModalProtocol() {
        setModalNewProtocol(true);
    }

    /* ======================= EFFECTS ========================= */

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter("Protocolo Virtual"));
    }, []);

    /* ======================= RENDERS ========================= */

    return (
        <Container openProtocol={openProtocol}>

            {!modalNewProtocol ?
                <>
                    {
                        <Button
                            onClick={handleModalProtocol}
                            theme="secondary"
                            marginTop={10}
                            style={size.sizeWidth <= 520 ? { margin: '10px auto 20px auto', fontWeight: 'bold', fontSize: 16, width: size.sizeWidth * 70 / 100, height: 40 } : {
                                marginLeft: 'auto', fontWeight: 'bold', fontSize: 18, width: 300, height: 40, marginTop: 40,
                            }}
                        >
                            Cadastrar Novo Protocolo
                        </Button>
                    }

                    {
                        isLoading && (
                            <Load>
                                <Loading type='spin' width={60} color={COLORS.SECONDARY} />
                            </Load>
                        )
                    }

                    <CardsWrapper
                    openProtocol={openProtocol}
                    style={protocol.length === 1 ? { alignSelf: "center" } : {}}
                    >
                        {
                            protocol && protocol.length !== 0 ?
                                protocol.map((p, index) => (
                                    <Cards
                                        key={p.id}
                                        description={p.descricao}
                                        dataEnvio={dateStringToDateTimeFormat(p.dataEnvio)}
                                        status={p.status}
                                        isOpen={openProtocol}
                                    >
                                        <ButtonCard data-testid="get-unique-protocol" onClick={() => handleOpenCard(p.id, index)} >
                                            <IoIosArrowForward size={20} />
                                        </ButtonCard>
                                    </Cards>
                                ))
                                :
                                !isLoading &&
                                <TextAlternative text='Nenhum protocolo' />
                        }
                    </CardsWrapper>

                    {
                        (openProtocol && specifcProtocol) &&
                        <SelectedProtocol cardId={cardId as number} protocol={protocol} specifcProtocol={specifcProtocol} handleClose={handleClose} />
                    }
                </>
                :
                <NewProtocol
                    modalVisible={modalNewProtocol}
                    setModalVisible={setModalNewProtocol}
                    getAllProtocols={getAllProtocols}
                />
            }

        </Container >
    );
};

export default VirtualProtocol;