import React from 'react';

import { Container, InputStyled, Label } from './styles';

interface Props {
  label?: string;
  marginTop?: number;
  checked?: boolean;
  lableColor?: string;
  disabled?: boolean;
  checkboxOrder?: '1' | '2';
  marginLeft?: number;
  onChange?: () => void;
}
const CheckBox = ({
  label, marginTop, checked = false, lableColor, disabled = false, checkboxOrder = '2', marginLeft, onChange,
}: Props) => (
  <Container data-margintop={marginTop}>
    {
      checkboxOrder === "2" ?
        (
          <>
            {label && <Label data-lablecolor={lableColor} data-marginleft={marginLeft}>{label}</Label>}
            <InputStyled type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
          </>
        )
        :
        (
          <>
            <InputStyled type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            {label && <Label data-lablecolor={lableColor} data-marginleft={marginLeft}>{label}</Label>}
          </>
        )
    }
  </Container>
);

export default CheckBox;
