import styled from 'styled-components';
import { COLORS } from '../../../utils/constants';
import { Table } from '../../../components/Table/styles';

export const TextEmpty = styled.p`
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const LinkDetails = styled.a`
color: ${COLORS.PRIMARY};
font-weight: bold;
font-size: 16px;

@media screen and (max-width: 1024px) {
  font-size: 14px;
}
`;

export const Container = styled.div`
  padding: 0 15px;
`;

export const TableFlex1 = styled(Table)`
  @media screen and (max-width: 1024px) {
      display: none;
  }
`;

export const TableFlex2 = styled(Table)`
    display: none;
    margin-bottom: 15px;

    tr {
      border-bottom: 1px solid rgba(77, 77, 77, 0.5);
      height: 40px;
    }

    tr:last-child {
      border: none;
    }

    th {
      padding-left: 10px;
      
      @media screen and (max-width: 520px) {
        padding-right: 15px;
      }
    }

    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }

    @media screen and (max-width: 1024px) {
      display: table;
    }
`;