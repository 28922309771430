import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Lottie from 'react-lottie';
import { animations } from '../../../../assets';

import * as S from './styles';

type Props = {
    handleClose : () => void
}

const ModalSuccess : React.FC<Props> = ({ handleClose }) => {
  const [playLottieAnimation, setPlayLottieAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlayLottieAnimation(true);
    }, 700);
  }, []);

  return (
    <S.Container
      id="container-modal-check-success"
      onClick={(event) => {
        const target = event.target as Element;

        if (target.id === 'container-modal-check-success') {
          handleClose();
        }
      }}
    >
      <S.Content>

        <button type="button" onClick={handleClose}>
          <MdClose size={24} color="#4d4e50" />
        </button>

        <div className="title-wrapper">
          <Lottie
            options={{
              autoplay: playLottieAnimation,
              loop: false,
              animationData: animations.markSuccess,
            }}
            height={79}
            width={79}
          />
          <h1>Pré-reserva criada com sucesso</h1>
        </div>

        <div className="separator" />

        <p>Fique atendo as datas e a confirmação realizada pela unidade.</p>
      </S.Content>
    </S.Container>
  );
};

export default ModalSuccess;
