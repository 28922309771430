import styled from 'styled-components';

export const Container = styled.div`
display : flex;
flex : 1;
align-items : center;
justify-content : center;
flex-direction : column;
width: 100vw;
height: 100vh;

select{
    padding : 10px;
    margin : 12px 0 12px;

    font-weight : bold;
    font-size : 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background : #fff;
    border : 1px solid #ddd;
    border-radius : 6px;
}

button{
    margin-top  : 24px;
    padding : 12px 24px;
    width:230px ;
    font-size : 18px;
    font-weight : bold;
}
`;
