import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

type ButtonProps = {
  cancel?: boolean;
  btnManager?: boolean;
};

export const Container = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    text-align: center;
    margin: 15px 0 25px;
    font-size: 24px;
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    height: fit-content;
    padding: 10px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
  }
`;

export const Button = styled.button<ButtonProps>`
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  width: 200px;
  height: 200px;
  margin: 0 8px;
  border-radius: 12px;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
    color: ${({ cancel }) => (cancel ? 'red' : COLORS.PRIMARY)};
  }

  p {
    font-weight: bold;
    font-size: 24px;
    margin: 16px 0;
  }

  @media screen and (max-width: 520px) {
    height: ${({ btnManager }) => (btnManager ? '150px' : 'fit-content')};
    width: 100%;
    padding: 15px 0;

    svg {
      width: 50px;
      height: 50px;
    }

    p {
      font-size: 15px;
      margin: 10px 0;
      white-space: ${({ btnManager }) => (btnManager ? 'normal' : 'nowrap')};
    }
  }
`;

export const CancelContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  > div {
    display: flex;
    margin-top: 12px;
    justify-content: space-around;
  }

  @media screen and (max-width: 520px) {
    height: fit-content;
    padding: 10px 0;
  }
`;

export const TemrmContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 16px;

    a {
      font-weight: bold;
      color: blue;
    }
  }

  p {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  > div {
    display: flex;
    margin-top: 12px;
    width: 100%;
    justify-content: space-around;
  }
`;

export const ButtonCancel = styled.button<{ primary?: boolean }>`
  border: 0;
  background: ${({ primary }) => (primary ? COLORS.PRIMARY : 'transparent')};
  box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
  border-radius: 6px;
  padding: 24px 44px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ primary }) => (primary ? '#fff' : '#333333')};
`;

const customStyles = {
  btn: {
    whiteSpace: 'normal',
  },
};

export default customStyles;