import styled from "styled-components";
import { COLORS } from "../../../../utils/constants";

export const Top = styled.div`
   display: flex;
   justify-content: center;
   gap: 10px;
   width: 100%;
   align-items: center;
`;

export const SeeFilter = styled.span`
  font-size: 18px;
  color: ${COLORS.PRIMARY};
`;

export const TopHideFilter = styled.div`
   display: flex;
   justify-content: center;
   gap: 10px;
   align-items: center;
   align-self: flex-end;
   cursor: pointer;
`;

export const ArrowButton = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 30px;
`;

export const CalendarContainer = styled.div`
  width: 50%;
  align-self: center;
  background: #fff;
  border-radius: 12px;
  margin-left: 20px;

  .fc {
    box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
    border-radius: 12px;
    .fc-button-group {
      margin: 12px 0 0 12px;
      > button {
        background: ${COLORS.PRIMARY};
        border: 0;
        box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
      }
      .fc-prev-button.fc-button.fc-button-primary {
        margin-right: 12px;
      }
      .fc-next-button.fc-button.fc-button-primary {
      }
    }

    .fc-today-button {
      margin-right: 12px;
      background: ${COLORS.SECONDARY};
    }
  }
`;
export const Container = styled.div`
  display: flex;
`;
