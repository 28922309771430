import { Accordion, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import Loading from "react-loading";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IListaUnica } from "../../../../interfaces/responses/IListaUnica";
import { IUnidades } from "../../../../interfaces/responses/IListUnity";
import request from "../../../../services/request";
import { ActionsHeader } from "../../../../store/ducks/header";
import { ActionsToggleButtons } from "../../../../store/ducks/toggleButtons";
import { COLORS } from "../../../../utils/constants";
import { Load } from "../../styles";
import MainTable from "./TablesAndList/MainTable";

export interface IList {
    condominio: number;
    data: string;
    id: number;
    status: string;
    idTaxa: number;
    descricao: string;
}

const ListarConsumos = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [baseList, setBaseList] = useState<Array<IList>>([]);
    const [leituraUnica, setLeituraUnica] = useState<Array<IListaUnica>>([]);
    const [listUnidades, setListUnidades] = useState<Array<IUnidades>>([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter('Listar Consumos'));
    }, [dispatch]);

    const getUnidades = useCallback(async () => {
        setLoading(true);

        request.get('buscarUnidadesCondominio')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setListUnidades(response.data.Unidades);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const listarTodasLeituras = useCallback(async () => {
        setLoading(true);

        request.get('listarTodasLeiturasGeral')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setBaseList(response.data.leiturasGerais);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        getUnidades();
        listarTodasLeituras();
    }, [listarTodasLeituras, getUnidades]);

    const submitData = (id: number) => {
        setLoading(true);

        const form = {
            idLeituraGeral: id,
        }

        request.post('listarLeituraUnica', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setLeituraUnica(response.data.leituras);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleExclude = (id: number) => {
        setLoading(true);

        const form = {
            idLeituraIndividual: id,
        }

        request.post('excluirLeituraIndividual', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    toast.success("Leitura excluída com sucesso");
                    submitData(id);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const submitFinish = (idLeituraGeral: number, controller: string) => {

        setLoading(true);

        const form = {
            idLeituraGeral
        }

        const endpoint = controller === 'finish' ? 'finalizarLeitura' : 'reiniciarLeituraGeral'

        request.post(endpoint, form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    toast.success(controller === 'finish' ? 'Leitura finalizada com sucesso' : 'Leitura reiniciada com sucesso');
                    listarTodasLeituras();
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleIdUnidade = (value: number) => {
        const singleUnity = listUnidades?.find((item) => item.idUnidade == value);
        if (singleUnity) {
            return singleUnity.nomeUnidade;
        }
    }

    const handleEdit = (idLeitura: number, taxa: number) => {
        dispatch(ActionsToggleButtons.setSelectedLeituraId(idLeitura));
        dispatch(ActionsToggleButtons.setSelectedTaxaId(taxa));
        history.push('/gravar_leituras');
    }

    return (
        <Stack p={15}>
            {
                baseList ? (
                    <Accordion allowToggle>
                        <MainTable
                            baseList={baseList}
                            leituraUnica={leituraUnica}
                            submitData={submitData}
                            handleIdUnidade={handleIdUnidade}
                            handleExclude={handleExclude}
                            handleEdit={handleEdit}
                            submitFinish={submitFinish}
                        />
                    </Accordion>
                ) : (
                    <Text
                        fontWeight={600}
                        bgColor='rgba(0,0,0,.2)'
                        textAlign="center"
                        borderRadius={6}
                    >
                        Nenhum consumo cadastrado
                    </Text>
                )
            }

            {
                loading && (
                    <Load>
                        <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                    </Load>
                )
            }
        </Stack>
    )
}

export default ListarConsumos;