import {
    Box, Button, Flex,
    Input, Select, Stack, Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Loading from 'react-loading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IUnidades } from '../../../../interfaces/responses/IListUnity';
import { IListVisits } from '../../../../interfaces/responses/IListVisits';
import request from '../../../../services/request';
import { ActionsHeader } from '../../../../store/ducks/header';
import { COLORS } from '../../../../utils/constants';
import { cpfMask } from '../../../../utils/masks';
import { useWindowSize } from '../../../Login';
import TableVisits from '../../components/TableVisits';
import { Load } from '../../styles';
import { ArrowButton, SeeFilter, TopHideFilter } from './styles';

const ListarVisitas = () => {
    const dispatch = useDispatch();
    const size = useWindowSize();

    const [loading, setLoading] = useState(false);
    const [filterIsOpen, setFilterIsOpen] = useState(true);

    const [listUnidades, setListUnidades] = useState<Array<IUnidades>>([]);
    const [visitsList, setVisitsList] = useState<Array<IListVisits>>([]);
    const [filters, setFilters] = useState({
        idUnidade: 0,
        dataAgendamento: '',
        nomeAgendamento: '',
        cpfAgendamento: '',
        unidadeAgendamento: '',
    });

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter('Visitas Cadastradas'));
    }, [dispatch]);

    useEffect(() => {
        if (size.sizeWidth <= 475) {
            setFilterIsOpen(false);
        } else {
            setFilterIsOpen(true);
        }
    }, [size.sizeWidth]);

    const clearFilters = () => {
        setFilters({
            ...filters,
            idUnidade: 0,
            dataAgendamento: '',
            nomeAgendamento: '',
            cpfAgendamento: '',
            unidadeAgendamento: '',
        });
        getVisits();
    }

    const getUnidades = useCallback(async () => {
        setLoading(true);

        request.get('buscarUnidadesCondominio')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setListUnidades(response.data.Unidades);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        getUnidades();
    }, [getUnidades]);

    const getVisits = useCallback(async () => {
        setLoading(true);
        request
            .post('/listarVisitasCadastradas')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setVisitsList(response.data.agendamentos)
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getVisits();
    }, [getVisits]);

    const filterVisits = () => {

        setLoading(true);

        const {
            cpfAgendamento,
            dataAgendamento,
            idUnidade,
            nomeAgendamento,
            unidadeAgendamento
        } = filters

        const form = {
            cpfAgendamento,
            dataAgendamento,
            idUnidade: idUnidade === 0 ? "" : idUnidade,
            nomeAgendamento,
            unidadeAgendamento
        }

        request
            .post('/listarVisitasCadastradas', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setVisitsList(response.data.agendamentos)
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleVisitorType = (visitor: number) => {
        if (visitor === 1) {
            return "Visitante";
        } else if (visitor === 2) {
            return "Prestador de serviço";
        } else {
            return "Entregador";
        }
    }

    return (
        <Flex
            h="100%"
            bg="gray.50"
            p="4"
            direction="column"
        >

            <TopHideFilter onClick={() => setFilterIsOpen(!filterIsOpen)}>
                <SeeFilter>{!filterIsOpen ? "Ver filtro" : "Ocultar filtro"}</SeeFilter>
                {
                    !filterIsOpen ? (
                        <ArrowButton>
                            <IoIosArrowDown
                                size={25}
                                color={COLORS.PRIMARY}
                            />
                        </ArrowButton>
                    ) : (
                        <ArrowButton>
                            <IoIosArrowUp
                                size={25}
                                color={COLORS.PRIMARY}
                            />
                        </ArrowButton>
                    )
                }
            </TopHideFilter>

            {
                filterIsOpen && (
                    <>
                        <Stack
                            direction={{
                                base: 'column', sm: 'column', md: 'column', lg: 'row',
                            }}
                            spacing="15px"
                            alignItems={size.sizeWidth >= 992 ? "flex-end" : ""}
                            w="100%"
                        >
                            <Box w={size.sizeWidth < 992 ? "50" : "100%"}>
                                <Text mb="3" fontWeight="bold" size="2xs">Horário:</Text>
                                <Input
                                    placeholder="Data do agendamento"
                                    size="md" bg="white" type="date"
                                    value={filters.dataAgendamento}
                                    onChange={(e: any) => setFilters({
                                        ...filters,
                                        dataAgendamento: e.target.value,
                                    })}
                                />
                            </Box>
                            <Box w={size.sizeWidth < 992 ? "50" : "100%"}>
                                <Text mb="3" fontWeight="bold" size="2xs">Unidade:</Text>
                                <Select
                                    placeholder="Selecione a unidade"
                                    size="md" bg="white"
                                    onChange={(e: any) => setFilters({
                                        ...filters,
                                        idUnidade: e.target.value,
                                    })}
                                    value={filters.idUnidade}
                                >
                                    {
                                        listUnidades.map((elem) => (
                                            <option key={elem.idUnidade} value={elem.idUnidade}>{elem.nomeUnidade}</option>
                                        ))
                                    }
                                </Select>
                            </Box>
                        </Stack>

                        <Stack
                            direction={{
                                base: 'column', sm: 'column', md: 'column', lg: 'row',
                            }}
                            spacing="24px"
                            alignItems={size.sizeWidth >= 992 ? "flex-end" : ""}
                            w="100%"
                            marginTop={15}
                        >
                            <Box w={size.sizeWidth < 992 ? "50" : "100%"}>
                                <Text mb="3" fontWeight="bold" size="2xs">Nome:</Text>
                                <Input
                                    placeholder="Nome do visitante"
                                    size="md" bg="white"
                                    value={filters.nomeAgendamento}
                                    onChange={(e: any) => setFilters({
                                        ...filters,
                                        nomeAgendamento: e.target.value,
                                    })}
                                />
                            </Box>
                            <Box w={size.sizeWidth < 992 ? "50" : "100%"}>
                                <Text mb="3" fontWeight="bold" size="2xs">CPF:</Text>
                                <Input
                                    placeholder="000.000.000-00"
                                    size="md" bg="white"
                                    value={filters.cpfAgendamento}
                                    onChange={(e: any) => setFilters({
                                        ...filters,
                                        cpfAgendamento: cpfMask(e.target.value),
                                    })}
                                />
                            </Box>
                        </Stack>

                        <Flex>
                            <Button
                                disabled={loading ? true : false}
                                colorScheme="blue"
                                marginTop={5}
                                onClick={() => filterVisits()}
                                w={size.sizeWidth > 520 ? "30%" : "50%"}
                            >
                                Filtrar
                            </Button>
                            <Button
                                onClick={clearFilters}
                                disabled={loading ? true : false}
                                marginTop={5}
                                marginLeft={5}
                                w={size.sizeWidth > 520 ? "30%" : "50%"}
                                style={{
                                    backgroundColor: 'transparent',
                                    borderWidth: 2,
                                    borderColor: COLORS.PRIMARY,
                                    borderStyle: 'solid',
                                    color: COLORS.PRIMARY,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                }}
                            >
                                Limpar filtros
                            </Button>
                        </Flex>
                    </>
                )
            }

            {
                loading ? (
                    <Load>
                        <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                    </Load>
                ) :
                    (
                        visitsList &&
                        <TableVisits
                            visitsList={visitsList}
                            handleVisitorType={handleVisitorType}
                        />
                    )
            }

        </Flex>
    );
}

export default ListarVisitas;
