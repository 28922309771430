import styled from 'styled-components';

export const FlexContainer = styled.div`
    padding: 15px;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 520px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;