import * as React from 'react';
import {
    Box, Text,
} from '@chakra-ui/react';

import {
    ImagePredial, FlexApp, ImagePhone,
    FlexStores, AsideFlex, FlexText,
    FlexButton
} from '../styles'

import {
    FaApple, FaGooglePlay,
} from 'react-icons/fa';

import bannerImage from '../../../assets/banner.png';
import classeconAppImage from '../../../assets/classecon-app.png';
import { goToExternalLink } from '../../../utils/functions';

const MarketingPanel = () => {
    return (
        <AsideFlex>
            <ImagePredial
                src={bannerImage}
                alt="Banner"
            />

            <FlexApp>
                <FlexText>
                    <Box>
                        <Text fontFamily="Montserrat" fontSize="18px" fontWeight="bold">
                            Baixe o APP Classecon
                        </Text>
                        <Text fontFamily="Montserrat" fontSize="14px" marginTop="4px" fontWeight="bold">
                            Tenha acesso a diversos serviços na palma da sua mão.
                        </Text>
                    </Box>

                    <FlexStores>
                        <FlexButton
                            onClick={() => goToExternalLink(
                                'https://apps.apple.com/us/app/classecon/id1190138751',
                            )}
                        >
                            <FaApple size={14} />
                            <Text fontWeight="bold" fontSize="12px" ml="6px">
                                App Store
                            </Text>
                        </FlexButton>
                        <FlexButton
                            onClick={() => goToExternalLink(
                                'https://play.google.com/store/apps/details?id=br.com.ideiatecnologia.classecon',
                            )}
                        >
                            <FaGooglePlay size={14} />
                            <Text fontWeight="bold" fontSize="12px" ml="6px">
                                Google Play
                            </Text>
                        </FlexButton>
                    </FlexStores>
                </FlexText>

                <ImagePhone
                    src={classeconAppImage}
                    alt="Banner"
                />
            </FlexApp>
        </AsideFlex>
    )
}

export default MarketingPanel;