import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import { numberToCurrency } from '../../utils/functions';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { COLORS } from '../../utils/constants';
import InadimplenciasBoletos from './InadimplenciasBoletos';
import Button from '../../components/Button/Button';
import {
  ArrowButton,
  ArrowButtonDown,
  ButtonsWrapper,
  Container,
  EmptyMessageText,
  Filters,
  FiltersWrapper,
  RelatorioContainer,
  SeeFilter,
  Top,
  TopHideFilter,
  Card,
  Relatorio,
  RelatorioTitle,
  RelatorioDesc,
  RelatorioRow,
  CardView,
} from './styles';
import RootState from '../../interfaces/states/RootState';
import InputAutoComplete from '../../components/Input/InputAutoComplete';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import { useWindowSize } from '../Login';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IRelatorio } from './types';
import { toast } from 'react-toastify';

type Condomino = {
  id: string;
  nome: string;
};

const optionsStatus = [
  { value: '', label: 'Todos' },
  { value: 'VENCIDO', label: 'Vencidos' },
  { value: 'ABERTO', label: 'A vencer' },
  { value: 'PAGO', label: 'Pago' },
];
const optionsGarantida = [
  { value: '', label: '' },
  { value: '0', label: 'Não Garantida' },
  { value: '1', label: 'Garantida' },
];
const optionsCobranca = [
  { value: '', label: '' },
  { value: 'ORDINARIA', label: 'Ordinária' },
  { value: 'ACORDO', label: 'Acordo' },
];
const optionsOrder = [
  { value: '', label: '' },
  { value: 'nomeUnidade', label: 'Unidade' },
  { value: 'nomeSacado', label: 'Nome' },
  { value: 'vencimentoOriginal', label: 'Vencimento' },
];

const Inadimplencias = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();

  const session = useSelector((state: RootState) => state.session);
  const [relatorio, setRelatorio] = React.useState<IRelatorio>();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [loadingBoletos, setLoadingBoletos] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showRelatorio, setShowRelatorio] = React.useState(true);
  //   const [sendingReport, setSendingReport] = React.useState(false);
  //   const [linkReport, setLinkReport] = React.useState<string>();
  const [boletosFilter, setBoletosFilter] = React.useState<Array<any>>([]);
  const [units, setUnits] = React.useState<Array<any>>([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [resultsQuery, setResultsQuery] = React.useState([]);
  const [nameSearch, setNameSearch] = React.useState('');
  const [condomino, setCondomino] = React.useState<Condomino>();
  const [timeoutSearch, setTimeoutSearch] = React.useState<any>();
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [filterIsOpen, setFilterIsOpen] = React.useState(true);
  const [emptyMessage, setEmptyMessage] = React.useState('');
  const [filters, setFilters] = React.useState({
    idUnidade: 0,
    competencia: '',
    status: '',
    garantido: '',
    cobranca: '',
    order: '',
    initialDate: '',
    finalDate: String(new Date()),
  });
  function clearFilters() {
    setEmptyMessage('');
    setFilters({
      ...filters,
      status: '',
      garantido: '',
      cobranca: '',
      order: '',
      initialDate: '',
      finalDate: String(new Date()),
    });

    setNameSearch('');
    setResultsQuery([]);
  }

  function onChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setNameSearch(e.target.value);

    if (timeoutSearch) clearTimeout(timeoutSearch);

    setLoadingSearch(true);

    if (e.target.value) {
      setTimeoutSearch(
        setTimeout(() => {
          request
            .get(`buscaNomeCondomino?q=${e.target.value}`)
            .then((res) => {
              if (res.data.ok) {
                setResultsQuery(
                  res.data.condominos.map((c: any) => ({ ...c, name: c.nome }))
                );
              }
            })
            .finally(() => {
              setLoadingSearch(false);
            });
        }, 1000)
      );
    } else {
      setResultsQuery([]);
      setLoadingSearch(false);
    }
  }

  //   React.useEffect(() => {
  //     if (nameSearch.length > 2) {
  //       searchBoletoByName();
  //     } else {
  //       setResultsQuery([]);
  //     }
  //   }, [nameSearch, searchBoletoByName]);

  async function listAllUnidadesCondominio() {
    request.get('listarUnidadesCondominio').then((response) => {
      if (response.data.ok) {
        setUnits([{ id: 0, nome: 'Todas' }, ...response.data.unidades]);
      }
    });
  }

  const getBoletos = React.useCallback(
    async (n = 0) => {
      setLoadingBoletos(true);

      const form = {} as any;
      if (filters.idUnidade) form.idUnidade = filters.idUnidade;

      if (filters.competencia.length >= 3)
        form.competencia = filters.competencia;

      if (filters.status) form.status = filters.status;

      if (filters.garantido) form.garantia = filters.garantido;

      if (filters.cobranca) form.cobranca = filters.cobranca;

      // if (condomino && nameSearch) form.nome = nameSearch;
      if (condomino || nameSearch != '') form.nome = nameSearch;

      if (filters.order) form.order = filters.order;

      const { initialDate, finalDate } = filters;

      if (initialDate) form.initialDate = initialDate;

      if (finalDate) form.finalDate = finalDate;

      request
        .post(`listarBoletosCondominioV2/${n}`, form)
        .then((response) => {
          if (response.data.ok) {
            if (initialDate && finalDate) {
              const filterDate = response.data.boletos.filter(
                (date: any) =>
                  date.vencimentoOriginal >= initialDate &&
                  date.vencimentoOriginal <= finalDate
              );
              setBoletosFilter(filterDate);
            }

            if (response.data.boletos.length === 0)
              setEmptyMessage(
                ' Não há boletos em inadimplência para esse período.'
              );
            setBoletosFilter(response.data.boletos);

            setTotalPages(response.data.total);

            setCurrentPage(n);
          }
        })
        .finally(() => {
          setLoadingBoletos(false);
        });
    },
    [filters, condomino, nameSearch]
  );

  const getBoletosCurrentMonth = React.useCallback(async () => {
    const currentDateForamted = moment(new Date()).format('MM/YYYY');

    setFilters((oldFilters) => ({
      ...oldFilters,
      competencia: currentDateForamted,
    }));

    setLoadingBoletos(true);

    request
      .post(`listarBoletosCondominioV2/${0}`, {
        competencia: currentDateForamted,
      })
      .then((response) => {
        if (response.data.ok) {
          setBoletosFilter(response.data.boletos);
          setTotalPages(response.data.total);
        }
      })
      .finally(() => {
        setLoadingBoletos(false);
      });
  }, []);

  function changeCondominoAndNameSearch(
    condominoSearched: Condomino,
    nameSearched: string
  ) {
    setCondomino(condominoSearched);
    setNameSearch(nameSearched);
    setResultsQuery([]);
  }

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Inadimplências Boletos'));
  }, [dispatch]);

  React.useEffect(() => {
    listAllUnidadesCondominio();
  }, [dispatch]);

  React.useEffect(() => {
    getBoletosCurrentMonth();
  }, [getBoletosCurrentMonth]);

  React.useEffect(() => {
    if (size.sizeWidth <= 475) {
      setFilterIsOpen(false);
    } else {
      setFilterIsOpen(true);
    }
  }, [size.sizeWidth]);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Inadimplências'));
    request
      .get('relatorio_geral_inadimplencia')
      .then((response) => {
        if (response.data.ok) {
          setRelatorio(response.data.listagem);
        }
      })
      .catch(() => {
        toast.error('Erro, Falha na requisição');
      })
      .finally(() => {});
  }, [dispatch]);

  const convertToDataChart = relatorio
    ? [
        {
          x: '1',
          y: ((relatorio.valor_pago / relatorio.repasse) * 100).toFixed(2),
          color: '#4a8',
          subtitle: 'Pago pelos condôminos',
          value: numberToCurrency(relatorio.valor_pago),
          show: true,
        },
        {
          x: '2',
          y: (
            ((relatorio.repasse - relatorio.valor_pago) * 100) /
            relatorio.repasse
          ).toFixed(2),
          color: '#F66',
          subtitle: 'Inadimplência corrigida',
          value: numberToCurrency(
            relatorio.rec_ordinaria - relatorio.valor_pago
          ),
          show: true,
        },

        {
          x: '3',
          y: ((relatorio.repasse / relatorio.rec_ordinaria) * 100).toFixed(2),
          color: 'blue',
          subtitle: 'Repassado ao condomínio',
          value: numberToCurrency(relatorio.repasse),
          show: false,
        },
      ]
    : [];

  const sanitizeCompetencia = (c: string) => {
    const t = c.replace(/[^/-9]+/, '');
    if (t.length === 2 && !t.includes('/')) {
      return t.concat('/');
    }
    return t;
  };

  // const handleExport = () => {
  // setSendingReport(true);
  // request
  //     .post("relatorio_detalhado_inadimplencia")
  //     .then((response) => {
  //     if (response.data.ok) {
  //         toast.success("Relatório enviado com sucesso");
  //         setLinkReport(response.data.link);
  //     }
  //     })
  //     .catch(() => {
  //     toast.error("Erro, falha na requisição");
  //     })
  //     .finally(() => {
  //     setSendingReport(false);
  //     });
  // };

  return (
    <Container>
      {!filterIsOpen && (
        <TopHideFilter onClick={() => setFilterIsOpen(true)}>
          <SeeFilter>Ver filtro</SeeFilter>
          <ArrowButtonDown>
            <IoIosArrowDown size={25} color={COLORS.PRIMARY} />
          </ArrowButtonDown>
        </TopHideFilter>
      )}
      {filterIsOpen && (
        <>
          <Top>
            <InputAutoComplete
              loading={loadingSearch}
              placeholder='Digite o nome que deseja filtrar'
              changeValue={changeCondominoAndNameSearch}
              value={nameSearch}
              onChange={onChangeSearch}
              resultsQuery={resultsQuery}
              style={{ width: '100%' }}
            />
            <ArrowButton>
              <IoIosArrowUp
                size={25}
                color={COLORS.PRIMARY}
                onClick={() => setFilterIsOpen(false)}
              />
            </ArrowButton>
          </Top>
          <FiltersWrapper>
            <Filters>
              <Select
                margintop={size.sizeWidth <= 768 ? 0 : 12}
                value={filters.idUnidade}
                options={units.map((u) => ({ label: u.nome, value: u.id }))}
                label='Unidade'
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    idUnidade: Number.parseInt(e.target.value, 10),
                  })
                }
              />
              <Select
                margintop={size.sizeWidth <= 768 ? 0 : 12}
                value={filters.status}
                options={optionsStatus}
                label='Status'
                onChange={(e) =>
                  setFilters({ ...filters, status: e.target.value })
                }
              />
              <Select
                margintop={size.sizeWidth <= 768 ? 0 : 12}
                value={filters.garantido}
                options={optionsGarantida}
                label='Garantia'
                onChange={(e) =>
                  setFilters({ ...filters, garantido: e.target.value })
                }
              />
              <Select
                margintop={size.sizeWidth <= 768 ? 0 : 12}
                value={filters.cobranca}
                options={optionsCobranca}
                label='Cobranças'
                onChange={(e) =>
                  setFilters({ ...filters, cobranca: e.target.value })
                }
              />
              <Select
                margintop={size.sizeWidth <= 768 ? 0 : 12}
                value={filters.order}
                options={optionsOrder}
                label='Ordenar'
                onChange={(e) =>
                  setFilters({ ...filters, order: e.target.value })
                }
              />
              <Input
                value={filters.competencia}
                label='Competência'
                placeholder='01/2020'
                onChange={(e: any) =>
                  setFilters({
                    ...filters,
                    competencia: sanitizeCompetencia(e.target.value),
                  })
                }
                maxLength={7}
                marginTop={size.sizeWidth <= 768 ? 0 : 12}
                styleContainer={{ padding: 0 }}
              />
              <Input
                value={filters.initialDate}
                label='Vencimento inicial'
                onChange={(e: any) =>
                  setFilters({ ...filters, initialDate: e.target.value })
                }
                maxLength={7}
                type='date'
                marginTop={size.sizeWidth <= 768 ? 0 : 12}
                styleContainer={{ padding: 0 }}
              />
              <Input
                value={filters.finalDate}
                label='Vencimento final'
                onChange={(e: any) =>
                  setFilters({ ...filters, finalDate: e.target.value })
                }
                maxLength={7}
                type='date'
                marginTop={size.sizeWidth <= 768 ? 0 : 12}
                styleContainer={{ padding: 0 }}
              />
            </Filters>

            <ButtonsWrapper>
              <Button
                theme='primary'
                onClick={() => getBoletos(0)}
                style={{
                  height: 35,
                  width: size.sizeWidth >= 768 ? '30%' : '60%',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                {loadingBoletos ? '...' : 'Buscar'}
              </Button>
              <Button
                theme='default'
                onClick={clearFilters}
                style={{
                  width: size.sizeWidth >= 768 ? '30%' : '60%',
                  backgroundColor: 'transparent',
                  borderWidth: 2,
                  borderColor: COLORS.PRIMARY,
                  borderStyle: 'solid',
                  color: COLORS.PRIMARY,
                  height: 35,
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                Limpar filtros
              </Button>
            </ButtonsWrapper>
          </FiltersWrapper>
        </>
      )}

      {showRelatorio && (
        <RelatorioContainer>
          <Card loading={loading}>
            {!loading && (
              <div style={{ maxHeight: 350 }}>
                <Doughnut
                  data={{
                    labels: ['Valor Pago', 'Inadimplência'],
                    datasets: [
                      {
                        label: 'Saúde Financeira',
                        data: convertToDataChart.map((i) => i.show && i.y),
                        backgroundColor: convertToDataChart.map(
                          (i) => i.show && i.color
                        ),
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  height={250}
                  width={300}
                  options={{}}
                />
              </div>
            )}
            {relatorio && (
              <CardView>
                <Relatorio>
                  <RelatorioRow>
                    <RelatorioTitle>
                      Total de taxas arrecadadas:{' '}
                    </RelatorioTitle>
                    <RelatorioDesc>
                      {numberToCurrency(relatorio.valor_pago)}
                    </RelatorioDesc>
                  </RelatorioRow>

                  <RelatorioRow>
                    <RelatorioTitle>
                      Total de débitos com encargos{' '}
                    </RelatorioTitle>
                    <RelatorioDesc>
                      {numberToCurrency(
                        relatorio.rec_ordinaria - relatorio.valor_pago
                      )}
                    </RelatorioDesc>
                  </RelatorioRow>
                </Relatorio>
              </CardView>
            )}
          </Card>
        </RelatorioContainer>
      )}
      {boletosFilter.length == 0 ? (
        <EmptyMessageText>{emptyMessage}</EmptyMessageText>
      ) : (
        <InadimplenciasBoletos
          boletos={boletosFilter}
          totalPages={totalPages}
          currentPage={currentPage}
          getBoletos={getBoletos}
        />
      )}
    </Container>
  );
};

export default Inadimplencias;
