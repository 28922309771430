import React from 'react';
import Modal from 'react-modal';

import * as S from './styles';
import customStyles from '../../../../../utils/customStylesModal';

import imgPermisstionGeolocation from '../../../../../assets/permissrion-location-v1.png';

  type Props = {
    modalVisible : any
    setModalVisible : any
  }

const ModalPermissionGeolocation: React.FC<Props> = ({
  modalVisible, setModalVisible,
}) => (
  <S.Modal>
    <Modal
      isOpen={modalVisible}
      // onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalVisible(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-content">

        <button
          type="button"
          onClick={() => setModalVisible(false)}
          className="close"
        >
          X
        </button>
        <S.ModalContent>
          <h1>Permissão de Localização</h1>

          <img src={imgPermisstionGeolocation} alt="Permissão de Localização" />

          <p>No canto superior esquerdo você deve permitir sua localização para estar apto a votar.</p>
        </S.ModalContent>
      </div>
    </Modal>
  </S.Modal>
);

export default ModalPermissionGeolocation;
