import React from 'react';
import Modal from 'react-modal';
import ClubePartiuSindico from '../../../components/ClubePartiu/ClubePartiuSindico';
import ClubePartiuCondomino from '../../../components/ClubePartiu/ClubePartiuCondomino'
import customStyles from '../../../utils/customStylesModal';

type Props = {
  visible: boolean;
  handleClose: () => void;
  vinculo: 'SINDICO' | 'CONDOMINO';
};

const ModalClubePartiu: React.FC<Props> = ({ visible, handleClose, vinculo }) => (
  <Modal
    isOpen={visible}
    onRequestClose={handleClose}
    style={customStyles}
    ariaHideApp={false}
  >
    <div className="modal-content">
      <button type="button" onClick={handleClose} className="close">
        X
      </button>

      {
        vinculo === "SINDICO" ?
          <ClubePartiuSindico closeModal={handleClose} />
          :
          <ClubePartiuCondomino closeModal={handleClose} />
      }

    </div>
  </Modal>
);

export default ModalClubePartiu;
