/* eslint array-callback-return:off */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { IoIosAttach } from 'react-icons/io';
import Modal from 'react-modal';
import './style.css';

import { toast } from 'react-toastify';
import produce from 'immer';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import Select from '../../components/Select/Select';
import { IBudget } from '../../interfaces/responses/IBudget';
import { dateStringToDateFormat } from '../../utils/dates';
import { goToExternalLink } from '../../utils/functions';
import { COLORS } from '../../utils/constants';
import Button from '../../components/Button/Button';
import customStyles from '../../utils/customStylesModal';
import Form from '../../components/Form/Form';
import Input from '../../components/Input/Input';
import Loading from '../../components/Loading/Loading';

import { FiltersBudget, Attatch, ContentModal, TableFlex2, TableFlex1, EmptyMessage, EmptyMessageText, TitleHeader, FormModal } from './styles';
import { useWindowSize } from '../Login';

const ListBudget = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const erro = 'Não há registros';
  const percentage = 50 / 100;

  const [budgets, setBudgets] = React.useState<Array<IBudget>>([]);
  const [filterBudgets, setFilterBudgets] = React.useState<string | null>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [desc, setDesc] = React.useState('');
  const [attatchs, setAttatchs] = React.useState<Array<string>>([]);
  const [loadingCreateOrcamento, setLoadingCreateOrcamento] = React.useState(false);
  const [loadingGetOrcamento, setLoadingGetOrcamento] = React.useState(false);
  const [toggleTable, setToggleTable] = React.useState(false);

  const budgetsFiltereds = filterBudgets ? budgets.filter((b) => b.status === filterBudgets) : budgets;

  const optionsFilters = [
    { value: null, label: 'Todos' },
    { value: 'SOLICITADO', label: 'Pendentes' },
    { value: 'AGUARDANDO_SINDICO', label: 'Aguardando aprovação' },
    { value: 'APROVADO', label: 'Aprovado' },
    { value: 'REPROVADO', label: 'Reprovado' },
    { value: 'CONCLUIDO', label: 'Concluído' },
    { value: 'CANCELADO', label: 'Cancelado' },
  ] as Array<{ value: string | number, label: string }>;

  const getListOrcamentos = React.useCallback(
    () => {
      setLoadingGetOrcamento(true);
      request.get('listarOrcamento').then((response) => {
        if (response.data.ok === 'Sucesso') {
          setBudgets(response.data.listagem);
        }
      }).finally(() => {
        setLoadingGetOrcamento(false);
      });
    }, [],
  );

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Orçamentos'));
    getListOrcamentos();
  }, [dispatch, getListOrcamentos]);

  React.useEffect(() => {
    if (size.sizeWidth <= 1200) {
      setToggleTable(true);
    } else {
      setToggleTable(false);
    }
  }, [size.sizeWidth]);

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const addAttach = async (value: string) => {
    const attatch = await toBase64(value) as string;
    setAttatchs([...attatchs, attatch]);
  };

  const removeAttach = (index: number) => {
    setAttatchs(produce(attatchs, (draft) => {
      draft.splice(index, 1);
    }));
  };

  const handleSubmit = () => {
    setLoadingCreateOrcamento(true);

    const attatchsForm = {} as any;
    attatchs.forEach((a, index) => {
      attatchsForm[`fotoAnexo[${index}]`] = a;
    });

    const form = {
      descricao: desc,
      ...attatchsForm,
    };

    request.post('cadastrarSolicitacaoOrcamento', form).then((response) => {
      if (response.data.ok) {
        toast.success('Muito Bem, sua solicitação foi cadastrada com sucesso!');
        getListOrcamentos();
        setModalVisible(false);
      } else {
        toast.error(response?.data?.erro || 'Erro, desculpe, correu um erro durante o processo');
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    }).finally(() => {
      setLoadingCreateOrcamento(false);
    });
  };

  function handleShowModalCreateOrcamento() {
    setModalVisible(true);
  }
  return (
    <div style={{ padding: '0 15px', display: 'flex', flexDirection: 'column' }}>
      {loadingGetOrcamento && <Loading />}

      <FiltersBudget>
        <Select
          options={optionsFilters}
          value={filterBudgets}
          label="Status"
          onChange={(e) => setFilterBudgets(e.target.value)}
        />
        <Button onClick={handleShowModalCreateOrcamento} theme="primary">
          Novo orçamento
        </Button>
      </FiltersBudget>

      {/* ======================= RESPONSIVE TABLE ===================== */}

      {!toggleTable ?
        <TableFlex1>
          <thead>
            <tr>
              <th>Solicitado por</th>
              <th>Observação do solicitante</th>
              <th>Data da solicitação</th>
              <th>Condomínio</th>
              <th>Status</th>
              <th>Orçamentos</th>
              <th>Anexos do síndico</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {(budgetsFiltereds?.length === 0) && (
                <EmptyMessage colSpan={7}>
                  <p>{erro}</p>
                </EmptyMessage>
              )}
            </tr>
            {budgetsFiltereds?.map((b) => (
              <tr key={b.id}>
                <td>{b.solicitadoPor}</td>
                <td>{b.observacaoSolicitante}</td>
                <td>{dateStringToDateFormat(b.dataSolicitacao)}</td>
                <td>{b.condominio}</td>
                <td>{b.status}</td>
                <td>
                  {b.orcamentos.length
                    ? b.orcamentos.map((o) => (
                      <IoIosAttach
                        key={o.chaveS3}
                        size={30}
                        color="#fff"
                        onClick={() => goToExternalLink(o.chaveS3)}
                        style={{
                          background: COLORS.PRIMARY,
                          padding: 5,
                          marginRight: 10,
                          borderRadius: 4,
                          cursor: 'pointer',
                        }}
                      />
                    ))
                    : '--------------'}
                </td>
                <td>
                  {b.anexosSindico.length
                    ? b.anexosSindico.map((a) => (
                      <IoIosAttach
                        key={a.chaveS3}
                        size={30}
                        color="#fff"
                        onClick={() => goToExternalLink(a.chaveS3)}
                        style={{
                          background: COLORS.PRIMARY,
                          padding: 5,
                          marginRight: 10,
                          borderRadius: 4,
                          cursor: 'pointer',
                        }}
                      />
                    ))
                    : '--------------'}
                </td>
              </tr>
            ))}
          </tbody>
        </TableFlex1>
        :
        <>
          {(budgetsFiltereds?.length === 0) && (
            <EmptyMessageText>{erro}</EmptyMessageText>
          )}
          {budgetsFiltereds?.map((b) => (
            <TableFlex2 key={b.id}>
              <thead>
                <tr>
                  <th>Solicitado por</th>
                  <td style={{ maxWidth: size.sizeWidth * percentage }}>{b.solicitadoPor}</td>
                </tr>
                <tr>
                  <th>Observação do solicitante</th>
                  <td style={{ maxWidth: size.sizeWidth * percentage }}>{b.observacaoSolicitante}</td>
                </tr>
                <tr>
                  <th>Data da solicitação</th>
                  <td style={{ maxWidth: size.sizeWidth * percentage }}>{dateStringToDateFormat(b.dataSolicitacao)}</td>
                </tr>
                <tr>
                  <th>Condomínio</th>
                  <td style={{ maxWidth: size.sizeWidth * percentage }}>{b.condominio}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td style={{ maxWidth: size.sizeWidth * percentage }}>{b.status}</td>
                </tr>
                <tr>
                  <th>Orçamentos</th>
                  <td>
                    {b.orcamentos.length
                      ? b.orcamentos.map((o) => (
                        <IoIosAttach
                          key={o.chaveS3}
                          size={30}
                          color="#fff"
                          onClick={() => goToExternalLink(o.chaveS3)}
                          style={{
                            background: COLORS.PRIMARY,
                            padding: 5,
                            marginRight: 10,
                            borderRadius: 4,
                            cursor: 'pointer',
                          }}
                        />
                      ))
                      : '--------------'}
                  </td>
                </tr>
                <tr>
                  <th>Anexos do síndico</th>
                  <td>
                    {b.anexosSindico.length
                      ? b.anexosSindico.map((a) => (
                        <IoIosAttach
                          key={a.chaveS3}
                          size={30}
                          color="#fff"
                          onClick={() => goToExternalLink(a.chaveS3)}
                          style={{
                            background: COLORS.PRIMARY,
                            padding: 5,
                            marginRight: 10,
                            borderRadius: 4,
                            cursor: 'pointer',
                          }}
                        />
                      ))
                      : '--------------'}
                  </td>
                </tr>
              </thead>
            </TableFlex2>
          ))}
        </>
      }

      {modalVisible && (
        <Modal
          isOpen={modalVisible}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setModalVisible(false)}
          style={customStyles}
          contentLabel="Example Modal"
          className="contentCustom"
          overlayClassName="overlayCustom"
        >
          <div className="modal-content">
            <button
              type="button"
              onClick={() => setModalVisible(false)}
              className="close"
            >
              X
            </button>

            <TitleHeader style={{ textAlign: "center" }}>Solicitar orçamento</TitleHeader>

            <FormModal>
              <Input
                label="Descrição do orçamento"
                value={desc}
                onChange={(e: any) => setDesc(e.target.value)}
                style={{ width: '100%' }}
              />

              <Input
                type="file"
                label="Anexos"
                onChange={(e: any) => addAttach(e.target.files[0])}
                style={{ width: '100%' }}
              />

              <div style={{ width: '100%' }}>
                {attatchs.map((a, i) => (
                  <Attatch key={i}>
                    Anexo
                    {' '}
                    {i + 1}
                    <AiOutlineCloseCircle
                      onClick={() => removeAttach(i)}
                      size={25}
                    />
                  </Attatch>
                ))}
              </div>

              <Button onClick={handleSubmit} marginTop={10} theme="primary">
                {loadingCreateOrcamento ? <Loading /> : 'Solicitar orçamento'}
              </Button>

            </FormModal>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ListBudget;
