import {
    Flex, Stack, Text, Select,
    Box, Button, Input
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaCamera, FaFolder } from 'react-icons/fa';
import Loading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCamera } from "../../../../hooks/modules/CameraContext";
import { IListaUnica } from "../../../../interfaces/responses/IListaUnica";
import { IUnidades } from "../../../../interfaces/responses/IListUnity";
import RootState from "../../../../interfaces/states/RootState";
import request from "../../../../services/request";
import { ActionsHeader } from "../../../../store/ducks/header";
import { ActionsToggleButtons } from "../../../../store/ducks/toggleButtons";
import { COLORS } from "../../../../utils/constants";
import { Load } from "../../styles";
import PhotoManage from "./Camera";
import { Container } from "./styles";

interface ITaxas {
    descricao: string;
    fimValidade: string;
    id: string;
    inicioValidade: string;
}

interface IForm {
    idUnidade: number | string;
    anexo: string;
    valorLeitura: number;
    idLeituraGeral: number | string;
}

const GravarLeituras = () => {

    const limit = 10;
    const dispatch = useDispatch();
    const { leituraId, taxaId } = useSelector((state: RootState) => state.toggleButtons);
    const { stopCameraFunction, mediaStream } = useCamera();

    const [taxas, setTaxas] = useState<ITaxas[]>([]);
    const [selectedTaxaId, setSelectedTaxaId] = useState(taxaId);
    const [listUnidades, setListUnidades] = useState<IUnidades[]>([]);
    const [filteredUnityList, setFilteredUnityList] = useState<any>([]);
    const [listIds, setListIds] = useState<string[]>([])
    const [leituraUnica, setLeituraUnica] = useState<IListaUnica[]>([]);
    const [arrayIncrement, setArrayIncrement] = useState(1);

    const [idLeituraGeral, setIdLeituraGeral] = useState(leituraId);
    const [formObject, setFormObject] = useState<IForm>({
        idUnidade: 0,
        anexo: '',
        valorLeitura: 0.000,
        idLeituraGeral: idLeituraGeral
    });
    const [formArray, setFormArray] = useState<Array<IForm>>([]);
    const [arrayUnityId, setArrayUnityId] = useState<any[]>([]);

    const [open, setOpen] = useState(false);
    const [cameraOpened, setCameraOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter('Gravar Leituras'));
    }, [dispatch]);

    useEffect(() => {
        if (formObject.anexo) {
            setCameraOpened(false);
        }
    }, [formObject.anexo]);

    useEffect(() => {
        if (leituraId !== 0 && taxaId !== 0) {
            setOpen(true);
        }
    }, [])

    useEffect(() => {
        if (idLeituraGeral !== 0) {
            setFormObject({
                ...formObject,
                idLeituraGeral: idLeituraGeral,
            })
        }
    }, [idLeituraGeral]);

    // Creating an image by canvas to compress file if file's size is greater than a certain number
    const addAttach = (item: any, size: number) => {
        const imgElement = document.createElement("img");
        imgElement.src = item;

        imgElement.onload = (event: any) => {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 400;
            const scaleSize = MAX_WIDTH / event.target.width;

            canvas.width = MAX_WIDTH;
            canvas.height = event.target.height * scaleSize;

            const ctx = canvas.getContext("2d");

            ctx?.drawImage(event.target, 0, 0, canvas.width, canvas.height);

            const l = 7 * 1024 * 1024;
            const md = 3 * 1024 * 1024;
            const sm = 1 * 1024 * 1024;

            const compressFactor = size >= l ? 0.7 : size >= md ? 0.8 : 0.87;
            const srcEncoded = ctx?.canvas.toDataURL("image/jpeg", compressFactor);

            if (size >= sm) {
                setFormObject({
                    ...formObject,
                    anexo: srcEncoded || "",
                })
            } else {
                setFormObject({
                    ...formObject,
                    anexo: item,
                })
            }
        }
    };

    const convertFileToBase64 = (file: Blob, fileSize: number) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => addAttach(reader.result, fileSize);
    };

    const handleAddFile = (event: any) => {

        const file = event.target.files[0];

        if (!file) {
            return;
        }
        const mimeType = file.type;
        const fileSize = file.size;

        const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

        if (!allowedFileTypes.includes(mimeType)) {
            toast.warn('Arquivo não suportado. Arquivos devem ser do tipo jpeg, jpg ou png.');
            return;
        } else if (fileSize > 15 * 1024 * 1024) {
            toast.warn('Tamanho de arquivo não suportado (Max.: 15Mb)');
            setFormObject({
                ...formObject,
                anexo: "",
            });

            return;
        } else {
            convertFileToBase64(file, fileSize);
        }
    };

    const getUnidades = useCallback(async () => {
        setLoading(true);

        request.get('buscarUnidadesCondominio')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setListUnidades(response.data.Unidades);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const callForList = useCallback(async () => {

        const form = {
            idLeituraGeral
        }

        request.post('listarLeituraUnica', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setLeituraUnica(response.data.leituras);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
    }, []);

    const listarTodosIdsLeitura = useCallback(async () => {

        setLoading(true);

        request.get('listarIdLeiturasGeral')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setListIds(response.data.Lista);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        callForList();
        listarTodosIdsLeitura();
    }, [callForList, listarTodosIdsLeitura]);

    const listarTaxas = useCallback(async () => {

        request.get('listaComTaxas')
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    setTaxas(response.data.taxas);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
    }, []);

    useEffect(() => {
        getUnidades();
        listarTaxas();
    }, [getUnidades, listarTaxas]);

    const handleInitialize = () => {

        setLoading(true);

        const form = {
            taxa: selectedTaxaId
        }

        request.post('iniciarLeitura', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    toast.success("Leitura iniciada no sistema");
                    setIdLeituraGeral(response.data.idGeral);

                    setOpen(!open);
                } else {
                    toast.error("Erro: " + response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = () => {

        setLoading(true);

        const form = {
            formArray
        }

        request.post('gravarLeituras', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    toast.success("Leitura cadastrada com sucesso");

                    setArrayIncrement(-1);
                    setFormArray([]);
                    setFormObject({
                        ...formObject,
                        idUnidade: 0,
                        anexo: '',
                        valorLeitura: 0.000
                    });
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const submitCancel = () => {

        setLoading(true);

        const form = {
            idLeituraGeral
        }

        request.post('cancelarLeitura', form)
            .then((response) => {
                if (response.data.ok === "Sucesso") {
                    toast.success("Leitura pausada no sistema");

                    setArrayIncrement(-1);
                    dispatch(ActionsToggleButtons.setSelectedLeituraId(0));
                    dispatch(ActionsToggleButtons.setSelectedTaxaId(0));
                    setIdLeituraGeral(0);
                    setSelectedTaxaId(0);
                    setArrayUnityId([]);
                    setFormArray([]);
                    setFormObject({
                        idUnidade: 0,
                        anexo: '',
                        valorLeitura: 0.000,
                        idLeituraGeral: idLeituraGeral
                    });
                    setOpen(!open);
                } else {
                    toast.error(response.data.erro);
                }
            })
            .catch((error) => {
                if (error?.message === 'Network Error') {
                    toast.error('Falha na rede: Verifique sua conexão e tente novamente.');
                } else {
                    toast.error('Erro: A requisição falhou');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleAdd = () => {
        const { anexo, idUnidade, valorLeitura } = formObject;
        if (anexo === '' || idUnidade === 0 || valorLeitura === 0.000) {
            toast.error("Adicione todos as informações para adicionar este registro");
            return;
        }
        setFormArray(formArray => [...formArray, formObject]);
        setArrayUnityId(arrayUnityId => [...arrayUnityId, formObject.idUnidade]);
        setFormObject({
            idUnidade: 0,
            anexo: '',
            valorLeitura: 0.000,
            idLeituraGeral: idLeituraGeral
        });
        setArrayIncrement(arrayIncrement + 1);
    }

    useEffect(() => {
        if (formArray.length < limit) {
            setArrayIncrement(formArray.length >= 0 ? formArray.length + 1 : 1);
            return;
        }
    }, [formArray, arrayIncrement])

    const handleRemove = (index: number) => {
        setArrayUnityId(arrayUnityId.filter(item => item != arrayUnityId[index]));
        setFormArray(formArray.filter(item => item !== formArray[index]));

        // arrayIncrement - 2 : irá fazer com que o input seja totalmente descartado e o useEffect criará outro quando ver que formArray.length < limit
        setArrayIncrement(arrayIncrement - 2);
    }

    const handleDisabled1 = (index: number) => {
        if (formArray.length >= arrayIncrement) {
            return true;
        }
        if ((index < arrayIncrement - 1) || formArray[limit - 1]) {
            return true;
        } else {
            return false;
        }
    }

    const handleDisabled2 = (index: number) => {
        if (index === 0 && (!formArray[0] || formArray[0] === null)) {
            return true;
        } else {
            return false;
        }
    }

    const handleUnityList = () => {
        let tempLeituraUnicaList: string[] = [];
        let tempListUnidades: string[] = [];

        leituraUnica?.map((elem) => {
            tempLeituraUnicaList.push(String(elem.idUnidade));
        });
        listUnidades.map((elem) => {
            tempListUnidades.push(String(elem.idUnidade));
        });

        if (listIds) {
            if (listIds.includes(String(idLeituraGeral)) && leituraUnica) {
                const difference = tempListUnidades.filter(obj => !tempLeituraUnicaList.includes(obj));
                setFilteredUnityList(difference);
                
            } else {
                const realTimeFilter = tempListUnidades.filter(obj => !arrayUnityId.includes(obj));
                setFilteredUnityList(realTimeFilter);
            }
        } else {
            const realTimeFilter = tempListUnidades.filter(obj => !arrayUnityId.includes(obj));
            setFilteredUnityList(realTimeFilter);
        }
    }

    useEffect(() => {
        if (listUnidades) {
            handleUnityList();
        }
    }, [listUnidades, listIds, idLeituraGeral, arrayUnityId, leituraUnica]);

    useEffect(() => {
        if (!cameraOpened && mediaStream !== null) {
            stopCameraFunction();
        }
    }, [cameraOpened])

    const sanitizeInput = (value: any) => {
        let input = value.replace(/\D/g, '');
        setFormObject({
            ...formObject,
            valorLeitura: Number((input / 1000).toFixed(3))
        })
    }

    return (

        <>
            {
                loading
                    ? (
                        <Container>
                            <Load>
                                <Loading type='spin' width={50} color={COLORS.SECONDARY} />
                            </Load>
                        </Container>
                    ) : (
                        <Container>

                            {
                                <Stack
                                    border={`1px solid ${COLORS.PRIMARY}`}
                                    bgColor="#fff"
                                    borderRadius={4} padding={2}
                                    marginTop={3}
                                >
                                    <Text
                                        mb="3" size="1xs"
                                        fontWeight="bold"
                                    >
                                        Taxa a ser lida:
                                    </Text>
                                    <Select
                                        placeholder="Selecione a taxa"
                                        size="sm" bg="white"
                                        w={['full', 'full', 'full', 'full']}
                                        borderColor="gray.200"
                                        value={selectedTaxaId}
                                        onChange={(e: any) => setSelectedTaxaId(e.target.value)}
                                        disabled={open ? true : false}
                                    >
                                        {
                                            taxas.map((elem) => (
                                                <option key={elem.id} value={elem.id}>{elem.descricao}</option>
                                            ))
                                        }
                                    </Select>
                                    <Button
                                        colorScheme="green"
                                        onClick={() => handleInitialize()}
                                        disabled={(!open && selectedTaxaId === 0) || (open && taxaId !== 0) || idLeituraGeral !== 0 ? true : false}
                                    >
                                        Iniciar leitura
                                    </Button>
                                </Stack>
                            }

                            {
                                open && (
                                    <Stack
                                        marginTop={5}
                                        marginBottom={10}
                                        paddingLeft={2}
                                    >
                                        <Button
                                            colorScheme="orange"
                                            onClick={() => submitCancel()}
                                            alignSelf="flex-end"
                                        >
                                            Pausar leitura
                                        </Button>
                                    </Stack>
                                )
                            }

                            {(open && arrayIncrement > 0) &&
                                [...Array(arrayIncrement)].map((elem, index) => (
                                    <>
                                        {(arrayIncrement > limit && index === arrayIncrement - 1) ? (
                                            <Stack
                                                key={index}
                                                border={(arrayIncrement > limit && index === arrayIncrement - 1) ? "2px solid orange" : "1px solid #ccc"}
                                                borderRadius={4} padding={2}
                                                marginTop={3}
                                            >
                                                <Box>
                                                    <Text>Para adicionar mais leituras, envie as já adicionadas</Text>
                                                </Box>
                                            </Stack>
                                        ) : (
                                            <Stack
                                                key={index}
                                                border={(arrayIncrement > limit && index === arrayIncrement - 1) ? "2px solid orange" : "1px solid #ccc"}
                                                borderRadius={4} padding={2}
                                                marginTop={3}
                                            >
                                                <Flex
                                                    gridGap={3}
                                                    flexDirection={['column', 'column', 'column', 'row']}
                                                >

                                                    <Box>
                                                        <Text
                                                            mb="3" size="1xs"
                                                            fontWeight="bold"
                                                        >
                                                            Unidade:
                                                        </Text>
                                                        <Select
                                                            placeholder="Selecione a unidade"
                                                            size="sm" bg="white"
                                                            w={['full', 'full', 'full', 'full']}
                                                            borderColor="gray.200"
                                                            value={formArray[index]?.idUnidade ? formArray[index].idUnidade : formObject.idUnidade}
                                                            onChange={(e: any) => setFormObject({
                                                                ...formObject,
                                                                idUnidade: e.target.value,
                                                            })}
                                                            disabled={handleDisabled1(index)}
                                                        >
                                                            {
                                                                listUnidades.map((elem) => (
                                                                    <option
                                                                        key={elem.idUnidade}
                                                                        value={elem.idUnidade}
                                                                        disabled={
                                                                            filteredUnityList.includes(String(elem.idUnidade)) && !arrayUnityId.includes(String(elem.idUnidade)) ?
                                                                                false : true
                                                                        }
                                                                    >
                                                                        {elem.nomeUnidade}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Box>

                                                    <Box>
                                                        <Text
                                                            mb="3" size="1xs"
                                                            fontWeight="bold"
                                                        >
                                                            Leitura/valor:
                                                        </Text>
                                                        <Input
                                                            size="sm" bg="white"
                                                            w={['full', 'full', 'full', 'full']}
                                                            type="number"
                                                            value={formArray[index]?.valorLeitura ? (formArray[index]?.valorLeitura).toFixed(3) : (formObject.valorLeitura).toFixed(3)}
                                                            onChange={(e: any) => sanitizeInput(e.target.value)}
                                                            disabled={handleDisabled1(index)}
                                                        />
                                                    </Box>

                                                </Flex>

                                                <Flex w="100%" flexDir="column" alignItems="flex-start">

                                                    <Text
                                                        fontWeight="bold"
                                                        alignSelf="flex-start"
                                                        size="1xs"
                                                        marginBottom={3}
                                                    >
                                                        Foto da leitura:
                                                    </Text>

                                                    <Flex
                                                        alignSelf="flex-start"
                                                        cursor="pointer"
                                                        onClick={() => setCameraOpened(!cameraOpened)}
                                                    >

                                                        {
                                                            cameraOpened ? (
                                                                <>
                                                                    <FaFolder size={20} color={COLORS.PRIMARY} />
                                                                    <Text size="2x" marginLeft={2}>
                                                                        Selecionar arquivo local
                                                                    </Text>
                                                                </>
                                                            )
                                                                : (
                                                                    <>
                                                                        <FaCamera size={20} color={COLORS.PRIMARY} />
                                                                        <Text size="2x" marginLeft={2}>
                                                                            Tirar foto com a câmera
                                                                        </Text>
                                                                    </>
                                                                )
                                                        }
                                                    </Flex>

                                                    <PhotoManage
                                                        file={formArray[index]?.anexo ? formArray[index]?.anexo : formObject.anexo}
                                                        cameraOpened={cameraOpened}
                                                        setFile={setFormObject}
                                                        formObject={formObject}
                                                        handleAddFile={handleAddFile}
                                                        disabled={handleDisabled1(index)}
                                                    />

                                                </Flex>

                                                <Button
                                                    width="1xs"
                                                    fontSize="sm"
                                                    colorScheme="green"
                                                    alignSelf="flex-end"
                                                    onClick={() => handleAdd()}
                                                    disabled={handleDisabled1(index)}
                                                >
                                                    Adicionar item
                                                </Button>

                                                <Button
                                                    width="1xs"
                                                    fontSize="sm"
                                                    _hover={{
                                                        backgroundColor: "#ff080814"
                                                    }}
                                                    bgColor="transparent"
                                                    color="red"
                                                    border="2px solid #f00"
                                                    alignSelf="flex-end"
                                                    fontWeight="extrabold"
                                                    onClick={() => handleRemove(index)}
                                                    disabled={handleDisabled2(index)}
                                                >
                                                    Remover
                                                </Button>
                                            </Stack>
                                        )

                                        }
                                    </>
                                ))
                            }

                            {
                                (open && formArray[0]) && (
                                    <Stack>
                                        <Button colorScheme="green" marginTop={5} onClick={() => handleSubmit()}>Enviar</Button>
                                    </Stack>
                                )
                            }

                        </Container >
                    )
            }
        </>

    );
}

export default GravarLeituras;