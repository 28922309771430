/* eslint-disable no-unused-expressions */
import React, { createContext, useContext, useState } from 'react';

interface TabSelectedContextData {
    defaultIndex: number;
    handleChangeDefaultIndex: (index :number) => void
}

const TabSelectedContext = createContext<TabSelectedContextData>(
  {} as TabSelectedContextData,
);

const TabSelectedProvider: React.FC = ({ children }) => {
  const [defaultIndex, setDefaultIndex] = useState(0);

  function handleChangeDefaultIndex(index : number) {
    setDefaultIndex(index);
  }

  return (
    <TabSelectedContext.Provider
      value={{
        defaultIndex,
        handleChangeDefaultIndex,
      }}
    >
      {children}
    </TabSelectedContext.Provider>
  );
};

function useTabSelected(): TabSelectedContextData {
  const context = useContext(TabSelectedContext);

  return context;
}

export { TabSelectedProvider, useTabSelected };
