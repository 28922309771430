import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 5px;
  margin: 5px;
  border-radius: 3px;
  min-height: 120px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const ContainerClean = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px;
  margin: 5px;
  border-radius: 3px;
  min-height: 80px;
`;

export const DivIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.PRIMARY}3;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  align-self: center;
`;

export const Title = styled.label`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: ${COLORS.TEXT_PRIMARY};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  span {
      color: ${COLORS.TEXT_PRIMARY};
    }
  [data-bold] {
    font-weight: bold;
    margin-right: 4px;
  }
  [data-flex1] {
    flex: 1;
  }
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const RowTitleStyled = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_PRIMARY};
  font-weight: bold;
  margin-right: 4px;
`;

export const RowTextStyled = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_PRIMARY};
  /* max-width: 45ch; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
`;
