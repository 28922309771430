/* eslint-disable no-unused-expressions */
import React, {
  createContext, useContext,
} from 'react';

type breadcrumbsChatFuncionarioProps = {
    title : string,
    to : string,
    hash : string
}
interface BreadcrumbsContextData {
    breadcrumbsChatFuncionario : breadcrumbsChatFuncionarioProps[];
    changeBreadcrumbsChatFuncionario : (breadcrumbsChatFuncionarioValue : breadcrumbsChatFuncionarioProps[]) => void
}

const BreadcrumbsContext = createContext<BreadcrumbsContextData>({} as BreadcrumbsContextData);

const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [breadcrumbsChatFuncionario, setBreadcrumbsChatFuncionario] = React.useState<breadcrumbsChatFuncionarioProps[]>([]);

  function changeBreadcrumbsChatFuncionario(breadcrumbsChatFuncionarioValue : breadcrumbsChatFuncionarioProps[]) {
    setBreadcrumbsChatFuncionario(breadcrumbsChatFuncionarioValue);
  }
  return (
    <BreadcrumbsContext.Provider value={{
      breadcrumbsChatFuncionario,
      changeBreadcrumbsChatFuncionario,
    }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

function useBreadcrumbs(): BreadcrumbsContextData {
  const context = useContext(BreadcrumbsContext);

  return context;
}

export { BreadcrumbsProvider, useBreadcrumbs };
