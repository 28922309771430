import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { IVisits } from '../../interfaces/responses/IVisits';
import request from '../../services/request';
import { ActionsHeader } from '../../store/ducks/header';
import { dateStringToDate, dateStringToTime } from '../../utils/dates';
import Button from '../../components/Button/Button';
import { cpfMask } from '../../utils/masks';
import {
  FormAgendamentoVisita, FormOption
} from './styles';

const ListVisits = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visit, setVisit] = React.useState<Array<IVisits>>([]);
  const { idVisit } = useParams() as any;

  React.useEffect(() => {
    request.get(`agendamentoVisitas/${idVisit}`).then((response) => {
      setVisit(response.data);
    });
    dispatch(ActionsHeader.changeTextCenter('Editar de Visita'));
  }, [dispatch, idVisit]);

  function editVisitById(e: any) {
    e.preventDefault();

    const form = new FormData(e.target);

    const sendForm = {
      id: idVisit,
      horario: form.get('dataAgendamento'),
      nomeVisitante: form.get('nomeVisitante'),
      cpf: form.get('cpf'),
      tipoVisitante: form.get('tipoVisitante'),
    };

    request.post('editarAgendamentodeVisita', sendForm).then((response) => {
      if (response.data.ok) {
        toast.success(response.data.ok);
        history.goBack();
      } else {
        toast.error(response.data.erro);
      }
    });
  }

  return (
    <>
      {visit.map((v) => (
        <FormAgendamentoVisita
          name="formAgendamentoVisita"
          className="formAgendamentoVisita"
          onSubmit={(e) => editVisitById(e)}
        >
          <FormOption>
            <label>Data agendamento:</label>
            <input
              type="datetime-local"
              name="dataAgendamento"
							// seta a data minima para agendamento
              min={`${dateStringToDate(new Date().toISOString())}T${dateStringToTime(new Date().toISOString())}`}
              defaultValue={`${dateStringToDate(v.horario)}T${dateStringToTime(v.horario)}`}
            />
          </FormOption>

          <FormOption>
            <label>Nome do visitante:</label>
            <input
              type="text"
              name="nomeVisitante"
              placeholder="Nome do visitante"
              defaultValue={v.nomeVisitante}
            />
          </FormOption>

          <FormOption>
            <label>CPF:</label>
            <input
              type="text"
              name="cpf"
              defaultValue={v.cpf}
              placeholder="000.000.000-00"
              onChange={(e) => (
                (e.target.value = cpfMask(e.target.value))
              )}
            />
          </FormOption>

          <FormOption>
            <label>Tipo de visita</label>
            <select
              name="tipoVisitante"
            >
              <option selected={v.tipoVisitante === "1"} value="1">Visitante/Familiar</option>
              <option selected={v.tipoVisitante === "2"} value="2">Prestador de serviço</option>
              <option selected={v.tipoVisitante === "3"} value="3">Entregador</option>
            </select>
          </FormOption>

          <Button theme="primary" type="submit">
            Editar
          </Button>
        </FormAgendamentoVisita>
      ))}
    </>
  );
};

export default ListVisits;