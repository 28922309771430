import {
    Button, Stack
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ActionsHeader } from "../../../../store/ducks/header";
import { useWindowSize } from "../../../Login";
import { Column } from "./styles";

const ConsumosFuncionario = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const screen = useWindowSize();

    useEffect(() => {
        dispatch(ActionsHeader.changeTextCenter('Consumos'));
    }, [dispatch]);

    return (
        <Stack>
            <Column>

                <Button
                    colorScheme="blue"
                    onClick={() => history.push('/listar_consumos')}
                    w="70%"
                    paddingTop={screen.sizeWidth >= 768 ? 10 : 7}
                    paddingBottom={screen.sizeWidth >= 768 ? 10 : 7}
                    fontSize={screen.sizeWidth >= 768 ? 25 : 18}
                >
                    Listagem de consumos
                </Button>

                <Button
                    colorScheme="blue"
                    onClick={() => history.push('/gravar_leituras')}
                    w="70%"
                    paddingTop={screen.sizeWidth >= 768 ? 10 : 7}
                    paddingBottom={screen.sizeWidth >= 768 ? 10 : 7}
                    fontSize={screen.sizeWidth >= 768 ? 25 : 18}
                >
                    Gravar Leituras
                </Button>

            </Column>
        </Stack>
    )
}

export default ConsumosFuncionario;