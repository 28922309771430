import styled, { css, keyframes } from 'styled-components';
import { Table } from '../../components/Table/styles';
import { COLORS } from '../../utils/constants';

type CardProps = {
  loading: boolean;
};

const shimmerAnimation = keyframes`
    0% { 
        background-position-x: 500%;
        background-position-y: 100%;
    }
  
    100% {
        background-position-x: 10000%;
        background-position-y: 100%;
    }
    
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: center;
`;
export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 90%;
  justify-content: center;
  margin: auto;
  @media screen and (max-width: 1085px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1085px) {
    flex-direction: column;
  }
`;
export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 475px) {
    gap: 5px;
    align-items: unset;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  select,
  input {
    width: 200px;

    @media screen and (max-width: 475px) {
      width: 100%;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media screen and (max-width: 1085px) {
    flex-direction: row;
    margin: auto;
    margin-top: 15px;
  }
`;
export const CardView = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export const TopView = styled.div`
  position: absolute;
  right: 60px;
  top: -8px;
`;

export const Card = styled.div<CardProps>`
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 12px;
  height: 350px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  table {
    tbody {
      tr {
        margin-top: 20px;
      }
    }
  }

  ${({ loading }) =>
    loading &&
    css`
      background-image: linear-gradient(
        90deg,
        #f6f7f9 0,
        #e9ebee 20%,
        #f6f7f9 40%,
        #f6f7f9
      );
      background-size: 99% 100%;
      background-repeat: no-repeat;
      animation: ${shimmerAnimation} 1s linear 1ms infinite backwards;
    `}
`;

export const RelatorioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 0;
  @media screen and (max-width: 520px) {
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    margin: auto;
  }
`;

export const Relatorio = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 20px;
`;
export const RelatorioRow = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  width: fit-content;
`;
export const RelatorioTitle = styled.p`
  font-weight: bold;
  margin: 0 5px 0 0;
`;
export const RelatorioDesc = styled.span``;

export const TableFlex1 = styled(Table)`
  margin: -20px 0;
  thead th {
    padding: 5px 10px;
    font-size: 14px;
  }

  tbody td {
    padding: 5px 10px;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableFlex2 = styled(Table)`
  margin: 15px 0;

  tr {
    border-bottom: 1px solid rgba(77, 77, 77, 0.5);
    height: 40px;

    @media screen and (max-width: 820px) {
      font-size: 12px;
    }
  }

  tr:last-child {
    border: none;
  }

  td {
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    padding-right: 10px;
    @media screen and (max-width: 620px) {
      margin: auto;
      flex-wrap: wrap;
      word-break: normal;
    }
  }

  th {
    padding-left: 10px;

    @media screen and (max-width: 520px) {
      padding-right: 15px;
    }
  }
  @media screen and (max-width: 1200px) {
    font-size: 12px;
    width: 90%;
    justify-content: center;
    margin: 15px auto;
  }
`;

export const EmptyMessage = styled.td`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

export const EmptyMessageText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 15px auto;
  align-self: center;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 92%;
  align-items: center;
  margin: 10px 30px;
  position: relative;
  @media screen and (max-width: 640px) {
    justify-content: center;
    margin-left: 22px;
  }
`;

export const ArrowButton = styled.div`
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
`;

export const SeeFilter = styled.span`
  font-size: 16px;
  color: ${COLORS.PRIMARY};
`;

export const TopHideFilter = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  align-self: flex-end;
`;

export const ArrowButtonDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;
