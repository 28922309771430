import React from 'react';
import Button from '../../../components/Button/Button';
import { IExpenses } from '../../../interfaces/responses/IExpenses';
import { dateStringToDateFormat } from '../../../utils/dates';
import { goToExternalLink } from '../../../utils/functions';

interface Props {
  expense: IExpenses;
}

const RowExpenses = ({ expense }:Props) => (
  <tr>
    {/* <td>{expense.numeroDocumento}</td> */}
    <td>{dateStringToDateFormat(expense.dataVencimento).split(' ')[0]}</td>
    {/* <td>{expense.dataPagamento && dateStringToDateFormat(expense.dataPagamento).split(' ')[0]}</td> */}
    <td>
      <p style={{
        maxWidth: '16ch',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        margin: 0,
        fontSize: '12px',
      }}
      >
        {expense.descricao}
      </p>
    </td>
    <td>{expense.valorDocumento}</td>
    {/* <td>{expense.valorPago}</td> */}
    <td>
      {expense.documentoLink
        && (
        <Button
          onClick={() => goToExternalLink(expense.documentoLink || '')}
          theme="primary"
          marginHorizontal={5}
        >
          Download
        </Button>
        )}
    </td>
    <td>
      {expense.documentoLink
        && (
        <Button
          onClick={() => goToExternalLink(expense.documentoLink || '')}
          theme="primary"
          marginHorizontal={5}
        >
          Ver Mais
        </Button>
        )}
    </td>
    {/* <td>
      {expense.comprovanteLink
        && (
        <Button
          onClick={() => goToExternalLink(expense.comprovanteLink || '')}
          theme="primary"
          marginHorizontal={5}
        >
          Download
        </Button>
        )}
    </td> */}
  </tr>
);

export default RowExpenses;
