import React, { InputHTMLAttributes, useEffect, useState } from 'react';

import {
  Input,
  Label,
  ContainerInput,
  Results,
  Loader,
  ContentInput,
} from './styles';

type result = {
  id: string;
  nome: string;
};

type InputAutoCompleteProps = {
  loading: boolean;
  resultsQuery: result[];
  changeValue: (resultSelect: result, value: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const InputAutoComplete: React.FC<InputAutoCompleteProps> = ({
  loading,
  changeValue,
  resultsQuery,
  ...rest
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    if (resultsQuery.length > 0) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  }, [resultsQuery]);

  return (
    <ContainerInput>
      <ContentInput>
        <Label>Nome</Label>

        <Input {...rest} />

        {loading && <Loader />}
      </ContentInput>
      {showDropDown && (
        <Results>
          {resultsQuery.map((r, i) => (
            <button
              key={i}
              type='button'
              onClick={() => {
                setShowDropDown(false);
                changeValue(r, r.nome);
              }}
            >
              {r.nome}
            </button>
          ))}
        </Results>
      )}
    </ContainerInput>
  );
};

export default InputAutoComplete;
