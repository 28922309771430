import * as ENV from '../.env';

export const WIDTHS = {
  MAX: 1400,
  MED: 1100,
  MIN: 800,
  DRAWER: 300,
};

export const HEIGHTS = {
  HEADER: 60,
};

export const COLORS = {
  PRIMARY: '#4a8',
  SECONDARY: '#333398',
  ICONS_HEADER: '#fff',
  TEXT_PRIMARY: '#555',
  TEXT_DISABLED: '#ccc',
  TEXT_PRIMARY_DISABLED: '#00968877',
  MODAL_BACKGROUND: '#0005',
};

export const endPoints = {
  BASE_URL_WEB: ENV.BASE_URL,
  BASE_URL_API: ENV.API,
  PROXY: ENV.PROXY,
};

export const { ENVIRONMENT } = ENV;

export const MONTH_NAMES = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril',
  'Maio', 'Junho', 'Julho', 'Agosto',
  'Setembro', 'Outubro', 'Novembro', 'Dezembro',
];

export const varLocalStorage = {
  session: '@classecon/SESSION',
};

export const MONTH_NAMES_SHORT = [
  'Jan', 'Fev', 'Mar', 'Abr',
  'Mai', 'Jun', 'Jul', 'Ago',
  'Set', 'Out', 'Nov', 'Dez',
];

export const CLASSECON_WEB = {
  DEV: 'https://dev.classecon.com.br/login',
  PRODUCTION: 'https://novo.classecon.com.br/login',
};
