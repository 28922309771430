import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    margin-top: 10px;
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 70%;
    height: 75%;
    padding: 15px;
    background-color: #fff;
    box-shadow: 4px 4px 10px #aaa;
    border-radius: 8px;

    @media screen and (max-width: 520px) {
      width: 90%;
    }
`;