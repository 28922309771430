import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const LegendContainer = styled.div`
  margin-top: 60px;
  display: flex;
  margin: 12px;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: #333333;
      margin-right: 2px;
      font-weight: bold;
    }
  }
`;

export const Ballet = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: 12px;
  margin-right: 12px;
`;

export const Wrapper = styled.div`
  padding: 40px;
`;

export const Container = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-right: auto;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  h4 {
    font-size: 21px;
    color: ${COLORS.TEXT_PRIMARY};
    margin: 0;
    margin: 12px 0 24px;
  }

  p {
    max-height: 320px;
    overflow-y: auto;

    h4 {
      margin-top: 2px;
    }
  }

  button {
    margin-top: auto;
  }
  /* Chrome, Edge, and Safari */
  p::-webkit-scrollbar {
    width: 5px;
  }

  p::-webkit-scrollbar-track-piece {
    background-color: #dcdceb;
  }

  p::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid ${COLORS.PRIMARY};
  }

  background: #fff;
  border-radius: 6px;
  padding: 12px;
`;

export const FormAddReseve = styled.div`
  width: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  h1 {
    color: #333333;
  }

  button {
    width: 80%;
    height: 40px;
    background: ${COLORS.PRIMARY};
    font-family: Montserrat, sans-serif;
    border-radius: 6px;
    margin: 24px auto 0;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      background: gray;
      cursor: not-allowed;
    }
  }
`;

export const MessageEmpty = styled.td`
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: center;
`;

export const ContainerTable = styled.div`
  flex: 1;
  overflow-y: auto;

  margin: 40px 0 100px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid ${COLORS.PRIMARY};
  }
`;

export const CalendarContainer = styled.div`
  width: 600px;
  align-self: center;
  background: #fff;
  border-radius: 12px;

  .fc {
    box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
    border-radius: 12px;
    .fc-button-group {
      margin: 12px 0 0 12px;
      > button {
        background: ${COLORS.PRIMARY};
        border: 0;
        box-shadow: 0px 0px 6px 0px rgba(212, 212, 212, 0.73);
      }
      .fc-prev-button.fc-button.fc-button-primary {
        margin-right: 12px;
      }
      .fc-next-button.fc-button.fc-button-primary {
      }
    }

    .fc-today-button {
      margin-right: 12px;
      background: ${COLORS.SECONDARY};
    }
  }
`;

export const FormReportContainer = styled.div`
  display: flex;
  margin: 12px 0 24px 0;
  align-items: flex-end;
`;
