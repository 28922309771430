import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: ${(props: any) =>
    props['data-margintop'] ? `${props['data-margintop']}px` : '18px'}; */

  select {
    min-width: 100px;
    max-width: 350px;
    background-color: #fff;
    color: ${COLORS.TEXT_PRIMARY};
    border-radius: 5px;
    height: 35px;
    padding: 0 12px;
    border: 1px solid #666;
    font-size: 14px;
    font-weight: bold;

    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }

  ${(attr: any) =>
    attr['data-margintop'] && `margin-top: ${attr['data-margintop']}px;`};
  ${(attr: any) => attr['data-padding'] && 'padding: 0px 5px;'};
`;

export const Span = styled.span`
  color: ${COLORS.TEXT_PRIMARY};
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;
