import React from 'react';

import { Container } from './styles';

export type Options = Array<{value : string | number, label : string, descricao : string}>

type SelectAreaPros = {
value : string,
options : Options;
label : string;
onChange : (e:React.ChangeEvent<HTMLSelectElement>) => void;
}
const SelectAreas: React.FC<SelectAreaPros> = ({
  label, options, onChange, value,
}) => (
  <Container>
    {label && <span>{label}</span>}
    <select id="email" onChange={onChange} value={value}>
      {options.map((v) => <option key={v.value} value={v.value}>{v.label}</option>)}
    </select>
  </Container>
);

export default SelectAreas;
