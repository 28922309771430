import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import { CardClean } from '../../../components/Card/Card';
import ToggleButtons from '../../../components/ToggleButtons/ToggleButtons';
import request from '../../../services/request';
import { ActionsHeader } from '../../../store/ducks/header';
import { dateStringToDateTimeFormat } from '../../../utils/dates';

const DetailsAssembly = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams() as any;
  const [pautas, setPautas] = React.useState([]);
  const [assembly, setAssembly] = React.useState<null | any>();
  const [indexButton, setIndexButton] = React.useState(assembly?.status === 'ATIVA' ? 1 : 0);

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Detalhes da assembleia'));

    request.get(`assembleiasvirtuais/${id}`).then((response) => (
      setAssembly(response.data.elemento)
    ));

    request.get(`pautas?idAssembleiaVirtual=${id}`).then((response) => {
      if (response.data.ok === 'Sucesso') {
        const pautasResponse = response.data.listagem.resultados;
        setPautas(pautasResponse);
      }
    });
  }, [dispatch, id]);

  const handleChangeStatus = (status:string) => {
    request.post(`assembleiasvirtuais/${assembly.id}/mudarStatus`, {
      status,
    }).then((response) => {
      if (response.data.ok) {
        if (status === 'ATIVA') {
          toast.success('Sucesso, assembleia ATIVADA com sucesso');
        } else {
          toast.success('Sucesso, a assembleia foi REPROVADA');
        }
        history.push('assembly/list');
      } else {
        toast.success('Erro', response.data.erro);
      }
    }).catch((error) => {
      if (error?.message === 'Network Error') {
        toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
      } else {
        toast.error('Erro : A requisição falhou');
      }
    });
  };

  return assembly ? (
    <div>

      {
        // Exibindo somente se estiver com status de EDIÇÂO
        assembly.status === 'APROVADA-EDICAO-ADM' && (
        <ToggleButtons
          selected={indexButton}
          buttons={[{
            label: 'Antes', onClick: () => setIndexButton(0),
          }, {
            label: 'Depois', onClick: () => setIndexButton(1),
          }]}
        />
        )
      }

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center' }}>
          {indexButton === 0 ? assembly.dadosPublicada.titulo || assembly.titulo : assembly.dadosAdministradora.titulo}
        </h2>
        <p style={{ textAlign: 'center' }}>
          {indexButton === 0 ? assembly.dadosPublicada.descricao || assembly.descricao : assembly.dadosAdministradora.descricao}
        </p>
        <p style={{ textAlign: 'center' }}>
          Horário de disponível para votos:
          {' '}
          {indexButton === 0
            ? dateStringToDateTimeFormat(assembly?.dadosPublicada?.horarioInicio || assembly?.horarioInicio)
            : dateStringToDateTimeFormat(assembly?.dadosAdministradora?.horarioInicio || assembly?.horarioInicio)}
          {' até '}
          {indexButton === 0
            ? dateStringToDateTimeFormat(assembly?.dadosPublicada?.horarioTermino || assembly?.horarioTermino)
            : dateStringToDateTimeFormat(assembly?.dadosAdministradora?.horarioTermino || assembly?.horarioTermino)}
        </p>
      </div>
      <div>
        <h3 style={{ textAlign: 'center' }}>Pautas</h3>
        {
          pautas.map((p:any) => {
            const pauta = indexButton === 0 ? p.dadosPublicada : p.dadosAdministradora;
            return (
              <CardClean.Card key={p.id}>
                <CardClean.Row>
                  <CardClean.RowTitle>Título: </CardClean.RowTitle>
                  <CardClean.RowText>{pauta.titulo || p.titulo}</CardClean.RowText>
                </CardClean.Row>
                <CardClean.Row>
                  <CardClean.RowTitle>Descrição: </CardClean.RowTitle>
                  <CardClean.RowText>{pauta.descricao || p.descricao}</CardClean.RowText>
                </CardClean.Row>
              </CardClean.Card>
            );
          })
        }
      </div>

      {
        // Exibindo somente se estiver com status de EDIÇÂO
        assembly.status === 'APROVADA-EDICAO-ADM' && (
        <div>
          <Button onClick={() => handleChangeStatus('ATIVA')}>Aprovar Modificações</Button>
          <Button onClick={() => handleChangeStatus('REPROVADA-SINDICO')}>Reprovar Modificações</Button>
        </div>
        )
      }

    </div>
  ) : <div />;
};

export default DetailsAssembly;
