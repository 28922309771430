import React, { ReactText } from 'react';
import {
  Flex, Icon, FlexProps, Text,
} from '@chakra-ui/react';

import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';

interface NavItemProps extends FlexProps {
  icon: IconType;
  hash: string;
  children: ReactText;
}

const NavItem = ({
  icon, children, hash, ...rest
}: NavItemProps) => {
  const { pathname } = useLocation();

  return (
    <Flex
      align="center"
      h="50px"
      paddingLeft="20px"
      color="black"
      role="group"
      mb="8px"
      style={
        pathname === `/${hash}` || (hash === '/' && pathname.includes('home'))
          ? { backgroundColor: '#009B6E', color: 'white' }
          : {}
      }
      cursor="pointer"
      _hover={{
        bg: '#c2f5e6',
        color: '#000',
      }}
      data-testid={`sidebar-button-${hash}`}
      {...rest}
    >
      {icon && (
        <Icon
          mr="12px"
          h="22px"
          w="22px"
          color="#009B6E"
          style={pathname === `/${hash}` || (hash === '/' && pathname.includes('home')) ? { color: 'white' } : {}}
          //   _groupHover={{
          //     color: 'white',
          //   }}
          as={icon}
        />
      )}
      <Text fontFamily="Montserrat" fontWeight="bold" fontSize="16px">
        {children}
      </Text>
    </Flex>
  );
};

export default NavItem;
