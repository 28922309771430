import IAction from '../../interfaces/IAction';
import IHeader from '../../interfaces/states/IHeader';

export const TypesHeader = {
  CHANGE_TEXT_CENTER: 'header/CHANGE_TEXT_CENTER',
};

// Reducer

const initialState = {
  textCenter: 'Início',
} as IHeader;

export default function reducer(state = initialState, action:IAction) {
  switch (action.type) {
    case TypesHeader.CHANGE_TEXT_CENTER:
      return { ...state, textCenter: action.payload.text } as IHeader;
    default:
      return state;
  }
}

// Action Creators
export const ActionsHeader = {
  changeTextCenter,
};

function changeTextCenter(text:string) {
  return {
    type: TypesHeader.CHANGE_TEXT_CENTER,
    payload: { text },
  } as IAction;
}
