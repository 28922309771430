import React, { useEffect } from "react";
import {
    AccordionButton, AccordionIcon,
    AccordionItem, AccordionPanel, Button,
    Flex, Table, Tbody,
    Td, Th, Thead, Tr
} from "@chakra-ui/react";
import { useWindowSize } from "../../../../Login";
import { COLORS } from "../../../../../utils/constants";
import { IList } from "..";
import { IListaUnica } from "../../../../../interfaces/responses/IListaUnica";
import { TableFlex2 } from "./styles";
import IndividualLeiturasTable from "./IndividualLeiturasTable";

interface Props {
    baseList: IList[];
    leituraUnica: IListaUnica[];
    submitData: (id: number) => void;
    handleIdUnidade: (value: number) => string | undefined;
    handleExclude: (id: number) => void;
    handleEdit: (idLeitura: number, taxa: number) => void;
    submitFinish: (idLeituraGeral: number, controller: string) => void;
}

const MainTable: React.FC<Props> = ({
    baseList,
    leituraUnica,
    submitData,
    handleIdUnidade,
    handleExclude,
    handleEdit,
    submitFinish
}) => {

    const screen = useWindowSize();
    const [toggleTable, setToggleTable] = React.useState(false);

    useEffect(() => {
        if (screen.sizeWidth <= 980)
            setToggleTable(true);
        else {
            setToggleTable(false);
        }
    }, [screen.sizeWidth]);

    return (
        <>
            {!toggleTable ?
                baseList?.map((elem) => (
                    <AccordionItem>
                        <AccordionButton
                            padding={0}
                            marginTop={1}
                            marginBottom={1}
                            borderRadius={6}
                            border={`1px solid ${COLORS.PRIMARY}`}
                            _focus={{ boxShadow: "none" }}
                            onClick={() => submitData(elem.id)}
                        >
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th w="30%">Data e hora</Th>
                                        <Th w="20%">status</Th>
                                        <Th w="30%">taxa</Th>
                                        <Th w="20%">Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr key={elem.id}>
                                        <Td>{elem.data}</Td>
                                        <Td>{elem.status}</Td>
                                        <Td>{elem.descricao}</Td>
                                        <Td>
                                            <Flex
                                                flexDirection="column"
                                                gridGap={2}
                                            >
                                                <Button
                                                    fontSize={15}
                                                    wordBreak="break-word"
                                                    overflowWrap="break-word"
                                                    colorScheme="green"
                                                    onClick={() => handleEdit(elem.id, elem.idTaxa)}
                                                    disabled={elem.status === 'FINALIZADO' ? true : false}
                                                >
                                                    Continuar leituras
                                                </Button>

                                                {elem.status !== 'FINALIZADO' &&
                                                    <Button
                                                        colorScheme="orange"
                                                        onClick={() => submitFinish(elem.id, 'finish')}
                                                        disabled={elem.status === 'FINALIZADO' ? true : false}
                                                    >
                                                        Finalizar
                                                    </Button>
                                                }

                                                {elem.status === 'FINALIZADO' &&
                                                    <Button
                                                        colorScheme="orange"
                                                        onClick={() => submitFinish(elem.id, 'reativate')}
                                                    >
                                                        Reativar
                                                    </Button>
                                                }
                                            </Flex>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                            {leituraUnica && (
                                <IndividualLeiturasTable
                                    leituraUnica={leituraUnica}
                                    handleIdUnidade={handleIdUnidade}
                                    handleExclude={handleExclude}
                                />
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                )) : (
                    baseList?.map((item) => (
                        <AccordionItem>
                            <AccordionButton
                                padding={0}
                                marginTop={1}
                                marginBottom={1}
                                borderRadius={6}
                                border={`1px solid ${COLORS.PRIMARY}`}
                                _focus={{ boxShadow: "none" }}
                                onClick={() => submitData(item.id)}
                            >

                                <TableFlex2 key={item.id}>
                                    <Thead>
                                        <Tr>
                                            <Th w="25%">Data e hora</Th>
                                            <Td w="75%">{item.data}</Td>
                                        </Tr>
                                        <Tr>
                                            <Th w="25%">Status</Th>
                                            <Td w="75%">{item.status}</Td>
                                        </Tr>
                                        <Tr>
                                            <Th w="25%">Taxa</Th>
                                            <Td w="75%">{item.descricao}</Td>
                                        </Tr>
                                        <Tr>
                                            <Th w="25%">Ações</Th>
                                            <Td w="75%">
                                                <Flex
                                                    flexDirection="column"
                                                    gridGap={2}
                                                >
                                                    <Button
                                                        fontSize={15}
                                                        wordBreak="break-word"
                                                        overflowWrap="break-word"
                                                        colorScheme="green"
                                                        onClick={() => handleEdit(item.id, item.idTaxa)}
                                                        disabled={item.status === 'FINALIZADO' ? true : false}
                                                    >
                                                        Continuar leituras
                                                    </Button>

                                                    {item.status !== 'FINALIZADO' &&
                                                        <Button
                                                            colorScheme="orange"
                                                            onClick={() => submitFinish(item.id, 'finish')}
                                                            disabled={item.status === 'FINALIZADO' ? true : false}
                                                        >
                                                            Finalizar
                                                        </Button>
                                                    }

                                                    {item.status === 'FINALIZADO' &&
                                                        <Button
                                                            colorScheme="orange"
                                                            onClick={() => submitFinish(item.id, 'reativate')}
                                                        >
                                                            Reativar
                                                        </Button>
                                                    }
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    </Thead>
                                </TableFlex2>

                                <AccordionIcon />
                            </AccordionButton>

                            <AccordionPanel pb={4}>
                                {leituraUnica && (
                                    <IndividualLeiturasTable
                                        leituraUnica={leituraUnica}
                                        handleIdUnidade={handleIdUnidade}
                                        handleExclude={handleExclude}
                                    />
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    ))
                )
            }
        </>
    )
}

export default MainTable;