import React from 'react';

import { Container } from './styles';

const FormContainer = ({ children }:any) => (
  <Container>
    {children}
  </Container>
);

export default {
  Container: FormContainer,
};
