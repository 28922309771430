import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ActionsHeader } from '../../store/ducks/header';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import { dateStringToDateFormat, dateStringToDateTimeFormat } from '../../utils/dates';
import request from '../../services/request';
import { Container, EditGroup, EditText, EditWrapper, Group, InputWrapper, Load, PhoneWrapper, SubmitButton, TextUpdated, Title } from './styles';
import { BiEdit } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { COLORS } from '../../utils/constants';
import { cpfMask, maskPhone } from '../../utils/masks';
import { useWindowSize } from '../Login';
import CheckBox from '../../components/CheckBox/CheckBox';
import FirstStep from './Steps/FirstStep';
import Loading from 'react-loading';

/* =============================== TYPES =============================== */

interface Props {
  previousData: any;
  updated: any;
}

/* =============================== COMPONENT =============================== */

function RegisterUpdatePF({ previousData, updated }: Props) {

  /* =============================== CONSTANTS / STATES =============================== */

  const dispatch = useDispatch();
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);

  const [nome, setNome] = useState('');
  const [rg, setRG] = useState('');
  const [sexo, setSexo] = useState('');
  const [ufRG, setUfRG] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [profissao, setProfissao] = useState('');

  const [isWhatsapp1, setIsWhatsapp1] = useState(false);
  const [isWhatsapp2, setIsWhatsapp2] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [firstStep, setFirstStep] = useState(false);

  /* =============================== EFFECTS =============================== */

  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Atualizacão cadastral'));
  }, [dispatch]);

  React.useEffect(() => {
    if (firstStep) {
      setIsEditable(false);
    }
  }, [firstStep])

  React.useEffect(() => {
    if (updated) {
      const [dateFormatted] = updated.dataNascimento
        ? dateStringToDateFormat(updated.dataNascimento).split(' ') : [];
      setNome(updated.nome);
      setCpf(updated.cpf);
      setRG(updated.rg);
      setDataNascimento(previousData.dataNascimento);
      setSexo(updated.sexo);
      setUfRG(updated.ufRg);
      setEstadoCivil(updated.estadoCivil);
      setEmail(updated.email);
      setTelefone(updated.telefone);
      setTelefone2(updated.telefone2);
      setProfissao(updated.profissao);

      // Transformação de string recebida da resposta da API para booleano
      if (updated.verificarWhats1 === '0') {
        setIsWhatsapp1(false);
      } else {
        setIsWhatsapp1(true);
      }

      if (updated.verificarWhats2 === '0') {
        setIsWhatsapp2(false);
      } else {
        setIsWhatsapp2(true);
      }
    } else if (previousData) {
      const [dateFormatted] = previousData.dataNascimento
        ? dateStringToDateFormat(previousData.dataNascimento).split(' ') : [];
      setNome(previousData.nome);
      setCpf(previousData.cpf);
      setRG(previousData.rg);
      setDataNascimento(previousData.dataNascimento);
      setSexo(previousData.sexo);
      setUfRG(previousData.ufRg);
      setEstadoCivil(previousData.estadoCivil);
      setEmail(previousData.email);
      setTelefone(previousData.celular);
      setTelefone2(previousData.telefoneResidencial);
      setProfissao(previousData.profissao);

      // Transformação de string recebida da resposta da API para booleano
      if (previousData.flagCelularWhats === '0') {
        setIsWhatsapp1(false);
      } else {
        setIsWhatsapp1(true);
      }

      if (previousData.flagTelefoneResidencialWhats === '0') {
        setIsWhatsapp2(false);
      } else {
        setIsWhatsapp2(true);
      }
    }
  }, [previousData, updated]);

  /* =============================== FUNCTIONS =============================== */

  const handleEdit = () => {
    setIsEditable(!isEditable);
  }

  /* =============================== REQUESTS =============================== */

  const handleSubmit = () => {
    if (!dataNascimento || dataNascimento.length < 10) {
      toast.warn('Atenção: A data de nascimento é obrigatória.');
      return;
    }
    if (!sexo || sexo.length < 3) {
      toast.warn('Atenção: O campo sexo é obrigatório.');
      return;
    }
    if (!estadoCivil || estadoCivil.length < 5) {
      toast.warn('Atenção: O estado civil é obrigatório.');
      return;
    }
    if (!profissao || profissao.length < 3) {
      toast.warn('Atenção: O campo profissão é obrigatório.');
      return;
    }

    setLoading(true);

    // const [day, month, year] = dataNascimento.split('/');

    const form = {
      nome,
      cpf,
      rg,
      dataNascimento,
      sexo,
      ufRG,
      estadoCivil,
      telefone,
      telefone2,
      isWhatsapp1,
      isWhatsapp2,
      email,
      profissao
    };

    try {
      request.post('atualizarCadastro', form).then((response) => {
        if (response.data.ok === 'Sucesso') {
          toast.success(`Muito Bem, ${response.data.mensagem}`);
          setIsEditable(false);
        } else {
          toast.error(`Atenção, ${response.data.erro}`);
        }
      }).catch((error) => {
        if (error?.message === 'Network Error') {
          toast.error('Falha na rede. : Verifique sua conexão e tente novamente.');
        } else {
          toast.error('Atenção : A requisição falhou');
        }
      }).finally(() => {
        setLoading(false);
      });
    } catch (err) {
      console.error(err);
    }
  };

  /* =============================== RENDERS =============================== */

  return (
    <Container>
      {
        updated && (
          <TextUpdated>
            {`Ultima atualização: ${dateStringToDateTimeFormat(updated.dataAtualizacao)}`}
          </TextUpdated>
        )
      }

      {
        !firstStep && (
          <>
            <Group>

              <EditGroup>
                <div style={{ width: 30 }} />
                <Title style={{ textAlign: "center" }}>Documentos</Title>
                <EditWrapper onClick={() => setFirstStep(true)}>
                  <BiEdit size={30} color={COLORS.PRIMARY} />
                  <EditText>editar</EditText>
                </EditWrapper>
              </EditGroup>

              <Input readOnly label="Nome" fontColor={COLORS.PRIMARY} value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Nome" style={{ width: "100%", color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }} />

              <Input readOnly label="RG" fontColor={COLORS.PRIMARY} value={rg} onChange={(e) => setRG(e.target.value)} placeholder="RG" style={{ width: "100%", color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }} />

              <Input readOnly label="UF do RG" fontColor={COLORS.PRIMARY} value={ufRG} onChange={(e) => setUfRG(e.target.value)} placeholder="Ex.: DF" style={{ width: "100%", color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }} />

              <Input readOnly label="CPF" fontColor={COLORS.PRIMARY} value={cpf} onChange={(e) => setCpf(cpfMask(e.target.value))} placeholder="000.000.000-00" style={{ width: "100%", color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }} />

              <InputWrapper>
                <Title style={{ textAlign: "center" }}>Contato</Title>
              </InputWrapper>

              <PhoneWrapper>
                <Input readOnly label="Telefone 1" fontColor={COLORS.PRIMARY} value={telefone} onChange={(e) => setTelefone(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

                <CheckBox label="Marcar como whatsapp" checked={isWhatsapp1} onChange={() => setIsWhatsapp1(!isWhatsapp1)} disabled lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
              </PhoneWrapper>

              <PhoneWrapper>
                <Input readOnly label="Telefone 2" fontColor={COLORS.PRIMARY} value={telefone2} onChange={(e) => setTelefone2(maskPhone(e.target.value))} placeholder="DDD + número" style={{ color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", width: size.sizeWidth <= 520 ? "100%" : "" }} />

                <CheckBox label="Marcar como whatsapp" checked={isWhatsapp2} onChange={() => setIsWhatsapp2(!isWhatsapp2)} disabled lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
              </PhoneWrapper>

              <Input readOnly label="Email" fontColor={COLORS.PRIMARY} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="nome@email.com" type="email" style={{ width: "100%", color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }} />

            </Group>

            <Group>

              <InputWrapper>
                <Title style={{ textAlign: "center" }}>Dados Adicionais</Title>
              </InputWrapper>

              <InputWrapper>
                <Input
                  type="date"
                  marginTop={0}
                  value={dataNascimento}
                  placeholder="00/00/0000"
                  label="Data de nascimento"
                  fontColor={COLORS.PRIMARY}
                  onChange={(e: any) => setDataNascimento(e.target.value)}
                  style={{ width: size.sizeWidth <= 1200 ? "100%" : 400, color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "", pointerEvents: !isEditable ? 'none' : "auto" }}
                />
                <Select
                  padding
                  label="Sexo"
                  value={sexo}
                  options={arraySexo}
                  disabled={!isEditable ? true : false}
                  margintop={size.sizeWidth <= 1200 ? 10 : 0}
                  onChange={(e: any) => setSexo(e.target.value)}
                  style={{ width: size.sizeWidth <= 1200 ? "100%" : 400, color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : `${COLORS.PRIMARY}` }}
                />
              </InputWrapper>

              <InputWrapper>
                <Select
                  padding
                  margintop={0}
                  value={estadoCivil}
                  label="Estado Civil"
                  options={arrayEstadoCivil}
                  disabled={!isEditable ? true : false}
                  onChange={(e: any) => setEstadoCivil(e.target.value)}
                  style={{ width: size.sizeWidth <= 1200 ? "100%" : 400, color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : `${COLORS.PRIMARY}` }}
                />

                <Input
                  value={profissao}
                  label="Profissão"
                  placeholder="Profissão"
                  fontColor={COLORS.PRIMARY}
                  readOnly={!isEditable ? true : false}
                  marginTop={size.sizeWidth <= 1200 ? 10 : 0}
                  onChange={(e) => setProfissao(e.target.value)}
                  style={{ width: size.sizeWidth <= 1200 ? "100%" : 400, color: !isEditable ? `${COLORS.TEXT_PRIMARY_DISABLED}` : "" }}
                />

              </InputWrapper>

              {
                isEditable &&
                (!loading ?
                  <InputWrapper>
                    <SubmitButton onClick={handleSubmit}>Confirmar</SubmitButton>
                  </InputWrapper>
                  :
                  <Load>
                    <Loading color={COLORS.PRIMARY} height={50} type="spin" />
                  </Load>
                )
              }

            </Group>
          </>
        )
      }

      {
        firstStep && (
          <FirstStep
            nome={nome}
            rg={rg}
            ufRg={ufRG}
            cpf={cpf}
            telefone1={telefone}
            telefone2={telefone2}
            isWhatsapp1={isWhatsapp1}
            isWhatsapp2={isWhatsapp2}
            sexo={sexo}
            estadoCivil={estadoCivil}
            profissao={profissao}
            dataNascimento={dataNascimento}
            email={email}
            firstStep={firstStep}
            setFirstStep={setFirstStep}
          />
        )
      }

    </Container>
  );
}

/* =============================== OBJECTS =============================== */

const arraySexo = [
  { value: '', label: 'Selecione uma opção' },
  { value: 'MASCULINO', label: 'Masculino' },
  { value: 'FEMININO', label: 'Feminino' },
];

const arrayEstadoCivil = [
  { value: '', label: 'Selecione uma opção' },
  { value: 'SOLTEIRO', label: 'Solteiro' },
  { value: 'CASADO', label: 'Casado' },
  { value: 'DIVORCIADO', label: 'Divorciado' },
  { value: 'SEPARADO_JUDICIALMENTE', label: 'Separado Judicialmente' },
  { value: 'VIUVO', label: 'Viúvo(a)' },
  { value: 'UNIAO_ESTAVEL', label: 'União Estável' },
  // { value: 'ESTADO_MARITAL', label: 'Estado Marital' },
  // { value: 'COMPANHEIRO', label: 'Companheiro' },
  // { value: 'OUTROS', label: 'Outros' },
];

export default RegisterUpdatePF;
