import IAction from '../../interfaces/IAction';
import ISession from '../../interfaces/states/ISession';

export const TypesSession = {
  CREATE: 'session/CREATE',
  DESTROY: 'session/DESTROY',
  REPLACE: 'session/REPLACE',
  SET_VINCULO: 'session/SET_VINCULO',
};

// Reducer

const initialState = {
  idCondominoAtual: 0,
  isLogged: false,
  isLoading: false,
  stayConnect: false,
  isManager: true,
  isEmployee: false,
  documentoFuncionario: '',
  todosVinculos: [],
  logoAdm: '',
  descVinculoAtual: 'Classecon',
  isDev: false,
  idVinculo: '',
  idUsuarioClassecon: '',
  hash: '',
  idUsuarioLogado: '',
  tipoUsuario: '',
} as ISession;

export default function reducer(state = initialState, action:IAction) {
  switch (action.type) {
    case TypesSession.CREATE:
      return { ...state, ...action.payload, isLogged: true } as ISession;
    case TypesSession.DESTROY:
      return initialState;
    case TypesSession.REPLACE:
      return action.payload;
    case TypesSession.SET_VINCULO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

// Action Creators

export const ActionsSession = {
  create,
  destroy,
  replace,
  setVinculo,
};

function create(payload:any) {
  return {
    type: TypesSession.CREATE,
    payload,
  } as IAction;
}

function destroy() {
  return {
    type: TypesSession.DESTROY,
  } as IAction;
}

export function replace(session:any) {
  return {
    type: TypesSession.REPLACE,
    payload: session,
  };
}

export function setVinculo(vinculo:any) {
  return {
    type: TypesSession.SET_VINCULO,
    payload: vinculo,
  };
}
