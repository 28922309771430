import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;

  align-items: center;
  justify-content: center;

  z-index: 20;
`;

const slideBottom = keyframes`
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  `;

export const Content = styled.div`
  color: #fff;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${slideBottom} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  border-radius: 12px;
  padding: 46px 56px;
  position: relative;

  button{
      position: absolute;
      right : 16px;
      top : 16px;
  }

  .title-wrapper {
    display: flex;
    align-self : flex-start;
    align-items : center;
  }

  h1 {
    font-family: Montserrat;
    font-weight: Medium;
    color: #4d4e50;
    font-size: 28px;
    margin-left: 49px;
  }

  p {
    font-family: Montserrat;
    font-weight: Medium;
    color: #989898;
    font-size: 22px;
    text-align: center;
    max-width : 544px;
  }

  .separator {
    height: 1px;
    background: #707070;
    margin: 24px 0;
    width : 100%;
    opacity : 0.5;
  }

  z-index: 40;
`;
