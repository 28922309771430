import styled from 'styled-components';

type BoxProps = {
  color: string;
};

export const Container = styled.div`
  display: flex;

  p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    margin-right: 21px;
  }
`;

export const Box = styled.div<BoxProps>`
  width: 20px;
  height: 20px;
  margin-right: 12px;

  background: ${({ color }) => color};
`;
