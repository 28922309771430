import styled from 'styled-components';

export const AsideFlex = styled.aside`
    padding: 0 10px 0 20px;
`;

export const ImagePredial = styled.img`
  width: 417px;
  height: 299px;
  object-fit: contain;

  @media screen and (max-width: 1300px) {
    width: 100%;
    height: 250px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1075px) {
    margin-top: 20px;
  }
`;

export const FlexApp = styled.div`
  display: flex;
  width: 417px;
  height: 151px;
  background-color: #FFFFFF;
  margin-top: 84px;
  border-radius: 0.625rem;
  box-shadow: 0px 3px 20px #00000014;
  position: relative;

  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: static;
    margin-top: 30px;
    padding: 20px;
    padding-bottom: 0;
  }

  @media screen and (max-width: 1075px) {
    margin: 30px auto;
    height: 200px;
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    height: 260px;
    margin-top: 0;
  }

  @media screen and (max-width: 425px) {
    height: fit-content;
    flex-direction: column;
  }
`;

export const FlexText = styled.div`
    padding: 16px;
    padding-right: 140px;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        padding: 0;
    }
`;

export const ImagePhone = styled.img`
  width: 165px;
  height: 216px;
  object-fit: contain;
  position: absolute;
  right: -12px;
  bottom: -4px;

  @media screen and (max-width: 1300px) {
    position: static;
    margin-bottom: -4px;
  }

  @media screen and (max-width: 1075px) {
    height: 180px;
    margin-bottom: -8px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: -32px;
    height: 216px;
  }

  @media screen and (max-width: 425px) {
    margin-bottom: -4px;
  }
`;

export const FlexStores = styled.div`
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    height: 120px;
    justify-content: space-evenly;
  }
`;

export const FlexButton = styled.div`
    display: flex;
    justify-content: center;
    padding: 4px 12px;
    background-color: #484E53;
    border-radius: 4px;
    color: #fff;
    margin: 0 5px;
    cursor: pointer;
    
    @media screen and (max-width: 1300px) {
        padding: 10px 12px;
    }
`;

export const Container = styled.div`
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 30px;

    @media screen and (max-width: 1075px) {
        flex-direction: column;
        justify-content: center;
        padding: 0 15px;
    }
`;

export const BoxMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    padding-left: 16px;

    @media screen and (max-width: 1075px) {
        max-width: unset;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;