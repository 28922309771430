import React, { useEffect } from "react";
import {
    ButtonDeletePhoto, ButtonStopVideo, ButtonTakePhoto,
    CaptureVideo, PictureTaken
} from "./styles";
import { BiTrash } from 'react-icons/bi';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { COLORS } from "../../utils/constants";
import { useWindowSize } from "../../pages/Login";

type Props = {
    videoRef: React.LegacyRef<HTMLVideoElement>;
    photoRef: React.LegacyRef<HTMLCanvasElement>;
    photo: any;
    hasPhoto: boolean;
    videoOpen: boolean;
    mediaStream?: MediaStreamTrack[] | null;
    clearFunction: (width: number, height: number) => void;
    clearCanvas?: () => void;
    snapShotFunction: (photoWidth: number, ratio: number) => void;
    getCameraFunction: (width: number, height: number) => void;
    stopCameraFunction: () => void;
    width: number;
    ratio: number;
}

const Camera: React.FC<Props> = ({
    videoRef,
    photoRef,
    hasPhoto,
    videoOpen,
    mediaStream,
    clearFunction,
    snapShotFunction,
    getCameraFunction,
    stopCameraFunction,
    width,
    ratio
}) => {

    const screen = useWindowSize();

    useEffect(() => {
        getCameraFunction(width, width / ratio);
    }, []);

    useEffect(() => {
        if (hasPhoto) {
            stopCameraFunction();
        }
    }, [hasPhoto]);

    return (
        <CaptureVideo style={screen.sizeWidth > 520 ? { height: (width * 40 / 100) / ratio, width: (width * 40 / 100) } : { height: width / ratio, width: width }}>

            {
                mediaStream && mediaStream !== null &&(
                    <video className="video" ref={videoRef} />

                )
            }

            {
                videoOpen && (
                    <ButtonTakePhoto onClick={() => snapShotFunction(width, ratio)}>
                        Capturar
                    </ButtonTakePhoto>
                )
            }

            {!hasPhoto ? (
                <ButtonStopVideo onClick={() => videoOpen ? stopCameraFunction() : getCameraFunction(width, width / ratio)}>
                    {
                        videoOpen ? (
                            <AiOutlineEyeInvisible
                                size={25}
                                color={COLORS.PRIMARY}
                                style={{ backgroundColor: "rgba(0,0,0,.7)", padding: 2 }}
                            />
                        ) : (
                            <AiOutlineEye
                                size={25}
                                color={COLORS.PRIMARY}
                                style={{ backgroundColor: "rgba(0,0,0,.7)", padding: 2 }}
                            />
                        )
                    }
                </ButtonStopVideo>
            ) : (
                <ButtonDeletePhoto onClick={() => clearFunction(width, width / ratio)}>
                    <BiTrash
                        size={25}
                        color={COLORS.PRIMARY}
                        style={{ backgroundColor: "rgba(0,0,0,.7)", padding: 2 }}
                    />
                </ButtonDeletePhoto>
            )}

            <PictureTaken style={hasPhoto ? { zIndex: 99999 } : { zIndex: 1 }}>
                <canvas ref={photoRef} />
            </PictureTaken>

        </CaptureVideo>
    );
}

export default Camera;