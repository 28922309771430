import styled from 'styled-components';

export const ViewCard = styled.div<{ openProtocol?: boolean }>`
  position: relative;
  width: 48%;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 20px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 48%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const ViewItemCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-around;
  padding-right: 20px;
  width: 100%;

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

export const ViewItemCardDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-around;
  padding-right: 20px;
  width: 100%;

  span {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

export const Title = styled.span`
    font-weight: 700;
`