import axios from 'axios';
import request from '../../services/request';

export const getExpenses = (year:number, month:number) => {
  const request1 = request.get(`listarDespesas/${month}/${year}?tipoDespesa=0`);
  const request2 = request.get(`listarDespesas/${month}/${year}?tipoDespesa=1`);
  const request3 = request.get(`listarDespesas/${month}/${year}?tipoDespesa=2`);

  return axios.all([request1, request2, request3]).then(axios.spread((...responses) => {
    const response0 = responses[0].data.listagem ? responses[0].data.listagem : [];
    const response1 = responses[1].data.listagem ? responses[1].data.listagem : [];
    const response2 = responses[2].data.listagem ? responses[2].data.listagem : [];

    return {
      response0,
      response1,
      response2,
    };
  }));
};
