import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

export const ButtonDefault = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px 8px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  min-width: 150px;
  max-width: 200px;

  ${(attr: any) => attr['data-maxwidth'] && 'width: 100%'}
  ${(attr: any) =>
    attr['data-margintop'] && `margin-top: ${attr['data-margintop']}px;`};
  ${(attr: any) =>
    attr['data-marginhorizontal'] &&
    `margin-left: ${attr['data-marginhorizontal']}px; margin-right: ${attr['data-marginhorizontal']}px`};
  ${(attr: any) =>
    attr['data-marginvertical'] &&
    `margin-top: ${attr['data-marginvertical']}px; margin-bottom: ${attr['data-marginvertical']}px`};
`;

export const ButtonPrimary = styled(ButtonDefault)`
  background-color: ${COLORS.PRIMARY};
  color: #fff;
`;

export const ButtonSecondary = styled(ButtonDefault)`
  background-color: ${COLORS.SECONDARY};
  color: #fff;
`;
