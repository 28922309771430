export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // adicionar o primeiro ponto no primeiro grupo de 3 numeros
    .replace(/(\d{3})(\d)/, '$1.$2') // adicionar o segundo ponto no segundo grupo de 3 numeros
    .replace(/(\d{3})(\d{1,2})/, '$1-$2') // adiciona o hifen antes dos dois ultimos digitos
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos do hifen e não digita mais nada
};

export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
};

export const maskPhone = (v: string) => {
  let r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else if (v.trim() !== '') {
    r = r.replace(/^(\d*)/, '($1');
  }
  return r;
};

export const maskBRL = (value: string) => {
  let res = value;

  res = res + '';
  res = res.replace(/[\D]+/g, '');
  res = res + '';
  res = res.replace(/([0-9]{2})$/g, ',$1');

  if (res.length > 6) {
    res = res.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  value = res;
  if (res === 'NaN') {
    return '';
  }

  return 'R$ ' + res;
};
