import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActionsHeader } from '../../store/ducks/header';

// import { Container } from './styles';

const NotFound = () => {
  const dispatch = useDispatch();
  const history = useHistory(

  );
  React.useEffect(() => {
    dispatch(ActionsHeader.changeTextCenter('Página não encontrada'));
  }, [dispatch, history]);

  return <div>Not found</div>;
};

export default NotFound;
